import { assign, forEach, isArray, every } from 'min-dash';

import { is } from 'bpmn-js/lib/util/ModelUtil';

import { isExpanded, isEventSubProcess } from 'bpmn-js/lib/util/DiUtil';

import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';

import { getChildLanes } from 'bpmn-js/lib/features/modeling/util/LaneUtil';

import { hasPrimaryModifier } from 'diagram-js/lib/util/Mouse';

import history from '@/routes/history';

/**
 * @typedef {import("didi").Injector} Injector
 * @typedef {import("diagram-js/lib/core/EventBus").default} EventBus
 * @typedef {import("diagram-js/lib/features/context-pad/ContextPad").default} ContextPad
 * @typedef {import("bpmn-js/lib/features/Modeling").default} Modeling
 * @typedef {import("bpmn-js/lib/features/ElementFactory").default} ElementFactory
 * @typedef {import("diagram-js/lib/features/connect/Connect").default} Connect
 * @typedef {import("diagram-js/lib/features/create/Create").default} Create
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").default} PopupMenu
 * @typedef {import("diagram-js/lib/features/canvas/Canvas").default} Canvas
 * @typedef {import("diagram-js/lib/features/rules/Rules").default} Rules
 * @typedef {import("diagram-js/lib/i18n/translate/translate").default} Translate
 *
 * @typedef {import("diagram-js/lib//model/Types").Element} Element
 * @typedef {import("diagram-js/lib//model/Types").ModdleElement} ModdleElement
 *
 * @typedef {import("diagram-js/lib/features/context-pad/ContextPadProvider").default<Element>} BaseContextPadProvider
 * @typedef {import("diagram-js/lib/features/context-pad/ContextPadProvider").ContextPadEntries} ContextPadEntries
 * @typedef {import("diagram-js/lib/features/context-pad/ContextPadProvider").ContextPadEntry} ContextPadEntry
 *
 * @typedef { { autoPlace?: boolean; } } ContextPadConfig
 */

/**
 * BPMN-specific context pad provider.
 *
 * @implements {BaseContextPadProvider}
 *
 * @param {ContextPadConfig} config
 * @param {Injector} injector
 * @param {EventBus} eventBus
 * @param {ContextPad} contextPad
 * @param {Modeling} modeling
 * @param {ElementFactory} elementFactory
 * @param {Connect} connect
 * @param {Create} create
 * @param {PopupMenu} popupMenu
 * @param {Canvas} canvas
 * @param {Rules} rules
 * @param {Translate} translate
 */

class ContextPadProvider {
  constructor(
    config,
    injector,
    eventBus,
    contextPad,
    modeling,
    elementFactory,
    connect,
    create,
    popupMenu,
    canvas,
    rules,
    translate
  ) {
    this.contextPad = contextPad;
    this.modeling = modeling;
    this.elementFactory = elementFactory;
    this.connect = connect;
    this.create = create;
    this.popupMenu = popupMenu;
    this.canvas = canvas;
    this.rules = rules;
    this.translate = translate;
    this.eventBus = eventBus;

    config = config || {};

    if (config.autoPlace) {
      this.autoPlace = injector.get('autoPlace', false);
    }

    eventBus.on('create.end', 250, (event) => {
      const context = event.context;
      const shape = context.shape;

      if (!hasPrimaryModifier(event) || !contextPad.isOpen(shape)) {
        return;
      }

      const entries = contextPad.getEntries(shape);

      if (entries.replace) {
        entries.replace.action.click(event, shape);
      }
    });

    contextPad.registerProvider(this);
  }

  getMultiElementContextPadEntries(elements) {
    const actions = {};

    if (this.isDeleteAllowed(elements)) {
      // assign(actions, {
      //   delete: {
      //     group: 'edit',
      //     className: 'bpmn-icon-trash',
      //     title: this.translate('Remove'),
      //     action: {
      //       click: (event, elements) => {
      //         this.modeling.removeElements(elements.slice());
      //       }
      //     }
      //   }
      // });
    }

    return actions;
  }

  isDeleteAllowed(elements) {
    const baseAllowed = this.rules.allowed('elements.delete', {
      elements: elements
    });

    if (isArray(baseAllowed)) {
      return every(baseAllowed, (element) => includes(baseAllowed, element));
    }
    return baseAllowed;
  }

  getContextPadEntries(element) {
    const contextPad = this.contextPad;
    const modeling = this.modeling;
    const elementFactory = this.elementFactory;
    const connect = this.connect;
    const create = this.create;
    const popupMenu = this.popupMenu;
    const rules = this.rules;
    const autoPlace = this.autoPlace;
    const translate = this.translate;
    const eventBus = this.eventBus;

    const actions = {};

    if (element.type === 'label') {
      return actions;
    }

    const businessObject = element.businessObject;

    function startConnect(event, element) {
      connect.start(event, element);
    }

    function removeElement(e, element) {
      eventBus.fire('studio.action.delete', {
        element,
        callback: () => {
          modeling.removeElements([element]);
        }
      });
    }

    function getReplaceMenuPosition(element) {
      const Y_OFFSET = 5;

      const pad = contextPad.getPad(element).html;

      const padRect = pad.getBoundingClientRect();

      const pos = {
        x: padRect.left,
        y: padRect.bottom + Y_OFFSET
      };

      return pos;
    }

    /**
     * Create an append action.
     *
     * @param {string} type
     * @param {string} className
     * @param {string} [title]
     * @param {Object} [options]
     *
     * @return {ContextPadEntry}
     */
    function appendAction(type, className, title, options) {
      const shape = elementFactory.createShape(assign({ type: type }, options));

      if (typeof title !== 'string') {
        options = title;
        title = translate('Append {type}', {
          type: type.replace(/^bpmn:/, '')
        });
      }

      function appendStart(event, element) {
        create.start(event, shape, {
          source: element
        });
      }

      const append = autoPlace
        ? (event, element) => autoPlace.append(element, shape)
        : appendStart;

      return {
        group: 'model',
        className: className,
        title: title,
        action: {
          dragstart: appendStart,
          click: append
        }
      };
    }

    function splitLaneHandler(count) {
      return function (_, element) {
        // actual split
        modeling.splitLane(element, count);

        // refresh context pad after split to
        // get rid of split icons
        contextPad.open(element, true);
      };
    }

    if (
      isAny(businessObject, ['bpmn:Lane', 'bpmn:Participant']) &&
      isExpanded(element)
    ) {
      const childLanes = getChildLanes(element);

      assign(actions, {
        'lane-insert-above': {
          group: 'lane-insert-above',
          className: 'bpmn-icon-lane-insert-above',
          title: translate('Add Lane above'),
          action: {
            click: function (event, element) {
              modeling.addLane(element, 'top');
            }
          }
        }
      });

      if (childLanes.length < 2) {
        if (element.height >= 120) {
          assign(actions, {
            'lane-divide-two': {
              group: 'lane-divide',
              className: 'bpmn-icon-lane-divide-two',
              title: translate('Divide into two Lanes'),
              action: {
                click: splitLaneHandler(2)
              }
            }
          });
        }

        if (element.height >= 180) {
          assign(actions, {
            'lane-divide-three': {
              group: 'lane-divide',
              className: 'bpmn-icon-lane-divide-three',
              title: translate('Divide into three Lanes'),
              action: {
                click: splitLaneHandler(3)
              }
            }
          });
        }
      }

      assign(actions, {
        'lane-insert-below': {
          group: 'lane-insert-below',
          className: 'bpmn-icon-lane-insert-below',
          title: translate('Add Lane below'),
          action: {
            click: function (event, element) {
              modeling.addLane(element, 'bottom');
            }
          }
        }
      });
    }

    if (is(businessObject, 'bpmn:FlowNode')) {
      if (
        !is(businessObject, 'bpmn:EndEvent') &&
        !businessObject.isForCompensation &&
        !isEventType(
          businessObject,
          'bpmn:IntermediateThrowEvent',
          'bpmn:LinkEventDefinition'
        ) &&
        !isEventSubProcess(businessObject)
      ) {
        assign(actions, {
          // 'append.end-event': {
          //   group: 'model',
          //   className: 'bpmn-icon-end-event-none',
          //   title: translate('Append event'),
          //   action: {
          //     click: function (event, element) {
          //       const position = assign(getReplaceMenuPosition(element), {
          //         cursor: { x: event.x, y: event.y }
          //       });
          //       popupMenu.open(
          //         { element, type: 'event' },
          //         'bpmn-append',
          //         position,
          //         {
          //           title: translate('Append event'),
          //           type: 'event',
          //           width: 300
          //         }
          //       );
          //     }
          //   }
          // }
          // 'append.gateway': {
          //   group: 'model',
          //   className: 'bpmn-icon-gateway-none',
          //   title: translate('Append Gateway'),
          //   action: {
          //     click: function (event, element) {
          //       const position = assign(getReplaceMenuPosition(element), {
          //         cursor: { x: event.x, y: event.y }
          //       });
          //       popupMenu.open(
          //         { element, type: 'gateway' },
          //         'bpmn-append',
          //         position,
          //         {
          //           title: translate('Append gateway'),
          //           type: 'gateway',
          //           width: 300
          //         }
          //       );
          //     }
          //   }
          // },
          // 'append.append-action': {
          //   group: 'model',
          //   className: 'bpmn-icon-task',
          //   title: translate('Append Action'),
          //   action: {
          //     click: function (event, element) {
          //       const position = assign(getReplaceMenuPosition(element), {
          //         cursor: { x: event.x, y: event.y }
          //       });
          //       popupMenu.open(
          //         { element, type: 'action' },
          //         'bpmn-append',
          //         position,
          //         {
          //           title: translate('Append action'),
          //           type: 'action',
          //           width: 300
          //         }
          //       );
          //     }
          //   }
          // }
        });
      }
    }

    if (
      isAny(businessObject, [
        'bpmn:FlowNode',
        'bpmn:InteractionNode',
        'bpmn:DataObjectReference',
        'bpmn:DataStoreReference'
      ])
    ) {
      assign(actions, {
        connect: {
          group: 'edit',
          className: 'bpmn-icon-connection-multi',
          title: translate(
            'Connect using ' +
              (businessObject.isForCompensation
                ? ''
                : 'Sequence/MessageFlow or ') +
              'Association'
          ),
          action: {
            click: startConnect,
            dragstart: startConnect
          }
        }
      });
    }

    if (
      isAny(businessObject, [
        'bpmn:Task',
        'bpmn:SequenceFlow',
        'bpmn:MessageFlow'
      ])
    ) {
      assign(actions, {
        replace: {
          group: 'edit',
          className: 'bpmn-icon-screw-wrench',
          title: translate('Edit'),
          action: {
            click: function (_, el) {
              const type = el.id.split('_')[0];
              const id = el.id.substring(el.id.indexOf('_') + 1);
              const processId = location.pathname.split('/')[1];

              if (type === 'Activity') {
                history.push(`/${processId}/editor/p/a/${id}/form`);
              }
              if (type === 'Flow') {
                history.push(`/${processId}/editor/p/t/${id}/condition`);
              }
            }
          }
        }
      });
    }

    if (is(businessObject, 'bpmn:Group')) {
      assign(actions, {
        'append.text-annotation': appendAction(
          'bpmn:TextAnnotation',
          'bpmn-icon-text-annotation',
          translate('Append TextAnnotation')
        )
      });
    }

    // delete element entry, only show if allowed by rules
    let deleteAllowed = rules.allowed('elements.delete', {
      elements: [element]
    });

    if (isArray(deleteAllowed)) {
      // was the element returned as a deletion candidate?
      deleteAllowed = deleteAllowed[0] === element;
    }

    if (deleteAllowed) {
      assign(actions, {
        delete: {
          group: 'edit',
          className: 'bpmn-icon-trash',
          title: translate('Remove'),
          action: {
            click: removeElement
          }
        }
      });
    }

    return actions;
  }
}

ContextPadProvider.$inject = [
  'config.contextPad',
  'injector',
  'eventBus',
  'contextPad',
  'modeling',
  'elementFactory',
  'connect',
  'create',
  'popupMenu',
  'canvas',
  'rules',
  'translate'
];

export default ContextPadProvider;

// helpers /////////

/**
 * @param {ModdleElement} businessObject
 * @param {string} type
 * @param {string} eventDefinitionType
 *
 * @return {boolean}
 */
function isEventType(businessObject, type, eventDefinitionType) {
  const isType = businessObject.$instanceOf(type);
  let isDefinition = false;

  const definitions = businessObject.eventDefinitions || [];
  forEach(definitions, function (def) {
    if (def.$type === eventDefinitionType) {
      isDefinition = true;
    }
  });

  return isType && isDefinition;
}

function includes(array, item) {
  return array.indexOf(item) !== -1;
}
