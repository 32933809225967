import { observer } from 'mobx-react';
import { Database, LucideIcon } from 'lucide-react';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import EndControls from '../../formBuilder/endControls';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import DndInterface, { DndKitValue } from '@atoms/testDndKit/dndInterface';
import { InputField } from '@atoms/input';
import { useAtom } from '@hooks/useAtom';
import { initialRepositoryData } from './repository.data';
import { ResolvedVariableType } from '@/mobx/types/atom.types';

export const RepositoryIcon: LucideIcon = Database;

const RepositoryBlock = (block: DNDBlock) => {
  const dataItem = useAtom({
    block,
    dataType: 'variable',
    initialData: initialRepositoryData,
    library: 'dataRepository',
    sourceId: 'none',
    sourceName: 'Data Repository',
    variableInfo: {
      resolvedType: ResolvedVariableType.List
    }
  });

  if (!dataItem) return null;

  const getEndControls = () => (
    <EndControls
      dndBlock={block}
      dataItem={dataItem}
      hasRequired={false}
    ></EndControls>
  );

  const updateValues = (newValues: DndKitValue[]) => {
    dataItem.data.values = newValues;
  };

  const addValue = (valueToAdd: DndKitValue) => {
    dataItem.data.values.push(valueToAdd);
  };

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={RepositoryIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Repository title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e) => (dataItem.data.title = e.target.value)}
        />
      </Column>
      <Column $width="50%">
        <DndInterface
          values={dataItem.data.values}
          updateValues={updateValues}
          addValue={addValue}
          title="Values"
        />
      </Column>
    </BlockBase>
  );
};

export default observer(RepositoryBlock);
