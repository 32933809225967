import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
}
const Portal = ({ children }: PortalProps): ReactNode => {
  if (typeof document !== 'object') return <></>;
  return createPortal(children, document.body) as ReactNode;
};

export default Portal;
