import styled from 'styled-components';

export const GeneralSettingsInput = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
`;

export const ButtonContainer = styled.footer`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;
