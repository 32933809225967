import { AllBlocks } from '.';
import { FbBlocks } from './formBuilder/formBuilder.type';
import { TOBlocks } from './traceOverview';
import { TIBlocks } from './traceInfo';
import { ConditionBlocks } from './condition/condition.type';
import { DTRBlocks } from './dataRepository';
import { NFBlocks } from './notification';
import {
  AlignJustify,
  AlignLeft,
  AppWindow,
  CalendarDays,
  CheckSquare,
  Database,
  FileUp,
  Hash,
  List,
  LucideIcon,
  Mail,
  MessageCircleMore,
  MessageSquarePlus,
  PanelTopOpen,
  Sheet,
  UserCircle,
  Variable
} from 'lucide-react';
import { GlobalVariablesBlocks } from './globalVariables';

export const iconMap: Record<AllBlocks, LucideIcon> = {
  root: AppWindow,
  [FbBlocks.CheckBox]: CheckSquare,
  [FbBlocks.Date]: CalendarDays,
  [FbBlocks.DropDown]: PanelTopOpen,
  [FbBlocks.FileUpload]: FileUp,
  [FbBlocks.Number]: Hash,
  [FbBlocks.RichText]: MessageSquarePlus,
  [FbBlocks.TextField]: AlignLeft,
  [FbBlocks.Comment]: MessageCircleMore,

  [TOBlocks.Date]: CalendarDays,
  [TOBlocks.File]: FileUp,
  [TOBlocks.List]: List,
  [TOBlocks.Number]: Hash,
  [TOBlocks.Text]: AlignLeft,
  [TOBlocks.User]: UserCircle,

  [TIBlocks.Date]: CalendarDays,
  [TIBlocks.File]: FileUp,
  [TIBlocks.List]: List,
  [TIBlocks.Number]: Hash,
  [TIBlocks.Text]: AlignLeft,
  [TIBlocks.DataTab]: Sheet,
  [TIBlocks.Comment]: MessageCircleMore,
  [TIBlocks.Window]: AppWindow,

  [ConditionBlocks.Condition]: AlignJustify,
  [ConditionBlocks.CodeCondition]: Variable,
  [ConditionBlocks.Or]: AppWindow,
  [ConditionBlocks.And]: AppWindow,
  [ConditionBlocks.Not]: AppWindow,

  [DTRBlocks.DataRepository]: Database,
  [GlobalVariablesBlocks.Variable]: Variable,

  [NFBlocks.Email]: Mail
} as const;
