import PaletteModule from "diagram-js/lib/features/palette";
import CreateModule from "diagram-js/lib/features/create";
import SpaceToolModule from "bpmn-js/lib/features/space-tool";
import LassoToolModule from "diagram-js/lib/features/lasso-tool";
import HandToolModule from "diagram-js/lib/features/hand-tool";
import GlobalConnectModule from "diagram-js/lib/features/global-connect";
import translate from "diagram-js/lib/i18n/translate";
import PopupMenuModule from "bpmn-js/lib/features/popup-menu";


import CustomPaletteProvider from "./CustomPaletteProvider";

export default {
  __depends__: [
    PaletteModule,
    CreateModule,
    SpaceToolModule,
    LassoToolModule,
    HandToolModule,
    GlobalConnectModule,
    translate,
    PopupMenuModule
  ],
  __init__: [ "paletteProvider" ],
  paletteProvider: [ "type", CustomPaletteProvider ]
};
