import RootStore from './root.store';
import { VariableValues } from '@hooks/useVariables';

export type VariableState = Map<string, VariableValues[]>;

export interface Variable {
  id: number;
  name: string;
  type: string;
}

export class VariablesStore {
  constructor(public rootStore: RootStore) {}
}
