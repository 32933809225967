import { DNDBlock } from '@components/dnd/base';
import useProcess from './useProcess';
import useStores from './useStore';
import { VariableValueOrigin } from '@/types/variables';
import { VariableValues } from './useVariables';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import AtomStore from '@/mobx/store/atom.store';
import { newError } from '@/services/errors/errors';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';

const parseDataRepoVariables = (
  dnd: DNDBlock,
  atomStore: AtomStore
): Maybe<VariableValues[]> => {
  const dataRepoBlocks = dnd.props?.root as DNDBlock[];

  if (!dataRepoBlocks) {
    newError('DataRepoBlocks state is undefined', false);
    return undefined;
  }

  const variables: VariableValues[] = [];

  dataRepoBlocks.forEach((block) => {
    const atom = atomStore.getAtomById<{ title: string }>(
      block.atomId,
      block.type
    );

    if (!atom || atom instanceof Error) {
      newError('atom should be defined', false);
      return;
    }

    variables.push({
      computedType: ResolvedVariableType.Text,
      id: block.id,
      actionId: '',
      name: atom.data.title,
      atomId: atom.id,
      type: block.type,
      path: '',
      origin: VariableValueOrigin.DataRepository,
      possibleValues: atom.possibleValues
    });
  });

  return variables;
};

export type VariableRepoInfos = {
  name: string;
  values: string[];
};
export type DataRepoVariables = Map<string, DataItemReference>;

export const useDataRepositoryVariables = (): DataRepoVariables => {
  const { process } = useProcess();
  const { dndStore, atomStore } = useStores();

  const dataRepoDND = dndStore.get(process?.dataRepositoryDndId);

  if (!process?.dataRepositoryDndId || !dataRepoDND?.state) {
    return new Map();
  }

  const variables: Maybe<VariableValues[]> = parseDataRepoVariables(
    dataRepoDND.state,
    atomStore
  );

  if (!variables) {
    return new Map();
  }

  const dataRepoVariablesMap: DataRepoVariables = new Map();
  variables.forEach((variable) => {
    dataRepoVariablesMap.set(variable.name, {
      dataItemId: variable.atomId,
      blockType: variable.type,
      sourceId: ''
    });
  });
  return dataRepoVariablesMap;
};
