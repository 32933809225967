type Epoch = [string, number];
interface Duration {
  interval: number;
  epoch: string;
}

const epochs: Epoch[] = [
  ['year', 31536000],
  ['month', 2592000],
  ['day', 86400],
  ['hour', 3600],
  ['minute', 60],
  ['second', 1]
];

/**
 * Takes a number representing a time in seconds and returns an object
 * containing the largest time interval and its corresponding epoch.
 */
const getDuration = (timeAgoInSeconds: number): Duration => {
  for (const [name, seconds] of epochs) {
    const interval = Math.floor(timeAgoInSeconds / seconds);
    if (interval >= 1) {
      return {
        interval: interval,
        epoch: name
      };
    }
  }
  return {
    interval: 0,
    epoch: 'seconds'
  };
};

/**
 * Calculates the time difference between a given date and the current date, and
 * returns a string representation of the time elapsed.
 */
export const getTimeAgo = (date?: string) => {
  if (!date) {
    console.warn('getTimeAgo: date is undefined');
    return '';
  }

  const dateDiff = new Date().getTime() - new Date(date).getTime();
  const timeAgoInSeconds: number = Math.floor(dateDiff / 1000);
  const { interval, epoch } = getDuration(timeAgoInSeconds);
  const pluralSuffix = interval > 1 ? 's' : '';
  return `${interval} ${epoch}${pluralSuffix} ago`;
};
