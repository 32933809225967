import { observer } from 'mobx-react';
import { MonacoEditor } from '@components/monaco/monacoEditor.code';
import useAction from '@hooks/useAction';
import useStore from '@hooks/useStore';
import { OnChange } from '@monaco-editor/react';
import { toJS } from 'mobx';
import useProcess from '@hooks/useProcess';
import { motion } from 'framer-motion';

export const CodePage = observer(() => {
  const { codeStore, processStore } = useStore();
  const action = useAction();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const process = useProcess(); // sets the current process in the processStore

  if (!processStore.currentProcessId) return <div>Loading...</div>;

  const codeModel = action?.codeModel;
  if (codeStore.lastCodeModel?.id !== codeModel?.id) {
    codeStore.lastCodeModel = codeModel;
  }

  codeStore.lastCodeModel = codeModel;

  if (!codeModel) return <></>;

  const onCodeChange: OnChange = (value) => {
    if (!value) return;
    codeModel.code = value;
  };

  return (
    <motion.div
      className="z-40 h-full w-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <MonacoEditor
        modelId={action.traceKey.value}
        preCode={toJS(codeModel.preCode)}
        code={toJS(codeModel.code)}
        onCodeChange={onCodeChange}
        handleMonacoErrors={(monacoErrors) => codeModel.setErrors(monacoErrors)}
      />
    </motion.div>
  );
});
