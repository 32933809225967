import { DndKitValuesSchema, TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const CheckBoxTypeSchema = z.enum(['checkbox', 'radio']);
export type CheckBoxType = z.infer<typeof CheckBoxTypeSchema>;

export const DataSourceOptionSchema = z.enum([
  'normalSource',
  'dataRepository'
]);

export type DataSourceOption = z.infer<typeof DataSourceOptionSchema>;

const NormalSourceSchema = z.object({
  normalSource: z.object({
    values: DndKitValuesSchema
  })
});

const DataRepositorySchema = z.object({
  dataRepository: z.object({
    selectedDataRepository: z.string().optional()
  })
});

export const CheckBoxDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      checkboxType: CheckBoxTypeSchema,
      dataSource: DataSourceOptionSchema,
      dataSourceData: NormalSourceSchema.merge(DataRepositorySchema).strict()
    })
    .merge(PrefillDataSchema)
    .strict();

export type CheckBoxData = z.infer<ReturnType<typeof CheckBoxDataSchema>>;

export const initialCheckBoxData: CheckBoxData = {
  title: 'Default CheckBox Title',
  required: true,
  checkboxType: 'checkbox',
  dataSource: 'normalSource',
  dataSourceData: {
    normalSource: { values: [] },
    dataRepository: {}
  }
};
