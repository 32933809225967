import { useState } from 'react';
import { VariableContainer, VariableValue } from './style';
import { Pencil } from 'lucide-react';
import { Modal, ModalClose } from '@mui/joy';
import StateMenu from '@components/stateMenu';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';

interface ActionButtonProps {
  onSelected?: (values: DataItemReference) => void;
  value: string | undefined;
  placeholder?: string;
  disabled?: boolean;
}

const VariableField = ({
  value,
  placeholder = 'Select a variable',
  disabled = false,
  onSelected
}: ActionButtonProps) => {
  const [isStateMenuOpen, setStateMenuOpen] = useState<boolean>(false);

  return (
    <>
      <VariableContainer
        onClick={() => {
          if (!onSelected || disabled) return;
          setStateMenuOpen(true);
        }}
        $disabled={disabled}
        data-tag="variable-field"
      >
        <VariableValue>{value ?? placeholder}</VariableValue>
        {!disabled && <Pencil size={16} color="#A4A4A4" />}
      </VariableContainer>
      {onSelected && (
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={isStateMenuOpen}
          onClose={() => setStateMenuOpen(false)}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '70px'
          }}
        >
          <>
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <StateMenu
              onSelected={onSelected}
              onClose={() => setStateMenuOpen(false)}
            />
          </>
        </Modal>
      )}
    </>
  );
};

export default VariableField;
