import { DNDModel } from '@/mobx/models/dnd.model';
import RootStore from './root.store';
import BaseStore from './base/base.store';
import { DNDBlock } from '@components/dnd/base';
import { DndLibrary } from '@components/dnd/library';
import { z } from 'zod';

const StateSchema: z.ZodType<DNDBlock> = z.any(); //! bad

export const LoadedDndModelSchema = z.object({
  id: z.string(),
  state: StateSchema,
  created_at: z.string().datetime(),
  updated_at: z.string().datetime()
});

export type LoadedDndModel = z.infer<typeof LoadedDndModelSchema>;

export class DNDStore extends BaseStore<DNDModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, DNDModel, 'dnd');
  }

  createNewDndModel(
    library: DndLibrary,
    newDndModelId?: string,
    state?: DNDBlock,
    loading?: boolean
  ): DNDModel {
    const newDndModel = new DNDModel(
      this,
      library,
      newDndModelId,
      state,
      loading
    );

    this.set(newDndModel.id, newDndModel);
    return newDndModel;
  }

  createDndFromLoaded(library: DndLibrary, loaded: LoadedDndModel): DNDModel {
    return this.createNewDndModel(library, loaded.id, loaded.state);
  }
}
