import { ReactNode } from 'react';
import { ShortcutContainer, ShortcutTile } from './shortcut.style';

type ShortcutHelperProps = {
  value: string[] | string | ReactNode;
};

const ShortcutHelper = ({ value }: ShortcutHelperProps) => {
  if (Array.isArray(value)) {
    return (
      <ShortcutContainer>
        {value.map((letter, index: number) => (
          <ShortcutTile key={index} $isMultiple>
            {letter}
          </ShortcutTile>
        ))}
      </ShortcutContainer>
    );
  }

  return <ShortcutTile>{value}</ShortcutTile>;
};

export default ShortcutHelper;
