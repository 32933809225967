export interface StateSourceData {
  t: string;
}

// TODO

const StateSourceOption = () => {
  return <p>Normal Source</p>;
};

export default StateSourceOption;
