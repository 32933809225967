import { observer } from 'mobx-react';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';
import { InputField } from '@atoms/input';
import { DataSourceOptionInfo, dataSourceOptions } from './dataSource.utils';
import {
  DataRepositoryOption,
  NormalSourceOption,
  StateSourceOption
} from './options';
import { DropDownModal } from './dropDown.modal';
import { useAtom } from '@hooks/useAtom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { initialDropDownData } from './dropDown.data';
import { DropDownIcon, isCorrectSource } from './dropDown.utils';
import { newError } from '@/services/errors/errors';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

const DropDown = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialDropDownData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.DropDown} title`,
      content: <DropDownModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  const renderDataSourceOptionComponent = (): JSX.Element => {
    switch (dataItem.data.dataSource) {
      case 'normalSource':
        return <NormalSourceOption dataItem={dataItem} />;
      case 'dataRepository':
        return <DataRepositoryOption dataItem={dataItem} />;
      case 'stateSource':
        return <StateSourceOption />;
      default:
        newError('Invalid data source option', true);
        return <></>;
    }
  };

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={DropDownIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <FormControl size="sm">
          <FormLabel>Drop down values source</FormLabel>
          <RadioGroup
            value={dataItem.data.dataSource}
            name="radio-buttons-group"
          >
            {dataSourceOptions.map((option: DataSourceOptionInfo) => (
              <Radio
                key={option.value}
                value={option.value}
                onChange={(e) => {
                  if (isCorrectSource(e.target.value)) {
                    dataItem.data.dataSource = e.target.value;
                  }
                }}
                label={option.name}
                color="neutral"
                size="sm"
                disabled={option.isDisabled ?? false}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>

      <Column $width="220px">{renderDataSourceOptionComponent()}</Column>
    </BlockBase>
  );
};

export default observer(DropDown);
