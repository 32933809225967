import { TabLinks } from '@pages/panel/PanelHeader/panelHeader.types';
// import FunctionX from '@/assets/svg/functionx.svg?react';
import { CustumCodePage, FormBuilder, SettingsPage } from './pages';

export const enum ActionPath {
  Form = 'form',
  Calcul = 'calcul',
  Code = 'code',
  Settings = 'settings'
}

export const ACTION_TAB_LINKS: TabLinks[] = [
  {
    content: 'Form Builder',
    path: ActionPath.Form
  },
  {
    content: 'Code',
    path: ActionPath.Code
  },
  {
    content: 'Settings',
    path: ActionPath.Settings
  }
];

export const ACTION_PATHS_TO_FC_MAP: Record<string, React.ReactNode> = {
  [ActionPath.Form]: <FormBuilder />,
  [ActionPath.Calcul]: <div>calcul</div>,
  [ActionPath.Code]: <CustumCodePage />,
  [ActionPath.Settings]: <SettingsPage />
};
