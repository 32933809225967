import styled from 'styled-components';

export const DefaultPage = styled.div`
  padding: 5rem;
  padding-top: 2rem;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  background-color: ${(p) => p.theme.verRail.background.page};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;
