import Header from '@components/library/header';
import AccountService, { User } from '@/services/account/account.service';
import { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import ReferencesModal from '@components/referencesModal';

type ContextType = { user: User; loadingUser: boolean };

export const Layout = () => {
  const [user, setUser] = useState<Maybe<User>>();
  const [loadingUser, setLoadingUser] = useState(true);
  useEffect(() => {
    const loadUser = async (): Promise<void> => {
      const user: Maybe<User> = await AccountService.getMe();
      setUser(user);
      setLoadingUser(false);
    };

    void loadUser();
  }, []);

  const pathname = window.location.pathname;
  const isDraftEditor = pathname.endsWith('draft-editor');
  if (!user && !isDraftEditor) return <></>;

  return (
    <>
      {user && <Header user={user} />}
      <ReferencesModal />
      <main>
        <Outlet context={{ user, loadingUser }} />
      </main>
    </>
  );
};

export function useUser() {
  return useOutletContext<ContextType>();
}

export default Layout;
