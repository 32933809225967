import { NotificationTemplateModel } from '@/mobx/models/notificationTemplate.model';
import RootStore from './root.store';
import AsyncStore from './base/async.store';
import { parseWithZod } from '@/utils/parseZodSchema';
import {
  NotificationTemplatesLoaded,
  NotificationTemplatesLoadedSchema
} from '../types/notification.types';
import { CustomElement } from '@pages/Settings/notifications/editor/slate';

export class NotificationTemplateStore extends AsyncStore<NotificationTemplateModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, NotificationTemplateModel, 'notificationTemplates');
  }

  loadNotificationTemplates(
    notificationTemplates: NotificationTemplatesLoaded[]
  ): Maybe<NotificationTemplateModel[]> {
    const loadedNotificationTemplates = parseWithZod(
      NotificationTemplatesLoadedSchema,
      notificationTemplates
    );

    if (!loadedNotificationTemplates) return;

    return loadedNotificationTemplates.map((template) => {
      const notificationTemplate = new NotificationTemplateModel(
        this,
        template.id,
        template.title,
        template.text,
        new Date(template.created_at),
        new Date(template.updated_at),
        template.process_id
      );

      this.set(notificationTemplate.id, notificationTemplate);
      return notificationTemplate;
    });
  }

  async createNewNotificationTemplate(
    title: string,
    text: CustomElement[],
    processId: string
  ) {
    const newNotificationTemplate = this.create();
    newNotificationTemplate.title = title;
    newNotificationTemplate.text = text;
    newNotificationTemplate.templateProcessId = processId;
    newNotificationTemplate.createdAt = new Date();
    newNotificationTemplate.updatedAt = new Date();

    const dto = {
      id: newNotificationTemplate.id,
      title: newNotificationTemplate.title,
      text: newNotificationTemplate.text,
      processId: newNotificationTemplate.templateProcessId,
      createdAt: newNotificationTemplate.createdAt,
      updatedAt: newNotificationTemplate.updatedAt
    };

    return await this.createNew(dto);
  }
}
