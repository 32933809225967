import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_LIST_VIEW_TYPES = ['List Compact', 'List'] as const;
export const TO_ListViewTypeSchema = z.enum(TO_LIST_VIEW_TYPES);

export const TO_ListDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_ListViewTypeSchema
    })
    .strict();

export type TO_ListData = z.infer<ReturnType<typeof TO_ListDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTOListData: Omit<TO_ListData, 'selectedVariable'> = {
  title: 'Default List Title',
  viewType: 'List Compact'
};
