import { toast } from 'sonner';
import { getIdFromBpmnId } from '../../bpmn.utils';
import { CreateTransitionEvent } from '../event.types';
import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import RootStore from '@/mobx/store/root.store';

export const onTransitionCreated = (
  event: CreateTransitionEvent,
  rootStore: RootStore
) => {
  // TODO: check if the flow is actually a transition (action -> action)
  // ? probably need a plexer that handles lots of cases in the future

  const transitionId = event.context.connection?.id;
  const fromActionId = event.context.source?.id;
  const toActionId = event.context.target?.id;

  if (!transitionId || !fromActionId || !toActionId) {
    return; // ? is simply returning a good idea ? the "flow" will be still be saved in the XML
  }

  rootStore.transitionStore
    .createNewTransition(
      getIdFromBpmnId(transitionId),
      getIdFromBpmnId(fromActionId),
      getIdFromBpmnId(toActionId)
    )
    .catch((error: unknown) => {
      newError(error);
      return;
    });
};

export const onTransitionDeleted = async (
  event: CreateTransitionEvent,
  rootStore: RootStore
): Promise<void> => {
  // cascade delete, the database will handle the rest
  if (event.context.hints?.nested) return;

  const flowId = event.context.connection?.id;
  if (!flowId) return;

  const transitionId = getIdFromBpmnId(flowId);
  const transitionStore = rootStore.transitionStore;
  return transitionStore
    .deleteById(transitionId)
    .then(() => {
      if (['local', 'staging'].includes(ENV)) {
        toast.info(`Transition ${transitionId} deleted`);
      }
    })
    .catch((e) => {
      newError(e, true, {
        errorType: 'error',
        isConsoleLogged: true
      });
    });
};
