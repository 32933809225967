import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { InputField } from '@atoms/input';
import { FbBlocks } from '../../formBuilder.type';
import {
  CheckBoxIcon,
  CheckBoxTypeOption,
  checkBoxTypeOptions,
  isCorrectCheckBoxType,
  isCorrectSource
} from './checkbox.utils';
import { CheckBoxModal } from './checkbox.modal';
import { useAtom } from '@hooks/useAtom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { initialCheckBoxData } from './checkbox.data';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';
import { DataRepositoryOption, NormalSourceOption } from './options';
import { newError } from '@/services/errors/errors';
import { dataSourceOptions } from './dataSource.utils';

const CheckBoxField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialCheckBoxData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      //? will change depending on checkbox type
      resolvedType: ResolvedVariableType.List
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.CheckBox} title`,
      content: <CheckBoxModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  const renderDataSourceOptionComponent = (): JSX.Element => {
    switch (dataItem.data.dataSource) {
      case 'normalSource':
        return <NormalSourceOption dataItem={dataItem} />;
      case 'dataRepository':
        return <DataRepositoryOption dataItem={dataItem} />;
      default:
        newError('Invalid data source option', true);
        return <></>;
    }
  };

  const onCheckBoxTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isCorrectCheckBoxType(e.target.value)) return;
    const newCheckBoxType = e.target.value;
    dataItem.data.checkboxType = newCheckBoxType;
    if (newCheckBoxType === 'radio') {
      // @ts-expect-error at this point, we know that variableInfo is defined
      dataItem.variableInfo.resolvedType = ResolvedVariableType.Text;
    } else {
      // @ts-expect-error at this point, we know that variableInfo is defined
      dataItem.variableInfo.resolvedType = ResolvedVariableType.List;
    }
  };

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={CheckBoxIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column $width="100px">
        <FormControl size="sm">
          <FormLabel>Checkbox type</FormLabel>
          <RadioGroup
            value={dataItem.data.checkboxType}
            name="radio-buttons-group"
          >
            {checkBoxTypeOptions.map((option: CheckBoxTypeOption) => (
              <Radio
                key={option.value}
                value={option.value}
                onChange={onCheckBoxTypeChange}
                label={option.name}
                color="neutral"
                size="sm"
                disabled={option.isDisabled ?? false}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>

      <Column>
        <FormControl size="sm">
          <FormLabel>Checkbox values source</FormLabel>
          <RadioGroup
            value={dataItem.data.dataSource}
            name="radio-buttons-group"
          >
            {dataSourceOptions.map((option) => (
              <Radio
                key={option.value}
                value={option.value}
                onChange={(e) => {
                  if (isCorrectSource(e.target.value)) {
                    dataItem.data.dataSource = e.target.value;
                  }
                }}
                label={option.name}
                color="neutral"
                size="sm"
                disabled={option.isDisabled ?? false}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>

      <Column $width="220px">{renderDataSourceOptionComponent()}</Column>
    </BlockBase>
  );
};

export default observer(CheckBoxField);
