import { forwardRef, PropsWithChildren, Ref } from 'react';
import styled from 'styled-components';
import { BaseProps } from './type';

const StyledMenu = styled.div<BaseProps>`
  position: absolute;
  display: flex;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  padding: 2px;
  border-radius: 5px;
  background-color: #FFF;
  opacity: 0;
  transition: opacity 0.75s;
  box-shadow: ${({ theme }) => theme.verRail.boxShadow};

  & > * {
    display: inline-block;
  }

  & > * + * {
    margin-left: 15px;
  }
`;

const Menu = forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLElement>
  ) => {
    return <StyledMenu {...props} ref={ref} className={className} />;
  }
);

export default Menu;
