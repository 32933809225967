import { z } from 'zod';
import { AtomModel } from '../atom.model';

export type AtomWithTitle<T = unknown> = AtomModel<T & { title: string }>;

export enum DynamicType {
  FormData = 'TraceFormData',
  StateData = 'TraceStateData',
  Lanes = 'TraceLanes',
  Action = 'TraceAction',
  GlobalState = 'TraceGlobalState'
}

export enum StaticType {
  FormFile = 'FormFile',
  Email = 'Email',
  URL = 'URLType',
  ToBeDetermined = 'ToBeDetermined',
  Date = 'Date',
  DateTime = 'DateTime',
  ActionComment = 'ActionComment'
}

export const UpdateCodeDTOSchema = z
  .object({
    code: z.string(),
    transpiled_code: z.string().nullable(),
    isActive: z.boolean()
  })
  .strict();

export const LoadedCodeDataSchema = UpdateCodeDTOSchema.merge(
  z.object({
    id: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime()
  })
).strict();

export type LoadedCodeData = z.infer<typeof LoadedCodeDataSchema>;
export type UpdateCodeDTO = z.infer<typeof UpdateCodeDTOSchema>;
