import { Fragment } from 'react';
import DocumentIcon from '@/assets/svg/document.svg?react';
import { HeaderContainer, Navbar, StyledNavLink } from './panelHeader.style';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, MoveRight, X } from 'lucide-react';
import { TabLinks } from './panelHeader.types';
import { ChipLabel } from '@components/library/chipLabel';

interface HeaderProps {
  workflowName: string;
  infos: string[];
  actionType: string;
  tabLinks?: TabLinks[];
}
const ICON_SIZE = '0.8em';
const PanelHeader = ({
  workflowName,
  infos,
  actionType,
  tabLinks = []
}: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <section className="flex min-h-0 w-full grow items-start justify-between">
        <div className="flex flex-row items-center gap-3 text-[15px] text-gray-dark">
          <i className="inline-flex select-none items-center rounded-[3px] p-[5px] text-center outline outline-1 outline-light">
            <DocumentIcon />
          </i>
          <div>{workflowName}</div>
          <ChevronRight size={ICON_SIZE} />
          {infos.map((info, index) => (
            <Fragment key={index}>
              <div> {info} </div>
              {index < infos.length - 1 && <MoveRight size={ICON_SIZE} />}
            </Fragment>
          ))}
          <ChipLabel>{actionType}</ChipLabel>
        </div>
        <div
          className="group absolute right-3 top-3 flex cursor-pointer items-center justify-center rounded-full p-6"
          onClick={() => navigate('../..')}
        >
          <X size={19} className="text-gray-700 group-hover:text-gray-800" />
        </div>
      </section>
      <Navbar>
        <ul>
          {tabLinks.map((tabLink, index) => (
            <li key={index}>
              <StyledNavLink to={tabLink.path} $fontFamily={tabLink.fontFamily}>
                {tabLink.content}
              </StyledNavLink>
            </li>
          ))}
        </ul>
      </Navbar>
    </HeaderContainer>
  );
};

export default PanelHeader;
