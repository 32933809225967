import { makeObservable, observable, reaction } from 'mobx';
import BaseStore from '@/mobx/store/base/base.store';
import { WorkflowModel } from './workflow.model';
import { DNDModel } from './dnd.model';
import { TransitionModel } from './transition.model';
import { ActionType } from '@/mobx/store/action.store';
import { newError } from '@/services/errors/errors';
import { CodeModel } from '@/mobx/models/code/code.model';
import { TraceKeyDTO } from './traceKey/schema';
import { BaseModelWithTraceKey } from './base/baseWithKey.model';
import { ModelError } from './base/base.model';
import { DndLibrary } from '@components/dnd/library';

export class BaseActionModel extends BaseModelWithTraceKey {
  public static DEFAULT_ACTION_NAME: string = 'New action';

  constructor(
    store: BaseStore<BaseActionModel>,
    id: string,
    traceKeyDTO: TraceKeyDTO,
    public actionType: ActionType,
    public workflow_id: WorkflowModel['id'],
    public formDndId: DNDModel['id'],
    public codeDndId: DNDModel['id'],
    public effectsDndId: DNDModel['id'],
    public name: string = BaseActionModel.DEFAULT_ACTION_NAME,
    public codeModelId: CodeModel['id'],
    public icon: string = 'icon'
  ) {
    const isLoading = false;
    super(store, id, traceKeyDTO, isLoading);

    // todo regarder le nesting des class
    makeObservable(this, {
      name: observable,
      icon: observable
    });

    reaction(
      () => this.name,
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError(error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => this.traceKey.toJSON,
      () => {
        this.store.update(this.id).catch((error: Error) => {
          newError(error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => this.name,
      () => {
        this.traceKey.followName(this.name);
        const bpmn = this.store.rootStore.processStore?.currentProcess?.bpmn;
        if (!bpmn?.modeler) return;
        bpmn.changeElementName(this.name || '');
      },
      {
        delay: 50
      }
    );
  }

  public get formDnd(): Maybe<DNDModel<DndLibrary.FormBuilder>> {
    return this.store.rootStore.dndStore.get(
      this.formDndId
    ) as DNDModel<DndLibrary.FormBuilder>;
  }

  /** @deprecated */
  public get codeDnd(): Maybe<DNDModel<DndLibrary.CodeEditor>> {
    return this.store.rootStore.dndStore.get(
      this.codeDndId
    ) as DNDModel<DndLibrary.CodeEditor>;
  }

  /** @deprecated */
  public get effectsDnd(): Maybe<DNDModel<DndLibrary.EffectsBuilder>> {
    return this.store.rootStore.dndStore.get(
      this.effectsDndId
    ) as DNDModel<DndLibrary.EffectsBuilder>;
  }

  public get workflow(): Maybe<WorkflowModel> {
    return this.store.rootStore.workflowStore.get(this.workflow_id);
  }

  public get incomingTransitions(): TransitionModel[] {
    const allTransitions = this.store.rootStore.transitionStore.toArray();
    return allTransitions.filter((transition) => {
      return transition.actionToId === this.id;
    });
  }

  public get outgoingTransitions(): TransitionModel[] {
    const allTransitions = this.store.rootStore.transitionStore.toArray();
    return allTransitions.filter((transition) => {
      return transition.actionFromId === this.id;
    });
  }

  public get previousActions(): Maybe<BaseActionModel>[] {
    return this.incomingTransitions.map((transition) => {
      return transition.actionFrom;
    });
  }

  public get nextActions(): Maybe<BaseActionModel>[] {
    return this.outgoingTransitions.map((transition) => {
      return transition.actionTo;
    });
  }

  public get codeModel(): Maybe<CodeModel> {
    return this.store.rootStore.codeStore.get(this.codeModelId);
  }

  public async delete(): Promise<boolean> {
    const dndStore = this.store.rootStore.dndStore;

    const formDnd = dndStore.get(this.formDndId);
    const codeDnd = dndStore.get(this.codeDndId);
    const effectsDnd = dndStore.get(this.effectsDndId);

    if (!formDnd || !codeDnd || !effectsDnd) {
      newError(
        `Error while deleting action "${this.id}" one of the DND is missing: formDnd: "${formDnd?.id}", codeDnd: "${codeDnd?.id}", effectsDnd: "${effectsDnd?.id}"`,
        true,
        {
          customMessage: 'Error while deleting the action'
        }
      );
      return false;
    }

    /* ------------------------ Delete the action itself ------------------------ */

    const isActionDeleted = await this.store.delete(this.id);
    if (!isActionDeleted) return false;

    /* ------------------- Delete action's DNDs in store only ------------------- */

    await dndStore.delete(this.effectsDndId, false);
    await dndStore.delete(this.codeDndId, false);
    await dndStore.delete(this.formDndId, false);

    return true;
  }

  get toJSON() {
    return {
      name: this.name,
      icon: this.icon,
      traceKey: this.traceKey.toJSON
    };
  }

  /** Checks if the action is the source of non deletable atoms. */
  get isDeletable(): boolean {
    return this.store.rootStore.atomStore.allReferencableAtoms
      .filter((atom) => atom.metaInfo.source.id === this.id)
      .map((a) => a.isDeletable)
      .every((a) => a);
  }

  get errors(): ModelError[] {
    return [];
  }
}
