import { newError } from '@/services/errors/errors';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Typography
} from '@mui/joy';
import { EyeOff, Eye } from 'lucide-react';
import { observer } from 'mobx-react';
import { useState } from 'react';
import { Info } from 'lucide-react';
import useStores from '@hooks/useStore';
import { redirectToAccountUILogin } from '@/services/account/account.service';

interface PasswordProtectedModalProps {
  closeModal: () => void;
  processId: string;
}

const PasswordProtectedModal = ({
  closeModal,
  processId
}: PasswordProtectedModalProps) => {
  const { processStore } = useStores();

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [forbidden, setForbidden] = useState(false);

  const loadLink = async () => {
    try {
      const response = await processStore.fetchPasswordProtectedProcess(
        processId,
        password
      );

      if (response) {
        closeModal();
      }
    } catch (error) {
      setForbidden(true);
      newError('Incorrect password', true);
    }
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={true}
      disableEscapeKeyDown
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'none',
        zIndex: 100001
      }}
    >
      <ModalDialog>
        <DialogTitle>Password protected</DialogTitle>
        <Divider />

        <DialogContent>
          <Typography>To view this link, please enter the password</Typography>
        </DialogContent>

        <FormControl error={forbidden}>
          <Input
            type={!showPassword ? 'password' : 'text'}
            onChange={(event) => {
              setPassword(event.target.value);

              if (forbidden) {
                setForbidden(false);
              }
            }}
            placeholder="Enter password"
            endDecorator={
              <IconButton onClick={() => setShowPassword(!showPassword)}>
                {!showPassword ? <EyeOff size={15} /> : <Eye size={15} />}
              </IconButton>
            }
            error={forbidden}
          />
          {forbidden && (
            <FormHelperText>
              <Info size={14} style={{ marginRight: 5 }} />
              Incorrect password
            </FormHelperText>
          )}
        </FormControl>
        <DialogActions
          orientation="horizontal"
          sx={{ justifyContent: 'center' }}
        >
          <Button
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={loadLink}
            disabled={password.length === 0}
          >
            View link
          </Button>

          <Button variant="outlined" onClick={() => redirectToAccountUILogin()}>
            Go back
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
export default observer(PasswordProtectedModal);
