import { StaticType } from '@/mobx/models/code/types';
import { TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';

export const TO_TEXT_VIEW_TYPES = ['Text', 'Code', 'Link', 'Prose'] as const;
export const TO_TextViewTypeSchema = z.enum(TO_TEXT_VIEW_TYPES);

export const TO_TextIconSchema = z.enum([
  'Text',
  'Link',
  'Code',
  'ScrollText',
  'Pilcrow'
]);

export type TO_TextIconType = z.infer<typeof TO_TextIconSchema>;

export const ALL_TYPE_KIND = ['normal', 'list', 'custom'] as const;

export const PRIMARY_TYPES = [
  'string',
  'number',
  'boolean',
  StaticType.Email,
  StaticType.URL,
  StaticType.FormFile
] as const;

export type PrimaryType = (typeof PRIMARY_TYPES)[number];
export type AllKindOfType = (typeof ALL_TYPE_KIND)[number];

const VariableTypeSchema = z.object({
  selectedKind: z.enum(ALL_TYPE_KIND),
  type: z.enum(PRIMARY_TYPES),
  customType: z.string()
});

export const GlobalVariableDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      variableType: VariableTypeSchema,
      defaultValue: z.any().optional(),
      description: z.string().optional()
    })
    .strict()
    .refine(
      (data) => {
        if (!valueValidation) return true;
        if (data.variableType.selectedKind === 'custom') {
          return data.variableType.customType !== '';
        }
        return true;
      },
      {
        message: 'Custom type must have a value',
        path: ['variableType', 'customValue']
      }
    );

export type GlobalVariableData = z.infer<
  ReturnType<typeof GlobalVariableDataSchema>
>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialGlobalVariableData: GlobalVariableData = {
  title: 'New variable',
  variableType: {
    selectedKind: 'normal',
    type: 'string',
    customType: ''
  },
  description: ''
};
