import { observer } from 'mobx-react';
import DNDBase from '@components/dnd/base';
import useStores from '@/hooks/useStore';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

const Notifications = () => {
  const transitionId = useParams<string>()[ParamsList.TransitionId] as string;
  const { transitionStore } = useStores();
  const transition = transitionStore.get(transitionId);
  const notificationDND = transition?.notificationDnd;

  if (!notificationDND) return <></>;

  return <DNDBase dnd={notificationDND} hasToolbar />;
};

export default observer(Notifications);
