import { DndLibrary } from '@components/dnd/library';
import { ActionLoaded, ActionLoadedSchema } from './action.store';
import BaseStore from './base/base.store';
import { LoadedDndModel } from './dnd.store';
import RootStore from './root.store';
import { WorkflowModel } from '@/mobx/models/workflow.model';
import { BaseActionModel } from '@/mobx/models/action.model';
import { parseWithZod } from '@/utils/parseZodSchema';

export interface WorkflowLoaded {
  id: string;
  published_id: string;
  metadata: {
    name: string;
  };
  traceInfo: LoadedDndModel;
  traceOverview: LoadedDndModel;
  globalVariables: LoadedDndModel;
  Action: ActionLoaded[]; //! Action with an 's', no upper case
}

export class WorkflowStore extends BaseStore<WorkflowModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, WorkflowModel);
  }

  public loadWorkflows = (
    loadedWorkflows: WorkflowLoaded[]
  ): Pick<WorkflowLoaded, 'id' | 'published_id'>[] => {
    return loadedWorkflows.map((loadedWorkflow) => {
      return this.loadWorkflow(loadedWorkflow);
    });
  };

  private loadWorkflow = (
    workflowLoaded: WorkflowLoaded
  ): Pick<WorkflowLoaded, 'id' | 'published_id'> => {
    console.log('workflowLoaded: ', workflowLoaded);
    const dndStore = this.rootStore.dndStore;
    const actionStore = this.rootStore.actionStore;

    const traceInfoDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceInfo,
      workflowLoaded.traceInfo
    );

    const traceOverviewDND = dndStore.createDndFromLoaded(
      DndLibrary.TraceOverview,
      workflowLoaded.traceOverview
    );

    const globalVariablesDND = dndStore.createDndFromLoaded(
      DndLibrary.GlobalVariables,
      workflowLoaded.globalVariables
    );

    /* ---------------------- Handle actions and their DNDs --------------------- */

    const actionIds: BaseActionModel['id'][] = [];
    workflowLoaded.Action.forEach((action: ActionLoaded) => {
      const loadedAction = parseWithZod(ActionLoadedSchema, action);

      if (!loadedAction) return;
      const newAction = actionStore.addLoadedActionToStore(loadedAction);

      actionIds.push(newAction.id);
    });

    /* ------------------------ Instanciate WorkflowModel ----------------------- */
    const workflow = new WorkflowModel(
      this,
      workflowLoaded.id,
      workflowLoaded.published_id,
      traceInfoDND.id,
      traceOverviewDND.id,
      globalVariablesDND.id,
      actionIds,
      workflowLoaded.metadata.name
    );

    this.rootStore.workflowStore.set(workflowLoaded.id, workflow);
    return { id: workflow.id, published_id: workflow.published_id };
  };
}
