import { AtomModel } from '../models/atom.model';
import BaseStore from './base/base.store';
import RootStore from './root.store';
import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';
import {
  DataType,
  MetaInfoSchema,
  VariableInfoSchema,
  allDataSchemasMap
} from '../types/atom.types';
import { RepositoryData } from '@components/dnd/library/dataRepository/block/repository.data';
import { BlockTypes } from '@components/dnd/library';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';
import { ENV } from '@/utils/constants';
import {
  TraceKeyDTO,
  TraceKeyDTOSchema,
  TraceKeyMode
} from '../models/traceKey/schema';
import { camelCase } from 'camel-case';
import { GlobalVariableData } from '@components/dnd/library/globalVariables/blocks/globalVariableBlock/globalVariable.data';

type CreateAtomDTO = {
  id: AtomModel['id'];
  type: AtomModel['dataType'];
  data: unknown;
  metaInfo: AtomModel['metaInfo'];
  variableInfo?: AtomModel['variableInfo'];
  processId: string;
  references: AtomModel['references'];
  referencedBy: AtomModel['referencedBy'];
  traceKey: TraceKeyDTO;
};

const AtomModelSchema = z.object({
  id: z.string(),
  traceKey: TraceKeyDTOSchema,
  type: z.string(),
  data: z.unknown(),
  meta_info: MetaInfoSchema.strict(),
  referenced_by: z.array(z.string()),
  references: z.array(z.string()),
  variable_info: VariableInfoSchema.optional().nullable()
});

export type AtomLoaded = z.infer<typeof AtomModelSchema>;

export default class AtomStore extends BaseStore<AtomModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, AtomModel, 'atom');
    this.store_ready = true;
  }

  public createAtom<TData>(
    atomId: AtomModel['id'],
    atomType: AtomModel['dataType'],
    data: TData,
    metaInfo: AtomModel['metaInfo'],
    variableInfo?: AtomModel['variableInfo']
  ): Maybe<AtomModel<TData>> {
    const dynamicSchema = allDataSchemasMap[metaInfo.dnd.blockType];

    if (!dynamicSchema) return;

    // ? we don't care about type any here here, since we are going to parse it anyway
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedData = parseWithZod(dynamicSchema, data);

    if (!parsedData) return;

    const dataWithTitle = parseWithZod(z.object({ title: z.string() }), data);

    const newTraceKeyDTO: TraceKeyDTO = {
      value: dataWithTitle ? camelCase(dataWithTitle.title) : atomId,
      mode: dataWithTitle ? TraceKeyMode.Follow : TraceKeyMode.Locked
    };

    const dataInstance = new AtomModel<TData>(
      this,
      atomId,
      newTraceKeyDTO,
      atomType,
      data,
      metaInfo,
      [],
      [],
      variableInfo
    );

    this.set(atomId, dataInstance);

    if (this.rootStore.processStore.currentProcessId === undefined) {
      newError('Atom not created: No current process found');
      return;
    }

    const dto: CreateAtomDTO = {
      id: atomId,
      type: dataInstance.dataType,
      data: dataInstance.data,
      metaInfo: dataInstance.metaInfo,
      variableInfo: dataInstance.variableInfo,
      processId: this.rootStore.processStore.currentProcessId,
      references: dataInstance.references,
      referencedBy: dataInstance.referencedBy,
      traceKey: dataInstance.traceKey.toJSON
    };

    this.httpWrapper.post('/', dto).catch((error: Error) => {
      newError(error, true);
    });

    return dataInstance;
  }

  loadSingleAtom(atom: AtomLoaded) {
    const loadAtom = parseWithZod(AtomModelSchema, atom);

    if (!loadAtom) return;

    const variableInfo =
      loadAtom.variable_info == null ? undefined : loadAtom.variable_info;

    const newAtom = new AtomModel(
      this.rootStore.atomStore,
      loadAtom.id,
      loadAtom.traceKey,
      loadAtom.type as DataType,
      loadAtom.data,
      loadAtom.meta_info,
      loadAtom.referenced_by,
      loadAtom.references,
      variableInfo
    );

    this.set(loadAtom.id, newAtom);

    return newAtom;
  }

  public loadAtoms(atoms: AtomLoaded[]) {
    atoms.map((atom) => this.loadSingleAtom(atom));
  }

  getAtomById<TData>(
    id: Maybe<AtomModel['id']>,
    blockType: BlockTypes
  ): Maybe<AtomModel<TData>> | Error {
    if (!id) return;
    const atom: Maybe<AtomModel<unknown>> = this.get(id);
    if (!atom) {
      newError(
        `Variable with id ${id} not found`,
        ['local', 'staging'].includes(ENV),
        {
          errorType: 'warning'
        }
      );
      return;
    }

    if (atom.metaInfo.dnd.blockType !== blockType) {
      return newError(
        `Variable with id ${id} has incorrect item type: "${atom.metaInfo.dnd.blockType}" instead of requested "${blockType}"`,
        true
      );
    }
    const dataSchema = allDataSchemasMap[atom.metaInfo.dnd.blockType];

    if (!dataSchema) {
      return newError(
        `Data item with id "${atom.metaInfo.dnd.blockType}" has no associated schema`,
        true
      );
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const parsedVariableData = parseWithZod(dataSchema, atom.data);
    if (!parsedVariableData) return new Error();

    return atom as AtomModel<TData>;
  }

  getAtomById_Unsafe<TData>(
    id: Maybe<AtomModel['id']>
  ): Maybe<AtomModel<TData>> {
    if (!id) return;
    const dataItem: Maybe<AtomModel<unknown>> = this.get(id);
    if (!dataItem) return;

    return dataItem as AtomModel<TData>;
  }

  getAllVariables(): AtomModel<{ title: string }>[] {
    return this.toArray().filter(
      (atom) => atom.dataType === 'variable'
    ) as AtomModel<{ title: string }>[];
  }

  getAllGlobalVariables(): AtomModel<GlobalVariableData>[] {
    return this.toArray().filter(
      (atom) => atom.metaInfo.source.library === 'globalVariables'
    ) as AtomModel<GlobalVariableData>[];
  }

  getAllDataRepositories(): AtomModel<RepositoryData>[] {
    return this.toArray().filter(
      (dataItem) => dataItem.metaInfo.source.library === 'dataRepository'
    ) as AtomModel<RepositoryData>[];
  }

  getAllDataRepositoriesReferences(): DataItemReference[] {
    const dataRepositories = this.getAllDataRepositories();

    return dataRepositories.map((dataRepository) => ({
      dataItemId: dataRepository.id,
      blockType: dataRepository.metaInfo.dnd.blockType,
      sourceId: dataRepository.metaInfo.source.id
    }));
  }

  /** Gets all the referencable atoms. Currently only form fields and data repositories are referencable, and they are both of dataType `variable`. */
  get allReferencableAtoms(): AtomModel[] {
    return this.getAllVariables();
  }

  getAllAtomsBySourceId(sourceId: string): AtomModel[] {
    return this.toArray().filter(
      (atom) => atom.metaInfo.source.id === sourceId
    );
  }

  public deleteAtom(id: AtomModel['id']): Promise<boolean> {
    const errorMessage = `Error while trying to delete atom with id "${id}"`;
    const atomToDelete = this.get(id);

    if (!atomToDelete) {
      newError(`${errorMessage}: not found`, true);
      return Promise.resolve(false);
    }

    const referencedAtoms = atomToDelete.references.map((referenceId) => {
      const referencedAtom = this.get(referenceId);
      if (!referencedAtom) {
        newError(
          `${errorMessage}: referenced Atom with id "${referenceId}" not found`,
          true
        );
        return;
      }
      return referencedAtom;
    });

    if (referencedAtoms.some((atom): atom is undefined => !atom?.id)) {
      newError(
        `${errorMessage}: Canceled task since some referenced atoms were not found`,
        true
      );
      return Promise.resolve(false);
    }

    (referencedAtoms as AtomModel[]).forEach((referencedAtoms) => {
      referencedAtoms.simpleRemoveReferencingAtom(id);
    });

    return this.delete(id);
  }

  public async deleteAtomsBySourceId(sourceId: string) {
    const atomsToDelete = this.getAllAtomsBySourceId(sourceId);

    if (atomsToDelete.length === 0) {
      return;
    }

    await Promise.all(atomsToDelete.map((atom) => this.deleteAtom(atom.id)));
  }
}
