import { TextInputSchema, TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const NumberFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      type: z.enum(['number', 'integer'])
    })
    .merge(PrefillDataSchema)
    .strict();

export type NumberFieldData = z.infer<ReturnType<typeof NumberFieldDataSchema>>;

export const initialNumberData: NumberFieldData = {
  title: 'Default Number Field Title',
  placeholder: '',
  required: true,
  type: 'number'
};
