import styled from 'styled-components';
import ActionButton from '@atoms/button';

export const TOWidgetMessage = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const LabeledVariable = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5px;

  label {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    padding: 10px 0;
  }
`;

export const AddWidgetButton = styled(ActionButton)`
  font-size: 11px;
`;
