import { Alert, IconButton, Typography } from '@mui/joy';
import { AlertTriangle, X } from 'lucide-react';
import { WarningType } from './condition';

interface WarningDndProps {
  setShowWarning: (warning: WarningType) => void;
}

const WarningDnd = ({ setShowWarning }: WarningDndProps) => {
  const handleWarning = () => {
    setShowWarning(WarningType.False);
    localStorage.setItem('showWarning', WarningType.False);
  }

  return (
    <Alert
      sx={{
        alignItems: 'flex-start',
        borderRadius: 0,
        position: 'absolute',
        width: 'auto%',
        top: '70px',
        zIndex: 100
      }}
      startDecorator={<AlertTriangle size={20} />}
      endDecorator={
        <IconButton
          onClick={handleWarning}
          variant="soft"
          color="warning"
        >
          <X size={20} />
        </IconButton>
      }
      variant="soft"
      color="warning"
    >
      <div>
        <div>Warning</div>
        <Typography level="body-sm" color="warning">
          Transition accepts only one statement (And, Or, Not, Condition) at the
          root. You can drag and drop inside a statement. The Not statement
          can't have more than one statement.
        </Typography>
      </div>
    </Alert>
  );
};

export default WarningDnd;
