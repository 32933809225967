import { DeleteRoleDraft, RoleDraft } from '@/mobx/types/process.types';

export const renderPermissions = (
  role: RoleDraft | undefined | null
): { label: string; value: RoleDraft | DeleteRoleDraft }[] => {
  switch (role) {
    case RoleDraft.Owner:
      return [
        { label: 'can edit', value: RoleDraft.Editor },
        { label: 'can view', value: RoleDraft.Viewer }
      ];
    case RoleDraft.Editor:
      return [
        { label: 'can edit', value: RoleDraft.Editor },
        { label: 'can view', value: RoleDraft.Viewer }
      ];
    case RoleDraft.Viewer:
      return [];
    default:
      return [];
  }
};

export const MenuRoleChoice = [
  { value: RoleDraft.Editor, label: 'edit' },
  { value: RoleDraft.Viewer, label: 'view' }
];

export const PublicAccessChoice = [
  { value: true, label: 'Anyone with the link can view the draft' },
  { value: false, label: 'Only people invited can view the draft' }
];

export function generateStrongPassword(length) {
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+{}[]<>?';
  let password = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset[randomIndex];
  }
  return password;
}

// Todo : will be replaced by getUsersDraft from draftStore/ processStore
export const MockUsers = [
  {
    name: 'Paul Planchon',
    role: RoleDraft.Owner,
    email: 'paul.planchon@sia-partners.com',
    avatar: ''
  },
  {
    name: 'Valentin Flageul',
    role: RoleDraft.Editor,
    email: 'valentin.flageul@sia-partners.com',
    avatar: ''
  },
  {
    name: 'BT Nguyen',
    role: RoleDraft.Editor,
    email: 'bang-tam.nguyen@sia-partners.com',
    avatar: ''
  },
  {
    name: 'Marco Saetta',
    role: RoleDraft.Viewer,
    email: 'marco.saetta@sia-partners.com',
    avatar: ''
  },
  {
    name: 'Stratumn Admin',
    role: RoleDraft.Owner,
    email: 'admin@stratumn.com',
    avatar: ''
  }
];
