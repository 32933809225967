import { LucideIcon, PanelTopOpen } from 'lucide-react';
import { DataSourceOption, DataSourceOptionSchema } from './dropDown.data';
import { parseWithZod } from '@/utils/parseZodSchema';

export const DropDownIcon: LucideIcon = PanelTopOpen;

export const isCorrectSource = (
  dataSource: string
): dataSource is DataSourceOption => {
  return !!parseWithZod(DataSourceOptionSchema, dataSource);
};
