import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { DropDownData } from './dropDown.data';

type Props = {
  atom: AtomModel<DropDownData>;
};

export const DropDownModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
