import styled from 'styled-components';

export const Root = styled.div`
  text-align: center;
  margin: 24px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

export const Button = styled.button`
  height: 32px;
  width: 125px;
  background: #5246f7;
  font-size: 11px;
  color: white;
  text-transform: uppercase;
  border-radius: 3px;
  font-family: 'Inter';
  font-weight: bold;
  border-width: 0;
  letter-spacing: 0.7px;

  &:hover {
    cursor: pointer;
  }
`;
