import { DndKitValuesSchema, TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const DataSourceOptionSchema = z.enum([
  'normalSource',
  'dataRepository',
  'stateSource'
]);

export type DataSourceOption = z.infer<typeof DataSourceOptionSchema>;

const NormalSourceSchema = z.object({
  normalSource: z.object({
    values: DndKitValuesSchema
  })
});

const DataRepositorySchema = z.object({
  dataRepository: z.object({
    selectedDataRepository: z.string().optional()
  })
});

const StateSourceSchema = z.object({
  stateSource: z.object({})
});

export const DropDownDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      dataSource: DataSourceOptionSchema,
      dataSourceData: NormalSourceSchema.merge(DataRepositorySchema)
        .merge(StateSourceSchema)
        .strict()
    })
    .merge(PrefillDataSchema)
    .strict()
    .superRefine((data, context) => {
      if (!valueValidation) return;
      if (
        data.dataSource === 'dataRepository' &&
        data.dataSourceData.dataRepository?.selectedDataRepository === undefined
      ) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message:
            'selectedDataRepository is required when the dataSource is DataRepository',
          path: ['dataSourceData', 'dataRepository', 'selectedDataRepository']
        });
      }
    });

export type DropDownData = z.infer<ReturnType<typeof DropDownDataSchema>>;

export const initialDropDownData: DropDownData = {
  title: 'Default Drop Down Title',
  required: true,
  dataSource: 'normalSource',
  dataSourceData: {
    normalSource: { values: [] },
    dataRepository: {},
    stateSource: {}
  }
};
