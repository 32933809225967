import { useEffect, useState } from 'react';
import useStores from './useStore';
import { ProcessModel } from '@/mobx/models/process.model';
import { newError } from '@/services/errors/errors';

export const useProcesses = () => {
  const { processStore } = useStores();
  const [loading, setLoading] = useState(true);
  const [processes, setProcesses] = useState<ProcessModel[]>([]);

  useEffect(() => {
    processStore
      .getAll()
      .then((p) => {
        setLoading(false);
        if (p) {
          setProcesses(p);
        }
      })
      .catch((e) => newError(e));
  }, [processStore]);

  return {
    loading,
    processes
  };
};
