import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { LucideIcon, Variable, WandSparkles } from 'lucide-react';
import EndControls from '../endControls';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { observer } from 'mobx-react';
import { useAtom } from '@hooks/useAtom';
import { ParamsList } from '@/routes/routes.types';
import { FormControl, FormLabel } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { initialCodeConditionData } from './codeCondition.data';
import useStore from '@hooks/useStore';
import { OnChange } from '@monaco-editor/react';
import { MonacoEditor } from '@components/monaco/monacoEditor.code';
import { useState } from 'react';
import { GlassModal } from '@atoms/glassModal';
import ActionButton from '@atoms/button';

export const CodeConditionIcon: LucideIcon = Variable;

const CodeCondition = (block: DNDBlock) => {
  const [isMonacoModalOpen, setIsMonacoModalOpen] = useState(false);
  const { codeStore } = useStore();
  const transitionId = useParams()[ParamsList.TransitionId];

  const atom = useAtom({
    block,
    initialData: initialCodeConditionData,
    dataType: 'condition',
    sourceId: transitionId,
    sourceName: 'Transition',
    library: 'condition'
  });

  if (!atom || !transitionId) return null;

  const onCodeChange: OnChange = (value) => {
    if (!value) return;
    atom.data.code = value;
  };

  return (
    <BlockBase
      dndBlock={block}
      icon={CodeConditionIcon}
      endControls={<EndControls dndBlock={block} />}
      hasTitle={false}
    >
      <Column $width="200px">
        <FormControl size="sm" required>
          <FormLabel>Condition code</FormLabel>
          <ActionButton
            onClick={() => setIsMonacoModalOpen(true)}
            value="Edit condition's code"
            color="neutral"
            variant="soft"
            startDecorator={<WandSparkles size={18} />}
          />
        </FormControl>
      </Column>
      <GlassModal
        open={isMonacoModalOpen}
        onClose={() => setIsMonacoModalOpen(false)}
        header={{
          title: `Code condition editor`,
          description: 'Customize the code of your condition'
        }}
        className="h-full w-full px-4"
      >
        <section className="-ml-4 h-full w-full overflow-hidden">
          <MonacoEditor
            modelId={atom.id}
            preCode={codeStore.computePreCode()}
            code={atom.data.code}
            onCodeChange={onCodeChange}
          />
        </section>
      </GlassModal>
    </BlockBase>
  );
};

export default observer(CodeCondition);
