import { useDrop } from 'react-dnd';
import { useContext } from 'react';

import { DNDMetaContext } from '..';
import { DropZone as DropZoneType, Item } from '@/mobx/models/dnd.model';
import { DropIndicator, DroppableDiv } from './dropZone.style';
import { ALL_BLOCKS } from '@components/dnd/library';

type DropZoneProps = {
  data: DropZoneType;
  isLast?: boolean;
};

const DropZone = ({ data, isLast }: DropZoneProps) => {
  const { onDropFunction, restriction } = useContext(DNDMetaContext);
  const accepted = Object.values(ALL_BLOCKS);

  const [{ isOver, isDroppable }, drop] = useDrop({
    accept: accepted,
    canDrop: (item: Item) => {
      if (restriction) return restriction(data.path, item);
      return !!item.path;
    },
    drop: (item: Item) => {
      onDropFunction(data, item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      isDroppable: monitor.canDrop()
    })
  });

  return (
    <DroppableDiv
      $isOver={isOver}
      $isLast={isLast}
      ref={drop}
      data-tag={data.path}
    >
      {<DropIndicator $isVisible={isOver && isDroppable} />}
    </DroppableDiv>
  );
};

export default DropZone;
