import { Enable } from 're-resizable';
import { Side, Width } from './panel.type';

const defaultEnable: Enable = {
  left: false,
  top: false,
  right: false,
  bottom: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
} as const;

const DEFAULT_WIDTH: Width = {
  default: '50vw',
  min: '700px',
  max: '70vw'
};

export const config = {
  width: DEFAULT_WIDTH
};

export const getEnable = (side: Side) => {
  if (side === Side.Left) {
    defaultEnable.right = true;
  }
  if (side === Side.Right) {
    defaultEnable.left = true;
  }
  return defaultEnable;
};
