import Modeler from 'bpmn-js/lib/Modeler';
import { Element } from 'bpmn-js/lib/model/Types';
import Modeling, { EventBus } from 'bpmn-js/lib/features/modeling/Modeling';
import { ElementRegistry } from '@components/editor/plugin/dragging/Dragging';
import { Connection } from 'diagram-js/lib/model/Types';
import {
  ElementIdType,
  ElementType,
  ModdleElement
} from '../../services/bpmn/bpmn.types';
import { camelCase } from 'camel-case';

export type BpmnState = {
  modeler: Modeler;
  selectedElement: Element;
};

interface BusinessObjectData {
  id: string;
  name: string;
  sourceRef: ModdleElement;
  targetRef: ModdleElement;
}

type BusinessObject = Partial<BusinessObjectData>;

export class BpmnModel {
  state: Partial<BpmnState>;

  constructor() {
    this.state = {};
  }

  get toJSON() {
    return {};
  }

  setModeler(modeler: Modeler) {
    this.state.modeler = modeler;
  }

  setSelectedElement(selectedElement: Element) {
    this.state.selectedElement = selectedElement;
  }

  get modeler(): Maybe<Modeler> {
    return this.state.modeler;
  }

  get selectedElement(): Maybe<Element> {
    return this.state.selectedElement;
  }

  get allElements(): Maybe<Element[]> {
    return this.elementRegistry?.getAll() as Element[];
  }

  get modeling(): Maybe<Modeling> {
    return this.state.modeler?.get<Modeling>('modeling');
  }

  get elementRegistry(): Maybe<ElementRegistry> {
    return this.modeler?.get<ElementRegistry>('elementRegistry');
  }

  get eventBus(): Maybe<EventBus> {
    return this.modeler?.get<EventBus>('eventBus');
  }

  /* -------------------------------------------------------------------------- */
  /*                                Element Utils                               */
  /* -------------------------------------------------------------------------- */

  getElementName(
    element: Maybe<Element> = this.selectedElement
  ): Maybe<string> {
    if (!element) return;
    const businessObject = element.businessObject as BusinessObject; // pretty dirty but bpmn exports businessObject as any
    return businessObject?.name;
  }

  changeElementName(
    name: string,
    element: Maybe<Element> = this.selectedElement
  ): void {
    if (!element || !this.modeling) return;

    this.modeling.updateProperties(element, {
      name
    });
  }

  /** Finds a BPMN element using its `id` and its `type`
   * @returns a BPMN element or `undefined`
   * @note Actually returns an object of type `ElementLike` from `bpmn-js` library,
   * but it doesn't looks like it's being exported, so we use `Element` instead.
   * @exemple
   * ```ts
   * const element: Maybe<Element> = findElementById('0lg5tdk', ElementType.Action)
   * ```
   */
  getElementById(
    id: string,
    type: ElementIdType = ElementIdType.Activity
  ): Maybe<Element> {
    if (!this.elementRegistry) return;
    return this.elementRegistry.get(`${type}_${id}`) as Element;
  }

  setSelectedElementByElementId(
    id: string,
    type: ElementIdType = ElementIdType.Activity
  ): Maybe<Element> {
    const element = this.getElementById(id, type);
    if (!element) return;
    this.setSelectedElement(element);
    return element;
  }

  resetSelectedElement(): void {
    this.state.selectedElement = undefined;
  }

  getElementConnections(
    element: Maybe<Element> = this.selectedElement
  ): Maybe<Connection[]> {
    if (!element) return;
    return element.outgoing;
  }

  getSourceAndTargetElements(
    element: Maybe<Element> = this.selectedElement
  ): [Maybe<ModdleElement>, Maybe<ModdleElement>] {
    if (!element) return [undefined, undefined];
    if (!this.isTransition(element)) return [undefined, undefined];
    const businessObject = element.businessObject as Partial<BusinessObject>;
    return [businessObject.sourceRef, businessObject.targetRef];
  }

  isTransition = (element: Maybe<Element> = this.selectedElement): boolean => {
    if (!element) return false;
    const elementType = element.type as ElementType;
    return (
      elementType === ElementType.MessageFlow ||
      elementType === ElementType.SequenceFlow
    );
  };

  //! not used (yet)
  reactToEvents = (): void => {
    this.eventBus?.on('element.click', (event) => {
      console.info('element.click', event);
    });
  };

  getLaneNames(): Maybe<string[]> {
    if (!this.elementRegistry) return;

    const laneElements: ModdleElement[] = this.elementRegistry
      .getAll()
      .map((bpmnElement) => bpmnElement.businessObject as ModdleElement)
      .filter((moddleElement) => moddleElement.$type === ElementType.Lane);

    const laneNames = laneElements.map((lane) => camelCase(lane.name));
    return laneNames;
  }

  get errors() {
    return [];
  }
}
