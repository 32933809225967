import { useEffect, Dispatch, SetStateAction } from 'react';
import { SideBarItem, StateMenuLeftColumn } from './stateMenu.style';
import useStores from '@hooks/useStore';

interface Props {
  sideBarItemNames: string[];
  selectedSideBarItem: string | null;
  cursor: number | null;
  handleItemClick: (key: string) => void;
  setHovered: Dispatch<SetStateAction<string | null>>;
}

const SideBar = ({
  sideBarItemNames,
  selectedSideBarItem,
  cursor,
  handleItemClick,
  setHovered
}: Props) => {
  const { actionStore } = useStores();

  const actionIdToNameMap = actionStore.toArray().reduce((acc, action) => {
    acc[action.id] = action.name;
    return acc;
  });

  useEffect(() => {
    if (!selectedSideBarItem && cursor) {
      const selected = document.querySelector(`#${sideBarItemNames[cursor]}`);
      if (selected) {
        selected?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [sideBarItemNames, cursor, selectedSideBarItem]);

  return (
    <StateMenuLeftColumn>
      <section className="w-full flex-grow overflow-y-auto">
        <div className="w-full px-[0.65rem] py-2 font-bold">Actions</div>
        <div className="w-full flex-grow overflow-y-auto">
          {sideBarItemNames.map((actionId, i) =>
            actionIdToNameMap[actionId] ? (
              <SideBarItem
                key={actionId}
                id={actionId}
                $isSelected={actionId === selectedSideBarItem}
                $onHover={!selectedSideBarItem && i === cursor}
                onClick={() => handleItemClick(actionId)}
                onMouseEnter={() =>
                  !selectedSideBarItem && setHovered(actionId)
                }
                onMouseLeave={() => setHovered(null)}
              >
                {actionIdToNameMap[actionId]}
              </SideBarItem>
            ) : null
          )}
        </div>
      </section>
      <section className="w-full">
        <div className="w-full pb-1">
          <SideBarItem
            onClick={() => handleItemClick('globalVariables')}
            $isSelected={false}
            $onHover={false}
          >
            Global Variables
          </SideBarItem>
        </div>
      </section>
    </StateMenuLeftColumn>
  );
};

export default SideBar;
