import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import * as Sentry from '@sentry/react';
import { VITE_ENV } from './utils/constants';
import { toast } from 'sonner';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import React from 'react';

Sentry.init({
  dsn: 'https://3d71f44df7aa92aa8755b47c2a551d12@sentry.stratu.mn/2',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration()
  ],
  tracePropagationTargets: [new RegExp('.*\\.stratumn\\.com*.', '')],
  // Performance Monitoring
  tracesSampleRate: 1, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.3, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: VITE_ENV || 'release',
  enabled: VITE_ENV !== 'local'
});

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Failed to find the root element');
} else {
  document.addEventListener('keydown', (e) => {
    if (e.key === 's' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      toast.info('No need to ctrl-s!', {
        description: 'Everything is saved automagically 🪄'
      });
    }
  });
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
