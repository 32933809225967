import { observer } from 'mobx-react';
import DndInterface, { DndKitValue } from '@atoms/testDndKit/dndInterface';
import { AtomModel } from '@/mobx/models/atom.model';
import { CheckBoxData } from '../checkbox.data';

export interface NormalSourceData {
  values: DndKitValue[];
}

export interface NormalSourceProps {
  dataItem: AtomModel<CheckBoxData>;
}

const NormalSourceOption = ({ dataItem }: NormalSourceProps) => {
  const updateValues = (newValues: DndKitValue[]) => {
    dataItem.data.dataSourceData.normalSource.values = newValues;
  };

  const addValue = (valueToAdd: DndKitValue) => {
    dataItem.data.dataSourceData.normalSource.values.push(valueToAdd);
  };

  const values: DndKitValue[] =
    dataItem.data.dataSourceData.normalSource.values;

  return (
    <DndInterface
      values={values}
      addValue={addValue}
      updateValues={updateValues}
      title="Checkbox values"
    />
  );
};

export default observer(NormalSourceOption);
