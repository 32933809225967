import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Slate, withReact, ReactEditor } from 'slate-react';
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Node as SlateNode,
  Point,
  Range,
  Transforms,
  Text,
  Descendant,
} from 'slate';
import { withHistory } from 'slate-history';
import { Element, Leaf, SHORTCUTS, getVariables, toggleMark } from './utils';
import HoveringToolbar from './Toolbar';
import { RichTextEditor } from './style';
import VariableInput from './VariableInput';
import { BulletListElement, CustomEditor, CustomElement } from './slate';

type SlateEditorProps = {
  onChange: (value: CustomElement[]) => void;
  value: CustomElement[];
};

enum Key {
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
}

const useForceUpdate = () => {
  const [, setState] = useState<number>(0);

  const forceUpdate = useCallback(() => {
    setState(n => n + 1);
  }, []);

  return forceUpdate;
};

const SlateEditor: React.FC<SlateEditorProps> = ({ onChange, value }) => {
  const editorRef = useRef<CustomEditor>()
  if (!editorRef.current) editorRef.current = withShortcuts(withReact(withHistory(createEditor())))
  const editor = editorRef.current

  // Force update with state value on refresh
  // https://github.com/ianstormtaylor/slate/issues/4612
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    editor.children = value;
    forceUpdate();
  }, [editor, value, forceUpdate]);


  const handleDOMBeforeInput = useCallback(
    (e: InputEvent) => {
      switch (e.inputType) {
        case 'formatBold':
          e.preventDefault();
          toggleMark(editor, 'bold');
          break;
        case 'formatItalic':
          e.preventDefault();
          toggleMark(editor, 'italic');
          break;
        case 'formatUnderline':
          e.preventDefault();
          toggleMark(editor, 'underlined');
          break;
      }

      queueMicrotask(() => {
        const pendingDiffs = ReactEditor.androidPendingDiffs(editor);

        const scheduleFlush = pendingDiffs?.some(({ diff, path }) => {
          if (!diff.text.endsWith(' ')) {
            return false;
          }

          const { text } = SlateNode.leaf(editor, path);
          const beforeText = text.slice(0, diff.start) + diff.text.slice(0, -1);
          if (!(beforeText in SHORTCUTS)) {
            return;
          }

          const blockEntry = Editor.above(editor, {
            at: path,
            match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
          });
          if (!blockEntry) {
            return false;
          }

          const [, blockPath] = blockEntry;
          return Editor.isStart(editor, Editor.start(editor, path), blockPath);
        });

        if (scheduleFlush) {
          ReactEditor.androidScheduleFlush(editor);
        }
      });
    },
    [editor]
  );

 const setNodesWithoutOverwriting = (newText: string, path: number[]) => {
   Transforms.insertText(editor, newText, { at: path });
 };

  const handleKeyDown = (evt: { key: string; }) => {
    const key = evt.key as Key;
    if(key === Key.ArrowRight || key === Key.Enter) {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const [node] = Editor.nodes(editor, {
          match: n => Text.isText(n) && !!n.variable,
        });
  
        if (node) {
          const { anchor } = selection;
          const endOffset = Editor.end(editor, anchor.path).offset;
  
          if (anchor.offset === endOffset) {
            toggleMark(editor, 'variable');
          }
        }
      }
    }
  }

  const variableValue = getVariables(value)

  return (
    <Slate editor={editor} initialValue={value} onChange={onChange as (value: Descendant[]) => void}>
      <HoveringToolbar />
      <RichTextEditor
        onKeyDown={handleKeyDown}
        renderElement={(props) => <Element {...props} />}
        renderLeaf={(props) => <Leaf variables={variableValue} {...props} />}
        placeholder="Enter some text..."
        onDOMBeforeInput={handleDOMBeforeInput}
      />
      <VariableInput
        value={value}
        onChange={setNodesWithoutOverwriting}
      />
    </Slate>
  );
};

const withShortcuts = (editor: CustomEditor) => {
  const { deleteBackward, insertText } = editor;

  editor.insertText = (text) => {
    const { selection } = editor;

    if (text.endsWith(' ') && selection && Range.isCollapsed(selection)) {
      const { anchor } = selection;
      const block = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });
      const path = block ? block[1] : [];
      const start = Editor.start(editor, path);
      const range = { anchor, focus: start };
      const beforeText = Editor.string(editor, range) + text.slice(0, -1);
      const type = SHORTCUTS[beforeText];

      if (type) {
        Transforms.select(editor, range);

        if (!Range.isCollapsed(range)) {
          Transforms.delete(editor);
        }

        const newProperties = {
          type,
        };
        Transforms.setNodes(editor, newProperties as Partial<Node>, {
          match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
        });

        if (type === 'list-item') {
          const list: BulletListElement = {
            type: 'bulleted-list',
            children: [],
          };
          Transforms.wrapNodes(editor, list, {
            match: (n) =>
              !Editor.isEditor(n) &&
              SlateElement.isElement(n) &&
              n.type === 'list-item',
          });
        }

        return;
      }
    }

    insertText(text);
  };

  editor.deleteBackward = (...args) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const match = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });

      if (match) {
        const [block, path] = match;
        const start = Editor.start(editor, path);

        if (
          !Editor.isEditor(block) &&
          SlateElement.isElement(block) &&
          block.type !== 'paragraph' &&
          Point.equals(selection.anchor, start)
        ) {
          const newProperties: Partial<SlateElement> = {
            type: 'paragraph',
          };
          Transforms.setNodes(editor, newProperties);

          if (block.type === 'list-item') {
            Transforms.unwrapNodes(editor, {
              match: (n) =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n.type === 'bulleted-list',
              split: true,
            });
          }

          return;
        }
      }

      deleteBackward(...args);
    }
  };

  return editor;
};

export default SlateEditor;
