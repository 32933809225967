import { Modal, ModalClose } from '@mui/joy';
import { GlassModalContainer } from './glassModal.style';
import { ReactNode } from 'react';

type GlassModalProps = {
  open: boolean;
  onClose?: () => void;
  header?: {
    title: string;
    description?: string;
  };
  className?: string;
  children: ReactNode;
};
export const GlassModal = ({
  children,
  open,
  header,
  onClose,
  className
}: GlassModalProps) => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'transparent',
        border: 'none',
        boxShadow: 'none'
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.2);'
          }
        }
      }}
      open={open}
      onClose={onClose}
    >
      <GlassModalContainer className={className}>
        <>
          <ModalClose />
          {header && (
            <section className="p-4 py-2 w-full items-center ">
              <h1 className="font-semibold">{header.title}</h1>
            </section>
          )}
          {children}
        </>
      </GlassModalContainer>
    </Modal>
  );
};
