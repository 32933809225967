import { observer } from 'mobx-react';
import { Option } from '@mui/joy';

import { SelectField } from '@atoms/select';
import { AtomModel } from '@/mobx/models/atom.model';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';
import { CheckBoxData } from '../checkbox.data';

export interface SelectedDataRepositoryData {
  selectedDataRepository: string; 
}

interface DataRepositoryProps {
  dataItem: AtomModel<CheckBoxData>;
}

const DataRepositoryOption = ({ dataItem }: DataRepositoryProps) => {
  const { atomStore: dataItemStore } = useStores();

  const allDataRepositories = dataItemStore.getAllDataRepositories();

  const onRepositoryChange = (value: string | null): void => {
    if (!value) {
      newError('null value after selecting a data repository');
      return;
    }

    dataItem.data.dataSourceData.dataRepository.selectedDataRepository = value;
  };

  return (
    <SelectField
      label="Data repository source"
      value={
        dataItem.data.dataSourceData.dataRepository.selectedDataRepository ?? ''
      }
      onChange={(_, value) => onRepositoryChange(value)}
    >
      {allDataRepositories.map((repo, index) => (
        <Option key={index} value={repo.id} label={repo.data.title}>
          {repo.data.title}
        </Option>
      ))}
    </SelectField>
  );
};

export default observer(DataRepositoryOption);
