import { DndKitValuesSchema, TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';

export const DataRepositoryDataSchema = (valueValidation?: boolean) =>
  z.object({
    title: valueValidation ? TitleInputSchema : z.string(),
    values: DndKitValuesSchema
  });

export type RepositoryData = z.infer<
  ReturnType<typeof DataRepositoryDataSchema>
>;

export const initialRepositoryData: RepositoryData = {
  title: 'Default repository title',
  values: []
};
