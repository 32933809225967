import { useCallback, useEffect, useRef } from 'react';

export const useKeyPress = (
  keys: string[],
  callback: (event: KeyboardEvent) => void,
  node: HTMLElement | null = null
) => {
  const callbackRef = useRef<(event: KeyboardEvent) => void>(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });

  /** Handles what happens on key press.*/
  const handleKeyPress = useCallback<(event: Event) => void>(
    (event) => {
      // checks if one of the key is part of the ones we want
      if (keys.some((key) => (event as unknown as KeyboardEvent).key === key)) {
        callbackRef.current(event as unknown as KeyboardEvent); // not ideal
      }
    },
    [keys]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode: HTMLElement | Document = node ?? document;
    targetNode && targetNode.addEventListener('keydown', handleKeyPress);
    return () =>
      targetNode && targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
