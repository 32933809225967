import { ParamsList } from '@/routes/routes.types';
import { useParams } from 'react-router-dom';
import useStores from './useStore';
import { useEffect, useState } from 'react';
import { ProcessModel } from '@/mobx/models/process.model';

/**
 * Returns the correct instance of `ProcessModel` using process ID found in the url parameters.
 */

interface ProcessData {
  process: Maybe<ProcessModel>;
  passwordProtectedPage: boolean;
  error: boolean;
}

const useProcess = (): ProcessData => {
  const processId = useParams()[ParamsList.ProcessId] as string;
  const { processStore } = useStores();

  const [process, setProcess] = useState<Maybe<ProcessModel>>();
  const [passwordProtectedPage, setPasswordProtectedPage] = useState(false);
  const [error, setError] = useState(false);
  const existingProcess = processStore.get(processId);

  // useEffect is allowed here because its performs an async task on
  // something that need to be fetch over the network.
  useEffect(() => {
    const loadProcess = async (): Promise<void> => {
      const loadedProcess = await processStore.fetchProcess(processId);
      if (!loadedProcess) return;

      processStore.currentProcessId = processId;
      setProcess(loadedProcess);
    };
    if (existingProcess) {
      setProcess(existingProcess);
    } else {
      loadProcess().catch((error) => {
        // Exception when we are in protected password mode
        if (
          error instanceof Error &&
          error.message === 'Password Protected Page'
        ) {
          setPasswordProtectedPage(true);
          return;
        }
        // We are not in password protected mode & process not found
        setError(true);
        return;
      });
    }
  }, [existingProcess, processId, processStore]);

  return {
    process: process ?? existingProcess,
    passwordProtectedPage,
    error
  };
};

export default useProcess;
