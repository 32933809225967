import { newError } from '@/services/errors/errors';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '../event.types';
import { UserAction } from '../shape/shape.plexer';
import { getIdFromBpmnId } from '../../bpmn.utils';
import { toast } from 'sonner';
import RootStore from '@/mobx/store/root.store';

export const onGatewayCreate = (
  event: CreateShapeEvent,
  rootStore: RootStore
): void => {
  const gatewayType = event.context.shape?.type;

  const rawShapeId = event.context.shape?.id;
  const rawWorflowId = event.context.parent?.id;

  if (!rawShapeId || !rawWorflowId) return;

  const shapeId = getIdFromBpmnId(rawShapeId);
  const workflowId = getIdFromBpmnId(rawWorflowId);

  const actionStore = rootStore.actionStore;

  switch (gatewayType) {
    case 'bpmn:StartEvent':
      actionStore
        .createGateway(shapeId, workflowId, 'START')
        .then(() => {
          toast.success('Start gateway created successfully!');
        })
        .catch((e: unknown) => {
          newError(e);
        });
      return;
    case 'bpmn:EndEvent':
      actionStore
        .createGateway(shapeId, workflowId, 'END')
        .then(() => {
          toast.success('End gateway created successfully!');
        })
        .catch((e: unknown) => {
          newError(e);
        });
      return;
    case 'label':
      // label are created on gateway
      return;
    default:
      newError('Trying to create a gateway that is not supported', true);
      return;
  }
};

export const onGatewayDeleted = (
  event: DeleteShapeEvent,
  rootStore: RootStore
): void => {
  const gatewayType = event.context.shape?.type;
  const shapeId = event.context.shape?.id;

  if (!shapeId) return;

  const actionStore = rootStore.actionStore;
  const gatewayId = getIdFromBpmnId(shapeId);

  switch (gatewayType) {
    case 'bpmn:StartEvent':
      actionStore
        .deleteById(gatewayId)
        .then((isDeleted: boolean) => {
          if (isDeleted) {
            toast.success('Gateway deleted with success');
            console.log(`Start gateway ${gatewayId} deleted`);
          }
        })
        .catch((error: unknown) => {
          newError(error, true);
        });
      return;
    case 'bpmn:EndEvent':
      actionStore
        .deleteById(gatewayId)
        .then((isDeleted: boolean) => {
          if (isDeleted) {
            toast.success('Gateway deleted with success');
            console.log(`End gateway ${gatewayId} deleted`);
          }
        })
        .catch((error: unknown) => {
          newError(error, true);
        });
      return;
    case 'label':
      // label are created on gateway
      return;
    default:
      newError('Trying to delete a gateway that is not supported', true);
      return;
  }
};

export const gatewayFunctionPlexer: Record<
  UserAction,
  (event: ShapeEvents, rootStore: RootStore) => void
> = {
  create: onGatewayCreate,
  delete: onGatewayDeleted
};
