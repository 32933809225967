import styled, { css } from 'styled-components';
import { ViewType } from './home.utils';

export const ContentContainer = styled.div`
  min-width: 1130px;
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

interface ProcessContainerProps {
  $view: ViewType;
}

export const ProcessesContainer = styled.section<ProcessContainerProps>`
  display: grid;
  transition: all 0.2s;
  ${(p) =>
    p.$view === ViewType.Grid
      ? css`
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-gap: 24px;
        `
      : css`
          grid-template-columns: 1fr;
          grid-gap: 12px;
        `}
`;

export const ProjectTypeTitle = styled.h2`
  font-size: 1.2rem;
  font-weight: 600;
`;
