import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_DATE_VIEW_TYPES = ['Date', 'Deadline'] as const;
export const TO_DateViewTypeSchema = z.enum(TO_DATE_VIEW_TYPES);

export const TO_DateDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_DateViewTypeSchema
    })
    .strict();

export type TO_DateData = z.infer<ReturnType<typeof TO_DateDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTODateData: Omit<TO_DateData, 'selectedVariable'> = {
  title: 'Default Date Title',
  viewType: 'Date'
};
