import { ProcessInfoLoaded } from '@/mobx/store/process.store';

const DEFAULT_LOADING_CARDS_AMOUT = 5;

export const getNumberOfProcesses = (): number => {
  const numberOfProcesses = localStorage.getItem('numberOfProcesses');
  return numberOfProcesses
    ? Number(numberOfProcesses)
    : DEFAULT_LOADING_CARDS_AMOUT;
};

export enum ViewType {
  Grid = 'grid',
  List = 'list'
}

export const loadingLoremProcess: ProcessInfoLoaded = {
  name: 'Lorem ipsum is',
  id: '0',
  iconUrl: '',
  created_at: '2023-08-24',
  updated_at: '2023-08-24',
  published: false,
  workflows: [],
  draft: false
};
