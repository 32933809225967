import { newError } from '@/services/errors/errors';
import { z } from 'zod';

export const parseWithZod = <TResponse>(
  schema: z.Schema<TResponse>,
  data: unknown,
  option: Partial<{
    withToast: boolean;
    withSentry: boolean;
  }> = {
    withToast: false,
    withSentry: true
  }
): Maybe<TResponse> => {
  const parsed = schema.safeParse(data);

  if (!parsed.success) {
    if (option.withSentry) {
      const showToastWhenDev = process.env.NODE_ENV === 'development';
      newError(parsed.error, option?.withToast ?? showToastWhenDev);
      //? only here
      // eslint-disable-next-line no-restricted-syntax
      console.error(data);
    }
    return undefined;
  }

  return parsed.data;
};

export const isValidType = <TResponse>(
  schema: z.Schema<TResponse>,
  data: unknown
): data is TResponse => {
  return !!parseWithZod(schema, data);
};
