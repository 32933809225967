import { FC } from 'react';
import { DNDBlock } from '@/components/dnd/base';
import * as Blocks from './blocks';
import { DNDInfo } from '../library.types';

export enum TOBlocks {
  File = 'TO_File',
  Date = 'TO_Date',
  List = 'TO_List',
  Text = 'TO_Text',
  Number = 'TO_Number',
  User = 'TO_User'
}

export const isTOBlocks = (blockType: unknown): blockType is TOBlocks => {
  return Object.values(TOBlocks).includes(blockType as TOBlocks);
};

export const TO_INFO_DND: DNDInfo = {
  title: 'Trace Overview',
  description: 'Add a widget to customize the Trace table.'
};

const TraceOverviewPlexer = {
  [TOBlocks.File]: Blocks.FileCompact,
  [TOBlocks.Date]: Blocks.Date,
  [TOBlocks.List]: Blocks.List,
  [TOBlocks.Text]: Blocks.Text,
  [TOBlocks.Number]: Blocks.Number,
  [TOBlocks.User]: Blocks.User
};

interface LibraryTraceOverviewProps {
  block: DNDBlock;
}

export const LibraryTO: FC<LibraryTraceOverviewProps> = ({ block }) => {
  const Block = TraceOverviewPlexer[block.type as TOBlocks];
  return <Block {...block} />;
};
