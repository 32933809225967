import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";

import {
	append as svgAppend,
	attr as svgAttr,
	create as svgCreate,
	classes as svgClasses,
} from "tiny-svg";

import { assign } from "min-dash";

import {
	getRoundRectPath,
	getLabelColor,
} from "bpmn-js/lib/draw/BpmnRenderUtil";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import { getBusinessObject } from "bpmn-js/lib/util/ModelUtil";
import pathMap from "./pathMap"; 

const HIGH_PRIORITY = 1500;
const TASK_BORDER_RADIUS = 10;

const traceModule = ["trace:Form", "trace:TableEditor", "trace:TableImporter"];

export default class CustomRenderer extends BaseRenderer {
	constructor(eventBus, bpmnRenderer, textRenderer, directEditing) {
		super(eventBus, HIGH_PRIORITY);

		this.bpmnRenderer = bpmnRenderer;
		this.textRenderer = textRenderer;
		this.directEditing = directEditing;
	}

	canRender(element) {
		// only render tasks and events (ignore labels)
		return isAny(element, traceModule);
	}

	drawShape(parentNode, element) {
		if (isAny(element, traceModule)) {
			const pathType = element.type.replace("trace:", "");
			const pathElement = pathMap[pathType];
			const pathData = pathElement.d;

			const rect = drawRect(parentNode, 100, 80, TASK_BORDER_RADIUS);

			prependTo(rect, parentNode);

			drawPath(parentNode, pathData, {
				transform: "translate(8 8)",
			});

			this.renderEmbeddedLabel(parentNode, element, "center-middle");

			return rect;
		}
	}

	getShapePath(shape, element) {
		if (isAny(element, traceModule)) {
			return getRoundRectPath(shape, TASK_BORDER_RADIUS);
		}

		return this.bpmnRenderer.getShapePath(shape);
	}

	renderLabel(parentGfx, label, options) {
		options = assign(
			{
				size: {
					width: 100,
				},
			},
			options
		);

		const text = this.textRenderer.createText(label || "", options);

		svgClasses(text).add("djs-label");

		svgAppend(parentGfx, text);

		return text;
	}

	renderEmbeddedLabel(parentGfx, element, align) {
		const semantic = getBusinessObject(element);

		return this.renderLabel(parentGfx, semantic.name, {
			box: element,
			align: align,
			padding: 7,
			style: {
				fill: getLabelColor(element),
			},
		});
	}
}

CustomRenderer.$inject = [
	"eventBus",
	"bpmnRenderer",
	"textRenderer",
	"directEditing",
];

// helpers //////////

// copied from https://github.com/bpmn-io/bpmn-js/blob/master/lib/draw/BpmnRenderer.js
function drawRect(parentNode, width, height, borderRadius, strokeColor) {
	const rect = svgCreate("rect");

	svgAttr(rect, {
		width: width,
		height: height,
		rx: borderRadius,
		ry: borderRadius,
		stroke: strokeColor || "#000",
		strokeWidth: 2,
		fill: "#fff",
	});

	svgAppend(parentNode, rect);

	return rect;
}

// copied from https://github.com/bpmn-io/diagram-js/blob/master/lib/core/GraphicsFactory.js
function prependTo(newNode, parentNode, siblingNode) {
	parentNode.insertBefore(newNode, siblingNode || parentNode.firstChild);
}

function drawPath(parentNode, d, attrs) {
	const path = svgCreate("path", {
		...attrs,
		d,
	});

	svgAppend(parentNode, path);

	return path;
}
