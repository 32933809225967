import {
  getBusinessObject,
  is
} from "bpmn-js/lib/util/ModelUtil";

import {
  isEventSubProcess,
  isExpanded
} from "bpmn-js/lib/util/DiUtil";

import {
  isDifferentType
} from "../util/TypeUtil.js";

import {
  forEach,
  filter,
  isArray,
  isUndefined
} from "min-dash";

import * as replaceOptions from "../MenuOptions";

class ReplaceMenuProvider {
  constructor(
    bpmnFactory, popupMenu, modeling, moddle,
    bpmnReplace, rules, translate
  ) {
    this.bpmnFactory = bpmnFactory;
    this.popupMenu = popupMenu;
    this.modeling = modeling;
    this.moddle = moddle;
    this.bpmnReplace = bpmnReplace;
    this.rules = rules;
    this.translate = translate;

    this.register();
  }

  register() {
    this.popupMenu.registerProvider("bpmn-replace", this);
  }

  getPopupMenuEntries(target) {
    const businessObject = target.businessObject;

    const rules = this.rules;

    let filteredReplaceOptions = [];

    if (isArray(target) || !rules.allowed("shape.replace", { element: target })) {
      return {};
    }

    const differentType = isDifferentType(target);

    // start events outside sub processes
    if (is(businessObject, "bpmn:StartEvent") && !is(businessObject.$parent, "bpmn:SubProcess")) {

      filteredReplaceOptions = filter(replaceOptions.EVENT, differentType);

      return this.createEntries(target, filteredReplaceOptions);
    }

    // expanded/collapsed pools
    if (is(businessObject, "bpmn:Participant")) {

      filteredReplaceOptions = filter(replaceOptions.PARTICIPANT, function(replaceOption) {
        return isExpanded(target) !== replaceOption.isExpanded;
      });

      return this.createEntries(target, filteredReplaceOptions);
    }

    // end events
    if (is(businessObject, "bpmn:EndEvent")) {

      filteredReplaceOptions = filter(replaceOptions.EVENT, function(replaceOption) {
        const target = replaceOption;

        // hide cancel end events outside transactions
        if (target.eventDefinitionType == "bpmn:CancelEventDefinition" && !is(businessObject.$parent, "bpmn:Transaction")) {
          return false;
        }

        return differentType(replaceOption);
      });

      return this.createEntries(target, filteredReplaceOptions);
    }

    // gateways
    if (is(businessObject, "bpmn:Gateway")) {

      filteredReplaceOptions = filter(replaceOptions.GATEWAY, differentType);

      return this.createEntries(target, filteredReplaceOptions);
    }

    // transactions
    if (is(businessObject, "bpmn:Transaction")) {

      filteredReplaceOptions = filter(replaceOptions.TRANSACTION, differentType);

      return this.createEntries(target, filteredReplaceOptions);
    }

    // sequence flows
    if (is(businessObject, "bpmn:SequenceFlow")) {
      return this.createSequenceFlowEntries(target, replaceOptions.SEQUENCE_FLOW);
    }

    // flow nodes
    if (is(businessObject, "bpmn:FlowNode")) {
      filteredReplaceOptions = filter(replaceOptions.TASK, differentType);

      return this.createEntries(target, filteredReplaceOptions);
    }

    return {};
  }

  createEntries(target, replaceOptions) {
    const entries = {};

    const self = this;
  
    forEach(replaceOptions, (replaceOption) => {
      entries[ replaceOption.actionName ] = self.createEntry(replaceOption, target);
    });
  
    return entries;
  }

  createSequenceFlowEntries(target, replaceOptions) {
    const businessObject = getBusinessObject(target);

    let entries = {};
  
    const modeling = this.modeling
    const moddle = this.moddle;
  
    const self = this;
  
    forEach(replaceOptions, (replaceOption) => {
  
      switch (replaceOption.actionName) {
        case "replace-with-default-flow":
          if (businessObject.sourceRef.default !== businessObject &&
                (is(businessObject.sourceRef, "bpmn:ExclusiveGateway") ||
                is(businessObject.sourceRef, "bpmn:InclusiveGateway") ||
                is(businessObject.sourceRef, "bpmn:ComplexGateway") ||
                is(businessObject.sourceRef, "bpmn:Activity"))) {
    
            entries = {
              ...entries,
              [ replaceOption.actionName ]: self.createEntry(replaceOption, target, function() {
                modeling.updateProperties(target.source, { default: businessObject });
              })
            };
          }
          break;
        case "replace-with-conditional-flow":
          if (!businessObject.conditionExpression && is(businessObject.sourceRef, "bpmn:Activity")) {
    
            entries = {
              ...entries,
              [ replaceOption.actionName ]: self.createEntry(replaceOption, target, function() {
                const conditionExpression = moddle.create("bpmn:FormalExpression", { body: "" });
    
                modeling.updateProperties(target, { conditionExpression: conditionExpression });
              })
            };
          }
          break;
        default:
    
          // conditional flow -> sequence flow
          if (is(businessObject.sourceRef, "bpmn:Activity") && businessObject.conditionExpression) {
            entries = {
              ...entries,
              [ replaceOption.actionName ]: self.createEntry(replaceOption, target, function() {
                modeling.updateProperties(target, { conditionExpression: undefined });
              })
            };
          }
    
          // default flow -> sequence flow
          if ((is(businessObject.sourceRef, "bpmn:ExclusiveGateway") ||
              is(businessObject.sourceRef, "bpmn:InclusiveGateway") ||
              is(businessObject.sourceRef, "bpmn:ComplexGateway") ||
              is(businessObject.sourceRef, "bpmn:Activity")) &&
              businessObject.sourceRef.default === businessObject) {
            entries = {
              ...entries,
              [ replaceOption.actionName ]: self.createEntry(replaceOption, target, function() {
                modeling.updateProperties(target.source, { default: undefined });
              })
            };
          }
      }
    });
  
    return entries;
  }

  createEntry(replaceOption, target, action) {
    const translate = this.translate;
    // CHANGE BPMN REPLACE
    const replaceElement = this.bpmnReplace.replaceElement;
  
    const replaceAction = function() {
      return replaceElement(target, replaceOption);
    };
  
    let label = replaceOption.title;

    if (label && typeof label === "function") {
      label = label(target);
    }
  
    action = action || replaceAction;

  
    return {
      label: translate(label),
      className: replaceOption.className,
      imageUrl: replaceOption.icon,
      action: action
    };
  }

}

ReplaceMenuProvider.$inject = [
  "bpmnFactory",
  "popupMenu",
  "modeling",
  "moddle",
  "bpmnReplace",
  "rules",
  "translate"
];

export default ReplaceMenuProvider
  