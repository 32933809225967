import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { CommentFieldData } from './comment.data';

type Props = {
  atom: AtomModel<CommentFieldData>;
};

export const CommentModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
