import { AtomModel } from '../atom.model';
import { AtomWithTitle } from './types';

export const atomHasTitle = (atom: AtomModel): atom is AtomWithTitle => {
  return (
    !!atom.data &&
    typeof atom.data === 'object' &&
    'title' in atom.data &&
    typeof atom.data.title === 'string'
  );
};

export const isFieldRequired = (atom: AtomWithTitle): boolean => {
  if ('required' in atom.data && typeof atom.data.required === 'boolean')
    return atom.data.required;
  return false; // This should never happen, but we'll return false to make the data possibly undefined in case
};
