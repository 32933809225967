import { BasicModal } from '@components/library/modal';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Textarea,
} from '@mui/joy';
import { useTheme } from 'styled-components';
import { reactions } from './utils';
import { Emojis } from './feedback.style';
import { useEffect, useState } from 'react';
import Emoji from './emoji';
import MetaDataServiceClass from '@/services/metadata/metadata.service';
import { toast } from 'sonner';
import { newError } from '@/services/errors/errors';
import { ENV, EnvironmentName } from '@/utils/constants';

interface FeedbackProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const FeedbackDialog = ({ openModal, setOpenModal }: FeedbackProps) => {
  const theme = useTheme();
  const [activeReaction, setActiveReaction] = useState('');
  const [feedback, setFeedback] = useState('');

  useEffect(() => {
    setFeedback('');
    setActiveReaction('');
  }, [openModal]);

  const handleSubmit = () => {
    if (feedback && ENV === EnvironmentName.Release) {
      const metaData = new MetaDataServiceClass();
      metaData.submitFeedback({ text: feedback, rating: activeReaction })
        .then(res => {
          if (res) {
            toast.success('Your feedback has been sent');
            setFeedback('')
            setActiveReaction('')
            setOpenModal(false);
          }
        })
        .catch((err: { message: string; }) => {
          newError(err.message);
        })
      return;
    }

    toast.success('Your feedback has been sent');
    setFeedback('')
    setActiveReaction('')
    setOpenModal(false);
  }

  return (
    <BasicModal
      open={openModal}
      setOpen={setOpenModal}
      title="Give us feedback"
    >
      <FormControl  sx={{ marginTop: '25px'}}>
        <FormLabel sx={{ color: theme.color.grey[2]}}>
          Thank you for giving feedback. Please describe any issue as detailed as possible.
        </FormLabel>
        <Textarea
          onChange={(evt) => setFeedback(evt.target.value)}
          placeholder="Your feedback..."
          minRows={3}
          sx={{
            minWidth: 400,
            minHeight: 150,
            m: '10px 0',
            p: '10px'
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            my: '20px',
            flex: 'auto',
            alignItems: 'center'
          }}
        >
          <Emojis>
            {reactions.map(reaction => (
              <Emoji
                reaction={reaction}
                key={reaction}
                isSelected={activeReaction === reaction}
                setActiveReaction={setActiveReaction}
              />
            ))}
          </Emojis>
          <Button 
            sx={{ ml: 'auto', height: 'fit-content' }}
            disabled={feedback.trim() === ''}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
      </FormControl>
    </BasicModal>
  )
}

export default FeedbackDialog;