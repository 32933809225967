import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_TextDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_TextData = z.infer<ReturnType<typeof TI_TextDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTITextData: Omit<TI_TextData, 'selectedVariable'> = {
  title: 'Default Text Title'
};
