/// <reference types="vite-plugin-svgr/client" />
import {
  MenuItem,
  MenuItems,
  SettingsContainer,
  SettingsContentContainer,
  SettingsMenu,
  SettingsTitle
} from './settings.style';
import { Outlet } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { STRATUMN_PROCESS_MENU_ITEMS } from './menu';
import { observer } from 'mobx-react';
import useProcess from '@hooks/useProcess';

const Settings = () => {
  const { process } = useProcess();

  const isDraft = process?.draft;

  const stratumnEditorPath = `../editor`;
  const draftEditorPath = `../draft-editor`;

  const MENU_ITEMS = isDraft
    ? STRATUMN_PROCESS_MENU_ITEMS.filter(
        (item) => item.name === 'General settings'
      )
    : STRATUMN_PROCESS_MENU_ITEMS;

  return !process ? (
    <>Loading ...</>
  ) : (
    <SettingsContainer>
      <SettingsMenu>
        <SettingsTitle
          to={process.draft ? draftEditorPath : stratumnEditorPath}
        >
          <ChevronLeft strokeWidth={1} />
          <h1>Settings</h1>
        </SettingsTitle>
        <MenuItems>
          {MENU_ITEMS.map((item) => (
            <MenuItem key={item.id} to={item.link}>
              {item.icon} <h2>{item.name}</h2>
            </MenuItem>
          ))}
        </MenuItems>
      </SettingsMenu>
      <SettingsContentContainer>
        <Outlet />
      </SettingsContentContainer>
    </SettingsContainer>
  );
};

export default observer(Settings);
