import { parseWithZod } from '@/utils/parseZodSchema';
import {
  CheckBoxType,
  CheckBoxTypeSchema,
  DataSourceOption,
  DataSourceOptionSchema
} from './checkbox.data';
import { CheckSquare, LucideIcon } from 'lucide-react';

export const CheckBoxIcon: LucideIcon = CheckSquare;

export interface CheckBoxTypeOption {
  name: string;
  value: CheckBoxType;
  isDisabled?: boolean;
}

export const checkBoxTypeOptions: CheckBoxTypeOption[] = [
  {
    name: 'Checkbox',
    value: 'checkbox'
  },
  {
    name: 'Radio',
    value: 'radio'
  }
];

export const isCorrectSource = (
  dataSource: string
): dataSource is DataSourceOption => {
  return !!parseWithZod(DataSourceOptionSchema, dataSource);
};

export const isCorrectCheckBoxType = (
  checkBoxType: string
): checkBoxType is CheckBoxType => {
  return !!parseWithZod(CheckBoxTypeSchema, checkBoxType);
};
