import { MouseEventHandler } from 'react';
import Button, {
  ButtonPropsColorOverrides,
  ButtonPropsSizeOverrides
} from '@mui/joy/Button';
import { OverridableStringUnion } from '@mui/types';
import { ColorPaletteProp } from '@mui/joy';

interface ActionButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  value: string;
  startDecorator?: React.ReactElement;
  endDecorator?: React.ReactElement;
  disabled?: boolean;
  size?: OverridableStringUnion<'sm' | 'md' | 'lg', ButtonPropsSizeOverrides>;
  color?: OverridableStringUnion<ColorPaletteProp, ButtonPropsColorOverrides>;
  [key: string]: unknown;
}

const ActionButton = ({
  onClick,
  value,
  startDecorator,
  endDecorator,
  disabled = false,
  size = 'md',
  color = 'primary',
  ...props
}: ActionButtonProps) => {
  return (
    <Button
      color={color}
      disabled={disabled}
      size={size}
      onClick={onClick}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      {...props}
    >
      {value}
    </Button>
  );
};

export default ActionButton;
