import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_DataTabDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_DataTabData = z.infer<ReturnType<typeof TI_DataTabDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTIDataTabData: Omit<TI_DataTabData, 'selectedVariable'> = {
  title: 'Default Data Tab Title'
};
