import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

/* ----------------------------- Helper schemas ----------------------------- */

export const DataSourceSchema = z
  .object({
    template: z.string(),
    selectedDestination: z.string()
  })
  .strict();

export const TemplateVariablesSchema = z
  .object({
    variable: z.string(),
    value: AtomReferenceSchema.nullable()
  })
  .strict();

export const DataSourceDataSchema = z
  .object({
    templateVariables: z.array(TemplateVariablesSchema).nullable(), // TODO: nullable is not great here
    to: z.union([z.string(), AtomReferenceSchema]).nullable()
  })
  .strict();

export type DataSource = z.infer<typeof DataSourceSchema>;

/* ------------------------------- Main Schema ------------------------------ */

export const EmailDataSchema = z
  .object({
    dataSource: DataSourceSchema.optional(),
    dataSourceData: DataSourceDataSchema.optional()
  })
  .strict();

export type EmailData = z.infer<typeof EmailDataSchema>;

export type TemplateVariable = z.infer<typeof TemplateVariablesSchema>;

export const initialEmailData: EmailData = {
  dataSource: {
    template: '',
    selectedDestination: ''
  },
  dataSourceData: {
    templateVariables: null,
    to: ''
  }
};
