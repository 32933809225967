import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { DateFieldData } from './date.data';

type Props = {
  atom: AtomModel<DateFieldData>;
};

export const DateModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};

