import Model, { ModelError } from './base/base.model';
import AsyncStore from '@/mobx/store/base/async.store';
import { makeObservable, observable, override } from 'mobx';
import { BpmnModel } from './bpmn.model';
import { WorkflowModel } from './workflow.model';
import { DNDModel } from './dnd.model';
import { RoleDraft } from '../types/process.types';
import { DndLibrary } from '@components/dnd/library';

export interface InputProcess {
  name: string;
  draft: boolean;
  bpmn_workflow: string;
  template: string;
}

export interface Collaborator {
  id?: string;
  name?: string;
  role: RoleDraft;
  avatar?: string;
  email: string;
}

export class ProcessModel extends Model {
  bpmn: Maybe<BpmnModel>;

  constructor(
    store: AsyncStore<ProcessModel>,
    id: Model['id'],
    public bpmnWorkflow: string, // xml of the bpmn schema
    public isPublished: boolean,
    public name: string,
    public createdAt: string, // timestamp
    public updatedAt: string, // timestamp
    public iconUrl: string,
    public draft: boolean,
    public workflowIds?: Pick<WorkflowModel, 'id' | 'published_id'>[],
    public dataRepositoryDndId?: DNDModel['id'],

    public password_protected?: boolean,
    public can_edit?: boolean,
    public is_public?: boolean,
    public user_role?: RoleDraft,
    public collaborators?: Collaborator[],
    loading?: boolean
  ) {
    super(store, id, loading);

    makeObservable(this, {
      name: observable,
      workflowIds: observable,
      password_protected: observable,
      can_edit: observable,
      is_public: observable,
      user_role: observable,
      collaborators: observable,
      loading: override
    });
  }

  get dataRepositoryDnd(): Maybe<DNDModel<DndLibrary.DataRepository>> {
    return this.store.rootStore.dndStore.get(
      this.dataRepositoryDndId
    ) as DNDModel<DndLibrary.DataRepository>;
  }

  get workflows(): Maybe<WorkflowModel>[] {
    if (!this.workflowIds) return [];
    return this.workflowIds.map((workflowId) => {
      return this.store.rootStore.workflowStore.get(workflowId.id);
    });
  }

  get toJSON() {
    return {};
  }

  get errors(): ModelError[] {
    return [];
  }
}
