import styled from 'styled-components';

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DestinationField = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TemplateVariableField = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.grey[4]};
  background: ${({ theme }) => theme.color.grey[8]};
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
`;

export const TemplateVariableContainer = styled.div`
  display: flex;
  gap: 20px;

  p {
    color: ${({ theme }) => theme.color.grey.dark};
  }
`;
