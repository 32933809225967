import { useCallback, useEffect, useState } from 'react';
import { 
  ReactEditor,
  useSlate 
} from 'slate-react';
import { Element as SlateElement, Text, Descendant, Path } from 'slate';
import { FormLabel } from '@mui/joy';
import { ChipInput, ChipVariableContainer, CustomChip } from './style';
import { CustomElement } from './slate';
import { isArrayEqual, randomColor } from './utils';

type VariableText = { text: string; path: Path; };

type VariableInputProps = {
  value: CustomElement[];
  onChange: (newText: string, path: number[]) => void;
};

const VariableInput = ({ value, onChange }: VariableInputProps) => {
  const editor = useSlate();
  const [inputs, setInputs] = useState<Record<string, VariableText>>({});
  const [variables,setVariables] = useState<VariableText[]>([]);

  const getVariableTexts = useCallback(
    (nodes: Descendant[]): VariableText[] => {
      return nodes.reduce((varText, node: Descendant) => {
        const path = ReactEditor.findPath(editor, node);
        if (Text.isText(node) && node.variable && node.text !== '') {
          varText.push({ text: node.text, path });
        } else if (SlateElement.isElement(node)) {
          varText.push(...getVariableTexts(node.children));
        }
        return varText;
      }, [] as VariableText[]);
    },
    [editor]
  );

  useEffect(() => {
    if(isArrayEqual(value, editor.children)) {
      const varText = getVariableTexts(value);
      const uniqueVars: Record<string, VariableText> = {};
      varText.forEach((input) => {
        uniqueVars[input.text] = input;
      });
      setVariables(varText);
      setInputs(uniqueVars);
    }
  }, [value, editor.children, getVariableTexts]);

  const handleInputChange = (text: string, newText: string) => {
    const newInputs = { ...inputs };
    const variable = newInputs[text];

    if (variable) {
      // Update all occurrences of the variable with the new text
      Object.values(newInputs).forEach((input) => {
        if (input.text === text) {
          input.text = newText;
        }
      });

      setInputs(newInputs);
      const allSameVariable = variables.filter((v) => v.text === text);
      if (allSameVariable) {
        allSameVariable.forEach((v) => onChange(newText, v.path));
      }
      onChange(newText, variable.path);
    }
  };

  return (
    <div style={{ padding: '10px 0' }}>
      {Object.keys(inputs).length > 0 && <FormLabel> All variables </FormLabel>}
      <ChipVariableContainer>
        {Object.values(inputs).map((input, index) => (
          <CustomChip key={index} color={randomColor(index)}>
            <ChipInput
              style={{ width: `${input.text.length}ch` }}
              value={input.text}
              onChange={(e) => handleInputChange(input.text, e.target.value)}
            />
          </CustomChip>
        ))}
      </ChipVariableContainer>
    </div>
  );
};

export default VariableInput;
