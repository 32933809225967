import styled from 'styled-components';

export const ItemBody = styled.div`
  height: 33px;
  padding-block: 1px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: ${(p) => p.theme.border.default};
`;

export const IconContainer = styled.div`
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
`;

export const ClickableIconContainer = styled(IconContainer)`
  cursor: pointer;
`;

export const GrabIconContainer = styled(IconContainer)`
  cursor: grab;
`;

export const ButtonIconContainer = styled(IconContainer)`
  cursor: pointer;
`;

export const StyledInput = styled.input`
  width: 0;
  border: none;
  outline: none;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${(p) => p.theme.color.grey.dark};
  font-family: Inter;
`;
