import { useEffect, Dispatch, SetStateAction } from 'react';
import {
  StateMenuList,
  StateMenuMessage,
  StateMenuRightColumn,
  StateMenuTitle
} from './stateMenu.style';
import VariableItem from './VariableItem';
import { AtomModel } from '@/mobx/models/atom.model';
import { ResolvedVariableType } from '@/mobx/types/atom.types';

interface VariableMenuProps {
  cursor: number | null;
  setCursor: Dispatch<SetStateAction<number>>;
  variablesByAction: AtomModel<{ title: string, variableInfo?:{ resolvedType?: ResolvedVariableType}}>[];
  handleVarClick: (index: number) => void;
  searchVar: string;
  selectedAction: string | null;
}

const VariableMenu = ({
  cursor,
  setCursor,
  variablesByAction,
  handleVarClick,
  searchVar,
  selectedAction
}: VariableMenuProps) => {
  useEffect(() => {
    if (selectedAction && cursor) {
      const selected = document.querySelector(`#variables-${cursor}`);
      if (selected) {
        selected?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }
  }, [cursor, selectedAction]);

  return (
    <StateMenuRightColumn>
      <div>
        {cursor !== null ? (
          <>
            <StateMenuTitle>Variables</StateMenuTitle>
            {variablesByAction.length > 0 ? (
              <StateMenuList>
                {variablesByAction.map((variable, i: number) => (
                  <VariableItem
                    key={`variables_${variable.id}_${i}`}
                    id={`variables_${i}`}
                    onHover={!!selectedAction && i === cursor}
                    onClick={() => handleVarClick(i)}
                    onMouseEnter={() => !!selectedAction && setCursor(i)}
                    searchVar={searchVar}
                    variable={variable}
                  />
                ))}
              </StateMenuList>
            ) : (
              <p style={{ textAlign: 'center' }}>No variables found</p>
            )}
          </>
        ) : (
          <StateMenuMessage>
            Search your workflows variable by typing in the search bar or by
            clicking on an action
          </StateMenuMessage>
        )}
      </div>
    </StateMenuRightColumn>
  );
};

export default VariableMenu;
