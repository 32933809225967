import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
  ViewType,
  getNumberOfProcesses,
  loadingLoremProcess
} from './home.utils';

import {
  ProcessesContainer,
  ContentContainer,
  ProjectTypeTitle
} from './home.style';
import ProcessCard from './processCard';
import HomeHeader from './homeHeader';
import { DefaultPage } from '../../atoms/defaultPage/style';
import EmptyProcess from './emptyProcess';
import { useProcesses } from '@hooks/useProcesses';
import { ProcessModel } from '@/mobx/models/process.model';

const Home = () => {
  const { processes, loading } = useProcesses();
  const [filter, setFilter] = useState('');

  const filteredProcess = processes.filter((p) =>
    p.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
  );

  const draftProcesses = filteredProcess.filter((p) => p.draft);
  const normalProcesses = filteredProcess.filter((p) => !p.draft);

  const onProcessSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();

    if (!searchValue) {
      setFilter('');
      return;
    }
  };

  const [view, setView] = useState<ViewType>(
    (localStorage.getItem('viewType') as ViewType) ?? ViewType.Grid
  );

  useEffect(() => {
    storeLocallyNumberOfProcesses(processes.length);
  }, [processes]);

  const storeLocallyNumberOfProcesses = (numberOfProcesses: number) => {
    localStorage.setItem('numberOfProcesses', numberOfProcesses.toString());
  };

  /** Renders LOADING_CARDS_AMOUT fake process cards */
  const renderFakeProcessCards = (): JSX.Element[] => {
    return [...Array<void>(getNumberOfProcesses())].map((_, index) => (
      <ProcessCard
        key={-(index + 1)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        process={loadingLoremProcess}
        loading={true}
        view={view}
      />
    ));
  };

  const RenderProcessCards: React.FC<{
    processesToRender: ProcessModel[];
  }> = ({ processesToRender }) => (
    <>
      {processesToRender.map((process: ProcessModel) => (
        <ProcessCard
          key={process.id}
          process={process}
          loading={false}
          view={view}
        />
      ))}
    </>
  );

  const onViewChange = (view: ViewType) => {
    setView(view);
    localStorage.setItem('viewType', view);
  };

  if (!loading && processes.length == 0) {
    return <EmptyProcess />;
  }

  const renderProcesses = () => {
    if (loading) {
      return (
        <>
          <ProjectTypeTitle>Processes</ProjectTypeTitle>
          <ProcessesContainer $view={view}>
            {renderFakeProcessCards()}
          </ProcessesContainer>
        </>
      );
    }
    if (normalProcesses.length > 0) {
      return (
        <>
          <ProjectTypeTitle>Processes</ProjectTypeTitle>
          <ProcessesContainer $view={view}>
            <RenderProcessCards processesToRender={normalProcesses} />
          </ProcessesContainer>
        </>
      );
    }
    return null;
  };

  const renderDraft = () => {
    if (draftProcesses.length > 0) {
      return (
        <>
          <ProjectTypeTitle>Drafts</ProjectTypeTitle>
          <ProcessesContainer $view={view}>
            <RenderProcessCards processesToRender={draftProcesses} />
          </ProcessesContainer>
        </>
      );
    }
    return null;
  };

  return (
    <DefaultPage>
      <ContentContainer>
        <HomeHeader
          onProcessSearch={onProcessSearch}
          onViewChange={onViewChange}
          selectedView={view}
        />
        {renderProcesses()}
        {renderDraft()}
      </ContentContainer>
    </DefaultPage>
  );
};

export default observer(Home);
