import { SaveXMLOptions } from 'bpmn-js/lib/BaseViewer';
import { HTTPWrapper } from '@/utils/http';
import { transformXML } from './utils/transform';
import { newError } from '@/services/errors/errors';
import RootStore from '@/mobx/store/root.store';
import Modeler from 'bpmn-js/lib/Modeler';

const SAVE_XML_OPTIONS: Partial<SaveXMLOptions> = {
  format: true,
  preamble: false
};

interface UpdateTemplateDTO {
  bpmn_workflow: string;
}

export const onElementsChanged = async (
  rootStore: RootStore,
  modeler: Modeler
) => {
  const processId = rootStore.processStore.currentProcessId;
  if (!processId) {
    newError('No current process found', true);
    return;
  }

  const saveXMLResult = await modeler.saveXML(SAVE_XML_OPTIONS);
  const { xml, error } = saveXMLResult;
  if (!xml || error) {
    newError(error, true, {
      customMessage: 'Error while generating the schema'
    });
    return;
  }

  const transformedXml = transformXML(xml);

  if (rootStore.processStore.currentProcess) {
    rootStore.processStore.currentProcess.bpmnWorkflow = transformedXml;
  } else {
    newError('No current process found', true);
  }

  const httpWrapper = new HTTPWrapper<boolean>('process');

  const dto: UpdateTemplateDTO = {
    bpmn_workflow: transformedXml
  };
  const response = await httpWrapper.put(`/${processId}`, dto);
  if (!response) {
    newError(response, true, {
      customMessage: 'Error while saving the schema'
    });
    return;
  }
};
