import { TraceKeyMode } from '@/mobx/models/traceKey/schema';
import { LucideIcon, Lock, PencilRuler, Sparkles } from 'lucide-react';

export const traceKeyModeDataMap: Record<
  TraceKeyMode,
  {
    label: string;
    icon: LucideIcon;
  }
> = {
  [TraceKeyMode.Follow]: {
    label: 'Follow',
    icon: Sparkles
  },
  [TraceKeyMode.Free]: {
    label: 'Custom',
    icon: PencilRuler
  },
  [TraceKeyMode.Locked]: {
    label: 'Locked',
    icon: Lock
  }
};
