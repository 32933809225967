import Model, { ModelError } from './base/base.model';
import AsyncStore from '@/mobx/store/base/async.store';
import { CustomElement } from '@pages/Settings/notifications/editor/slate';

export class NotificationTemplateModel extends Model {
  constructor(
    store: AsyncStore<NotificationTemplateModel>,
    id: Model['id'],
    public title: string,
    public text: CustomElement[],
    public createdAt: Date,
    public updatedAt: Date,
    private processId: string,
    loading?: boolean
  ) {
    super(store, id, loading);
  }

  public get templateProcessId() {
    return this.processId;
  }

  public set templateProcessId(processId: string) {
    this.processId = processId;
  }

  get toJSON() {
    return {
      title: this.title,
      text: this.text,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    };
  }

  get errors(): ModelError[] {
    return [];
  }
}
