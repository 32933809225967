import { TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const DateFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type DateFieldData = z.infer<ReturnType<typeof DateFieldDataSchema>>;

export const initialDateFieldData: DateFieldData = {
  title: 'Default Date Field Title',
  required: true
};
