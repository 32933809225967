import { NormalSourceData, SelectedDataRepositoryData } from './options';
import { DataSourceOption } from './checkbox.data';

export type DataSourceData = [NormalSourceData, SelectedDataRepositoryData];

export interface DataSourceOptionInfo {
  name: string;
  value: DataSourceOption;
  isDisabled?: boolean;
}

export const dataSourceOptions: DataSourceOptionInfo[] = [
  {
    name: 'Normal Source',
    value: 'normalSource'
  },
  {
    name: 'Data Repository',
    value: 'dataRepository'
  }
];
