import Model from '../base/base.model';
import { camelCase } from 'camel-case';
import { TraceKeyDTO, TraceKeyMode } from './schema';
import BaseStore from '@/mobx/store/base/base.store';
import { makeObservable, observable, reaction } from 'mobx';
import { FormMessage } from '@atoms/input';
import { ZodError, z } from 'zod';

export class TraceKeyModel extends Model {
  public value: string;
  public mode: TraceKeyMode;
  private readonly prodValue: Nullable<string>;
  public errors: FormMessage[] = [];

  constructor(store: BaseStore<Model>, id: string, traceKeyDTO: TraceKeyDTO) {
    super(store, id);
    this.value = traceKeyDTO.value ?? id;
    this.mode = traceKeyDTO.mode;
    this.prodValue = traceKeyDTO.prodValue ?? null;

    makeObservable(this, {
      value: observable,
      mode: observable
    });

    reaction(
      () => this.value,
      () => {
        this.checkErrors();
      },
      { fireImmediately: true }
    );

    reaction(
      () => this.value,
      () => {
        if (!this.store.rootStore.codeStore.lastCodeModel) return;
        this.store.rootStore.codeStore.lastCodeModel?.recomputePreCode();
      },
      {
        delay: 500
      }
    );
  }

  /** If the key's value is set manually, it should't be in `follow` mode anymore. */
  public setManualValue(newValue: string) {
    this.setValue(newValue);
    this.mode = TraceKeyMode.Free;
  }

  public followName(name: Maybe<string>) {
    if (typeof name !== 'string') return;
    if (this.mode !== TraceKeyMode.Follow) return;
    const newValue = camelCase(name);
    const errors = this.getErrors(newValue);

    if (errors.length > 0) {
      this.setDefaultValue();
      return;
    }

    this.setValue(camelCase(name));
  }

  private setValue(newValue: string) {
    if (newValue === '') this.setDefaultValue();
    else this.value = newValue;
  }

  private setDefaultValue() {
    this.setValue(this.id);
  }

  public setMode(
    newMode: TraceKeyMode.Follow | TraceKeyMode.Free,
    name?: string
  ) {
    this.mode = newMode;
    if (newMode === TraceKeyMode.Follow && name) {
      this.followName(name);
    }
  }

  public get warnings(): FormMessage[] {
    if (!this.isMigrationNeeded) return [];
    return [
      {
        message: 'This workflow will be migrated since this Trace key changed.'
      }
    ];
  }

  private get isMigrationNeeded(): boolean {
    if (!this.isInProd) return false;
    return this.prodValue !== this.value;
  }

  private checkErrors(valueToCheck: string = this.value): void {
    this.errors = this.getErrors(valueToCheck);
  }

  private getErrors(traceKeyValue: string): FormMessage[] {
    const errors: FormMessage[] = [];
    const zodSchema = z
      .string()
      .max(40, {
        message:
          'Max length of 40 characters. Try to keep the key short to save the 🌎.'
      })
      .refine((value) => value.length >= 3 || value.length === 0, {
        message: 'Min length of 3 characters.'
      })
      .refine((value) => !/\s/.test(value), {
        message: 'No whitespaces allowed.'
      })
      .refine((value) => /^[a-zA-Z0-9_\s]*$/.test(value), {
        message: 'Only alphanumeric characters and underscores allowed.'
      })
      .refine((value) => !/^\d/.test(value.charAt(0)), {
        message: 'The first character cannot be a number.'
      });

    try {
      zodSchema.parse(traceKeyValue);
    } catch (zodError) {
      if (zodError instanceof ZodError) {
        zodError.errors.forEach((error) => {
          errors.push({
            name: error.path.join('.'),
            message: error.message
          });
        });
      }
    }

    return errors;
  }

  public get visualValue(): string {
    if (this.value === this.id) return '';
    return this.value;
  }

  public get isInProd(): boolean {
    return !!this.prodValue;
  }

  get toJSON(): TraceKeyDTO {
    return {
      value: this.value,
      mode: this.mode,
      prodValue: this.prodValue
    };
  }
}
