import { ReactNode, useContext, useState } from 'react';
import { Settings, Trash2 } from 'lucide-react';
import styled, { css } from 'styled-components';
import { Checkbox } from '@mui/joy';
import { DNDBlock, DNDMetaContext } from '@components/dnd/base';
import { observer } from 'mobx-react';
import { AtomModel } from '@/mobx/models/atom.model';
import useStores from '@hooks/useStore';
import { GlassModal } from '@atoms/glassModal';
import { TraceKeyInput } from '@atoms/traceKeyInput/traceKeyInput';

interface EndControlsLineProps {
  $isDisplayed: boolean;
}

const EndControlsLine = styled.div<EndControlsLineProps>`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  gap: 0.6rem;
  align-items: center;
  user-select: none;
  opacity: 1;

  ${(p) =>
    !p.$isDisplayed &&
    css`
      opacity: 0;
    `}
`;

const Control = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ICON_SIZE = 17;

export interface SettingsConfig {
  title: string;
  content: ReactNode;
}

type EndControlsProps = {
  hasDelete?: boolean;
  noDeleteCheck?: boolean;
  isHovering?: boolean;
  blockPath?: string;
  settings?: SettingsConfig;
  children?: ReactNode;
  dndBlock: DNDBlock;
} & (
  | {
      hasRequired: true;
      dataItem: AtomModel<{ required: boolean }>;
    }
  | {
      hasRequired: false;
      dataItem: AtomModel;
    }
);
const EndControlsFB = ({
  dndBlock,
  dataItem: atom,
  isHovering = true,
  hasDelete = true,
  noDeleteCheck = false,
  hasRequired,
  settings: settingsConfig,
  children
}: EndControlsProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { onDelete } = useContext(DNDMetaContext);

  const { modalStore } = useStores();

  const isDeletable = atom.isDeletable;

  const onDeleteClick = () => {
    if (!noDeleteCheck && !isDeletable) {
      modalStore.referenceModal.open(
        'atom',
        atom.id,
        'referencedBy',
        atom.metaInfo.dnd.blockType
      );

      return;
    }
    onDelete(dndBlock.path);
  };

  return (
    <>
      <EndControlsLine $isDisplayed={isHovering}>
        {hasRequired && (
          <Checkbox
            label="Required"
            variant="outlined"
            color="neutral"
            size="sm"
            checked={atom.data.required}
            onChange={(e) => (atom.data.required = e.target.checked)}
          />
        )}
        <Control>{children}</Control>

        {hasDelete && (
          <Control data-tag="delete-control" onClick={onDeleteClick}>
            <Trash2 size={ICON_SIZE} />
          </Control>
        )}
        {settingsConfig && (
          <Control onClick={() => setOpenModal(true)}>
            <Settings size={ICON_SIZE} />
          </Control>
        )}
      </EndControlsLine>
      {settingsConfig && openModal && (
        <GlassModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          header={{ title: `Settings — ${settingsConfig.title}` }}
        >
          <section className="flex flex-col gap-4 p-4">
            <TraceKeyInput
              traceKey={atom.traceKey}
              // @ts-expect-error parentName is not a required prop
              parentName={atom.data.title as Maybe<string>}
            />
            {settingsConfig.content}
          </section>
        </GlassModal>
      )}
    </>
  );
};

export default observer(EndControlsFB);
