// @ts-check
import { IMPORT_STATUS } from '@pages/StratumnEditor';
import CommandInterceptor from 'diagram-js/lib/command/CommandInterceptor';
import nanoID from '@/utils/nanoID';

/**
 * @typedef {import("diagram-js/lib/core/EventBus").default} EventBus
 * @typedef {import("bpmn-js/lib/features/modeling/Modeling").default} Modeling
 */

class CustomNanoIds extends CommandInterceptor {
  /**
   * @param {EventBus} eventBus
   * @param {Modeling} modeling
   */
  constructor(eventBus, modeling) {
    super(eventBus);

    this.postExecute('shape.create', 99999, function ({ context }) {
      // import_status is a global var for convienence
      // the value is IMPORTED or IMPORTING
      if (IMPORT_STATUS == 'IMPORTED') {
        // replace the id only for new shape
        const shape = context.shape;
        const old_id = shape.id;
        const type = old_id.split('_')[0];
        const new_id = `${type}_std${nanoID(14)}`;

        modeling.updateProperties(context.shape, {
          id: new_id
        });
      }
    });

    this.postExecute('connection.create', 99999, function ({ context }) {
      // import_status is a global var for convienence
      // the value is IMPORTED or IMPORTING
      if (IMPORT_STATUS == 'IMPORTED') {
        // replace the id only for new shape

        const shape = context.connection;
        const old_id = shape.id;
        const type = old_id.split('_')[0];
        const new_id = `${type}_std${nanoID(14)}`;

        modeling.updateProperties(context.connection, {
          id: new_id
        });
      }
    });

    this.postExecuted('shape.create', function (context) {
      if (IMPORT_STATUS == 'IMPORTED') {
        eventBus.fire('studio.shape.create', context);
      }
    });

    this.postExecuted('shape.delete', function (context) {
      if (IMPORT_STATUS == 'IMPORTED') {
        eventBus.fire('studio.shape.delete', context);
      }
    });

    this.postExecuted('connection.create', function (context) {
      if (IMPORT_STATUS == 'IMPORTED') {
        eventBus.fire('studio.connection.create', context);
      }
    });

    this.postExecuted('connection.delete', function (context) {
      if (IMPORT_STATUS == 'IMPORTED') {
        eventBus.fire('studio.connection.delete', context);
      }
    });
  }
}

CustomNanoIds.$inject = ['eventBus', 'modeling'];

export default CustomNanoIds;
