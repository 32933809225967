import { observer } from 'mobx-react';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '../../formBuilder.type';
import { DateModal } from './date.modal';
import { useAtom } from '@hooks/useAtom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { DateIcon } from './date.utils';
import { initialDateFieldData } from './date.data';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

const DateField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialDateFieldData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Date
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.TextField} title`,
      content: <DateModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={DateIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(DateField);
