import { observer } from 'mobx-react';
import { useState } from 'react';
import useStores from '@/hooks/useStore';
import DNDBase from '@/components/dnd/base';
import { Grid3X3 } from 'lucide-react';
import SettingHelper from '../SettingHelper';
import { SettingBody } from '../style';
import { Modal, ModalClose } from '@mui/joy';
import StateMenu from '@components/stateMenu';
import ActionButton from '@atoms/button';
import { SettingsContent } from '../settings.style';
import useProcess from '@hooks/useProcess';
import SettingHeader from '../SettingHeader';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import { newError } from '@/services/errors/errors';
import { isTOBlocks } from '@components/dnd/library/traceOverview';
import { toast } from 'sonner';

const TraceOverview = () => {
  const { dndStore, atomStore: dataItemStore } = useStores();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const { process } = useProcess();

  const traceOverviewDnd = dndStore.get(
    process?.workflows[0]?.traceOverviewDndId
  );

  if (!process || !traceOverviewDnd) return <></>;

  const onSelected = (selectedVariableRef: DataItemReference) => {
    const selectedVariable = dataItemStore.getAtomById(
      selectedVariableRef.dataItemId,
      selectedVariableRef.blockType
    );

    if (!selectedVariable || selectedVariable instanceof Error) {
      throw newError('Variable not found');
    }

    if (!selectedVariable.variableInfo) {
      throw newError('Selected variable has no variable infos');
    }

    const TOBlockType = `TO_${selectedVariable.variableInfo.resolvedType}`;

    if (!isTOBlocks(TOBlockType)) {
      toast.info('Cannot select this type of variable yet.');
      throw newError('Selected variable resolved type is not a TI block');
    }

    const newTOBlock = traceOverviewDnd.createDndBlock(TOBlockType, '');

    newTOBlock.other.selectedVariableRef = selectedVariableRef;

    const path = 'root:root|0';

    // The new TO block is added to the window
    const stateWithNewBlock = traceOverviewDnd.insertBlockInState(
      path,
      traceOverviewDnd.state,
      newTOBlock
    );

    traceOverviewDnd.setState(stateWithNewBlock);

    // wait async 0.5s to make sure the new block is added to the state
    setTimeout(() => {
      selectedVariable.addReferencingAtom(newTOBlock.atomId);
    }, 500);
  };

  return (
    <SettingsContent>
      <SettingHeader
        title="Trace Overview"
        description="Customize the big summary table where all trace are referenced"
      />

      <SettingHelper
        title="Trace overview"
        icon={<Grid3X3 />}
        description="The Trace overview is the table you see when you open your workflow in Trace. It shows all the Traces in your workflow and gives you an overview of their state. Only use the most relevant variables here. You can change what columns should be displayed by adding widgets."
      />

      {process.loading && <></>}
      {!process.loading && (
        <SettingBody>
          <div className="title-body">
            <h3>Trace overview settings</h3>
            <ActionButton
              value="Add a column"
              onClick={() => setOpenModal(true)}
            />
          </div>
          <DNDBase dnd={traceOverviewDnd} />
        </SettingBody>
      )}

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '70px'
        }}
      >
        <>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <StateMenu
            onSelected={onSelected}
            onClose={() => setOpenModal(false)}
            isOverview
          />
        </>
      </Modal>
    </SettingsContent>
  );
};

export default observer(TraceOverview);
