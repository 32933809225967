import { DNDBlock } from '@components/dnd/base';
import { AllBlocks, DndLibrary } from '@components/dnd/library';
import { deepCopy } from '@/utils/deepCopy';
import { computed, makeObservable, observable, reaction, toJS } from 'mobx';
import Model, { ModelError } from './base/base.model';
import BaseStore from '@/mobx/store/base/base.store';
import nanoID from '@/utils/nanoID';
import { newError } from '@/services/errors/errors';
import { toast } from 'sonner';
import { ENV } from '@/utils/constants';
import { AtomModel } from './atom.model';
import { EmptyFormDataType } from './code/constants';
import { atomHasTitle, isFieldRequired } from './code/utils';
import { AtomWithTitle } from './code/types';

export interface DropZone {
  path: string;
}

export interface Item {
  name?: string;
  id: string;
  path: string;
  type: AllBlocks;
}

interface DeleteResults {
  rootState: DNDBlock;
  deletedBlock: DNDBlock;
  deletedBlockOldIndex: number;
}

export class DNDModel<Lib extends DndLibrary = DndLibrary> extends Model {
  state: DNDBlock;
  constructor(
    store: BaseStore<Model>,
    public library: Lib,
    id: Model['id'] = nanoID(), //! check if this is needed
    state?: DNDBlock,
    loading?: boolean
  ) {
    super(store, id, loading);
    this.state = state ?? DNDModel.getNewRootDNDBlock();
    if (this.isFormBuilderDndModel()) this.computeFormDataTsType();

    makeObservable(this, {
      state: observable,
      toJSON: computed
    });

    reaction(
      () => toJS(this.state),
      () => {
        if (['local', 'staging'].includes(ENV)) {
          toast.info(`Updated DND block ${this.id}`); // ! TEMP for debugging
        }
        this.store.update(this.id).catch((error: Error) => {
          newError(error, true);
        });
        this.store.rootStore.codeStore.lastCodeModel?.recomputePreCode();
      },
      {
        delay: 1000
      }
    );
  }

  get toJSON() {
    return toJS(this.state);
  }

  setState(state: DNDBlock) {
    this.state = state;
    this.store.set(this.id, this);
  }

  createDndBlock(
    type: DNDBlock['type'],
    path: string = 'not relevant'
  ): DNDBlock {
    const element: DNDBlock = {
      type,
      path,
      atomId: nanoID(21),
      other: {},
      props: {}
    };

    return element;
  }

  static getNewRootDNDBlock(): DNDBlock {
    const newRootBlock: DNDBlock = {
      path: 'root',
      type: 'root',
      atomId: nanoID(21),
      props: {
        root: []
      },
      other: {}
    };

    return newRootBlock;
  }

  /** Deletes a block in the state using its provided path to locate it.
   *  After deleting it from Drag & Drop state, the dataItem related to it is also deleted in the dataItemStore.
   */
  deleteBlockByPath(path: string) {
    const previousState = deepCopy(this.state);
    const deleteResult: DeleteResults = this.deleteBlockFromState(
      path,
      this.state
    );

    const newStateAfterDelete = deleteResult.rootState;
    this.setState(newStateAfterDelete);

    try {
      const atomId = deleteResult.deletedBlock.atomId;
      if (!atomId) return;

      this.store.rootStore.atomStore
        .deleteAtom(atomId)
        .then((isDeleted) => {
          if (!isDeleted) {
            throw new Error(
              `Could not delete dataItem related to dndBlock with id ${deleteResult.deletedBlock.id}`
            );
          }
        })
        .catch((error) => {
          newError(error, true);
          this.setState(previousState); // ? we rollback the state if the deletion of the atom failed
        });
    } catch (error) {
      newError(error);
      this.setState(previousState); // ? we rollback the state if the deletion of the atom failed
    }
  }

  // delete a DNDBlock from the DNDState
  deleteBlockFromState(path: string, state: DNDBlock): DeleteResults {
    const clonedState: DNDBlock = deepCopy(state);
    let deletedElement = <DNDBlock>{};
    let deletedElementIndex = -1;

    /**  Goes through all the elements of the state, in recursion */
    const deleteInProps = (element: DNDBlock): void => {
      if (!element.props) return;
      const keys: string[] = Object.keys(element.props);
      for (const key of keys) {
        const currentElement = element.props[key];

        if (Array.isArray(currentElement)) {
          const indexToRemove = currentElement.findIndex(
            (block: DNDBlock) => block.path === path
          );

          if (indexToRemove >= 0) {
            // remove the element from the list, in the ref

            deletedElement = currentElement.splice(indexToRemove, 1)[0];
            deletedElementIndex = indexToRemove;
          } else {
            currentElement.forEach(deleteInProps);
          }
        }
      }
    };

    deleteInProps(clonedState);

    return {
      rootState: clonedState,
      deletedBlock: deletedElement,
      deletedBlockOldIndex: deletedElementIndex
    };
  }

  onDropFunction(dropZone: DropZone, item: Item) {
    let deleteResults = <DeleteResults>{};

    if (item.path === 'toolbar') {
      const newBlock = this.createDndBlock(item.type);
      deleteResults.rootState = this.state;
      deleteResults.deletedBlock = newBlock;
    } else {
      deleteResults = this.deleteBlockFromState(item.path, this.state);
    }
    const newState = this.insertBlockInState(
      dropZone.path,
      deleteResults.rootState,
      deleteResults.deletedBlock,
      deleteResults.deletedBlockOldIndex
    );
    this.setState(newState);
  }

  // put the object in the path
  insertBlockInState(
    dropzonePath: string,
    currentState: DNDBlock,
    blockToAdd: DNDBlock,
    oldBlockIndex?: number
  ): DNDBlock {
    const clone_state: DNDBlock = deepCopy(currentState);
    /** A path is "root/a/b/c:props|0"*/
    const path = dropzonePath.split(':')[0];
    const props_key = dropzonePath.split(':')[1].split('|')[0];
    const newBlockIndex = parseInt(dropzonePath.split(':')[1].split('|')[1]);

    /** Finds the DNDBlock in which the element will be placed.*/
    const findParentblockAndPlaceBlock = (
      subState: DNDBlock,
      propsKey: string
    ): void => {
      // we are in the block we wanted to work on
      if (subState.path == path) {
        // ensure the props_key exists in the props
        // can break for toolbar component
        if (!subState.props?.[propsKey]) {
          if (!subState.props) subState.props = {};
          subState.props[propsKey] = [];
        }

        const refProps = subState?.props[propsKey];
        if (typeof refProps == 'string') {
          newError('cannot have string and DNDBlock in the same props');
        }
        const oldBlockPath = deepCopy(blockToAdd.path);
        const newPathId = nanoID();
        blockToAdd.path = `${path}/${newPathId}`;

        if (
          typeof oldBlockIndex == 'number' &&
          oldBlockIndex >= 0 &&
          oldBlockIndex < newBlockIndex && // if the block is moved down
          this.areBlocksAndDropzoneInTheSameParent(oldBlockPath, path) // and the block is in the same parent as the dropzone
        ) {
          const correctedNewIndex = newBlockIndex - 1;
          refProps.splice(correctedNewIndex, 0, blockToAdd);
        } else {
          refProps.splice(newBlockIndex, 0, blockToAdd);
        }
      }

      const props_keys = Object.keys(subState.props ?? {});
      for (const key of props_keys) {
        const props_ref = subState.props?.[key];
        // just make sure the props is an array
        if (!props_ref) continue;

        for (const e of props_ref) {
          findParentblockAndPlaceBlock(e, propsKey);
        }
      }
    };

    findParentblockAndPlaceBlock(clone_state, props_key);

    return clone_state;
  }

  areBlocksAndDropzoneInTheSameParent(
    blockPath: string,
    dropPath: string
  ): boolean {
    const splitedBlockPath = blockPath?.split('/');
    splitedBlockPath.pop();
    const finalBlockPath = splitedBlockPath.join('/');

    return finalBlockPath === dropPath;
  }

  get atom(): Maybe<AtomModel> {
    return this.store.rootStore.atomStore.get(this.state.atomId);
  }

  public getAllAtoms(
    blocks: Maybe<DNDBlock[]> = this.state.props?.root
  ): AtomModel[] {
    if (!blocks) return [];

    const atoms: AtomModel[] = [];

    const extractAtoms = (blocks: DNDBlock[]) => {
      blocks.forEach((block: DNDBlock) => {
        const atomModel = this.store.rootStore.atomStore.get(block.atomId);
        if (atomModel) atoms.push(atomModel);

        Object.values(block.props ?? {}).forEach((nextBlocks) => {
          if (Array.isArray(nextBlocks)) extractAtoms(nextBlocks);
        });
      });
    };

    extractAtoms(blocks);
    return atoms;
  }

  /* -------------------------------------------------------------------------- */
  /*                            CODE TYPE GENERATOR                           */
  /* -------------------------------------------------------------------------- */

  private getBlocksToAtomsModelMap() {
    if (!this.state.props?.root) return {};
    const blockToAtomMap: Record<
      string,
      {
        dndBlock: DNDBlock;
        atomModel: AtomModel;
      }
    > = {};
    this.state.props.root.forEach((dndBlock: DNDBlock) => {
      if (!dndBlock?.atomId || !dndBlock.id) return;
      const atomModel = this.store.rootStore.atomStore.get(dndBlock.atomId);
      if (!atomModel) return;
      blockToAtomMap[dndBlock.id] = {
        dndBlock,
        atomModel
      };
    });

    return blockToAtomMap;
  }

  // public get formDataTsType(): string {
  //   if (this.library !== DndLibrary.FormBuilder) return NeverFormDataType;
  //   return this.computeFormDataTsType();
  // }

  public computeFormDataTsType(
    this: this['library'] extends DndLibrary.FormBuilder
      ? DNDModel<DndLibrary.FormBuilder>
      : never
  ): string {
    if (!Array.isArray(this.state.props?.root)) return EmptyFormDataType;

    const blockToAtomMap = this.getBlocksToAtomsModelMap();
    const fields = Object.values(blockToAtomMap);

    let formDataTsTypeContent = '';
    for (const field of fields) {
      if (!atomHasTitle(field.atomModel)) continue;
      formDataTsTypeContent += this.getFormFieldLine(
        field.dndBlock,
        field.atomModel
      );
    }
    return `{\n${formDataTsTypeContent}\n};`;
  }

  private getFormFieldLine(dndBlock: DNDBlock, atom: AtomWithTitle): string {
    const formFieldType = atom.getTypeScriptType(dndBlock.type);
    const isRequired = isFieldRequired(atom);
    const fieldKey = atom.traceKey.value;

    const source = atom.source;

    let formFieldJsDoc = `Path of the field **${fieldKey}** with DND block ID \`${dndBlock.id}\``;
    if (source) {
      formFieldJsDoc += ` defined in {@link http://www.google.com|Google}`;
    }
    formFieldJsDoc;
    /** ${formFieldJsDoc}.*/
    return `
  ${fieldKey}${!isRequired ? '?' : ''}: ${formFieldType}; \n`;
  }

  isFormBuilderDndModel(): this is DNDModel<DndLibrary.FormBuilder> {
    return this.library === DndLibrary.FormBuilder;
  }

  get errors(): ModelError[] {
    return [
      {
        message: ''
      }
    ];
  }
}
