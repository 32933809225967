import { Outlet, useParams } from 'react-router-dom';
import PanelHeader from '@pages/panel/PanelHeader';
import { PanelBody } from '@pages/panel/panel.style';
import { observer } from 'mobx-react';
import { ACTION_TAB_LINKS } from './action.config';
import { ParamsList } from '@/routes/routes.types';
import { useEffect } from 'react';
import useProcess from '@hooks/useProcess';
import useAction from '@hooks/useAction';
import useStores from '@hooks/useStore';

const Action = () => {
  const { workflowStore } = useStores();
  const actionId = useParams<string>()[ParamsList.ActionId] as string;
  const { process } = useProcess();
  const action = useAction();
  const wf = workflowStore.get(action?.workflow_id);

  useEffect(() => {
    if (!process?.bpmn?.modeler) return;
    process.bpmn.setSelectedElementByElementId(actionId);
    return () => process?.bpmn?.resetSelectedElement();
  }, [actionId, process?.bpmn, process?.bpmn?.modeler]);

  if (process?.loading) {
    return <></>;
  }

  return (
    <>
      <PanelHeader
        workflowName={wf?.name ?? 'Error WF'}
        infos={[action?.name ?? 'Error action']}
        actionType="form action"
        tabLinks={ACTION_TAB_LINKS}
      />

      <PanelBody>
        <Outlet />
      </PanelBody>
    </>
  );
};

export default observer(Action);
