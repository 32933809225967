import { DynamicType, StaticType } from './types';

export const STATIC_TYPES = `
type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? \`\${Key}\` | \`\${Key}.\${NestedKeyOf<ObjectType[Key]>}\` 
    : \`\${Key}\`;
}[keyof ObjectType & (string | number)];

type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};


type ${StaticType.Email} = \`\${string}@\${string}.\${string}\`;
type ${StaticType.URL} = \`\${'http' | 'https'}://\${string}\`;
type ${StaticType.Date} = \`\${number}-\${number}-\${number}\`;
type ${StaticType.DateTime} = \`\${number}-\${number}-\${number}T\${number}:\${number}:\${number}Z\`;

type ${StaticType.ActionComment} = {
  action: string;
  comment: string;
  date: string;
  group: string;
  groupId: string;
  groupLabel: string;
  user: {
    accountId: string;
    avatar: ${StaticType.URL};
    name: string;
  };
};

/** The type of a file uploaded in a Trace form. */
type ${StaticType.FormFile} = {
  id: string;
  key: string;
  name: string;
  /** Size of the file in bits. */
  size: number;
  digest: string;
  mimetype: string;
  /** Timestamp of the file creation in Media API. */
  createdAt: ${StaticType.DateTime};
  uploading: boolean;
}
`;

export const starterTypes = `
${STATIC_TYPES}

type Data = Prettify<Partial<
  ${DynamicType.StateData} & {
    comments: ActionComment[];
  }
>> & {
    global: Partial<${DynamicType.GlobalState}>;
    context: TraceContext;
  };

type ${StaticType.ToBeDetermined} = any;

type LinkCreatedBy = {
  type?: string;
  accountId?: string;
  name: string;
  firstName?: string;
  lastName?: string;
  avatar: ${StaticType.URL} | null;
  email?: ${StaticType.Email};
  // user ID for back-compatibility
  id?: string;
};

type LinkMetaData = {
  createdAt: ${StaticType.DateTime};
  action: {
    key: string;
    title: string;
    icon: string;
  };
  createdBy: LinkCreatedBy;
  owner: {
    id: string;
    name: string;
    avatar: ${StaticType.URL} | null;
  };
  group: {
    label: string;
    id: string;
    name: string;
    avatar: ${StaticType.URL} | null;
  };
  process: Process;
  inputs: string[];
  traceCreatedAt: ${StaticType.DateTime};
  traceId: string;
  traceName: string;
  traceShortId: string;
};

type NextActions = Record<string, ${DynamicType.Action}[]>;
type Tasks = {
  /** The current groups' priorities.*/
  groups: Record<
    string,
    {
      /** Determines how responsible the group is in regards to handling a trace. */
      responsibility: number;
      /** The priority actions of the current group. */
      todo: ${DynamicType.Action}[];
    }
  >;
  /** The current Trace's deadlines. */
  deadlines?: {
    date: ${StaticType.URL};
  }[];
};

type TraceContext = {
  /**
   *  The next actions after the effects execution.
   *  - Use \`nextActions.normal\` to add actions to lanes that are not persistent.
   *  - Use \`nextActions.persistent\` to add actions to lanes that are persistent.
   * */
  nextActions: {
    /** Targets all lanes' persistent actions. */
    persistent: PartialRecord<${DynamicType.Lanes}, ${DynamicType.Action}[]> & {
      /** Targets all lanes' persistent actions. */
      '*'?: ${DynamicType.Action}[];
    };
    /** Targets all lanes' normal actions. */
    normal: PartialRecord<${DynamicType.Lanes}, ${DynamicType.Action}[]>;
  };
  /** Stores each lane value here.
   * @example lanes.entity = 'AXA XL/FR';
   */
  lanes: PartialRecord<${DynamicType.Lanes}, string>;
};

type TraceState = {
  /** The data of the current state */
  data: Data;
  /** The priorities of the current Trace */
  tasks: Tasks;
  /**
   * The computed next actions.
   *
   * Do not assign values directly in this field, use \`data.context.nextActions\` instead.
   * */
  nextActions: Readonly<NextActions>;
  notifications: unknown[];
};

type $Variables = {
  /** The link object. @deprecated */
  link: Readonly<ChainScriptBaseLink>;
  meta: Readonly<LinkMetaData>;
  formData: Readonly<${DynamicType.FormData}>;
  state: TraceState;
  action: Readonly<unknown>;
};

type FilterPath = \`\${('data' | 'meta')}.\${string}\`;

interface TextFilterValue {
  type: 'text';
  path: FilterPath;
  value: string | string[];
  exact?: boolean;
  not?: boolean;
}

interface NumberFilterValue {
  type: 'number';
  path: FilterPath;
  value: string;
}

interface DateFilterValue {
  type: 'date';
  path: FilterPath;
  value: string | string[];
  inputFormat?: string;
  format?: string;
}

type JSONDeepFilterValue =
  | TextFilterValue
  | NumberFilterValue
  | DateFilterValue;

type Attachment = {
  digest: string;
};

type NotificationData = {
  attachments?: Attachment[];
};
type OfflineNotificationOptions = {
  /** Email of the notified user */
  email: ${StaticType.Email};
  /** Trace id */
  traceId: string;
  /** Workflow ID */
  workflowId: string;
  /** Trace name */
  traceName: string;
  /** Group id */
  groupId: string;
  /** Action key */
  action: string;
  /** Email sender name */
  senderName: string;
  /** Template name */
  templateName?: string;
  /** Template version */
  templateVersion?: string;
  /** Email subject */
  emailSubject: string;
  /** Email body */
  emailBody: string;
  /** Email data */
  data?: NotificationData;
};

type Link = any;
type Process = {
  name: string;
  state: 'FREE' | 'OWNED' | 'PUSHING';
};

type ChainScriptProtoLink =  {
  /** Link version. */
  version: string;

  /** Link data. */
  data: Uint8Array;

  /** Link meta. */
  meta?: (${StaticType.ToBeDetermined}|null);

  /** Link signatures. */
  signatures: ${StaticType.ToBeDetermined}[];
  /**
   * Creates a Link message from a plain object. Also converts values to their respective internal types.
   * @param object Plain object
   * @returns Link
   */
  fromObject(object: { [k: string]: any }): ${StaticType.ToBeDetermined};

  /**
   * Creates a plain object from a Link message. Also converts values to other types if specified.
   * @param message Link
   * @param [options] Conversion options
   * @returns Plain object
   */
  toObject(message: ${StaticType.ToBeDetermined}, options?: ${StaticType.ToBeDetermined}): { [k: string]: any };

  /**
   * Converts this Link to JSON.
   * @returns JSON object
   */
  toJSON(): { [k: string]: any };
};

type ChainScriptBaseLink = {
  link: ChainScriptProtoLink;
  /**
   * A link is usually created as a result of an action.
   * @returns the link's action.
   */
  action(): string;
  /**
   * Add a signature to the link.
   * This will validate the signature before adding it.
   * @param signature link signature.
   */
  addSignature(signature: ${StaticType.ToBeDetermined}): void;
  /**
   * The client id allows segment receivers to figure out how the segment was
   * encoded and can be decoded.
   * @returns the link's client id.
   */
  clientId(): string;
  /**
   * The link data (business logic details about the execution of a process step).
   * @returns the object containing the link details.
   */
  data(): any;
  /**
   * Serialize the link and compute a hash of the resulting bytes.
   * The serialization and hashing algorithm used depend on the link version.
   * @returns the hash bytes.
   */
  hash(): Uint8Array;
  /**
   * A link always belongs to a specific process map.
   * @returns the link's map id.
   */
  mapId(): string;
  /**
   * The link metadata can contain a custom object.
   * @returns the object containing the link metadata details.
   */
  metadata(): any;
  /**
   * Maximum number of children a link is allowed to have.
   * This is set to -1 if the link is allowed to have as many children as it
   * wants.
   * @returns the maximum number of children allowed.
   */
  outDegree(): number;
  /**
   * A link can have a parent, referenced by its link hash.
   * @returns the parent link hash.
   */
  prevLinkHash(): Uint8Array;
  /**
   * The priority can be used to order links.
   * @returns the link's priority.
   */
  priority(): number;
  /**
   * A link always belong to an instance of a process.
   * @returns the link's process name.
   */
  process(): Process;
  /**
   * A link can contain references to other links.
   * @returns referenced links.
   */
  refs(): ${StaticType.ToBeDetermined}[];
  /**
   * Create a segment from the link.
   * @returns the segment wrapping the link.
   */
  segmentify(): ${StaticType.ToBeDetermined};
  /**
   * Serialize the link.
   * @returns link bytes.
   */
  serialize(): Uint8Array;
  /**
   * Set the given object as the link's data.
   * @param data custom data to save with the link.
   */
  setData(data: any): void;
  /**
   * Set the given object as the link's metadata.
   * @param data custom data to save with the link metadata.
   */
  setMetadata(data: any): void;
  /**
   * Sign configurable parts of the link with the current signature version.
   * The payloadPath is used to select what parts of the link need to be signed
   * with the given private key. If no payloadPath is provided, the whole link
   * is signed.
   * The signature is added to the link's signature list.
   * @param key private key in PEM format (generated by @stratumn/js-crypto).
   * @param payloadPath link parts that should be signed.
   */
  sign(key: Uint8Array, payloadPath: string): void;
  /**
   * @returns the link's signatures (if any).
   */
  signatures(): ${StaticType.ToBeDetermined}[];
  /**
   * Compute the bytes that should be signed.
   * @argument version impacts how those bytes are computed.
   * @argument payloadPath parts of the link that should be signed.
   * @returns bytes to be signed.
   */
  signedBytes(version: string, payloadPath: string): Uint8Array;
  /**
   * (Optional) A link can be interpreted as a step in a process.
   * @returns the corresponding process step.
   */
  step(): string;
  /**
   * (Optional) A link can be tagged.
   * Tags are useful to filter link search results.
   * @returns link tags.
   */
  tags(): string[];
  /**
   * Convert to a plain object.
   * @argument conversionOpts specify how to convert certain types.
   * @returns a plain object.
   */
  toObject(conversionOpts?: ${StaticType.ToBeDetermined}): any;
  /**
   * Validate checks for errors in a link.
   */
  validate(): void;
  /**
   * The link version is used to properly serialize and deserialize it.
   * @returns the link version.
   */
  version(): string;  
};

type AddTagType = { traceId?: string; tags: string[] };

type YouSignFile = {
  digest: string;
  name: string;
  size: BigInteger;
  key: string;
  createdAt: string;
};

type $Modules = {
  defaultStateUpdate: (
    state: unknown,
    action: unknown,
    formData: unknown
  ) => Promise<unknown>;
  decrypt: (ciphertext: string) => unknown;
  createOfflineNotification: (
    options: OfflineNotificationOptions
  ) => Promise<void>;
  defaultNextActions: (
    meta: Link | LinkMetaData,
    currentActions: unknown
  ) => Promise<{
    [x: number]: unknown;
  }>;
  transferStateUpdate: (
    meta: Link | LinkMetaData,
    currentState: unknown
  ) => Promise<{
    data: unknown;
    nextActions: {
      [x: number]: string[];
    };
  }>;
  transferNextActions: (meta: Link | LinkMetaData) => Promise<{
    [x: number]: unknown[];
  }>;
  createLink: (args: {
    action: string;
    workflowId: string;
    groupLabel: string;
    formData?: unknown;
    refs?: unknown;
    tags?: string[] | undefined;
    traceId?: string | undefined;
    createdAt?: string | undefined;
  }) => Promise<unknown>;
  searchTrace: (args: {
    workflowId: string;
    filters: JSONDeepFilterValue[];
  }) => Promise<unknown>;
  searchTraces: (args: {
    workflowId: string;
    filters: JSONDeepFilterValue[];
  }) => Promise<unknown[]>;
  addTraceTags: (...args: AddTagType[]) => Promise<void>;
  externalFetch: (args: {
    url: string;
    method: 'get' | 'post' | 'put' | 'delete' | 'patch';
    body?: unknown;
    headers?: { [name: string]: string };
    responseType?: 'json' | 'text';
  }) => Promise<{
    headers: unknown;
    body: unknown;
  }>;
  createAndActivateYousignSignature: (args: {
    step: string;
    fileToSign?: YouSignFile;
    fields?: unknown[];
    signature_authentication_mode?: string;
    manual_fields?: boolean;
    signatureRequestName?: string;
    fileNature?: string;
    documentsData?: unknown[];
    locale?: string;
    signer?: {
      email: ${StaticType.Email};
    };
    signatureData?: unknown;
    parseAnchors?: string;
    cancelReason?: string;
    cancelCustomNote?: string;
    documentSigned?: YouSignFile;
    documentSignedName?: string;
    auditDocument?: YouSignFile;
    auditDocumentName?: string;
  }) => Promise<unknown>;
  createScheduler: (args: {
    name: string;
    description?: string;
    webhook_url: string;
    webhook_payload?: unknown;
    cron_expression: string;
    created_by: string;
    starts_at?: string;
    ends_at?: string;
  }) => Promise<unknown>;
  stopScheduler: (args: { id: string }) => Promise<unknown>;
  addCommentToState: (args: {
    commentPath: NestedKeyOf<${DynamicType.FormData}>;
  }) => Promise<void>;
};

type $Definitions = Record<string, unknown>;

type Definitions = Readonly<$Definitions>;
type Functions = Readonly<$Modules>;

type DSLContext = {
  $variables: $Variables;
  $definitions?: Readonly<$Definitions>;
  $modules?: Readonly<$Modules>;
  $break?: Readonly<true>;
};
` as const;
