import { Book, ChevronRight } from 'lucide-react';
import {  NotificationContainer, NotificationDescription, NotificationIcon } from './style';
import { Link } from 'react-router-dom';
import { ContentBase, IconBase } from '@pages/Settings/style';

type NotificationItemProps = {
  id: string;
  title: string;
  updateAt: Date
}

const NotificationItem = ({
  id,
  title,
  updateAt
}: NotificationItemProps) => {

  const timeAgo = (date) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - date) / 1000);
  
    if (diffInSeconds < 30) {
      return 'just now';
    } else if (diffInSeconds < 60) {
      return '1 minute ago';
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minutes ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} hours ago`;
    } else if (diffInSeconds < 2592000) {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} days ago`;
    } else if (diffInSeconds < 31536000) {
      const months = Math.floor(diffInSeconds / 2592000);
      return `${months} months ago`;
    } else {
      const years = Math.floor(diffInSeconds / 31536000);
      return `${years} years ago`;
    }
  }

  return (
    <Link to={`${id}`}>
      <NotificationContainer>
        <IconBase><Book /></IconBase>
        <ContentBase>
          <h2>{title}</h2>
          <NotificationDescription>Last edited {timeAgo(updateAt)}</NotificationDescription>
        </ContentBase>
        <NotificationIcon>
          < ChevronRight />
        </NotificationIcon>
      </NotificationContainer>
    </Link>
  );
};

export default NotificationItem;
