import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormLabel } from '@mui/joy';
import { CircleSlash2, LucideIcon } from 'lucide-react';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { InteractionZone } from '@components/dnd/base/interaction';
import { useAtom } from '@hooks/useAtom';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

export const NotIcon: LucideIcon = CircleSlash2;

const Not = (dndBlock: DNDBlock) => {
  const dndPath = `${dndBlock.path}:not`;
  const notBlock: DNDBlock[] = dndBlock.props?.not || [];
  const transitionId = useParams()[ParamsList.TransitionId];

  const dataItem = useAtom({
    block: dndBlock,
    initialData: {},
    dataType: 'condition',
    sourceId: transitionId,
    sourceName: 'Transition',
    library: 'condition'
  });

  // Add reference between parent and child
  useEffect(() => {
    if (!dataItem) return;
    notBlock.forEach((el) => {
      if (el.atomId === dndBlock.atomId) return;
      dataItem.addReferencingAtom(el.atomId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem, dndBlock]);

  if (!dataItem) return <></>;

  return (
    <BlockBase
      dndBlock={dndBlock}
      hasTitle={false}
      icon={NotIcon}
      isStatement
      endControls={
        <EndControlsFB
          dataItem={dataItem}
          hasRequired={false}
          dndBlock={dndBlock}
        />
      }
    >
      <Column>
        <FormLabel sx={{ fontSize: '12px' }}>Condition</FormLabel>
      </Column>
      <Column $width="100%">
        <InteractionZone path={dndPath} dndBlock={notBlock} />
      </Column>
    </BlockBase>
  );
};

export default observer(Not);
