import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_DateDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_DateData = z.infer<ReturnType<typeof TI_DateDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTIDateData: Omit<TI_DateData, 'selectedVariable'> = {
  title: 'Default Date Title'
};
