import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Modeler from 'bpmn-js/lib/Modeler';
import gridModule from 'diagram-js-grid';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import { Outlet } from 'react-router-dom';

import customPaletteModule from '@components/editor/plugin/palette';
import createMenuModule from '@components/editor/plugin/pop-menu';
import customDraggin from '@components/editor/plugin/dragging';
import customHandTool from '@components/editor/plugin/hand-tool';
import customMoveCanvas from '@components/editor/plugin/canvas-behavior';
import customRendererModule from '@components/editor/draw';
import moduleExtension from '@/assets/json/modelExtension.json';
import menuModule from '@components/editor/plugin/pop-menu';
import appendMenuModule from '@components/editor/plugin/pop-menu';
import contextPadProvider from '@components/editor/plugin/context-pad';
import bpmnParserModule from '@components/editor/plugin/bpmn-parser';
import elementRegistryModule from '@components/editor/plugin/element-registry';
import SuppressUndo from '@components/editor/plugin/custom-events';
import studioRules from '@components/editor/plugin/studioRules';
import DefaultName from '@components/editor/plugin/default-name';
import CustomNanoIds from '@components/editor/plugin/nano-ids';

import Canvas from 'diagram-js/lib/core/Canvas';

import { BpmnEditor, LoaderBox, LoaderContainer } from './editor.style';
import { BpmnModel } from '@/mobx/models/bpmn.model';
import useProcess from '@hooks/useProcess';
import RootStore from '@/mobx/store/root.store';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';
import { StratumnBpmnEventHandlerCreator } from '@/services/bpmn/events/models/eventHandler.model';
import { CircularProgress } from '@mui/joy';
import ExportButton from '@components/export';

export let IMPORT_STATUS: 'IMPORTING' | 'IMPORTED' = 'IMPORTING';
const stratumnEventHandlerCreator = new StratumnBpmnEventHandlerCreator();

const StratumnEditor = () => {
  const editorRef = useRef<HTMLDivElement>(null);
  const { process } = useProcess();

  const [modeler, setModeler] = useState<Modeler>();
  const rootStore: RootStore = useStores();

  useEffect(() => {
    const newModeler = new Modeler({
      container: editorRef.current ?? undefined,
      keyboard: {
        bindTo: document
      },
      additionalModules: [
        customPaletteModule,
        menuModule,
        appendMenuModule,
        createMenuModule,
        customRendererModule,
        contextPadProvider,
        customDraggin,
        customHandTool,
        customMoveCanvas,
        gridModule,
        bpmnParserModule,
        elementRegistryModule,
        SuppressUndo,
        studioRules,
        DefaultName,
        CustomNanoIds,
        {
          removeElementBehavior: ['value', {}] // disable automatic transition connexion
        }
      ],
      moddleExtensions: {
        trace: moduleExtension
      }
    });

    setModeler(newModeler);
    document.getElementsByClassName('bjs-powered-by')[0].remove();
  }, []);

  useEffect(() => {
    if (!process || !!process.loading || !process.bpmnWorkflow) return;
    if (!modeler) return;

    modeler
      .importXML(process.bpmnWorkflow)
      .then(({ warnings }) => {
        IMPORT_STATUS = 'IMPORTED';
        if (warnings.length) {
          console.warn(warnings);
        }

        const canvas = modeler.get<Canvas>('canvas');
        canvas.zoom('fit-viewport');
        process.bpmn = new BpmnModel();
        process.bpmn.setModeler(modeler);
        const bpmnEventHandler = stratumnEventHandlerCreator.createHandler(
          modeler,
          rootStore
        );
        bpmnEventHandler.listenToEvents();
      })
      .catch((error) => {
        newError(error, true, {
          customMessage: 'Error: process XML corrupted'
        });
      });
  }, [modeler, process, rootStore]);

  return (
    <>
      {modeler && process && (
        <ExportButton modeler={modeler} processName={process.name} />
      )}
      <BpmnEditor ref={editorRef} />
      <Outlet />
      {process?.loading && (
        <LoaderContainer>
          <LoaderBox>
            <CircularProgress />
          </LoaderBox>
        </LoaderContainer>
      )}
    </>
  );
};

export default observer(StratumnEditor);
