import { AtomModel } from '@/mobx/models/atom.model';
import { ReferenceMap } from '@/mobx/models/referenceModal.model';
import { ActionPath } from '@pages/panel/components/action/action.config';
import { TransitionPath } from '@pages/panel/components/transition/transition.config';

export const getPathToSource = (
  atom: AtomModel,
  processId?: string
): string => {
  const { source } = atom.metaInfo;
  if (!processId || !source || !source.library) return '';

  const main = ['ui', 'condition', 'notification'].includes(source.library)
    ? 'editor/p'
    : 'settings';

  const finalPartMap: Record<typeof source.library, string> = {
    ui: `a/${source.id}/${ActionPath.Form}`,
    condition: `t/${source.id}/${TransitionPath.Condition}`,
    notification: `t/${source.id}/${TransitionPath.Notifications}`,
    traceInfo: 'trace-info',
    dataRepository: 'data-repository',
    traceOverview: 'trace-overview',
    template: 'notifications',
    globalVariables: 'global-variables'
  };

  const finalPart = finalPartMap[source.library];

  return finalPart ? `/${processId}/${main}/${finalPart}` : '';
};

export const getNumberOfReferences = (refMap: ReferenceMap): number => {
  return Object.values(refMap).reduce(
    (total, references) => total + references.length,
    0
  );
};
