import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { FormLabel, FormControl, Textarea } from '@mui/joy';

import { InputField } from '@atoms/input';
import { SwitchField } from '@atoms/switch';
import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';

import { useAtom } from '@hooks/useAtom';
import { initialCommentData } from './comment.data';
import { CommentIcon } from './comment.utils';
import { ParamsList } from '@/routes/routes.types';
import { useParams } from 'react-router-dom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { CommentModal } from './comment.modal';

const Comment = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];
  const dataItem = useAtom({
    block,
    initialData: initialCommentData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Comment
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.Comment} title`,
      content: <CommentModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={CommentIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          label="Field title"
          placeholder="Aa"
          required
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column $width="300px">
        <FormControl size="sm">
          <FormLabel>Placeholder</FormLabel>
          <Textarea
            placeholder="Aa"
            maxRows={4}
            value={dataItem.data.placeholder}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              dataItem.data.placeholder = e.target.value;
            }}
          />
        </FormControl>
      </Column>

      <Column $gap="0">
        <FormControl size="sm">
          <FormLabel>Additional features</FormLabel>
        </FormControl>
        <SwitchField
          label="Allow mentions"
          checked={dataItem.data.withMention}
          onChange={(e) => {
            dataItem.data.withMention = e.target.checked;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(Comment);
