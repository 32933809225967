import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_FileUploadDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_FileUploadData = z.infer<
  ReturnType<typeof TI_FileUploadDataSchema>
>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTIFileUploadData: Omit<
  TI_FileUploadData,
  'selectedVariable'
> = {
  title: 'Default File Upload Title'
};
