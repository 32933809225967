import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { Option } from '@mui/joy';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';

import { InputField } from '@atoms/input';
import { SelectField } from '@atoms/select';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';
import { TextFieldModal } from './textField.modal';

import { initialTextFieldData } from './textField.data';
import {
  TextFieldIcon,
  ValidationOption,
  validationOptions
} from './textField.utils';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';
import { useAtom } from '@hooks/useAtom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';

const TextField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialTextFieldData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.TextField} title`,
      content: <TextFieldModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={TextFieldIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <InputField
          label="Placeholder"
          placeholder="Aa"
          value={dataItem.data.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.placeholder = e.target.value;
          }}
        />
      </Column>

      <Column $width="170px">
        <SelectField
          label="Validation"
          value={dataItem.data.validation}
          onChange={(_, value) => {
            if (!value) return;
            dataItem.data.validation = value as ValidationOption;
          }}
        >
          {validationOptions.map(({ value, label, Icon }, index) => (
            <Option key={index} value={value} label={label}>
              {Icon}
              {label}
            </Option>
          ))}
        </SelectField>
      </Column>
    </BlockBase>
  );
};

export default observer(TextField);
