import { newError } from '@/services/errors/errors';

export const deepCopy = <T>(obj: T): T => {
  try {
    return JSON.parse(JSON.stringify(obj)) as T;
  } catch (error: unknown) {
    newError(error);
    throw error;
  }
};
