import { assign } from 'min-dash';

/**
 * @typedef {import("diagram-js/lib/features/palette/Palette").default} Palette
 * @typedef {import("diagram-js/lib/features/create/Create").default} Create
 * @typedef {import("diagram-js/lib/core/ElementFactory").default} ElementFactory
 * @typedef {import("bpmn-js/lib/features/space-tool").default} SpaceTool
 * @typedef {import("diagram-js/lib/features/lasso-tool/LassoTool").default} LassoTool
 * @typedef {import("diagram-js/lib/features/hand-tool/HandTool").default} HandTool
 * @typedef {import("diagram-js/lib/features/global-connect/GlobalConnect").default} GlobalConnect
 * @typedef {import("diagram-js/lib/i18n/translate/translate").default} Translate
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").default} PopupMenu
 *
 *
 * @typedef {import("diagram-js/lib/features/palette/Palette").PaletteEntries} PaletteEntries
 */

/**
 * A palette provider for BPMN 2.0 elements.
 *
 * @param {Palette} palette
 * @param {Create} create
 * @param {ElementFactory} elementFactory
 * @param {SpaceTool} spaceTool
 * @param {LassoTool} lassoTool
 * @param {HandTool} handTool
 * @param {GlobalConnect} globalConnect
 * @param {Translate} translate
 * @param {PopupMenu} popupMenu
 */
class PaletteProvider {
  constructor(
    palette,
    create,
    elementFactory,
    spaceTool,
    lassoTool,
    handTool,
    globalConnect,
    translate,
    popupMenu
  ) {
    this.palette = palette;
    this.create = create;
    this.elementFactory = elementFactory;
    this.spaceTool = spaceTool;
    this.lassoTool = lassoTool;
    this.handTool = handTool;
    this.globalConnect = globalConnect;
    this.translate = translate;
    this.popupMenu = popupMenu;
    palette.registerProvider(this);
  }

  getPaletteEntries() {
    const actions = {};
    const create = this.create;
    const elementFactory = this.elementFactory;
    const spaceTool = this.spaceTool;
    const lassoTool = this.lassoTool;
    const handTool = this.handTool;
    const globalConnect = this.globalConnect;
    const translate = this.translate;
    const popupMenu = this.popupMenu;

    function createParticipant(event) {
      create.start(event, elementFactory.createParticipantShape());
    }

    function getReplaceMenuPosition(element) {
      const X_OFFSET = 40;

      const padRect = element.getBoundingClientRect();

      const pos = {
        x: padRect.left + X_OFFSET,
        y: padRect.top + 10
      };

      return pos;
    }

    function createMenu(event, type, providerId, className, options) {
      let element = document.querySelector(`.${className}`);
      const position = assign(getReplaceMenuPosition(element), {
        cursor: { x: event.x, y: event.y }
      });

      popupMenu.open(type, providerId, position, options);
    }

    assign(actions, {
      'hand-tool': {
        group: 'tools',
        className: 'bpmn-icon-hand-tool',
        title: translate('Activate the hand tool'),
        action: {
          click: function (event) {
            handTool.activateHand(event);
          }
        }
      },
      'lasso-tool': {
        group: 'tools',
        className: 'bpmn-icon-lasso-tool',
        title: translate('Activate the lasso tool'),
        action: {
          click: function (event) {
            lassoTool.activateSelection(event);
          }
        }
      },
      // "space-tool": {
      //   group: "tools",
      //   className: "bpmn-icon-space-tool",
      //   title: translate("Activate the create/remove space tool"),
      //   action: {
      //     click: function(event) {
      //       spaceTool.activateSelection(event);
      //     }
      //   }
      // },
      'tool-separator': {
        group: 'tools',
        separator: true
      },
      'global-connect-tool': {
        group: 'tools',
        className: 'bpmn-icon-connection-multi',
        title: translate('Activate the global connect tool'),
        action: {
          click: function (event) {
            globalConnect.start(event);
          }
        }
      },
      'create.start-event': {
        group: 'create',
        className: 'bpmn-icon-start-event-none start-menu',
        title: 'Event',
        action: {
          click: (event) =>
            createMenu(event, 'event', 'bpmn-create', 'start-menu', {
              title: translate('Create event'),
              type: 'event',
              width: 300,
              scale: false
            })
        }
      },
      // "create.exclusive-gateway": {
      //   group: "create",
      //     className: "bpmn-icon-gateway-none gateway-menu",
      //     title: "Gateway",
      //     action: {
      //       click: event => createMenu(
      //         event,
      //         "gateway",
      //         "bpmn-create",
      //         "gateway-menu",
      //         {
      //           title: translate("Create gateway"),
      //           type: "gateway",
      //           width: 300,
      //           scale: false
      //         }
      //       )
      //   }
      // },
      'create.action': {
        group: 'create',
        className: 'bpmn-icon-task action-menu',
        title: 'Action',
        action: {
          click: (event) =>
            createMenu(event, 'action', 'bpmn-create', 'action-menu', {
              title: translate('Create action'),
              type: 'action',
              width: 300,
              scale: false
            })
        }
      },
      'create.participant-expanded': {
        group: 'collaboration',
        className: 'bpmn-icon-participant disabled',
        title: translate('Workflow')
        // Uncomment when you enable it
        // action: {
        //   dragstart: createParticipant,
        //   click: createParticipant
        // }
      }
    });

    return actions;
  }
}

PaletteProvider.$inject = [
  'palette',
  'create',
  'elementFactory',
  'spaceTool',
  'lassoTool',
  'handTool',
  'globalConnect',
  'translate',
  'popupMenu'
];

export default PaletteProvider;
