import { ContainerBase, DescriptionBase, IconBase } from '@pages/Settings/style';
import styled from 'styled-components';

export const NotificationContainer = styled(ContainerBase)`
  cursor: pointer;
  margin-bottom: 0;
`

export const NotificationDescription = styled(DescriptionBase)`
  margin: 0;
`

export const NotificationIcon = styled(IconBase)`
  align-items: center;
  justify-content: flex-start;
`