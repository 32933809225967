export enum RoleDraft {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer'
}

export enum DeleteRoleDraft {
  Remove = 'remove',
  Leave = 'leave'
}
