import { IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 600px;
`;

export const FormLabelCustom = styled('div')`
  color: ${({ theme }) => theme.color.grey[2]};
  font-weight: bold;
  margin-bottom: 24px;
`;

export const NavbarEditorMenu = styled(Menu)`
  z-index: 100001 !important;

  .MuiInput-root {
    background-color: transparent;
  }

  .Mui-focusVisible {
    outline: none !important;
  }

  .Mui-focused::before {
    box-shadow: none;
  }
`;

export const FormContainerInput = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
  gap: 12px;
  width: 100%;
  max-width: 100%;
  min-height: 50px;
`;

export const CustomMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f4f8;
  }
`;

export const RoleMenuContainer = styled(Menu)`
  z-index: 100001 !important;
  font-size: 12px;

  .MuiInput-root {
    background-color: transparent;
  }

  .Mui-focusVisible {
    outline: none !important;
  }

  .Mui-focused::before {
    box-shadow: none;
  }
`;

export const ButtonShowPassword = styled(IconButton)`
  padding: 0px;
  &:hover {
    background-color: transparent;
  }
`;

export const PasswordContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const StyledTagInputContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.color.grey[5]};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 450px;
  & .rti--container {
    width: 100%;
    height: 100%;
    border: none;
    padding: 7px;
    font-size: small;
    flex-wrap: wrap-reverse;
    &:focus-within {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
  & .rti--tag {
    display: block;
  }
  & .rti--tag button {
    line-height: normal;
  }
  & .rti--input {
    width: 100%;
    border: none;
    font-family: 'Inter';
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  gap: 12px;
  max-width: 60%;
  height: 100%;
  margin: 0;
`;

export const CustomMenuButton = styled(MenuButton)`
  padding: 8px;
  margin: 0px;
`;
