import { FC } from 'react';
import * as Blocks from '@components/dnd/library/condition/blocks';
import { DNDInfo, LibraryProps, Plexer, ToolbarItem } from '../library.types';
import { ConditionBlocks } from './condition.type';

export const CDT_TOOLBAR_ITEMS: ToolbarItem<ConditionBlocks>[] = [
  {
    type: ConditionBlocks.And,
    icon: <Blocks.AndIcon />
  },
  {
    type: ConditionBlocks.Or,
    icon: <Blocks.OrIcon />
  },
  {
    type: ConditionBlocks.Not,
    icon: <Blocks.NotIcon />
  },
  {
    type: ConditionBlocks.Condition,
    icon: <Blocks.ConditionIcon />
  },
  {
    type: ConditionBlocks.CodeCondition,
    icon: <Blocks.CodeConditionIcon />
  }
];

export const CONDITION_INFO_DND: DNDInfo = {
  title: 'Condition',
  description: `
  Drag & drop a block to your transition to configure condition.
  Use Block Or, And or Not to create
  complex conditions.
  Each Transition can only have one Block at the root.
  `
};

const TransitionPlexer: Plexer<ConditionBlocks> = {
  [ConditionBlocks.And]: Blocks.And,
  [ConditionBlocks.Or]: Blocks.Or,
  [ConditionBlocks.Not]: Blocks.Not,
  [ConditionBlocks.Condition]: Blocks.Condition,
  [ConditionBlocks.CodeCondition]: Blocks.CodeCondition
};

export const LibraryTransition: FC<LibraryProps> = ({ block }) => {
  const Block = TransitionPlexer[block.type as ConditionBlocks];
  return <Block {...block} />;
};
