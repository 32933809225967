import { z } from 'zod';
import { TitleInputSchema, TextInputSchema } from '@/utils/zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const RichTextDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      withMention: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type RichTextData = z.infer<ReturnType<typeof RichTextDataSchema>>;

export const initialRichTextData: RichTextData = {
  title: 'Default Rich Text Title',
  required: true,
  placeholder: '',
  withMention: false
};
