export enum Side {
  Left = 'left',
  Right = 'right',
}

export interface Width {
  default: number | string;
  min: number | string;
  max: number | string;
}
