import {
  getBusinessObject
} from "bpmn-js/lib/util/ModelUtil";

import {
  isExpanded
} from "bpmn-js/lib/util/DiUtil";

/**
 * @typedef {import("bpmn-js/lib/model/Types").Element} Element
 * @typedef {import("diagram-js/lib/features/popup-menu/PopupMenu").PopupMenuTarget} PopupMenuTarget
 *
 * @typedef {(entry: PopupMenuTarget) => boolean} DifferentTypeValidator
 */

/**
 * Returns true, if an element is from a different type
 * than a target definition. Takes into account the type,
 * event definition type and triggeredByEvent property.
 *
 * @param {Element} element
 *
 * @return {DifferentTypeValidator}
 */
export function isDifferentType(element) {

  return function(entry) {
    const target = entry;

    const businessObject = getBusinessObject(element);
    const eventDefinition = businessObject.eventDefinitions && businessObject.eventDefinitions[0];

    const isTypeEqual = businessObject.$type === target.type;

    const isEventDefinitionEqual = (
      (eventDefinition && eventDefinition.$type) === target.eventDefinitionType
    );

    const isTriggeredByEventEqual = (

      // coherse to <false>
      !!target.triggeredByEvent === !!businessObject.triggeredByEvent
    );

    const isExpandedEqual = (
      target.isExpanded === undefined ||
      target.isExpanded === isExpanded(element)
    );

    return !isTypeEqual || !isEventDefinitionEqual || !isTriggeredByEventEqual || !isExpandedEqual;
  };
}
