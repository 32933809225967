import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_KEYVALUE_VIEW_TYPES = ['KeyValue'] as const;
export const TO_KeyValueViewTypeSchema = z.enum(TO_KEYVALUE_VIEW_TYPES);

export const TO_KeyValueDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_KeyValueViewTypeSchema
    })
    .strict();

export type TO_KeyValueData = z.infer<ReturnType<typeof TO_KeyValueDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTOKeyValueData: Omit<TO_KeyValueData, 'selectedVariable'> =
  {
    title: 'Default Key Value Title',
    viewType: 'KeyValue'
  };
