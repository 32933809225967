import { AllBlocks } from '@components/dnd/library';
import { ConditionBlockToSchemaMap } from '@components/dnd/library/condition/condition.schema';
import { DataRepositoryBlockToSchemaMap } from '@components/dnd/library/dataRepository/dataRepository.schema';
import { FormBuilderBlockToSchemaMap } from '@components/dnd/library/formBuilder/formBuilder.schema';
import { NotificationBlockToSchemaMap } from '@components/dnd/library/notification/notification.schema';
import { TraceInfoBlockToSchemaMap } from '@components/dnd/library/traceInfo/traceInfo.schema';
import { TraceOverviewBlockToSchemaMap } from '@components/dnd/library/traceOverview/traceOverview.schema';
import { z } from 'zod';
import { AtomModel } from '../models/atom.model';
import { GlobalVariablesBlockToSchemaMap } from '@components/dnd/library/globalVariables/globalVariables.schema';

export type DataType =
  | 'variable'
  | 'traceInfo'
  | 'traceOverview'
  | 'condition'
  | 'action'
  | 'notification'
  | 'globalVariable'
  | 'null';

export enum ResolvedVariableType {
  Text = 'Text',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
  User = 'User',
  File = 'File',
  List = 'List',
  Comment = 'Comment',
  Custom = 'Custom'
}

export const allDataSchemasMap: Record<AllBlocks, z.Schema> = {
  ...FormBuilderBlockToSchemaMap,
  ...NotificationBlockToSchemaMap,
  ...DataRepositoryBlockToSchemaMap,
  ...ConditionBlockToSchemaMap,
  ...TraceInfoBlockToSchemaMap,
  ...TraceOverviewBlockToSchemaMap,
  ...GlobalVariablesBlockToSchemaMap,
  root: z.unknown()
};

const SourceLibrarySchema = z.enum([
  'ui',
  'condition',
  'notification',
  'dataRepository',
  'traceInfo',
  'traceOverview',
  'template',
  'globalVariables'
]);

export const MetaInfoSchema = z
  .object({
    source: z
      .object({
        id: z.string(),
        library: SourceLibrarySchema,
        name: z.string().optional()
      })
      .strict(),
    dnd: z
      .object({
        id: z.string(),
        blockType: z.string() as z.ZodType<AllBlocks>
      })
      .strict()
  })
  .strict();

export const VariableInfoSchema = z.object({
  resolvedType: z.nativeEnum(ResolvedVariableType)
});

export type MetaInfo = z.infer<typeof MetaInfoSchema>;
export type DataSourceLibrary = z.infer<typeof SourceLibrarySchema>;
export type VariableInfo = z.infer<typeof VariableInfoSchema>;

export type AtomWithTitle = AtomModel<{ title: string }>;
