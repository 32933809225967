// @ts-check
import CustomNanoIds from './CustomNanoIds';

/**
 * @type { import("didi").ModuleDeclaration }
 */
export default {
  __init__: ['nanoIds'],
  nanoIds: ['type', CustomNanoIds]
};
