export enum FbBlocks {
  CheckBox = 'Check Box',
  Date = 'Date',
  DropDown = 'Drop Down',
  FileUpload = 'File Upload',
  Number = 'Number',
  RichText = 'Rich Text',
  TextField = 'TextField',
  Comment = 'Comment'
}
