import { ChangeEvent, useState } from 'react';
import { FormItem } from '../newProcess.utils';
import {
  ModalContent,
  SearchInput,
  ItemsContainer,
  ItemList,
  Item,
  ItemIconContainer,
  ItemName
} from './form.style';
import { Typography } from '@mui/joy';

interface NewProcessFormProps {
  selectedItem: FormItem;
  onItemSelected: (formItem: FormItem) => void;
  items: FormItem[];
  defaultItem: FormItem;
  dividerTitle?: string;
  searchPlaceholder?: string;
}

const NewProcessForm = ({
  selectedItem,
  onItemSelected,
  items,
  defaultItem,
  dividerTitle,
  searchPlaceholder
}: NewProcessFormProps) => {
  const [filteredItems, setFilteredItems] = useState<FormItem[]>(items);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (!items) return setFilteredItems([]);
    if (!e.target.value) return setFilteredItems(items);
    const filteredItems = items.filter((item) => {
      const name = item.name.toLowerCase();
      const firstWordIndex = name.indexOf(' ') + 1;
      return name
        .substring(firstWordIndex)
        .includes(e.target.value.toLowerCase());
    });
    setFilteredItems(filteredItems);
  };

  return (
    <ModalContent>
      {searchPlaceholder && (
        <SearchInput
          placeholder={searchPlaceholder}
          onChange={onSearch}
        ></SearchInput>
      )}
      <ItemsContainer>
        <Item
          onClick={() => onItemSelected(defaultItem)}
          $isSelected={selectedItem.value === defaultItem.value}
        >
          <ItemIconContainer>{defaultItem.icon}</ItemIconContainer>
          <ItemName>{defaultItem.name}</ItemName>
        </Item>

        {dividerTitle && (
          <Typography level="body-sm" padding={'0.3rem'} paddingLeft={'0.6rem'}>
            {dividerTitle}
          </Typography>
        )}

        <ItemList>
          {filteredItems.map((item: FormItem) => (
            <Item
              key={item.value}
              $disabled={item.disabled}
              $isSelected={selectedItem.value === item.value}
              onClick={() => onItemSelected(item)}
            >
              <ItemIconContainer>{item.icon}</ItemIconContainer>
              <ItemName>{item.name}</ItemName>
            </Item>
          ))}
        </ItemList>
      </ItemsContainer>
    </ModalContent>
  );
};

export default NewProcessForm;
