import Editor, {
  OnChange,
  useMonaco,
  Monaco,
  OnValidate
} from '@monaco-editor/react';
import { IDisposable, editor, MarkerSeverity } from 'monaco-editor';
import { useCallback, useEffect, useState } from 'react';

export type MonacoError = editor.IMarker;

type Props = {
  modelId?: string;
  code: string;
  onCodeChange: OnChange;
  options?: Partial<editor.IStandaloneEditorConstructionOptions>;
  preCode?: string;
  handleMonacoErrors?: (monacoErrors: MonacoError[]) => void;
};

export const MonacoEditor = ({
  modelId = 'default',
  preCode,
  code,
  options = {},
  onCodeChange,
  handleMonacoErrors
}: Props) => {
  const [lib, setLib] = useState<Maybe<IDisposable>>();

  const monaco = useMonaco();

  const setExtraLibs = useCallback(
    (monaco: Monaco) => {
      if (lib) lib.dispose();
      if (!preCode) return;
      monaco.languages.typescript.typescriptDefaults.setExtraLibs([]);
      const newExtraLib =
        monaco.languages.typescript.typescriptDefaults.addExtraLib(preCode);
      setLib(newExtraLib);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [preCode]
  );

  const onValidate: OnValidate = (markers: MonacoError[]) => {
    if (!handleMonacoErrors) return;
    const errors = markers.filter(
      (marker) => marker.severity === MarkerSeverity.Error
    );
    handleMonacoErrors(errors);
  };

  useEffect(() => {
    if (!monaco) return;
    setExtraLibs(monaco);

    return () => {
      if (!monaco) return;
      monaco.editor.getModel(monaco.Uri.parse(modelId))?.dispose();
    };
  }, [modelId, monaco, preCode, setExtraLibs]);

  return (
    <Editor
      defaultLanguage="typescript"
      defaultValue={code}
      path={modelId}
      onChange={onCodeChange}
      onValidate={onValidate}
      theme="vs-light"
      options={{
        bracketPairColorization: {
          enabled: true
        },
        stickyScroll: {
          enabled: true
        },
        minimap: { enabled: false },
        scrollbar: { verticalHasArrows: false },
        fontSize: 14,
        tabSize: 2,
        contextmenu: true,
        formatOnType: true,
        formatOnPaste: true,
        links: false,
        padding: {
          top: 10,
          bottom: 10
        },
        scrollBeyondLastLine: false,
        fixedOverflowWidgets: true, // menus can bypass defined overflows
        ...options
      }}
    />
  );
};
