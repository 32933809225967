import { z } from 'zod';
import { TitleInputSchema, TextInputSchema } from '@/utils/zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const CommentDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      withMention: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type CommentFieldData = z.infer<ReturnType<typeof CommentDataSchema>>;

export const DEFAULT_COMMENT_TITLE = 'Comment';
export const initialCommentData: CommentFieldData = {
  title: DEFAULT_COMMENT_TITLE,
  required: false,
  placeholder: 'Leave a comment here',
  withMention: true
};
