import { newError } from '@/services/errors/errors';
import { getIdFromBpmnId } from '../../bpmn.utils';
import {
  CreateShapeEvent,
  DeleteShapeEvent,
  ShapeEvents
} from '../event.types';
import { UserAction } from './shape.plexer';
import { Shape } from 'bpmn-js/lib/model/Types';
import { ElementIdType } from '../../bpmn.types';
import { toast } from 'sonner';
import RootStore from '@/mobx/store/root.store';

export type DeleteButtonPressed = {
  callback: () => void;
  element: Shape;
};

export const deleteButtonPressed = (
  event: DeleteButtonPressed,
  rootStore: RootStore
) => {
  const type = event.element.id.split('_')[0] as ElementIdType;
  const id = getIdFromBpmnId(event.element.id);
  const referenceModal = rootStore.modalStore.referenceModal;

  switch (type) {
    case ElementIdType.Activity: {
      const actionStore = rootStore.actionStore;
      const action = actionStore.get(id);

      if (action == undefined) {
        newError(`Action with id ${id} not found in store`, true);
        return;
      }

      if (action.isDeletable) {
        event.callback();
      } else {
        referenceModal.open('action', id, 'referencedBy', 'action');
      }

      break;
    }
    case ElementIdType.Event:
      event.callback();
      break;
    case ElementIdType.StartEvent:
      event.callback();
      break;
    case ElementIdType.Transition:
      event.callback();
      break;
    case ElementIdType.Lane:
      event.callback();
      break;
    case ElementIdType.Workflow:
      newError('You cannot delete a workflow', true);
      break;
    default:
      newError(`Type ${type} with id ${id} cannot be deleted`, true);
      break;
  }
};

export const onActionCreated = (
  event: CreateShapeEvent,
  rootStore: RootStore
): void => {
  const rawShapeId = event.context.shape?.id;
  const rawWorflowId = event.context.parent?.id;
  if (!rawShapeId || !rawWorflowId) return;

  const shapeId = getIdFromBpmnId(rawShapeId);
  const workflowId = getIdFromBpmnId(rawWorflowId);

  const actionStore = rootStore.actionStore;
  actionStore.createNewAction(shapeId, workflowId).catch((e: unknown) => {
    newError(e);
    return false;
  });
};

export const onActionDeleted = (
  event: DeleteShapeEvent,
  rootStore: RootStore
) => {
  const shapeId = event.context.shape?.id;
  if (!shapeId) return;

  const actionId = getIdFromBpmnId(shapeId);
  rootStore.actionStore
    .deleteById(actionId)
    .then((isDeleted: boolean) => {
      if (isDeleted) {
        toast.success(`Action deleted with success`);
      }
    })
    .catch((error: unknown) => {
      newError(error, true);
      return false;
    });

  // TODO close the site panel if it was opened on this action
};

export const actionsFunctionPlexer: Record<
  UserAction,
  (event: ShapeEvents, rootStore: RootStore) => void
> = {
  create: onActionCreated,
  delete: onActionDeleted
};
