import { ChangeEvent, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { InputField } from '@atoms/input';
import useAction from '@hooks/useAction';
import { TraceKeyInput } from '@atoms/traceKeyInput/traceKeyInput';

const SettingsPage = () => {
  const [actionName, setActionName] = useState<string>('');
  const action = useAction();

  useEffect(() => {
    const actionName: Maybe<string> = action?.name;
    setActionName(actionName ?? '');
  }, [action?.name]);

  if (!action) return <div>No action found</div>;

  const onActionNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    action.name = event.target.value;
    setActionName(event.target.value);
  };

  return (
    <section className="h-full p-4">
      <section className="flex h-full w-full max-w-[400px] flex-col gap-6">
        <InputField
          label="Action name"
          value={actionName}
          onChange={onActionNameChange}
          placeholder="Aa"
          required
        />
        <TraceKeyInput traceKey={action.traceKey} parentName={action.name} />
      </section>
    </section>
  );
};

export default observer(SettingsPage);
