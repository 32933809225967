import ActionStore from './action.store';
import AtomStore from './atom.store';
import CodeStore from './code.store';
import { DNDStore } from './dnd.store';
import ModalStore from './modal.store';
import { NotificationTemplateStore } from './notificationTemplate.store';
import { ProcessStore } from './process.store';
import { TransitionStore } from './transition.store';
import { VariablesStore } from './variables.store';
import { WorkflowStore } from './workflow.store';

export default class RootStore {
  dndStore: DNDStore;
  actionStore: ActionStore;
  variablesStore: VariablesStore;
  processStore: ProcessStore;
  transitionStore: TransitionStore;
  workflowStore: WorkflowStore;
  notificationTemplateStore: NotificationTemplateStore;
  atomStore: AtomStore;
  modalStore: ModalStore;
  codeStore: CodeStore;

  constructor() {
    this.dndStore = new DNDStore(this);
    this.dndStore.api_enabled = true;
    this.actionStore = new ActionStore(this);
    this.transitionStore = new TransitionStore(this);
    this.variablesStore = new VariablesStore(this);
    this.processStore = new ProcessStore(this);
    this.transitionStore = new TransitionStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.notificationTemplateStore = new NotificationTemplateStore(this);
    this.atomStore = new AtomStore(this);
    this.modalStore = new ModalStore(this);
    this.codeStore = new CodeStore(this);
  }
}
