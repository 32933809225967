import styled from 'styled-components';
import LogoSVG from './logo.svg';
import { Link } from 'react-router-dom';
import { ROUTE_PROCESSES } from '@/utils/constants';

const Button = styled(Link)`
  padding: 6px 17px 12px 14px;

  background: transparent;
  border-width: 0;
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

export default function Logo() {
  return (
    <Button to={ROUTE_PROCESSES}>
      <LogoSVG />
    </Button>
  );
}
