import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_FILE_VIEW_TYPES = ['File Compact', 'File'] as const;
export const TO_FileViewTypeSchema = z.enum(TO_FILE_VIEW_TYPES);

export const TO_FileDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_FileViewTypeSchema
    })
    .strict();

export type TO_FileData = z.infer<ReturnType<typeof TO_FileDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTOFileData: Omit<TO_FileData, 'selectedVariable'> = {
  title: 'Default File Title',
  viewType: 'File Compact'
};
