import { ReactNode } from 'react';

import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  switchClasses
} from '@mui/joy';

interface SwitchFieldProps extends SwitchProps {
  label?: ReactNode;
  title?: string;
}

export const SwitchField = (props: SwitchFieldProps) => (
  <div>
    {props.title && (
      <FormControl size={props.size || 'sm'}>
        <FormLabel required={props.required}>{props.title}</FormLabel>
      </FormControl>
    )}
    <Switch
      size={'sm'}
      sx={{
        [`& .${switchClasses.thumb}`]: {
          transition: 'left 0.2s',
          width: 14,
          height: 14
        }
      }}
      endDecorator={props.label}
      {...props}
    />
  </div>
);
