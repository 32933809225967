import {
  NormalSourceData,
  StateSourceData,
  SelectedDataRepositoryData
} from './options';
import { DataSourceOption } from './dropDown.data';

export type DataSourceData = [
  NormalSourceData,
  SelectedDataRepositoryData,
  StateSourceData
];

export interface DataSourceOptionInfo {
  name: string;
  value: DataSourceOption;
  isDisabled?: boolean;
}

export const dataSourceOptions: DataSourceOptionInfo[] = [
  {
    name: 'Normal Source',
    value: 'normalSource'
  },
  {
    name: 'Data Repository',
    value: 'dataRepository'
  },
  {
    name: 'State Source',
    value: 'stateSource',
    isDisabled: true
  }
];
