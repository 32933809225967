import {
  AlignLeft,
  CircleSlash2,
  CreditCard,
  LucideIcon,
  Mail,
  Phone
} from 'lucide-react';
import { CSSProperties } from 'react';

const ICON_SIZE = 15;
const ICON_STYLE: CSSProperties = { marginRight: 10 };

export const TextFieldIcon: LucideIcon = AlignLeft;

export enum ValidationOption {
  None = 'none',
  Email = 'email',
  Phone = 'phone',
  CreditCard = 'creditCard'
}

interface SelectOption {
  label: string;
  value: ValidationOption;
  Icon?: JSX.Element;
}

export const defaultValidationOption: SelectOption = {
  label: 'No validation',
  value: ValidationOption.None,
  Icon: <CircleSlash2 size={ICON_SIZE} style={ICON_STYLE} />
};

export const validationOptions: SelectOption[] = [
  defaultValidationOption,
  {
    label: 'Email',
    value: ValidationOption.Email,
    Icon: <Mail size={ICON_SIZE} style={ICON_STYLE} />
  },
  {
    label: 'Phone number',
    value: ValidationOption.Phone,
    Icon: <Phone size={ICON_SIZE} style={ICON_STYLE} />
  },
  {
    label: 'Credit card',
    value: ValidationOption.CreditCard,
    Icon: <CreditCard size={ICON_SIZE} style={ICON_STYLE} />
  }
];
