import { makeObservable } from 'mobx';
import BaseStore from '@/mobx/store/base/base.store';
import Model, { ModelError } from './base/base.model';
import { DNDModel } from './dnd.model';
import { BaseActionModel } from './action.model';
import { newError } from '@/services/errors/errors';

export class TransitionModel extends Model {
  constructor(
    store: BaseStore<TransitionModel>,
    id: string,
    public conditionDndId: string,
    public notificationDndId: string,
    public prioritiesDndId: string,
    public actionFromId: string,
    public actionToId: string,
    loading?: boolean
  ) {
    super(store, id, loading);

    makeObservable(this, {});
  }

  public async delete(): Promise<boolean> {
    const dndStore = this.store.rootStore.dndStore;

    const conditionDnd = dndStore.get(this.conditionDndId);
    const notificationDnd = dndStore.get(this.notificationDndId);
    const prioritiesDnd = dndStore.get(this.prioritiesDndId);

    if (!conditionDnd || !notificationDnd || !prioritiesDnd) {
      newError(
        `Error while deleting the transition "${this.id}" one of the DND is missing: 
        conditionDnd: "${conditionDnd?.id}", 
        notificationDnd: "${notificationDnd?.id}", 
        prioritiesDnd: "${prioritiesDnd?.id}"`,
        true,
        {
          customMessage: 'Error while deleting the transition'
        }
      );
      return false;
    }

    /* ---------------------- Delete the transition itself ---------------------- */

    const isTransitionDeleted = await this.store.delete(this.id);
    if (!isTransitionDeleted) return false;

    /* ----------------- Delete transaction's DNDs in store only ---------------- */

    await dndStore.delete(this.conditionDndId, false);
    await dndStore.delete(this.notificationDndId, false);
    await dndStore.delete(this.prioritiesDndId, false);

    return true;
  }

  get toJSON() {
    return {};
  }

  /** Gets the `actionTo` in the Action Store using the transition `actionToId`. */
  get actionTo(): Maybe<BaseActionModel> {
    return this.store.rootStore.actionStore.get(this.actionToId);
  }

  /** Gets the `actionFrom` in the Action Store using the transition `actionFrom`. */
  get actionFrom(): Maybe<BaseActionModel> {
    return this.store.rootStore.actionStore.get(this.actionFromId);
  }

  get conditionDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.conditionDndId);
  }

  get notificationDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.notificationDndId);
  }

  get prioritiesDnd(): Maybe<DNDModel> {
    return this.store.rootStore.dndStore.get(this.prioritiesDndId);
  }

  get errors(): ModelError[] {
    return [];
  }
}
