import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import RootStore from '@/mobx/store/root.store';

/** Returns the current `RootStore` instance, providing access to associated sub-stores.
 * @example const { processStore } = useStores();
 */
const useStores = (): RootStore => {
  return useContext(MobXProviderContext) as RootStore;
};

export default useStores;
