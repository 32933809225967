import ts from 'typescript';

export const transpileCode = (code: string): string => {
  return ts.transpile(code, {
    module: ts.ModuleKind.CommonJS,
    allowJs: true,
    target: ts.ScriptTarget.ES2022,
    removeComments: true,
    noEmitOnError: true
  });
};
