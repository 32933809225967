import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const HeaderContainer = styled.header`
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  gap: 11px;
`;
export const LAYOUT_BORDER_RADIUS = 6;

export const SearchBarContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  border: 1px solid hsla(0, 0%, 92%, 1);
  background-color: white;
  color: grey;
  overflow: hidden;
  border-radius: ${LAYOUT_BORDER_RADIUS}px;
  display: flex;
  box-sizing: border-box;
`;

export const SearchIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
`;

export const SearchBar = styled.input`
  border: none;
  outline: none;
  background: none;
  flex-grow: 1;
  font-size: 15px;
`;

const LAYOUT_PADDING = 4;
export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  padding: ${LAYOUT_PADDING}px;
  border: 1px solid hsla(0, 0%, 92%, 1);
  border-radius: ${LAYOUT_BORDER_RADIUS}px;
  background-color: white;
`;

export const LayoutIconContainer = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px 10px;
  border-radius: ${LAYOUT_BORDER_RADIUS - LAYOUT_PADDING}px;
  box-sizing: border-box;
  cursor: pointer;
  ${(p) =>
    p.$isSelected &&
    css`
      color: hsla(0, 0%, 9%, 1);
      background-color: hsla(0, 0%, 95%, 1);
    `}
  transition: background-color 0.2s ease-in-out;
  &:hover {
    ${(p) =>
      !p.$isSelected &&
      css`
        color: black;
      `}
  }
`;

export const AddNewProcessBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  box-sizing: border-box;
  padding: 0px 15px;
  border: solid 1px transparent;
  outline: none;
  background-color: ${(p) => p.theme.color.indigoTailwind[700]};
  color: white;
  border-radius: ${LAYOUT_BORDER_RADIUS}px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.85rem;
  gap: 0.5rem;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${(p) => p.theme.color.indigoTailwind[800]};
  }
`;
