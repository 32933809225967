import React, { forwardRef } from 'react';
import {
  StateMenuChipType,
  StateMenuInput,
  StateMenuSearchBar
} from './stateMenu.style';
import { Search, X } from 'lucide-react';

interface SearchBarProps {
  searchVar: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedAction: string | null;
  resetSearch: React.MouseEventHandler<SVGSVGElement>;
}

const SearchBar = forwardRef<HTMLInputElement, SearchBarProps>(
  ({ searchVar, onSearch: handleSearch, selectedAction, resetSearch }, ref) => (
    <StateMenuSearchBar>
      <Search size={18} />
      {selectedAction && (
        <StateMenuChipType>
          {selectedAction}
          <X size={14} onClick={resetSearch} />
        </StateMenuChipType>
      )}
      <StateMenuInput
        ref={ref}
        type="text"
        placeholder={`Search ${
          selectedAction
            ? `${selectedAction}'s variables`
            : 'all your variables...'
        }`}
        autoFocus
        value={searchVar}
        onChange={handleSearch}
      />
    </StateMenuSearchBar>
  )
);

export default SearchBar;
