import { Shape } from 'bpmn-js/lib/model/Types';
import { ElementIdType } from '../../bpmn.types';
import { actionsFunctionPlexer as actionsFunctionMap } from './action';
import { CreateShapeEvent, DeleteShapeEvent } from '../event.types';
import { gatewayFunctionPlexer } from '../gateway/event';

export enum UserAction {
  Create = 'create',
  Delete = 'delete'
}
export type CreateFunction = (e: CreateShapeEvent) => void;
export type DeleteFunction = (e: DeleteShapeEvent) => void;

export const shapeToFunctionPlexer = (shape: Shape, actionType: UserAction) => {
  const shapeBpmnType = shape?.id.split('_')[0] as ElementIdType;

  switch (shapeBpmnType) {
    case ElementIdType.Activity:
      return actionsFunctionMap[actionType];
    case ElementIdType.Event:
      return gatewayFunctionPlexer[actionType];
    case ElementIdType.Gateway:
    case ElementIdType.Lane:
    case ElementIdType.Transition:
    case ElementIdType.Workflow:
    default:
      return actionType === UserAction.Create ? () => {} : () => {};
  }
};
