import { LucideIcon, Mail } from 'lucide-react';
import VariableField from '@atoms/variableField';
import { observer } from 'mobx-react';
import { TemplateVariableContainer, TemplateVariableField } from './style';
import type { TemplateVariable } from './email.data';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import { AtomModel } from '@/mobx/models/atom.model';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';

export const EmailIcon: LucideIcon = Mail;

export interface TemplateVariableProps {
  template: TemplateVariable;
  blockAtomId: string;
}

const TemplateVariable = ({ template, blockAtomId }: TemplateVariableProps) => {
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;

  const { atomStore } = useStores();

  if (template.value) {
    const tmpSelectedVariable = atomStore.getAtomById(
      template.value.dataItemId,
      template.value.blockType
    );

    if (selectedVariable instanceof Error) {
      newError("Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const onVariableSelected = (seletedVariableReference: DataItemReference) => {
    if (!seletedVariableReference) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    template.value = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    selectedVariable.addReferencingAtom(blockAtomId);
  };

  return (
    <TemplateVariableContainer>
      <TemplateVariableField>{template.variable}</TemplateVariableField>
      <p>{'-->'}</p>
      <VariableField
        onSelected={onVariableSelected}
        value={selectedVariable?.data?.title}
      />
    </TemplateVariableContainer>
  );
};

export default observer(TemplateVariable);
