import BaseStore from '@/mobx/store/base/base.store';
import Model from './base.model';
import { TraceKeyModel } from '../traceKey/traceKey.model';
import { TraceKeyDTO } from '../traceKey/schema';
import { makeObservable, observable } from 'mobx';

export abstract class BaseModelWithTraceKey extends Model {
  public traceKey: TraceKeyModel;

  constructor(
    store: BaseStore<Model>,
    id: string,
    traceKeyDTO: TraceKeyDTO,
    isLoading?: boolean
  ) {
    super(store, id, isLoading);
    this.traceKey = new TraceKeyModel(store, id, traceKeyDTO);

    makeObservable(this, {
      traceKey: observable
    });
  }

  abstract get toJSON();
}
