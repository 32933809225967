import { TextInputSchema, TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { ValidationOption, defaultValidationOption } from './textField.utils';
import { PrefillDataSchema } from '../../prefill.schema';

export const TextFieldDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean(),
      placeholder: valueValidation ? TextInputSchema : z.string(),
      validation: z.nativeEnum(ValidationOption)
    })
    .merge(PrefillDataSchema)
    .strict();

export type TextFieldData = z.infer<ReturnType<typeof TextFieldDataSchema>>;

export const initialTextFieldData: TextFieldData = {
  title: 'Default Text Field Title',
  required: true,
  placeholder: '',
  validation: defaultValidationOption.value
};
