export enum NumberViewType {
  Number = 'Number',
  Progress = 'Progress',
  Code = 'Code'
}

import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_NUMBER_VIEW_TYPES = ['Number', 'Progress', 'Code'] as const;
export const TO_NumberViewTypeSchema = z.enum(TO_NUMBER_VIEW_TYPES);

export const TO_NumberIconSchema = z.enum([
  'Hash',
  'Loader',
  'Code',
  'FileDigit',
  'CircleDashed'
]);

export type TO_NumberIconType = z.infer<typeof TO_NumberIconSchema>;

export const TO_NumberDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_NumberViewTypeSchema,
      hasIcon: z.boolean().default(false),
      icon: TO_NumberIconSchema
    })
    .strict();

export type TO_NumberData = z.infer<ReturnType<typeof TO_NumberDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTONumberData: Omit<TO_NumberData, 'selectedVariable'> = {
  title: 'Default Number Title',
  viewType: 'Number',
  hasIcon: false,
  icon: 'Hash'
};
