import { z } from 'zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';

export const OperatorSchema = z.enum([
  '==',
  '!=',
  '>',
  '<',
  '>=',
  '<=',
  'isTrue',
  'isFalse',
  'contains',
  'defined',
  'startsWith',
  'endsWith',
  'oneOf',
  'allOf',
  'noneOf'
]);

export type Operator = z.infer<typeof OperatorSchema>;

export const ConditionInfoSchema = z
  .object({
    operator: OperatorSchema,
    value: z.union([z.string(), z.array(z.string()), z.null()])
  })
  .strict();

export const ConditionDataSchema = z
  .object({
    selectedVariable: AtomReferenceSchema.nullable(),
    condition: ConditionInfoSchema.nullable()
  })
  .strict();

export type ConditionData = z.infer<typeof ConditionDataSchema>;

export const initialConditionData: ConditionData = {
  selectedVariable: null,
  condition: null
};
