import styled from 'styled-components';

interface EmptyStateRootProps {
  $isDndSettings: boolean;
}

export const EmptyStateRoot = styled.div<EmptyStateRootProps>`
  position: absolute;
  top: ${({ $isDndSettings }) => ($isDndSettings ? '300%' : '50%')};
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  text-align: center;

  .dnd-illustartion {
    width: 400px;
    height: 400px;
  }
`;
