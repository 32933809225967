import {
  Routes,
  Route,
  useLocation,
  Location,
  Navigate
} from 'react-router-dom';
import Settings from '@/pages/Settings';
import DataRepository from '@pages/Settings/data-repository';
import GeneralSetting from '@pages/Settings/general-setting';
import SettingNotifications from '@pages/Settings/notifications';
import EditorNotifications from '@pages/Settings/notifications/editor';
import TraceInfo from '@pages/Settings/trace-info';
import TraceOverview from '@pages/Settings/trace-overview';
import PageNotFound from '@/pages/PageNotFound';
import StratumnEditor from '@pages/StratumnEditor';
import Layout from '@/layout';
import Home from '@/pages/Home';
import Panel from '@/pages/panel';
import Action from '@/pages/panel/components/action';
import Transition from '@/pages/panel/components/transition';
import { TRANSITION_PATHS_TO_FC_MAP } from '@/pages/panel/components/transition/transition.config';
import { ACTION_PATHS_TO_FC_MAP } from '@/pages/panel/components/action/action.config';
import { ParamsList } from './routes.types';
import AddNewProcess from '@pages/NewProcess';
import DraftEditor from '@pages/DraftEditor';
import GlobalVariables from '@pages/Settings/global-variables/globalVariable.page';

const Router = () => {
  const location: Location = useLocation();

  return (
    <Routes location={location}>
      <Route path="/" element={<Layout />} errorElement={<PageNotFound />}>
        <Route index element={<Home />} errorElement={<PageNotFound />} />
        <Route
          path="/new"
          element={<AddNewProcess />}
          errorElement={<PageNotFound />}
        />
        <Route
          path="/:id/"
          errorElement={
            <PageNotFound
              description="An error has occured"
              title="An error has occured"
              label="take me home"
            />
          }
        >
          <Route
            path="editor"
            errorElement={
              <PageNotFound
                description="An error has occured"
                title="An error has occured"
                label="take me home"
              />
            }
            element={<StratumnEditor />}
          >
            <Route path="p" element={<Panel />}>
              <Route path={`a/:${ParamsList.ActionId}`} element={<Action />}>
                {Object.keys(ACTION_PATHS_TO_FC_MAP).map(
                  (actionPath: string) => (
                    <Route
                      key={actionPath}
                      path={actionPath}
                      element={ACTION_PATHS_TO_FC_MAP[actionPath]}
                    />
                  )
                )}
              </Route>
              <Route
                path={`t/:${ParamsList.TransitionId}`}
                element={<Transition />}
              >
                {Object.keys(TRANSITION_PATHS_TO_FC_MAP).map(
                  (transitionPath: string) => (
                    <Route
                      key={transitionPath}
                      path={transitionPath}
                      element={TRANSITION_PATHS_TO_FC_MAP[transitionPath]}
                    />
                  )
                )}
              </Route>
            </Route>
          </Route>
          <Route
            path="draft-editor"
            errorElement={
              <PageNotFound
                description="An error has occured"
                title="An error has occured"
                label="take me home"
              />
            }
            element={<DraftEditor />}
          ></Route>
          <Route path="settings" element={<Settings />}>
            <Route index element={<Navigate to="general" replace />} />
            <Route path="general" element={<GeneralSetting />} />
            <Route path="notification">
              <Route index element={<SettingNotifications />} />
              <Route path={`new`} element={<EditorNotifications />} />
              <Route
                path={`:${ParamsList.NotificationId}`}
                element={<EditorNotifications />}
              />
            </Route>
            <Route path="data-repository" element={<DataRepository />} />
            <Route path="trace-info" element={<TraceInfo />} />
            <Route path="trace-overview" element={<TraceOverview />} />
            <Route path="global-variables" element={<GlobalVariables />} />
          </Route>
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
