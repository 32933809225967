import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { FileUploadData } from './fileUpload.data';

type Props = {
  atom: AtomModel<FileUploadData>;
};

export const FileUploadModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
