import styled from 'styled-components';

interface ChipLabelProps {
  $fontSize?: number;
}
export const ChipLabel = styled.div<ChipLabelProps>`
  background-color: ${(p) => p.theme.color.border.light};
  border-radius: 2px;
  padding: 2px 5px;
  font-size: ${(p) => p.$fontSize || 12}px;
  font-weight: 400;
  display: inline-flex;
  align-items: center;
  text-align: center;
  color: ${(p) => p.theme.color.grey.dark};
  user-select: none;
  font-family: 'JetBrains Mono';
  margin-left: 5px;
  white-space: nowrap;
`;
