import { observer } from 'mobx-react';
import {
  getToolbarItemsByLibrary,
  DndLibrary,
  ALL_BLOCKS
} from '../../library';
import { DNDToolbarItem } from './ToolbarItem';
import { DeleteZone, ToolbarContainer } from './toolbar.style';
import { useDragLayer, useDrop } from 'react-dnd';
import { Trash2 } from 'lucide-react';
import { Item } from '@/mobx/models/dnd.model';
import { useContext } from 'react';
import { DNDMetaContext } from '..';

interface ToolbarProps {
  library: DndLibrary;
}

const DNDToolbar = ({ library }: ToolbarProps) => {
  const items = getToolbarItemsByLibrary(library);
  const { onDelete } = useContext(DNDMetaContext);

  const isDeletableItem = (item: Item): boolean => {
    return !!item.path && item.path !== 'toolbar';
  };

  const [, drop] = useDrop({
    accept: ALL_BLOCKS,
    canDrop(item: Item) {
      return isDeletableItem(item);
    },
    drop: (item: Item) => {
      onDelete(item.path);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop()
    })
  });

  const { isDraggingDeletableItem } = useDragLayer((monitor) => ({
    isDraggingDeletableItem:
      monitor.isDragging() && isDeletableItem(monitor.getItem<Item>())
  }));

  return (
    <ToolbarContainer id="toolbar" ref={drop}>
      {!isDraggingDeletableItem ? (
        items.map((item, index) => <DNDToolbarItem key={index} {...item} />)
      ) : (
        <DeleteZone>
          <Trash2 size={23} />
        </DeleteZone>
      )}
    </ToolbarContainer>
  );
};

export default observer(DNDToolbar);
