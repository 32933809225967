export enum ElementIdType {
  Activity = 'Activity',
  Transition = 'Flow', //! not a shape btw
  Gateway = 'Gateway',
  StartEvent = 'StartEvent',
  Event = 'Event',
  Workflow = 'Participant',
  Lane = 'Lane', // ? what's the best name here? Group?
  Collaboration = 'Collaboration'
}

export enum ElementType {
  Collaboration = 'bpmn:Collaboration',
  Participant = 'bpmn:Participant',
  Lane = 'bpmn:Lane',
  StartEvent = 'bpmn:StartEvent',
  ParallelGateway = 'bpmn:ParallelGateway',
  EndEvent = 'bpmn:EndEvent',
  SequenceFlow = 'bpmn:SequenceFlow',
  MessageFlow = 'bpmn:MessageFlow',
  ExclusiveGateway = 'bpmn:ExclusiveGateway',
  Form = 'trace:Form',
  TableImporter = 'trace:TableImporter',
  TableEditor = 'trace:TableEditor'
}

export type ModdleElement = {
  id: string;
  name: string;
  $type: ElementType;
  [key: string]: unknown;
};
