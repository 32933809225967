import customDraggin from '@components/editor/plugin/dragging';
import customHandTool from '@components/editor/plugin/hand-tool';

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Modeler from 'bpmn-js/lib/Modeler';
import BpmnColorPickerModule from 'bpmn-js-color-picker';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import { Outlet } from 'react-router-dom';

import Canvas from 'diagram-js/lib/core/Canvas';

import { BpmnEditor, LoaderBox, LoaderContainer } from './editor.style';
import { BpmnModel } from '@/mobx/models/bpmn.model';
import useProcess from '@hooks/useProcess';
import RootStore from '@/mobx/store/root.store';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';
import { DraftBpmnEventHandlerCreator } from '@/services/bpmn/events/models/eventHandler.model';
import { CircularProgress } from '@mui/joy';
import ExportButton from '../../components/export';

export let IMPORT_STATUS: 'IMPORTING' | 'IMPORTED' = 'IMPORTING';
const draftBpmnEventHandlerCreator = new DraftBpmnEventHandlerCreator();

const Editor = () => {
  const editorRef = useRef<HTMLDivElement>(null);
  const { process } = useProcess();
  const [modeler, setModeler] = useState<Modeler>();

  const rootStore: RootStore = useStores();

  const loadModeler = () => {
    const newModeler = new Modeler({
      container: editorRef.current ?? undefined,
      keyboard: {
        bindTo: document
      },
      additionalModules: [customDraggin, customHandTool, BpmnColorPickerModule]
    });

    setModeler(newModeler);
    document.getElementsByClassName('bjs-powered-by')[0] &&
      document.getElementsByClassName('bjs-powered-by')[0].remove();
  };

  useEffect(() => {
    loadModeler();
  }, []);

  useEffect(() => {
    if (!process || !!process.loading || !process.bpmnWorkflow) return;
    if (!modeler) {
      return;
    }

    modeler
      .importXML(process.bpmnWorkflow)
      .then(({ warnings }) => {
        IMPORT_STATUS = 'IMPORTED';
        if (warnings.length) {
          console.warn(warnings);
        }

        const canvas = modeler.get<Canvas>('canvas');
        canvas.zoom('fit-viewport');
        process.bpmn = new BpmnModel();
        process.bpmn.setModeler(modeler);
        const bpmnEventHandler = draftBpmnEventHandlerCreator.createHandler(
          modeler,
          rootStore
        );
        bpmnEventHandler.listenToEvents();
      })
      .catch((error) => {
        newError(error, true, {
          customMessage: 'Error: process XML corrupted'
        });
      });
  }, [modeler, process, rootStore]);

  return (
    <>
      {modeler && process && (
        <ExportButton modeler={modeler} processName={process.name} />
      )}
      <BpmnEditor ref={editorRef} />
      <Outlet />
      {process?.loading && (
        <LoaderContainer>
          <LoaderBox>
            <CircularProgress />
          </LoaderBox>
        </LoaderContainer>
      )}
    </>
  );
};

export default observer(Editor);
