import {
  Database,
  Settings,
  AppWindow,
  Grid3X3,
  Bell,
  Variable
} from 'lucide-react';

type MenuItem = {
  id: number;
  name: string;
  icon: React.ReactElement;
  link: string;
};

export const STRATUMN_PROCESS_MENU_ITEMS: MenuItem[] = [
  {
    id: 1,
    name: 'General settings',
    icon: <Settings size={20} />,
    link: 'general'
  },
  {
    id: 2,
    name: 'Notifications',
    icon: <Bell size={20} />,
    link: 'notification'
  },
  {
    id: 3,
    name: 'Data repository',
    icon: <Database size={20} />,
    link: 'data-repository'
  },
  {
    id: 4,
    name: 'Trace info',
    icon: <AppWindow size={20} />,
    link: 'trace-info'
  },
  {
    id: 5,
    name: 'Trace overview',
    icon: <Grid3X3 size={20} />,
    link: 'trace-overview'
  },
  {
    id: 6,
    name: 'Global variables',
    icon: <Variable size={20} />,
    link: 'global-variables'
  }
];
