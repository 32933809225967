import { z } from 'zod';

export enum TraceKeyMode {
  Follow = 'follow',
  Free = 'free',
  Locked = 'locked'
}

export const ModeSchema = z.nativeEnum(TraceKeyMode);

export const TraceKeyDTOSchema = z
  .object({
    value: z.string().optional(),
    mode: ModeSchema,
    prodValue: z.string().nullish()
  })
  .strict();

export type TraceKeyDTO = z.infer<typeof TraceKeyDTOSchema>;
