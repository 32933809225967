import BaseStore from '@/mobx/store/base/base.store';
import { makeObservable, observable } from 'mobx';
import nanoID from '@/utils/nanoID';

export type ModelError = {
  name?: string;
  code?: number;
  message: string;
};
export default abstract class Model {
  constructor(
    public store: BaseStore<Model>,
    public id: string = nanoID(),
    public loading: boolean = false
  ) {
    makeObservable(this, {
      loading: observable
    });
  }

  abstract get toJSON();

  public abstract get errors(): ModelError[];
}
