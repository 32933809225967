import { observer } from 'mobx-react';
import { Database } from 'lucide-react';
import DNDBase from '@/components/dnd/base';
import SettingHeader from '../SettingHeader';
import { SettingBody } from '../style';
import SettingHelper from '../SettingHelper';
import useStores from '@hooks/useStore';
import { DTRBlocks } from '@components/dnd/library/dataRepository';
import ActionButton from '@atoms/button';
import { SettingsContent } from '../settings.style';
import useProcess from '@hooks/useProcess';

const DataRepository = () => {
  const { dndStore } = useStores();
  const { process } = useProcess();

  const dndDataRepo = dndStore.get(process?.dataRepositoryDndId);

  const addNewDataRepository = () => {
    if (!dndDataRepo) return;
    const path = 'root:root|0';
    const element = dndDataRepo.createDndBlock(DTRBlocks.DataRepository);

    const newState = dndDataRepo.insertBlockInState(
      path,
      dndDataRepo.state,
      element
    );
    dndDataRepo.setState(newState);
  };

  if (!dndDataRepo) return <></>;

  return (
    <SettingsContent>
      <SettingHeader
        title="Data repositories"
        description="Store all the data that aren't processus specific"
      />
      <SettingHelper
        title="How to use a data repository?"
        icon={<Database />}
        description="A data repository consists of variables you'd want to reference throughout all your process workflows. Take, for example, a list of all the countries in Europe or all the days of the week. You might use it frequently in various actions so instead of typing this info repeatedly, keep it in this repository for easy access."
      />
      <SettingBody>
        {process?.loading && <></>}
        {!process?.loading && (
          <>
            <div className="title-body">
              <div>
                <h3>Manage your data repositories</h3>
              </div>
              <ActionButton
                value="Add a new repository"
                onClick={() => addNewDataRepository()}
              />
            </div>
            <DNDBase dnd={dndDataRepo} />
          </>
        )}
      </SettingBody>
    </SettingsContent>
  );
};

export default observer(DataRepository);
