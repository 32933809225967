import { CircleDashed, Code, FileDigit, Hash, Loader2 } from 'lucide-react';
import { TO_NumberIconType } from './number.data';

type IconOption = {
  value: TO_NumberIconType;
  label: string;
  icon: JSX.Element;
};

export const numberIconOptions: IconOption[] = [
  { value: 'Hash', label: 'Number', icon: <Hash size={14} /> },
  { value: 'Loader', label: 'Progress', icon: <Loader2 size={14} /> },
  { value: 'Code', label: 'Code', icon: <Code size={14} /> },
  { value: 'FileDigit', label: 'Digit', icon: <FileDigit size={14} /> },
  { value: 'CircleDashed', label: 'Circle', icon: <CircleDashed size={14} /> }
];
