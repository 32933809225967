import RootStore from '@/mobx/store/root.store';
import { CreateShapeEvent, DeleteShapeEvent } from '../event.types';
import { UserAction, shapeToFunctionPlexer } from './shape.plexer';

export const onShapeCreated = (
  event: CreateShapeEvent,
  rootStore: RootStore
) => {
  if (!event.context) return;
  if (!event.context.shape) return;

  const { shape } = event.context;
  const createShapeFunction = shapeToFunctionPlexer(shape, UserAction.Create);
  createShapeFunction(event, rootStore);
};

export const onShapeDelete = (
  event: DeleteShapeEvent,
  rootStore: RootStore
) => {
  if (!event.context) return;
  if (!event.context.shape) return;

  const { shape } = event.context;
  const deleteShapeFunction = shapeToFunctionPlexer(shape, UserAction.Delete);
  deleteShapeFunction(event, rootStore);
};
