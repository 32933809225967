import { FC } from 'react';
import * as Blocks from '@components/dnd/library/notification/blocks';
import { DNDInfo, LibraryProps, Plexer, ToolbarItem } from '../library.types';

export enum NFBlocks {
  Email = 'Send Email'
}

export const NOTIF_TOOLBAR_ITEMS: ToolbarItem<NFBlocks>[] = [
  {
    type: NFBlocks.Email,
    icon: <Blocks.EmailIcon />
  }
];

export const NF_INFO_DND: DNDInfo = {
  title: 'Notification',
  description:
    'Drag & drop a block to add a sending method. Choose the person(s) who will receive the notification.'
};

const NotificationPlexer: Plexer<NFBlocks> = {
  [NFBlocks.Email]: Blocks.Email
};

export const LibraryNF: FC<LibraryProps> = ({ block }) => {
  const Block = NotificationPlexer[block.type as NFBlocks];
  return <Block {...block} />;
};
