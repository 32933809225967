import { observer } from 'mobx-react';
import { LucideIcon, Mail } from 'lucide-react';

import BlockBase from '@/components/dnd/base/blockBase';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { DNDBlock } from '@components/dnd/base';
import useVariableNotifications from '@hooks/useNotificationVariables';
import { SelectField } from '@atoms/select';
import { FormControl, FormLabel, Option } from '@mui/joy';
import TemplateVariable from './TemplateVariable';
import styled from 'styled-components';
import EmailHeader from './email.header';
import { useEffect } from 'react';
import { initialEmailData } from './email.data';
import { useAtom } from '@hooks/useAtom';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';
import { deepCopy } from '@/utils/deepCopy';

export const EmailIcon: LucideIcon = Mail;

export type SelectEvent =
  | React.MouseEvent<Element, MouseEvent>
  | React.KeyboardEvent
  | React.FocusEvent<Element, Element>
  | null;

const TVList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Email = (dndBlock: DNDBlock) => {
  const transitionId = useParams()[ParamsList.TransitionId];
  const notificationsVariables = useVariableNotifications();

  const dataItem = useAtom({
    block: dndBlock,
    dataType: 'notification',
    library: 'notification',
    initialData: initialEmailData,
    sourceId: transitionId,
    sourceName: 'Transition'
  });

  useEffect(() => {
    if (!dataItem) return;

    if (
      dataItem.data.dataSource?.template &&
      dataItem.data.dataSourceData?.templateVariables &&
      notificationsVariables
    ) {
      // Check if template  still exist
      const currentVariable = notificationsVariables.find(
        (o) => o.id === dataItem.data.dataSource?.template
      );

      if (currentVariable) {
        const notifId: string[] = deepCopy(
          dataItem.data.dataSourceData.templateVariables
        ).map((temp) => temp.variable);

        const updateTemplateVariables = currentVariable.variables.map((v) => {
          if (
            dataItem.data.dataSourceData?.templateVariables &&
            notifId.includes(v.text)
          ) {
            const notifValue = deepCopy(
              dataItem.data.dataSourceData.templateVariables
            ).find((temp) => temp.variable === v.text);

            return {
              variable: v.text,
              value: notifValue?.value ?? null
            };
          }

          return { variable: v.text, value: null };
        });

        dataItem.data.dataSourceData.templateVariables =
          updateTemplateVariables.length === 0 ? null : updateTemplateVariables;
      } else {
        //Remove data if template no longer exist
        dataItem.data.dataSourceData.templateVariables = null;
        dataItem.data.dataSource.template = '';
      }
    }
  }, [dataItem, notificationsVariables]);

  if (!dataItem) return null;

  const handleChange = (value: string | null): void => {
    if (!value || !dataItem.data.dataSource || !dataItem.data.dataSourceData) {
      return; // Check if value exist to avoid to filling dataSource.template with a null value on load
    }

    dataItem.data.dataSource.template = value;

    if (notificationsVariables) {
      const currentVariable = notificationsVariables.find(
        (o) => o.id === value
      );
      if (currentVariable?.variables && currentVariable.variables.length > 0) {
        const variableState = currentVariable.variables.map((v) => ({
          variable: v.text,
          value: null
        }));
        dataItem.data.dataSourceData.templateVariables = variableState;
      } else {
        dataItem.data.dataSourceData.templateVariables = null;
      }
    }
  };

  if (!dataItem) return null;

  return (
    <>
      <BlockBase
        dndBlock={dndBlock}
        title={'notification'}
        icon={EmailIcon}
        header={
          <EmailHeader dataItem={dataItem} blockAtomId={dndBlock.atomId} />
        }
        endControls={
          <EndControlsFB
            hasRequired={false}
            dataItem={dataItem}
            dndBlock={dndBlock}
            isHovering={false}
          />
        }
      >
        <Column $width="auto">
          <SelectField
            label="Template"
            value={dataItem.data.dataSource?.template ?? ''}
            onChange={(_, value) => handleChange(value)}
          >
            {notificationsVariables &&
              notificationsVariables.map((notif, index) => (
                <Option key={index} value={notif.id}>
                  {notif.title}
                </Option>
              ))}
          </SelectField>
          {dataItem.data.dataSourceData?.templateVariables && (
            <FormControl sx={{ marginTop: '10px' }} size="sm">
              <FormLabel>Template variables</FormLabel>
              <TVList>
                {dataItem.data.dataSourceData.templateVariables.map(
                  (template, index) => (
                    <TemplateVariable
                      key={index}
                      template={template}
                      blockAtomId={dndBlock.atomId}
                    />
                  )
                )}
              </TVList>
            </FormControl>
          )}
        </Column>
      </BlockBase>
    </>
  );
};

export default observer(Email);
