export enum EnvironmentName {
  Local = 'local',
  Demo = 'demo',
  Sandbox = 'sandbox',
  Staging = 'staging',
  Preprod = 'preprod',
  Release = 'release'
}

export const ANALYTICS_API_URL: string = import.meta.env
  .VITE_ANALYTICS_API_URL as string;
export const ANALYTICS_UI_URL: string = import.meta.env
  .VITE_ANALYTICS_UI_URL as string;
export const ACCOUNT_API_URL: string = import.meta.env
  .VITE_ACCOUNT_API_URL as string;
export const TRACE_UI_URL: string = import.meta.env.VITE_TRACE_UI_URL as string;
export const TRACE_API_URL: string = import.meta.env
  .VITE_TRACE_API_URL as string;
export const STUDIO_API_URL: string = import.meta.env
  .VITE_STUDIO_API_URL as string;
export const ACCOUNT_UI_URL: string = import.meta.env
  .VITE_ACCOUNT_UI_URL as string;
export const VITE_ENV: string = import.meta.env.VITE_ENV as string;
export const CLIENT_ID: string = import.meta.env.VITE_CLIENT_ID as string;

export const ROUTE_PROCESSES = '/';
export const ENV: EnvironmentName = import.meta.env.VITE_ENV as EnvironmentName;
