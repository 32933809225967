import { TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';
import { PrefillDataSchema } from '../../prefill.schema';

export const FileUploadDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      required: z.boolean()
    })
    .merge(PrefillDataSchema)
    .strict();

export type FileUploadData = z.infer<ReturnType<typeof FileUploadDataSchema>>;

export const initialFileUploadData: FileUploadData = {
  title: 'Default File Upload Title',
  required: true
};
