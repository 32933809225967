import { MetaInfo, VariableInfo } from '@/mobx/types/atom.types';
import useStores from './useStore';
import { AtomModel } from '@/mobx/models/atom.model';
import { newError } from '@/services/errors/errors';
import { DNDBlock } from '@components/dnd/base';

type UseDataProps<TData> = {
  block: DNDBlock;
  initialData: TData;
  dataType: AtomModel['dataType'];
  sourceId: MetaInfo['source']['id'] | undefined;
  sourceName: MetaInfo['source']['name'] | undefined;
  library: MetaInfo['source']['library'];
  variableInfo?: VariableInfo;
};

/**
 * Returns the atom associated with a Drag & Drop block.
 * If the atom doesn't exist, it will be created.
 */
export const useAtom = <TData>({
  block,
  initialData,
  dataType,
  sourceId,
  sourceName,
  library,
  variableInfo
}: UseDataProps<TData>): Maybe<AtomModel<TData>> => {
  const { atomStore } = useStores();

  const dataVariable = atomStore.getAtomById<TData>(block.atomId, block.type);

  if (dataVariable instanceof Error) return;

  if (dataVariable) {
    return dataVariable;
  }

  if (!sourceId) {
    newError('Source id is undefined when creating a new data item');
    return;
  }

  const metaInfo: MetaInfo = {
    source: {
      id: sourceId ?? 'no source id',
      name: sourceName,
      library
    },
    dnd: {
      id: block.id ?? 'no dnd block id',
      blockType: block.type
    }
  };

  const newDataVariable = atomStore.createAtom(
    block.atomId,
    dataType,
    initialData,
    metaInfo,
    variableInfo
  );

  if (!newDataVariable) {
    newError('Failed to create a new data variable');
    return;
  }

  return newDataVariable;
};
