import { FbBlocks } from './formBuilder.type';
import { TextFieldDataSchema } from './blocks/textField/textField.data';
import { RichTextDataSchema } from './blocks/richText/richText.data';
import { NumberFieldDataSchema } from './blocks/number/number.data';
import { FileUploadDataSchema } from './blocks/fileUpload/fileUpload.data';
import { DropDownDataSchema } from './blocks/dropDown/dropDown.data';
import { DateFieldDataSchema } from './blocks/date/date.data';
import { CheckBoxDataSchema } from './blocks/checkbox/checkbox.data';
import { z } from 'zod';
import { CommentDataSchema } from './blocks/comment/comment.data';

export const FormBuilderBlockToSchemaMap: Record<FbBlocks, z.Schema> = {
  [FbBlocks.TextField]: TextFieldDataSchema(),
  [FbBlocks.Date]: DateFieldDataSchema(),
  [FbBlocks.Number]: NumberFieldDataSchema(),
  [FbBlocks.RichText]: RichTextDataSchema(),
  [FbBlocks.FileUpload]: FileUploadDataSchema(),
  [FbBlocks.CheckBox]: CheckBoxDataSchema(),
  [FbBlocks.DropDown]: DropDownDataSchema(),
  [FbBlocks.Comment]: CommentDataSchema()
};
