import { TOBlocks } from '.';
import { TO_TextDataSchema } from './blocks/text/text.data';
import { TO_UserDataSchema } from './blocks/user/user.data';
import { TO_NumberDataSchema } from './blocks/number/number.data';
import { TO_FileDataSchema } from './blocks/fileCompact/fileCompact.data';
import { TO_DateDataSchema } from './blocks/date/date.data';
import { TO_ListDataSchema } from './blocks/list/list.data';
import { z } from 'zod';

export const TraceOverviewBlockToSchemaMap: Record<TOBlocks, z.Schema> = {
  [TOBlocks.Text]: TO_TextDataSchema(),
  [TOBlocks.File]: TO_FileDataSchema(),
  [TOBlocks.Date]: TO_DateDataSchema(),
  [TOBlocks.List]: TO_ListDataSchema(),
  [TOBlocks.Number]: TO_NumberDataSchema(),
  [TOBlocks.User]: TO_UserDataSchema()
};
