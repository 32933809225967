const INF = 100000;

function StudioDefaultName(eventBus, modeling) {
  eventBus.on('commandStack.shape.create.postExecute', INF, (event) => {
    // if (
    //   ['bpmn:StartEvent', 'bpmn:EndEvent'].includes(event.context.shape.type)
    // ) {
    //   // do nothing for start gateway and end gateways
    // } else {
    //   console.log(event);
    //   modeling.updateProperties(event.context.shape, { name: 'New action' });
    // }
  });
}

StudioDefaultName.$inject = ['eventBus', 'modeling'];

export default StudioDefaultName;
