import { z } from 'zod';
import { ConditionDataSchema } from './blocks/condition.data';
import { ConditionBlocks } from './condition.type';
import { CodeConditionDataSchema } from './blocks/codeCondition.data';

export const ConditionBlockToSchemaMap: Record<ConditionBlocks, z.Schema> = {
  [ConditionBlocks.Condition]: ConditionDataSchema,
  [ConditionBlocks.CodeCondition]: CodeConditionDataSchema,
  [ConditionBlocks.And]: z.unknown(),
  [ConditionBlocks.Or]: z.unknown(),
  [ConditionBlocks.Not]: z.unknown()
};
