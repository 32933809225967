import { TIBlocks } from '.';
import { TI_WindowDataSchema } from './blocks/window/window.data';
import { TI_DataTabDataSchema } from './blocks/dataTab/dataTab.data';
import { TI_FileUploadDataSchema } from './blocks/fileUpload/fileUpload.data';
import { TI_DateDataSchema } from './blocks/date/date.data';
import { TI_ListDataSchema } from './blocks/list/list.data';
import { TI_TextDataSchema } from './blocks/text/text.data';
import { TI_NumberDataSchema } from './blocks/number/number.data';
import { TI_CommentDataSchema } from './blocks/comment/comment.data';
import { z } from 'zod';

export const TraceInfoBlockToSchemaMap: Record<TIBlocks, z.Schema> = {
  [TIBlocks.Text]: TI_TextDataSchema(),
  [TIBlocks.DataTab]: TI_DataTabDataSchema(),
  [TIBlocks.Window]: TI_WindowDataSchema(),
  [TIBlocks.File]: TI_FileUploadDataSchema(),
  [TIBlocks.Date]: TI_DateDataSchema(),
  [TIBlocks.List]: TI_ListDataSchema(),
  [TIBlocks.Number]: TI_NumberDataSchema(),
  [TIBlocks.Comment]: TI_CommentDataSchema()
};
