import styled, { css } from 'styled-components'

interface VariableContainerProps {
    $disabled: boolean;
}

export const VariableContainer = styled.div<VariableContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 14px;
  border: 1px solid #CDD7E1;
  padding: 4px 10px;
  border-radius: 6px;
  box-shadow: 0 0 #000, 0 0 #000, 0px 1px 2px 0px rgba(21 21 21 / 0.08);
  cursor: pointer;

  span {
      font-style: italic;
      color: ${({ theme }) => theme.color.grey[4]};
  }

  ${({ $disabled }) =>
    $disabled ?
    css`
      pointer-events: none;
      cursor: default;
      color: #9FA6AD;
      border-color: #DDE7EE;
    ` :
    css`
      &:hover {
          background-color: #f0f4f8;
      }
    `}
`

export const VariableValue = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`