import { MouseEvent, useEffect, useRef, useState } from 'react';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';

import AccountSVG from '@/assets/svg/account.svg?react';
import SignOutSVG from '@/assets/svg/signout.svg?react';
import Logo from '../logo/logo.comp';
import {
  ACCOUNT_UI_URL,
  EnvironmentName,
  TRACE_UI_URL
} from '@/utils/constants';
import { logout } from '@/utils/logout';
import { ENV } from '@/utils/constants';
import {
  AccountPicture,
  CustomMenuItem,
  EnvironmentSpan,
  HeaderSection,
  HeaderWrapper,
  NavbarEditorMenu,
  NavbarEditorSection,
  ProcessInfos,
  ProcessList,
  ProcessNameEllipsis,
  ProjectTypeTitle,
  RainbowButton,
  Spacer
} from './header.style';
import {
  Avatar,
  Button,
  Divider,
  Dropdown,
  ListDivider,
  ListItemDecorator,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Tooltip,
  Typography
} from '@mui/joy';
import { useTheme } from 'styled-components';
import useStores from '@hooks/useStore';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProcessModel } from '@/mobx/models/process.model';
import { observer } from 'mobx-react';
import {
  ChevronsUpDown,
  Pencil,
  Rocket,
  Search,
  Settings,
  Share2
} from 'lucide-react';
import ActionButton from '@atoms/button';
import { InputField } from '@atoms/input';
import { ParamsList } from '@/routes/routes.types';
import {
  ProcessInfoLoaded,
  ProcessInfosLoadedSchema
} from '@/mobx/store/process.store';
import { toast } from 'sonner';
import { User } from '@/services/account/account.service';
import FeedbackDialog from '@components/feedback';
import { parseWithZod } from '@/utils/parseZodSchema';
import { motion } from 'framer-motion';
import ShareDialog from '@components/share';

interface HeaderProps {
  user: User;
}

const DEFAULT_ICON = '/src/assets/images/sia.png';

const Header = ({ user }: HeaderProps) => {
  const theme = useTheme();
  const { processStore } = useStores();
  const currentProcessId = useParams()[ParamsList.ProcessId];
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isEnvironmentDisplayed: boolean =
    ENV !== EnvironmentName.Release &&
    !!ENV &&
    Object.values(EnvironmentName).includes(ENV);

  const [processes, setProcesses] = useState<ProcessInfoLoaded[]>([]);
  const [draftProcesses, setDraftProcesses] = useState<ProcessInfoLoaded[]>([]);
  const [filteredProcesses, setFilteredProcesses] = useState<
    ProcessInfoLoaded[]
  >([]);
  const [filteredDraftProcesses, setFilteredDraftProcesses] = useState<
    ProcessInfoLoaded[]
  >([]);

  const buttonRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalShare, setOpenModalShare] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [processSearch, setProcessSearch] = useState('');
  const [enableAnimation, setEnableAnimation] = useState(false);
  const isEditor = pathname.includes('editor');
  const currentProcess =
    currentProcessId && processStore && processStore.get(currentProcessId);

  const currentProcessFromStore =
    currentProcess && processStore.get(currentProcessId)?.workflowIds;

  const hasBeenGenerated =
    currentProcessFromStore &&
    currentProcessFromStore.some((wf) => wf.published_id);

  const isDraft =
    currentProcessId &&
    processStore &&
    processStore.get(currentProcessId)?.draft;

  useEffect(() => {
    const handleProcesses = async () => {
      const data = await processStore.getAll();
      const parsedProcesses = parseWithZod(ProcessInfosLoadedSchema, data);
      if (!parsedProcesses) return;

      const sortedProcesses = parsedProcesses.sort((a, b) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });

      setDraftProcesses([]);
      setProcesses([]);

      sortedProcesses.forEach((process) => {
        if (process.draft) {
          setDraftProcesses((draftProcesses) => [...draftProcesses, process]);
          setFilteredDraftProcesses((draftProcesses) => [
            ...draftProcesses,
            process
          ]);
        } else {
          setProcesses((stratumnProcesses) => [...stratumnProcesses, process]);
          setFilteredProcesses((stratumnProcesses) => [
            ...stratumnProcesses,
            process
          ]);
        }
      });
    };
    if (!currentProcessId) return;
    void handleProcesses();
  }, [processStore, currentProcessId, currentProcess]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSelectorOpen]);

  useEffect(() => {
    setFilteredProcesses(
      processes
        .filter((process) => process.id !== currentProcessId)
        .filter((process) => {
          return process?.name
            ?.toLowerCase()
            .includes(processSearch.toLowerCase());
        })
    );

    setFilteredDraftProcesses(
      draftProcesses
        .filter((draft) => draft.id !== currentProcessId)
        .filter((draft) => {
          return draft?.name
            ?.toLowerCase()
            .includes(processSearch.toLowerCase());
        })
    );
  }, [currentProcessId, processSearch, processes, draftProcesses]);

  const onProcessSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProcessSearch(event.target.value);
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  const stopEventPropagation = (evt: React.KeyboardEvent<HTMLElement>) => {
    evt.stopPropagation();
    if (inputRef.current && document.activeElement !== inputRef.current) {
      inputRef.current.focus();
    }
  };

  const onProcessChange = (
    processId: ProcessModel['id'],
    isDraft: ProcessModel['draft']
  ) => {
    window.location.href = isDraft
      ? `/${processId}/draft-editor`
      : `/${processId}/editor`; //! Not ideal, find an other if possible // when using navigate, we have error in console double key
  };

  const generateProcess = () => {
    const prom = processStore.generateCurrent();
    toast.promise(prom, {
      loading: 'Your process generation has been queue!',
      success: () => {
        setEnableAnimation(true);
        return 'Your workflow has been generated';
      },
      error: () => {
        return 'An error occured while generating your process';
      }
    });
  };

  const divElement =
    currentProcess && document.getElementById(currentProcess.id);

  const isTextTruncated =
    divElement && divElement.scrollWidth > divElement?.clientWidth;
  return (
    <>
      <HeaderSection>
        <HeaderWrapper>
          <Logo />
          <NavbarEditorSection>
            {currentProcessId && currentProcess && (
              <>
                <Typography level="title-lg" textColor={theme.color.grey[6]}>
                  /
                </Typography>

                <Dropdown
                  onOpenChange={() => setIsSelectorOpen(!isSelectorOpen)}
                >
                  <MenuButton
                    size="sm"
                    endDecorator={
                      <ChevronsUpDown color={theme.color.grey[6]} size={14} />
                    }
                    variant="plain"
                    sx={{ gap: '10px' }}
                  >
                    <Avatar
                      size="sm"
                      sx={{ height: '20px', width: '20px' }}
                      src={DEFAULT_ICON}
                    />
                    <ProcessInfos>
                      <Tooltip
                        title={currentProcess.name}
                        enterDelay={200}
                        variant="outlined"
                        color="neutral"
                        disableHoverListener={!isTextTruncated}
                        sx={{ zIndex: 10001 }}
                      >
                        <ProcessNameEllipsis id={currentProcess.id}>
                          {currentProcess.name}
                        </ProcessNameEllipsis>
                      </Tooltip>
                    </ProcessInfos>
                  </MenuButton>

                  <NavbarEditorMenu>
                    <MenuItem
                      onKeyDown={stopEventPropagation}
                      sx={{
                        padding: 0,
                        '&:not(.Mui-selected, [aria-selected="true"]):hover': {
                          bgcolor: 'transparent'
                        }
                      }}
                    >
                      <InputField
                        id="input-field"
                        variant="plain"
                        value={processSearch}
                        onChange={onProcessSearch}
                        startDecorator={<Search size={20} />}
                        placeholder="Search process or draft..."
                        slotProps={{ input: { ref: inputRef } }}
                      />
                    </MenuItem>
                    <ListDivider />
                    <ProcessList>
                      {filteredProcesses.length === 0 ? (
                        <p style={{ textAlign: 'center' }}>
                          {filteredDraftProcesses.length === 0
                            ? 'No process or draft found'
                            : 'No process found'}
                        </p>
                      ) : (
                        <>
                          <ProjectTypeTitle>Processes</ProjectTypeTitle>
                          {filteredProcesses.map((process) => (
                            <CustomMenuItem
                              key={process.id}
                              onClick={() => onProcessChange(process.id, false)}
                            >
                              <Avatar
                                size="sm"
                                sx={{ height: '20px', width: '20px' }}
                                src={DEFAULT_ICON}
                              />
                              {process.name}
                            </CustomMenuItem>
                          ))}
                        </>
                      )}
                      {filteredDraftProcesses.length === 0 ? (
                        filteredProcesses.length !== 0 && (
                          <>
                            <ListDivider />
                            <p style={{ textAlign: 'center' }}>
                              No drafts found
                            </p>
                          </>
                        )
                      ) : (
                        <>
                          <ListDivider />
                          <ProjectTypeTitle>Drafts</ProjectTypeTitle>
                          {filteredDraftProcesses.map((draft) => (
                            <CustomMenuItem
                              key={draft.id}
                              onClick={() => onProcessChange(draft.id, true)}
                            >
                              <Avatar
                                size="sm"
                                sx={{ height: '20px', width: '20px' }}
                                src={DEFAULT_ICON}
                              />
                              {draft.name}
                            </CustomMenuItem>
                          ))}
                        </>
                      )}
                    </ProcessList>
                  </NavbarEditorMenu>
                </Dropdown>
              </>
            )}
          </NavbarEditorSection>

          {isEnvironmentDisplayed && (
            <EnvironmentSpan>{ENV} environment</EnvironmentSpan>
          )}
          <Spacer />

          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            sx={{ margin: '0 24px' }}
          >
            <Button
              color="neutral"
              size="sm"
              variant="plain"
              sx={{ fontWeight: 500 }}
              disabled
            >
              Docs
            </Button>

            <RainbowButton
              onClick={() => {
                setOpenModal(true);
                setOpenModalShare(false);
              }}
              data-tag="feedback"
            >
              Feedback
            </RainbowButton>

            {currentProcessId &&
              hasBeenGenerated !== undefined &&
              currentProcessFromStore !== undefined && (
                <>
                  <Divider orientation="vertical" />

                  <Button
                    color="primary"
                    size="sm"
                    variant="plain"
                    onClick={(e: MouseEvent<HTMLButtonElement>) => {
                      e.preventDefault();
                      navigate(
                        `${currentProcessId}/${
                          isEditor
                            ? 'settings'
                            : isDraft
                            ? 'draft-editor'
                            : 'editor'
                        }`
                      );
                    }}
                    startDecorator={
                      isEditor ? <Settings size={18} /> : <Pencil size={18} />
                    }
                  >
                    {isEditor ? 'Settings' : 'Editor'}
                  </Button>

                  {isDraft !== false ? (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        setOpenModal(false);
                        setOpenModalShare(!openModalShare);
                      }}
                      startDecorator={<Share2 size={18} />}
                    >
                      Share
                    </Button>
                  ) : (
                    <>
                      <ActionButton
                        value="Generate"
                        startDecorator={<Rocket size={18} />}
                        size="sm"
                        onClick={generateProcess}
                      />

                      {hasBeenGenerated &&
                        (currentProcessFromStore.length === 1 ? (
                          enableAnimation ? (
                            <motion.div
                              initial={{ opacity: 0.5, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{
                                duration: 0.7,
                                ease: [0, 0.71, 0.2, 1.01]
                              }}
                            >
                              <Link
                                to={`${TRACE_UI_URL}/workflowoverview/${currentProcessFromStore[0].published_id}`}
                                target="_blank"
                              >
                                <Button variant="outlined">Trace</Button>
                              </Link>
                            </motion.div>
                          ) : (
                            <Link
                              to={`${TRACE_UI_URL}/workflowoverview/${currentProcessFromStore[0].published_id}`}
                              target="_blank"
                            >
                              <Button variant="outlined">Trace</Button>{' '}
                            </Link>
                          )
                        ) : (
                          <Dropdown
                            onOpenChange={() =>
                              setIsSelectorOpen(!isSelectorOpen)
                            }
                          >
                            <MenuButton
                              size="sm"
                              endDecorator={
                                <ChevronsUpDown
                                  color={theme.color.grey[6]}
                                  size={14}
                                />
                              }
                              variant="outlined"
                              sx={{ gap: '10px' }}
                            >
                              Trace
                            </MenuButton>

                            <NavbarEditorMenu>
                              <ProcessList>
                                {currentProcessFromStore.map((wf) => (
                                  <CustomMenuItem key={wf.id}>
                                    <Link
                                      to={`${TRACE_UI_URL}/workflowoverview/${wf.published_id}`}
                                      target="_blank"
                                    >
                                      Workflow {wf.published_id}
                                    </Link>
                                  </CustomMenuItem>
                                ))}
                              </ProcessList>
                            </NavbarEditorMenu>
                          </Dropdown>
                        ))}
                    </>
                  )}
                </>
              )}
          </Stack>

          <AccountPicture
            $image={
              user?.avatar != null
                ? user?.avatar
                : 'https://www.w3schools.com/howto/img_avatar.png'
            }
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
            onClick={() => {
              setOpenMenu(!openMenu);
            }}
            ref={buttonRef}
          />
          <Popper
            role={undefined}
            id="composition-menu"
            open={openMenu}
            anchorEl={buttonRef.current}
            placement="bottom-end"
            disablePortal
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [0, 4]
                }
              }
            ]}
          >
            <ClickAwayListener
              onClickAway={(event) => {
                if (event.target !== buttonRef.current) {
                  handleClose();
                }
              }}
            >
              <MenuList variant="outlined" sx={{ boxShadow: 'md' }}>
                <MenuItem
                  sx={{ gap: '20px', fontSize: '14px' }}
                  onClick={() => (location.href = ACCOUNT_UI_URL)}
                >
                  <ListItemDecorator
                    sx={{
                      width: '20px',
                      height: '20px',
                      minInlineSize: 'unset'
                    }}
                  >
                    <AccountSVG />
                  </ListItemDecorator>{' '}
                  Account
                </MenuItem>
                <MenuItem
                  sx={{ gap: '20px', fontSize: '14px' }}
                  onClick={() => logout()}
                >
                  <ListItemDecorator
                    sx={{
                      width: '20px',
                      height: '20px',
                      minInlineSize: 'unset'
                    }}
                  >
                    <SignOutSVG />
                  </ListItemDecorator>{' '}
                  Sign out
                </MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Popper>
        </HeaderWrapper>
      </HeaderSection>

      <FeedbackDialog openModal={openModal} setOpenModal={setOpenModal} />
      {isDraft && (
        <ShareDialog
          key="modal"
          openModalShare={openModalShare}
          setOpenModalShare={setOpenModalShare}
          user={user}
          processId={currentProcessId}
        />
      )}
    </>
  );
};

export default observer(Header);
