import { AtomModel } from '@/mobx/models/atom.model';
import { AllBlocks } from '@components/dnd/library';
import { z } from 'zod';

export const AtomReferenceSchema = z
  .object({
    dataItemId: z.string() as z.ZodType<AtomModel['id']>,
    blockType: z.string() as z.ZodType<AllBlocks>,
    sourceId: z.string()
  })
  .strict();

export type DataItemReference = z.infer<typeof AtomReferenceSchema>;
