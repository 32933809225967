import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { LucideIcon, MessageCircle } from 'lucide-react';
import { FormControl, FormLabel } from '@mui/joy';

import BlockBase from '@/components/dnd/base/blockBase';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { DNDBlock } from '@components/dnd/base';
import VariableField from '@atoms/variableField';
import { useAtom } from '@hooks/useAtom';
import { initialTICommentData } from './comment.data';
import useStores from '@hooks/useStore';

export const CommentIcon: LucideIcon = MessageCircle;

const Comment = (dndBlock: DNDBlock) => {
  const { atomStore: dataItemStore } = useStores();

  const dataItem = useAtom({
    block: dndBlock,
    dataType: 'traceInfo',
    initialData: {
      ...initialTICommentData,
      selectedVariable: dndBlock.other?.selectedVariableRef
    },
    library: 'traceInfo',
    sourceId: 'null',
    sourceName: 'Trace Info'
  });

  if (!dataItem) return null;

  const selectedVariable = dataItem.data.selectedVariable;
  if (!selectedVariable) return null;

  const variable = dataItemStore.getAtomById<{ title: string }>(
    selectedVariable.dataItemId,
    selectedVariable.blockType
  );

  if (!variable || variable instanceof Error) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dataItem.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={dndBlock}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={CommentIcon}
      endControls={
        <EndControlsFB
          hasRequired={false}
          dndBlock={dndBlock}
          dataItem={dataItem}
        />
      }
    >
      <Column $width="200px">
        <InputField
          label="Widget title"
          value={dataItem.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableField value={variable.data.title} disabled />
        </FormControl>
      </Column>
    </BlockBase>
  );
};

export default observer(Comment);
