import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { CheckBoxData } from './checkbox.data';

type Props = {
  atom: AtomModel<CheckBoxData>;
};

export const CheckBoxModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
