import styled from 'styled-components';
export const TOOLBAR_HEIGHT = 70;
export const ToolbarContainer = styled.section`
  box-sizing: border-box;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  z-index: 100;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 0;
  height: ${TOOLBAR_HEIGHT}px;
  overflow-x: auto;
  background-color: #fafafa0a;
  width: 100%;
  box-shadow: 0px 2px 8px 0px #0000001a;
  backdrop-filter: blur(12px);
  scrollbar-width: thin;
`;

const DELETE_ZONE_MARGIN = 0.5;
export const DeleteZone = styled.section`
  box-sizing: border-box;
  height: calc(100% - ${2 * DELETE_ZONE_MARGIN}rem);
  margin-block: ${DELETE_ZONE_MARGIN}rem;
  border-radius: 5px;
  width: 100%;
  border: 1px ${(p) => p.theme.color.grey[4]};
  border-style: dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  color: ${(p) => p.theme.color.grey.dark};
`;
