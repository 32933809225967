export const pluralize = (
  count: number,
  word: string,
  showCount: boolean = true,
  firstLetterUppercase: boolean = false
) => {
  const firstLetter = firstLetterUppercase ? word[0].toUpperCase() : word[0];
  return `${showCount ? count : ''} ${firstLetter + word.slice(1)}${
    count !== 1 ? 's' : ''
  }`;
};
