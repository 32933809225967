import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_KeyValueDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_KeyValueData = z.infer<ReturnType<typeof TI_KeyValueDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTIKeyValueData: Omit<TI_KeyValueData, 'selectedVariable'> =
  {
    title: 'Default KeyValue Title'
  };
