import { TitleInputSchema } from '@/utils/zod';
import { z } from 'zod';

export const TI_WindowDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      isCollapsable: z.boolean(),
      value: z.string() //! not sure if this is needed
    })
    .strict();

export type TI_WindowData = z.infer<ReturnType<typeof TI_WindowDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTIWindowData: Omit<TI_WindowData, 'selectedVariable'> = {
  title: 'Default Window Title',
  isCollapsable: true,
  value: ''
};
