import { FC } from 'react';
import * as Blocks from '@components/dnd/library/formBuilder/blocks';
import { LibraryProps, Plexer, ToolbarItem } from '../library.types';
import { FbBlocks } from './formBuilder.type';
import { DateIcon, FileUploadIcon, NumberIcon } from '../traceInfo/blocks';
import { CheckBoxIcon } from './blocks/checkbox/checkbox.utils';
import { DropDownIcon } from './blocks/dropDown/dropDown.utils';
import { RichTextIcon } from './blocks/richText/richText.utils';
import { TextFieldIcon } from './blocks/textField/textField.utils';
import { CommentIcon } from './blocks/comment/comment.utils';

export const FB_TOOLBAR_ITEMS: ToolbarItem<FbBlocks>[] = [
  {
    type: FbBlocks.TextField,
    icon: <TextFieldIcon />
  },
  {
    type: FbBlocks.CheckBox,
    icon: <CheckBoxIcon />
  },
  {
    type: FbBlocks.DropDown,
    icon: <DropDownIcon />
  },
  {
    type: FbBlocks.Number,
    icon: <NumberIcon />
  },
  {
    type: FbBlocks.Date,
    icon: <DateIcon />
  },
  {
    type: FbBlocks.Comment,
    icon: <CommentIcon />
  },
  {
    type: FbBlocks.FileUpload,
    icon: <FileUploadIcon />
  },
  {
    type: FbBlocks.RichText,
    icon: <RichTextIcon />
  }
];

export const FB_INFO_DND = {
  title: 'Form Builder',
  description:
    'Drag and drop a block to add a field to your form. Each block represents a field that you can customize.'
};

const FormBuilderPlexer: Plexer<FbBlocks> = {
  [FbBlocks.CheckBox]: Blocks.CheckBoxField,
  [FbBlocks.Date]: Blocks.DateField,
  [FbBlocks.DropDown]: Blocks.DropDown,
  [FbBlocks.FileUpload]: Blocks.FileUpload,
  [FbBlocks.Number]: Blocks.Number,
  [FbBlocks.RichText]: Blocks.RichText,
  [FbBlocks.TextField]: Blocks.TextField,
  [FbBlocks.Comment]: Blocks.Comment
};

export const LibraryFB: FC<LibraryProps> = ({ block }) => {
  const Block = FormBuilderPlexer[block.type as FbBlocks];
  return <Block {...block} />;
};
