import { computed, makeObservable, observable, reaction, toJS } from 'mobx';
import AtomStore from '../store/atom.store';
import { toast } from 'sonner';
import { DataType, MetaInfo, VariableInfo } from '../types/atom.types';
import { parseWithZod } from '@/utils/parseZodSchema';
import { DndKitValuesSchema } from '@/utils/zod';
import { newError } from '@/services/errors/errors';
import { ENV } from '@/utils/constants';
import { AllBlocks } from '@components/dnd/library';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';
import { DropDownData } from '@components/dnd/library/formBuilder/blocks/dropDown/dropDown.data';
import { CheckBoxData } from '@components/dnd/library/formBuilder/blocks/checkbox/checkbox.data';
import { RepositoryData } from '@components/dnd/library/dataRepository/block/repository.data';
import { DTRBlocks } from '@components/dnd/library/dataRepository';
import { StaticType } from './code/types';
import { arrayToUnionType } from '@/utils/typescript/arrayToUnionType';
import { BaseActionModel } from './action.model';
import { DNDModel } from './dnd.model';
import { BaseModelWithTraceKey } from './base/baseWithKey.model';
import { z } from 'zod';
import { TraceKeyDTO } from './traceKey/schema';
import { ModelError } from './base/base.model';

export class AtomModel<TData = unknown> extends BaseModelWithTraceKey {
  constructor(
    store: AtomStore,
    id: string,
    traceKeyDTO: TraceKeyDTO,
    public dataType: DataType,
    public data: TData,
    /** Infos related to the data such as dnd and source info */
    public metaInfo: MetaInfo,
    public referencedBy: AtomModel['id'][],
    public references: AtomModel['id'][],
    public variableInfo?: VariableInfo
  ) {
    super(store, id, traceKeyDTO);

    makeObservable(this, {
      data: observable,
      referencedBy: observable,
      references: observable,
      toJSON: computed
    });

    reaction(
      () => ({
        data: toJS(this.data),
        referencedBy: toJS(this.referencedBy),
        references: toJS(this.references),
        traceKey: toJS(this.traceKey.toJSON)
      }),
      () => {
        if (['local', 'staging'].includes(ENV)) {
          toast.info(
            `Data updated ${this.metaInfo.dnd.blockType} ${this.id.substring(
              0,
              6
            )}`
          );
        }

        this.store.update(this.id).catch((error: Error) => {
          newError(error, true);
        });
      },
      {
        delay: 1000
      }
    );

    reaction(
      () => toJS(this.data),
      () => this.store.rootStore.codeStore.lastCodeModel?.recomputePreCode(),
      { delay: 200 }
    );

    reaction(
      () => {
        if (this.isAtomWithTitle()) return this.data.title;
        return null;
      },
      () => {
        if (!this.traceKey) return;
        // @ts-expect-error at this point, we know that this is an Atom with title
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        this.traceKey.followName(this.data.title);
      }
    );
  }

  /** Retrives the possible values from a data item.
   * In the case of a dropdown or a data repository, the values are stored in the data item itself inside `values`.
   * In the case of a checkbox, the values are stored in the `normalSource` inside `values`.
   */
  get possibleValues(): string[] {
    if (!this.data || typeof this.data !== 'object') return [];
    let values: unknown = [];

    if ('values' in this.data) {
      // ? case dropdown || data repository
      values = this.data.values;
    } else if (
      // ? case checkbox with normal source
      'dataSourceData' in this.data &&
      this.data.dataSourceData &&
      typeof this.data.dataSourceData === 'object' &&
      'normalSource' in this.data.dataSourceData &&
      this.data.dataSourceData.normalSource &&
      typeof this.data.dataSourceData.normalSource === 'object' &&
      'values' in this.data.dataSourceData.normalSource
    ) {
      values = this.data.dataSourceData.normalSource.values;
    }

    const parsedValues = parseWithZod(DndKitValuesSchema, values, {
      withSentry: false
    });
    return parsedValues ? parsedValues.map((value) => value.name) : [];
  }

  /* ---------------------------- References utils ---------------------------- */

  addReferencingAtom(atomId: AtomModel['id']): boolean {
    if (this.referencedBy.includes(atomId)) return false;

    const referencingAtom = this.store.get(atomId) as Maybe<AtomModel>;
    if (!referencingAtom) {
      newError(
        `Atom not found in store: atomId = "${atomId}", referencedAtomId = "${this.id}".`
      );
      return false;
    }

    if (referencingAtom.references.includes(this.id)) {
      newError(
        `Atom "${this.id}" is already in the references of atom "${atomId}".`
      );
      return false;
    }

    this.referencedBy.push(atomId);
    referencingAtom.addReference(this.id);

    /* ------------------------------ Debug things ------------------------------ */

    this.checkReferencesSync();
    this.checkReferencedBySync();

    return true;
  }

  simpleRemoveReferencingAtom(atomId: AtomModel['id']): boolean {
    if (!this.referencedBy.includes(atomId)) return false;

    this.referencedBy = this.referencedBy.filter((id) => id !== atomId);
    return true;
  }

  removeReferencingAtom(atom: AtomModel): boolean {
    if (!this.referencedBy.includes(atom.id)) {
      newError(
        `Atom not found in referencedBy: atomId = "${
          atom.id
        }, referencedBy = "${JSON.stringify(this.referencedBy)}"`
      );
      return false;
    }

    const referencingAtom = this.store.get(atom.id) as Maybe<AtomModel>;
    if (!referencingAtom) {
      newError(
        `Atom not found in store: atomId = "${atom.id}", referencedAtomId = "${this.id}".`
      );
      return false;
    }

    if (!referencingAtom.references.includes(this.id)) {
      newError(
        `Atom "${this.id}" is not in the references of atom "${atom.id}".`
      );
      return false;
    }

    this.referencedBy = this.referencedBy.filter((id) => id !== atom.id);
    referencingAtom.removeReference(this.id);
    return true;
  }

  private addReference(atomId: AtomModel['id']): boolean {
    this.references.push(atomId);
    return true;
  }

  private removeReference(atomId: AtomModel['id']): boolean {
    this.references = this.references.filter((id) => id !== atomId);
    return true;
  }

  get referenceByAtoms(): AtomModel[] {
    return this.referencedBy
      .map((id) => this.store.get(id))
      .filter((atom): atom is AtomModel => !!atom);
  }

  get isDeletable(): boolean {
    return this.referencedBy.length === 0;
  }

  get dndModel(): Maybe<DNDModel> {
    const action = this.store.rootStore.actionStore.get(
      this.metaInfo.source.id
    );
    return action?.formDnd;
  }

  checkReferencesSync(): boolean {
    const checkResults: Record<AtomModel['references'][number], boolean> = {};

    this.references.forEach((referenceId) => {
      const referencedAtom = this.store.get(referenceId) as Maybe<AtomModel>;
      if (!referencedAtom) {
        newError(
          `Atom not found in references: atomId (this.id) = "${this.id}", referenceId = "${referenceId}".`
        );
        checkResults[referenceId] = false;
      } else if (!referencedAtom.referencedBy.includes(this.id)) {
        newError(
          `Atom "${this.id}" is referencing atom "${referenceId}", but atom "${this.id}" is not in the referencedBy of atom "${referenceId}".`
        );
        checkResults[referenceId] = false;
      } else checkResults[referenceId] = true;
    });

    if (Object.values(checkResults).includes(false)) {
      newError(
        `References check failed, failing sync: ${JSON.stringify(
          checkResults
        )}`,
        false
      );
    }

    return true;
  }

  checkReferencedBySync(): boolean {
    const checkResults: Record<AtomModel['referencedBy'][number], boolean> = {};

    this.referencedBy.forEach((referencedBy) => {
      const referencingAtom = this.store.get(referencedBy) as Maybe<AtomModel>;
      if (!referencingAtom) {
        newError(
          `Atom not found in referencedBy: atomId (this.id) = "${this.id}", referencedBy = "${referencedBy}".`
        );
        checkResults[referencedBy] = false;
      } else if (!referencingAtom.references.includes(this.id)) {
        newError(
          `Atom "${this.id}" is referenced by atom "${referencedBy}", but atom "${this.id}" is not in the references of atom "${referencedBy}".`
        );
        checkResults[referencedBy] = false;
      } else checkResults[referencedBy] = true;
    });

    if (Object.values(checkResults).includes(false)) {
      newError(
        `ReferencedBy check failed, failing sync: ${JSON.stringify(
          checkResults
        )}`,
        false
      );
    }

    return true;
  }

  /* -------------------------------------------------------------------------- */
  /*                                 Custom code                                */
  /* -------------------------------------------------------------------------- */

  public getTypeScriptType(blockType: AllBlocks): string {
    if (!isFormBlock(blockType)) return 'unknown';
    switch (blockType) {
      case FbBlocks.TextField:
      case FbBlocks.RichText:
        return 'string';
      case FbBlocks.DropDown:
      case FbBlocks.CheckBox:
        return this.getMultiSelectType(blockType);
      case FbBlocks.Number:
        return 'number';
      case FbBlocks.Date:
        return '`${number}-${number}-${number}`';
      case FbBlocks.FileUpload:
        return `${StaticType.FormFile}[]`; // type provided by the starter file
      case FbBlocks.Comment:
        return `${StaticType.ActionComment}`;
    }
  }

  private getMultiSelectType(
    blockType: FbBlocks.CheckBox | FbBlocks.DropDown
  ): string {
    const atomModel = this as AtomModel<DropDownData | CheckBoxData>;
    let fieldType: string;
    switch (atomModel.data.dataSource) {
      case 'normalSource': {
        const values = atomModel.data.dataSourceData.normalSource.values;
        fieldType = arrayToUnionType(values.map((value) => value.name));
        break;
      }
      case 'dataRepository': {
        const repository = atomModel.getRepositoryAtom();
        if (!repository) return 'never';

        const values = repository.data.values;
        fieldType = arrayToUnionType(values.map((value) => value.name));
        break;
      }
      case 'stateSource':
        fieldType = 'unknown';
        break;
      default:
        fieldType = 'never';
    }
    if (
      atomModel.isCheckBox(blockType) &&
      atomModel.data.checkboxType === 'checkbox'
    ) {
      fieldType = `(${fieldType})[]`;
    }
    return fieldType;
  }

  private getRepositoryAtom(
    this: AtomModel<CheckBoxData | DropDownData>
  ): Maybe<AtomModel<RepositoryData>> {
    const { atomStore } = this.store.rootStore;

    const repositoryAtom = atomStore.getAtomById<RepositoryData>(
      this.data.dataSourceData.dataRepository.selectedDataRepository,
      DTRBlocks.DataRepository
    );

    if (!repositoryAtom || !('data' in repositoryAtom)) return;
    return repositoryAtom;
  }

  private isCheckBox(
    this: AtomModel,
    blockType: AllBlocks
  ): this is AtomModel<CheckBoxData> {
    return blockType === FbBlocks.CheckBox;
  }

  get source(): Maybe<BaseActionModel> {
    const sourceInfo = this.metaInfo.source;
    if (sourceInfo.library === 'ui') {
      const action = this.store.rootStore.actionStore.get(sourceInfo.id);
      return action;
    }
    return;
  }

  private isAtomWithTitle(): this is AtomModel<{ title: string }> {
    return (
      !!this.data &&
      typeof this.data === 'object' &&
      'title' in this.data &&
      typeof this.data.title === 'string'
    );
  }

  get toJSON() {
    const AtomUpdateDTO = {
      dataType: this.dataType,
      data: this.data,
      metaInfo: this.metaInfo,
      variableInfo: this.variableInfo,
      referencedBy: this.referencedBy,
      references: this.references,
      traceKey: this.traceKey.toJSON
    };
    return toJS(AtomUpdateDTO);
  }

  get errors(): ModelError[] {
    return [];
  }
}

const isFormBlock = (blockType: AllBlocks): blockType is FbBlocks => {
  return Object.values(FbBlocks).includes(blockType as FbBlocks);
};

export const AtomWithTitleSchema = z.object({
  title: z.string()
});
