import { useState } from 'react';
import { observer } from 'mobx-react';
import nanoID from '@/utils/nanoID';
import { FormControl, FormLabel, Tooltip } from '@mui/joy';
import { Check, Pencil } from 'lucide-react';
import { UniqueIdentifier } from '@dnd-kit/core';

import DndKitContainer from '@atoms/testDndKit';
import {
  ClickableIconContainer,
  IconContainer,
  ItemBody,
  StyledInput
} from '@atoms/testDndKit/sortableItem/sortableItem.style';

export interface DndKitValue {
  id: UniqueIdentifier;
  name: string;
}

interface DndInterfaceProps {
  values?: DndKitValue[];
  updateValues: (newValues: DndKitValue[]) => void;
  addValue: (value: DndKitValue) => void;
  title: string;
}

const DndInterface = ({
  values,
  updateValues,
  addValue,
  title
}: DndInterfaceProps) => {
  const [newValue, setNewValue] = useState<string>('');

  const resetInput = () => {
    setNewValue('');
  };

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addNewValue();
    }
  };

  const addNewValue = () => {
    if (!newValue || newValue === '') return;

    const valueToAdd: DndKitValue = {
      id: nanoID(8),
      name: newValue
    };

    addValue(valueToAdd);
    resetInput();
  };

  return (
    <FormControl size="sm">
      <FormLabel>{title}</FormLabel>
      {!!values?.length && (
        <DndKitContainer dndValues={values} updateValues={updateValues} />
      )}
      <ItemBody>
        <IconContainer>
          <Pencil size={13} color="#A4A4A4" />
        </IconContainer>
        <StyledInput
          type="text"
          onKeyDown={onEnterDown}
          onChange={(e) => setNewValue(e.target.value)}
          value={newValue}
          placeholder="Aa"
          data-tag="input-dnd"
        />
        <Tooltip
          enterDelay={700}
          title="Or press Enter"
          placement="bottom"
          variant="plain"
        >
          <ClickableIconContainer onClick={addNewValue}>
            <Check size={15} color="#A4A4A4" />
          </ClickableIconContainer>
        </Tooltip>
      </ItemBody>
    </FormControl>
  );
};

export default observer(DndInterface);
