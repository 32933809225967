import { SelectField } from '@atoms/select';
import {
  Dropdown,
  IconButton,
  ListDivider,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
  Option,
  Typography
} from '@mui/joy';
import { ChevronDown, X } from 'lucide-react';
import { SelectEvent } from '.';
import { observer } from 'mobx-react';
import VariableField from '@atoms/variableField';
import { InputField } from '@atoms/input';
import { useDataRepositoryVariables } from '@hooks/useDataRepoVariables';
import { DestinationField, HeaderContent } from './style';
import { AtomModel } from '@/mobx/models/atom.model';
import { DataSource, EmailData } from './email.data';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import { metaOptions, stateOptions } from './email.utils';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';

interface EmailHeaderProps {
  dataItem: AtomModel<EmailData>;
  blockAtomId: string;
}

const EmailHeader = ({ dataItem, blockAtomId }: EmailHeaderProps) => {
  const dataRepo = useDataRepositoryVariables();
  const dataRepoOptions = Array.from(dataRepo.keys());
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;

  const { atomStore } = useStores();

  const handleDestinationChange = (value: string) => {
    if (!dataItem.data.dataSource || !dataItem.data.dataSourceData) return; //? or fill datasource?
    dataItem.data.dataSource.selectedDestination = value;
    dataItem.data.dataSourceData.to = '';
  };

  const onRepositoryChange = (_: SelectEvent, value: unknown): void => {
    if (!dataItem.data.dataSourceData || typeof value != 'string') return; //? or fill dataSourceData?

    const to = dataRepo.get(value);
    if (!to) return;

    dataItem.data.dataSourceData.to = to;

    const selectedVariable = atomStore.getAtomById_Unsafe(to.dataItemId);

    if (!selectedVariable) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    selectedVariable.addReferencingAtom(blockAtomId);
  };

  if (
    dataItem?.data?.dataSourceData?.to &&
    typeof dataItem?.data?.dataSourceData?.to === 'object'
  ) {
    const tmpSelectedVariable = atomStore.getAtomById(
      dataItem.data.dataSourceData.to.dataItemId,
      dataItem.data.dataSourceData.to.blockType
    );

    if (selectedVariable instanceof Error) {
      newError("Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const onStateVariableSelected = (
    seletedVariableReference: DataItemReference
  ) => {
    if (!seletedVariableReference) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    if (!dataItem.data.dataSourceData) return;
    dataItem.data.dataSourceData.to = seletedVariableReference;

    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    selectedVariable.addReferencingAtom(blockAtomId);
  };

  const renderDestinationData = (
    type: DataSource['selectedDestination']
  ): JSX.Element => {
    switch (type) {
      case 'state':
        return (
          <VariableField
            onSelected={onStateVariableSelected}
            value={selectedVariable?.data?.title}
          />
        );
      case 'mail':
        return (
          <InputField
            value={
              typeof dataItem.data.dataSourceData?.to == 'string'
                ? dataItem.data.dataSourceData?.to
                : ''
            }
            onChange={(event) => {
              if (!dataItem.data.dataSourceData) return; //? or fill dataSourceData?
              dataItem.data.dataSourceData.to = event.target.value;
            }}
            placeholder="mail@studio.com"
            type="email"
          />
        );
      case 'repository':
        return (
          <SelectField
            value={selectedVariable?.data?.title}
            onChange={onRepositoryChange}
            placeholder="Choose a value..."
          >
            {dataRepoOptions.map((name, index) => (
              <Option key={index} value={name} label={name}>
                {name}
              </Option>
            ))}
          </SelectField>
        );

      default:
        return <></>;
    }
  };

  return (
    <HeaderContent>
      <Typography sx={{ fontWeight: 'normal' }} level="title-sm">
        {dataItem.metaInfo.dnd.blockType}
      </Typography>
      <Typography sx={{ fontWeight: 'normal' }} level="title-sm">
        to
      </Typography>
      <Dropdown>
        {!dataItem.data.dataSource?.selectedDestination ? (
          <MenuButton size="sm" endDecorator={<ChevronDown size={14} />}>
            Destination
          </MenuButton>
        ) : (
          <DestinationField>
            {renderDestinationData(
              dataItem.data.dataSource.selectedDestination
            )}
            <IconButton
              size="sm"
              onClick={() => {
                if (!dataItem.data.dataSource) return; //? or fill dataSource?
                dataItem.data.dataSource.selectedDestination = '';
              }}
            >
              <X size={20} />
            </IconButton>
          </DestinationField>
        )}
        <Menu size="sm" sx={{ minWidth: 160 }}>
          {metaOptions.map((metaOption, index) => (
            <MenuItem
              key={index}
              onClick={() => handleDestinationChange(metaOption.value)}
              disabled={metaOption.disabled}
            >
              <ListItemDecorator>{metaOption.icon}</ListItemDecorator>
              {metaOption.label}
            </MenuItem>
          ))}
          <ListDivider />
          {stateOptions.map((stateOption, index) => (
            <MenuItem
              key={index}
              onClick={() => handleDestinationChange(stateOption.value)}
              disabled={stateOption.disabled}
            >
              <ListItemDecorator>{stateOption.icon}</ListItemDecorator>
              {stateOption.label}
            </MenuItem>
          ))}
        </Menu>
      </Dropdown>
    </HeaderContent>
  );
};

export default observer(EmailHeader);
