import { DNDBlock } from '@components/dnd/base';
import useStores from './useStore';
import { VariableValueOrigin } from '@/types/variables';

import { AtomModel } from '@/mobx/models/atom.model';
import { newError } from '@/services/errors/errors';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { BaseActionModel } from '@/mobx/models/action.model';

export type VariableValues = {
  id: DNDBlock['id'];
  type: DNDBlock['type'];
  name: string;
  computedType: ResolvedVariableType;
  origin: VariableValueOrigin;
  atomId: AtomModel['id'];
  actionId: BaseActionModel['id'];
  path: string;
  possibleValues?: string[];
  possiblePath?: string;
};

export const useVariableData = (): Map<
  string,
  AtomModel<{ title: string }>[]
> => {
  const { atomStore, actionStore } = useStores();
  const allVariables = atomStore.getAllVariables();

  const variableByActionMap = new Map<string, AtomModel<{ title: string }>[]>();

  allVariables.forEach((variable) => {
    const actionId = variable.metaInfo.source.id;

    const action = actionStore.get(actionId)?.name;
    if (!action) {
      newError(`Action with id "${actionId}" was not found`);
      return;
    }

    const actionVariables = variableByActionMap.get(actionId) ?? [];
    actionVariables.push(variable);
    variableByActionMap.set(actionId, actionVariables);
  });

  variableByActionMap.set('globalVariables', atomStore.getAllGlobalVariables());
  return variableByActionMap;
};
