import SettingHelper from '../SettingHelper';
import { AppWindow } from 'lucide-react';
import { SettingBody } from '../style';

import DNDBase from '@/components/dnd/base';
import useStores from '@/hooks/useStore';
import ActionButton from '@atoms/button';
import { observer } from 'mobx-react';
import { Variable } from '@/mobx/store/variables.store';
import { SettingsContent } from '../settings.style';
import SettingHeader from '../SettingHeader';
import useProcess from '@hooks/useProcess';
import { TIBlocks } from '@components/dnd/library/traceInfo';

const TraceInfo = () => {
  const { dndStore } = useStores();

  const { process } = useProcess();

  if (!process) return <></>;

  const traceInfoDnd = dndStore.get(process.workflows[0]?.traceInfoDndId);
  if (!traceInfoDnd) return <></>;

  const onAddWindowClick = () => {
    const window = traceInfoDnd.createDndBlock(TIBlocks.Window, 'unrelevant');

    const dropZonePath = 'root:root|0';
    const stateWithNewWindow = traceInfoDnd.insertBlockInState(
      dropZonePath,
      traceInfoDnd.state,
      window
    );

    traceInfoDnd.setState(stateWithNewWindow);
  };

  const isDropInRoot = (path: string, item: Variable) => {
    return (
      (path.includes('root:root|') && item.type === 'Window') ||
      !path.includes('root:root|')
    );
  };

  if (!process || !traceInfoDnd) return <></>;

  return (
    <SettingsContent>
      <SettingHeader
        title="Trace Info"
        description="Customize the panels available in each trace inspector"
      />

      <SettingHelper
        title="Trace info"
        icon={<AppWindow />}
        description="The Trace info refers to the small boxes you see at the bottom right corner of the trace inspector. You can customize them by creating new windows (boxes) and adding data to them (variables from your workflow). This data will be accessible throughout the Trace."
      />

      {process.loading && <></>}
      {!process.loading && (
        <SettingBody>
          <div className="title-body">
            <h3>Trace Infos settings</h3>
            <ActionButton value="Add a window" onClick={onAddWindowClick} />
          </div>

          <DNDBase dnd={traceInfoDnd} restriction={isDropInRoot} />
        </SettingBody>
      )}
    </SettingsContent>
  );
};

export default observer(TraceInfo);
