import EmptyProcessImage from '@/assets/svg/emptyProcess.svg?react';
import {
  EmptyButtonRoot,
  EmptyProcessRoot,
  EmptyProcessText
} from './emptyProcess.style';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const RotateAnimation = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const RainbowButton = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
  padding: 4px 12px;
  font-size: 14px;
  font-family: 'Inter', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  box-shadow: ${({ theme }) => theme.boxShadow.light};
  &:before {
    content: '';
    position: absolute;
    left: -10rem;
    right: -10rem;
    top: -10rem;
    bottom: -10rem;
    margin: auto;
    background: conic-gradient(
      #fd004c,
      #fe9000,
      #fff020,
      #3edf4b,
      #3363ff,
      #b102b7
    );
    z-index: -2;
    pointer-events: none;
    animation: ${RotateAnimation} 2s infinite linear;
  }

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 1px;
    background: white;
    border-radius: 3px;
    z-index: -1;
    pointer-events: none;
  }
`;

const EmptyProcess = () => {
  const navigate = useNavigate();

  return (
    <EmptyProcessRoot>
      <EmptyProcessImage opacity={0.5} />
      <EmptyProcessText>It's look empty here !</EmptyProcessText>
      <EmptyButtonRoot>
        <RainbowButton
          onClick={() => {
            navigate('/new');
          }}
          style={{
            paddingTop: 10,
            paddingRight: 20,
            paddingLeft: 20,
            paddingBottom: 10,
            borderRadius: 5
          }}
        >
          Create your first project
        </RainbowButton>
      </EmptyButtonRoot>
    </EmptyProcessRoot>
  );
};

export default EmptyProcess;
