import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { CalendarDays, LucideIcon } from 'lucide-react';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy';

import BlockBase from '@/components/dnd/base/blockBase';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { DNDBlock } from '@components/dnd/base';
import VariableField from '@atoms/variableField';
import useStores from '@hooks/useStore';
import { useAtom } from '@hooks/useAtom';
import { TO_DATE_VIEW_TYPES, initialTODateData } from './date.data';

export const DateIcon: LucideIcon = CalendarDays;

const Date = (dndBlock: DNDBlock) => {
  const { atomStore: dataItemStore } = useStores();

  const dataItem = useAtom({
    block: dndBlock,
    dataType: 'traceOverview',
    initialData: {
      ...initialTODateData,
      selectedVariable: dndBlock.other.selectedVariableRef
    },
    library: 'traceOverview',
    sourceId: 'null',
    sourceName: 'Trace Overview'
  });

  if (!dataItem) return null;

  const selectedVariable = dataItem.data.selectedVariable;
  if (!selectedVariable) return null;

  const variable = dataItemStore.getAtomById<{ title: string }>(
    selectedVariable.dataItemId,
    selectedVariable.blockType
  );

  if (!variable || variable instanceof Error) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dataItem.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={dndBlock}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={DateIcon}
      endControls={
        <EndControlsFB
          hasRequired={false}
          dndBlock={dndBlock}
          dataItem={dataItem}
        />
      }
    >
      <Column>
        <InputField
          label="Widget title"
          value={dataItem.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableField value={variable.data.title} disabled />
        </FormControl>
      </Column>
      <Column>
        <FormControl size="sm">
          <FormLabel>Display type</FormLabel>
          <RadioGroup value={dataItem.data.viewType} name="radio-buttons-group">
            {TO_DATE_VIEW_TYPES.map((viewType) => (
              <Radio
                key={viewType}
                value={viewType}
                onChange={(e) =>
                  (dataItem.data.viewType = e.target.value as typeof viewType)
                }
                label={viewType}
                color="neutral"
                size="sm"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
    </BlockBase>
  );
};

export default observer(Date);
