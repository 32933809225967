import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { NumberFieldData } from './number.data';

type Props = {
  atom: AtomModel<NumberFieldData>;
};

export const NumberFieldModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
