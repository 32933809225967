import { useEffect, useRef } from "react";
import { EmojiLabel, EmojiLottie, EmojiWrapper } from "./feedback.style";
import selectAnimationData from "./utils";
import { LottieRefCurrentProps } from "lottie-react";

interface EmojiProps {
  reaction: string;
  isSelected: boolean;
  setActiveReaction: React.Dispatch<React.SetStateAction<string>>;
}

const Emoji = ({ reaction, isSelected, setActiveReaction }: EmojiProps) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (!isSelected) {
      lottieRef.current?.stop();
    }
  }, [isSelected]);

  const handleClick = () => {
    if (isSelected) {
      setActiveReaction("");
    } else {
      setActiveReaction(reaction);
    }
  };

  return (
    <EmojiWrapper
      $isSelected={isSelected}
      onClick={handleClick}
      data-tag='emoji'
    >
      <EmojiLottie
        lottieRef={lottieRef}
        animationData={selectAnimationData(reaction)}
        autoplay={false}
        onMouseEnter={() => lottieRef.current?.play()}
        onMouseLeave={() => !isSelected && lottieRef.current?.stop()}
        loop
      />
      {isSelected && <EmojiLabel>{reaction}</EmojiLabel>}
    </EmojiWrapper>
  );
};

export default Emoji;