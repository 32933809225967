import { MouseEventHandler } from 'react';
import icon from './oops.png';
import { Button, ButtonDiv, Root } from './oops.style';

interface OopsProps {
  onHomeClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  description: string;
}

const Oops = ({ onHomeClick, label, description }: OopsProps) => (
  <Root>
    <img
      alt=""
      src={icon}
      style={{ margin: 'auto', width: 350, marginBottom: 12 }}
    />
    <p>{description}</p>
    <ButtonDiv>
      <Button onClick={onHomeClick}>{label}</Button>
    </ButtonDiv>
  </Root>
);

export default Oops;
