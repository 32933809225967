import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  ListItemDecorator,
  Option,
  Radio,
  RadioGroup,
  Select,
  SelectOption
} from '@mui/joy';
import { Hash, LucideIcon } from 'lucide-react';
import BlockBase from '@/components/dnd/base/blockBase';

import { LabeledVariable } from '../blocks.style';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { DNDBlock } from '@components/dnd/base';
import VariableField from '@atoms/variableField';
import { useAtom } from '@hooks/useAtom';
import useStores from '@hooks/useStore';
import {
  TO_NUMBER_VIEW_TYPES,
  TO_NumberIconSchema,
  initialTONumberData
} from './number.data';
import { numberIconOptions } from './number.utils';
import { newError } from '@/services/errors/errors';
import { isValidType } from '@/utils/parseZodSchema';

export const NumberIcon: LucideIcon = Hash;

const renderSelectedIconOption = (option: SelectOption<string> | null) => {
  if (!option) return null;

  if (!isValidType(TO_NumberIconSchema, option.value)) {
    throw newError(`Invalid icon option "${option.value}"`);
  }

  const icon = numberIconOptions.find(
    (iconOption) => iconOption.value === option.value
  )?.icon;

  return (
    <>
      <ListItemDecorator>{icon}</ListItemDecorator>
      {option.label}
    </>
  );
};

const Number = (dndBlock: DNDBlock) => {
  const { atomStore: dataItemStore } = useStores();

  const dataItem = useAtom({
    block: dndBlock,
    dataType: 'traceOverview',
    initialData: {
      ...initialTONumberData,
      selectedVariable: dndBlock.other.selectedVariableRef
    },
    library: 'traceOverview',
    sourceId: 'null',
    sourceName: 'Trace Overview'
  });

  if (!dataItem) return null;

  const selectedVariable = dataItem.data.selectedVariable;
  if (!selectedVariable) return null;

  const variable = dataItemStore.getAtomById<{ title: string }>(
    selectedVariable.dataItemId,
    selectedVariable.blockType
  );

  if (!variable || variable instanceof Error) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dataItem.data.title = event.target.value;
  };

  return (
    <BlockBase
      dndBlock={dndBlock}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={NumberIcon}
      endControls={
        <EndControlsFB
          hasRequired={false}
          dndBlock={dndBlock}
          dataItem={dataItem}
        />
      }
    >
      <Column>
        <InputField
          label="Widget title"
          value={dataItem.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="200px">
        <FormControl size="sm">
          <FormLabel> State variable</FormLabel>
          <VariableField value={variable.data.title} disabled />
        </FormControl>
      </Column>
      <Column>
        <FormControl size="sm">
          <FormLabel>Display type</FormLabel>
          <RadioGroup value={dataItem.data.viewType} name="radio-buttons-group">
            {TO_NUMBER_VIEW_TYPES.map((viewType) => (
              <Radio
                key={viewType}
                value={viewType}
                onChange={(e) =>
                  (dataItem.data.viewType = e.target.value as typeof viewType)
                }
                label={viewType}
                color="neutral"
                size="sm"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
      <Column>
        <LabeledVariable>
          <FormLabel>Icon</FormLabel>
          <Checkbox
            label="Add an icon"
            variant="outlined"
            color="neutral"
            size="sm"
            checked={dataItem.data.hasIcon}
            onChange={(e) => (dataItem.data.hasIcon = e.target.checked)}
          />
          <Select
            placeholder="Select an icon…"
            value={dataItem.data.icon}
            renderValue={renderSelectedIconOption}
            disabled={!dataItem.data.hasIcon}
            size="sm"
            sx={{
              '--ListItemDecorator-size': '20px',
              marginTop: '10px',
              width: '100%'
            }}
            onChange={(_, newValue) => {
              if (newValue) dataItem.data.icon = newValue;
            }}
          >
            {numberIconOptions.map((iconOption) => (
              <Option key={iconOption.value} value={iconOption.value}>
                <ListItemDecorator>{iconOption.icon}</ListItemDecorator>
                {iconOption.label}
              </Option>
            ))}
          </Select>
        </LabeledVariable>
      </Column>
    </BlockBase>
  );
};

export default observer(Number);
