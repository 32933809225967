import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';
import { NumberFieldModal } from './numberField.modal';
import { SwitchField } from '@atoms/switch';

import { useAtom } from '@hooks/useAtom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { initialNumberData } from './number.data';
import { NumberIcon } from './number.utils';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

const NumberField = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialNumberData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Number
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.Number} title`,
      content: <NumberFieldModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  const onIntegerChange = (e: ChangeEvent<HTMLInputElement>): void => {
    dataItem.data.type = e.target.checked ? 'integer' : 'number';
  };

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={NumberIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <InputField
          label="Placeholder"
          placeholder="Aa"
          value={dataItem.data.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.placeholder = e.target.value;
          }}
        />
      </Column>
      <Column $gap="0">
        <SwitchField
          title="Additional features"
          label="Integer"
          onChange={(e) => onIntegerChange(e)}
          checked={dataItem.data.type === 'integer'}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(NumberField);
