import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { TextFieldData } from './textField.data';

type Props = {
  atom: AtomModel<TextFieldData>;
};

export const TextFieldModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
