import { TransitionModel } from '@/mobx/models/transition.model';
import BaseStore from './base/base.store';
import RootStore from './root.store';
import { DndLibrary } from '@components/dnd/library';
import { z } from 'zod';
import { LoadedDndModelSchema } from './dnd.store';
import { parseWithZod } from '@/utils/parseZodSchema';
import { newError } from '@/services/errors/errors';

const CreateTransitionDTOSchema = z.object({
  fromActionId: z.string(),
  toActionId: z.string(),
  processId: z.string(),
  transitionId: z.string()
});

export type CreateTransitionDTO = z.infer<typeof CreateTransitionDTOSchema>;

const TransitionLoadedSchema = z.object({
  id: z.string(),
  condition: LoadedDndModelSchema,
  notification: LoadedDndModelSchema,
  priorities: LoadedDndModelSchema,
  from_actionId: z.string(),
  to_actionId: z.string(),
  conditionDnd: z.string().optional(),
  notificationDnd: z.string().optional(),
  prioritiesDnd: z.string().optional()
});

export type TransitionLoaded = z.infer<typeof TransitionLoadedSchema>;

export class TransitionStore extends BaseStore<TransitionModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, TransitionModel, 'transition');
  }

  public async createNewTransition(
    transitionId: string,
    actionFromId: string,
    actionToId: string
  ): Promise<Maybe<TransitionModel>> {
    const processId: Maybe<string> =
      this.rootStore.processStore.currentProcessId;

    if (!processId) {
      newError('No current process found in the store');
      return;
    }

    const dto: CreateTransitionDTO = {
      fromActionId: actionFromId,
      toActionId: actionToId,
      processId,
      transitionId
    };

    const rawResponse = await this.httpWrapper.post('/', dto);
    if (!rawResponse) {
      newError(
        `Error while creating a new action with dto:
      ${JSON.stringify(dto)}`,
        true
      );
      // TODO delete the created action in the BPMN schema
      return;
    }

    const parsedResponse = parseWithZod(TransitionLoadedSchema, rawResponse);

    if (!parsedResponse) return;

    const transitionLoaded = parsedResponse;

    const dndStore = this.rootStore.dndStore;

    const newConditionDnd = dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      transitionLoaded.condition
    );

    const newNotificationDnd = dndStore.createDndFromLoaded(
      DndLibrary.Notification,
      transitionLoaded.notification
    );

    const newPrioritiesDnd = dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      transitionLoaded.priorities
    );

    const newTransition = new TransitionModel(
      this,
      transitionLoaded.id,
      newConditionDnd.id,
      newNotificationDnd.id,
      newPrioritiesDnd.id,
      actionFromId,
      actionToId,
      false
    );

    this.set(newTransition.id, newTransition);
    return newTransition;
  }

  public addLoadedTransitionToStore(
    rawLoadedTransition: TransitionLoaded
  ): Maybe<TransitionModel> {
    const loadedTransition = parseWithZod(
      TransitionLoadedSchema,
      rawLoadedTransition
    );

    if (!loadedTransition) return;

    const IS_LOADED = true;

    const newConditionDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      loadedTransition.condition
    );

    const newNotificationDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Notification,
      loadedTransition.notification
    );

    const newPrioritiesDnd = this.rootStore.dndStore.createDndFromLoaded(
      DndLibrary.Condition,
      loadedTransition.priorities
    );

    const newTransition = new TransitionModel(
      this,
      loadedTransition.id,
      newConditionDnd.id,
      newNotificationDnd.id,
      newPrioritiesDnd.id,
      loadedTransition.from_actionId,
      loadedTransition.to_actionId,
      IS_LOADED
    );
    this.set(newTransition.id, newTransition);
    return newTransition;
  }

  public loadTransitions(
    loadedTransitions: TransitionLoaded[]
  ): Maybe<TransitionModel>[] {
    return loadedTransitions.map((loadedTransition) =>
      this.addLoadedTransitionToStore(loadedTransition)
    );
  }

  public async deleteById(transitionId: string): Promise<boolean> {
    const transitionToDelete: Maybe<TransitionModel> = this.get(transitionId);

    await this.rootStore.atomStore.deleteAtomsBySourceId(transitionId);

    if (!transitionToDelete) {
      newError(`No transition found in the store with the id: ${transitionId}`);
      return false;
    }

    return await transitionToDelete.delete();
  }
}
