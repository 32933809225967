import awefulAnimationData from "./lotties/aweful.json";
import badAnimationData from "./lotties/bad.json";
import okAnimationData from "./lotties/ok.json";
import goodAnimationData from "./lotties/good.json";
import amazingAnimationData from "./lotties/amazing.json";

export const reactions = ["Aweful", "Bad", "Ok", "Good", "Amazing"];

export const NORMAL_SCALE = 1;
export const HOVERED_SCALE = 1.3;
export const SELECTED_SCALE = 1.5;

const selectAnimationData = reaction => {
  switch (reaction) {
    case reactions[0]:
      return awefulAnimationData;
    case reactions[1]:
      return badAnimationData;
    case reactions[2]:
      return okAnimationData;
    case reactions[3]:
      return goodAnimationData;
    case reactions[4]:
      return amazingAnimationData;
    default:
      return null;
  }
};

export default selectAnimationData;