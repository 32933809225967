import styled from 'styled-components';

const CONTENT_PADDING = 0.3;
const ITEM_INNER_PADDING = 0.5;

export const ReferenceHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${CONTENT_PADDING + ITEM_INNER_PADDING}rem;
  padding-top: 1rem;
  height: 95px;
  border-bottom: ${(p) => p.theme.verRail.glass.border};
  color: rgb(139 0 0 / 70%);
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #8f959c4c transparent;
`;

export const ReferenceContainerTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 600;
`;

export const DescriptionContainer = styled.i`
  font-size: 0.8rem;
`;

export const ReferenceItemContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  padding: ${CONTENT_PADDING}rem;
  flex-direction: column;
  user-select: none;

  &:not(:last-child) {
    border-bottom: ${(p) => p.theme.verRail.glass.border};
  }
`;

export const DataType = styled.span`
  color: #0643d0;
  background-color: ${(p) => p.theme.color.border.light};
  color: ${(p) => p.theme.color.grey.dark};
  user-select: none;
  border-radius: 3px;
  padding: 2px 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CurrentAtomTitle = styled.h2`
  padding: 0rem ${CONTENT_PADDING + ITEM_INNER_PADDING}rem;
  font-size: 13px;
  font-weight: 600;
  margin-top: ${ITEM_INNER_PADDING}rem;
`;

export const ReferenceTitle = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ReferenceItem = styled.div`
  font-size: 13px;
  width: 100%;
  height: 38px;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  padding: 0rem ${ITEM_INNER_PADDING}rem;
  border-radius: 4px;

  &:hover {
    background-color: #efeff0;
  }

  &:hover ${DataType} {
    background-color: #4b35d2;
    color: #fff;
  }
`;
