import SettingHeader from '../SettingHeader';
import SettingHelper from '../SettingHelper';
import { Bell } from 'lucide-react';
import { ListBase, SettingBody } from '../style';
import { SettingsContent } from '../settings.style';
import ActionButton from '@atoms/button';
import useStores from '@hooks/useStore';
import NotificationItem from './notificationItem';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import useProcess from '@hooks/useProcess';

const SettingNotifications = () => {
  const navigate = useNavigate();
  const { notificationTemplateStore } = useStores();
  const { process } = useProcess();
  const notificationTemplates = notificationTemplateStore.toArray();

  if (!process) return <></>;

  return (
    <SettingsContent>
      <SettingHeader
        title="Notifications"
        description="Create and customize Trace email template"
      />
      <SettingHelper title="Notifications" icon={<Bell />} />
      <SettingBody>
        <div className="title-body">
          <h3>Template(s)</h3>
          <ActionButton
            value="Add a template"
            onClick={() => navigate('new')}
          />
        </div>
        <ListBase>
          {process.loading && <></>}
          {!process.loading &&
            notificationTemplates?.map((notif) => (
              <NotificationItem
                key={notif.id}
                id={notif.id}
                title={notif.title}
                updateAt={notif.updatedAt}
              />
            ))}
        </ListBase>
      </SettingBody>
    </SettingsContent>
  );
};

export default observer(SettingNotifications);
