import PopupMenuModule from "diagram-js/lib/features/popup-menu";
import CreateModule from "diagram-js/lib/features/create";

import CreateMenuProvider from "./create/CreateMenuProvider";
import AppendMenuProvider from "./append/AppendMenuProvider";
import ReplaceMenuProvider from "./replace/ReplaceMenuProvider";

export default {
	__depends__: [PopupMenuModule, CreateModule],
	__init__: ["createMenuProvider", "appendMenuProvider", "replaceMenuProvider"],
	createMenuProvider: ["type", CreateMenuProvider],
	appendMenuProvider: ["type", AppendMenuProvider],
	replaceMenuProvider: ["type", ReplaceMenuProvider],
};
