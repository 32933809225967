export default {
  Form: {
    d: `M10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12
        H10.6667C11.4 12 12 11.4 12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0
        ZM2.66667 2.66667H4V4H2.66667V2.66667ZM2.66667 5.33333H4V6.66667H2.66667V5.33333ZM2.66667 8
        H4V9.33333H2.66667V8ZM9.33333 9.33333H5.33333V8H9.33333V9.33333ZM9.33333 6.66667
        H5.33333V5.33333H9.33333V6.66667ZM9.33333 4H5.33333V2.66667H9.33333V4Z`,
    height: 12,
    width: 12,
  },
  TableEditor: {
    d: `M1.33333 0H10.6667C11.0203 0 11.3594 0.144866 11.6095 0.402728C11.8595 0.660591 12 1.01033 12 1.375
        V9.625C12 9.98967 11.8595 10.3394 11.6095 10.5973C11.3594 10.8551 11.0203 11 10.6667 11
        H1.33333C0.979711 11 0.640573 10.8551 0.390524 10.5973C0.140476 10.3394 0 9.98967 0 9.625
        V1.375C0 1.01033 0.140476 0.660591 0.390524 0.402728C0.640573 0.144866 0.979711 0 1.33333 0ZM1.33333 2.75V5.5H5.33333V2.75
        H1.33333ZM6.66667 2.75V5.5H10.6667V2.75H6.66667ZM1.33333 6.875V9.625H5.33333V6.875H1.33333ZM6.66667 6.875V9.625
        H10.6667V6.875H6.66667Z`,
    height: 12,
    width: 12,
  },
  TableImporter: {
    d: `M1.30391 0H10.4313C11.155 0 11.7352 0.632432 11.7352 1.40541V6.38054C10.7247 6.19784 9.72718 6.45081 8.91876 7.02703
        H6.51956V9.83784H7.22367C7.15196 10.3157 7.15196 10.7865 7.22367 11.2432
        H1.30391C0.58676 11.2432 0 10.6178 0 9.83784V1.40541C0 0.632432 0.58676 0 1.30391 0ZM1.30391 2.81081V5.62162
        H5.21565V2.81081H1.30391ZM6.51956 2.81081V5.62162H10.4313V2.81081H6.51956ZM1.30391 7.02703V9.83784H5.21565V7.02703
        H1.30391ZM13 10.1892H11.6961V13H10.3922V10.1892H9.08826L11.0441 8.08108L13 10.1892Z`,
    height: 12,
    width: 12,
  },
}