import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import { FileDown, FileCode, FileImage, Component } from 'lucide-react';
import Modeler from 'bpmn-js/lib/Modeler';
import Viewer from 'bpmn-js/lib/Viewer';
import { ExportContainer } from './export.style';

export enum ExportType {
  Svg = 'svg',
  Png = 'png',
  Bpmn = 'bpmn'
}

interface ExportButtonProps {
  modeler: Modeler | Viewer;
  processName: string;
}
const ExportButton = ({ modeler, processName }: ExportButtonProps) => {
  const exportTo = async (mode: ExportType) => {
    if (!modeler) {
      return;
    }
    let responseFromExport: string = '';

    if (mode === ExportType.Bpmn) {
      const { xml } = await modeler.saveXML({ format: true });
      if (xml) {
        responseFromExport = xml;
      }
    } else if (mode === ExportType.Svg) {
      const { svg } = await modeler.saveSVG();
      if (svg) responseFromExport = svg;
    } else {
      await exportToPng();
    }

    if (responseFromExport !== '') {
      const filename = `${processName.split(' ').join('_')}.${mode}`;
      const link = document.createElement('a');
      const blob = new Blob([responseFromExport], { type: 'text/plain' });
      link.setAttribute('href', window.URL.createObjectURL(blob));
      link.setAttribute('download', filename);
      link.dataset.downloadurl = ['text/plain', link.download, link.href].join(
        ':'
      );

      link.click();
    }
  };

  const exportToPng = async () => {
    try {
      const domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error('(browser doesnt support this)');
      }
      const { svg } = await modeler.saveSVG();
      const match = svg.match(/height="(\d+)/m);
      const matchWidth = svg.match(/width="(\d+)/m);
      const height = match && match[1] ? parseInt(match[1], 10) : 200;
      const width =
        matchWidth && matchWidth[1] ? parseInt(matchWidth[1], 10) : 200;
      const margin = 0;
      // // create a canvas element to pass through
      const canvas = document.createElement('canvas');
      canvas.width = width + margin * 2;
      canvas.height = height + margin * 2;
      const ctx = canvas.getContext('2d');

      // // make a blob from the svg
      const svgBlob = new Blob([svg], {
        type: 'image/svg+xml;charset=utf-8'
      });

      const url = domUrl.createObjectURL(svgBlob);
      const img = new Image();
      img.onload = function () {
        ctx?.drawImage(this as CanvasImageSource, margin, margin);
        domUrl.revokeObjectURL(url);
        const fileName = `${processName.split(' ').join('_')}.png`;
        const downloadLink = document.createElement('a');
        downloadLink.download = fileName;
        downloadLink.innerHTML = 'Get BPMN PNG';
        downloadLink.href = canvas.toDataURL();
        downloadLink.style.visibility = 'hidden';
        document.body.appendChild(downloadLink);
        downloadLink.click();
      };
      img.src = url;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ExportContainer>
      <Dropdown>
        <MenuButton
          variant="plain"
          sx={{
            margin: 0,
            padding: 0,
            width: '100%',
            height: '100%',
            '&:hover': {
              background: 'none'
            }
          }}
        >
          <FileDown />
        </MenuButton>
        <Menu placement="top-start">
          <MenuItem onClick={() => void exportTo(ExportType.Bpmn)}>
            <FileCode size={18} />
            Download as BPMN file
          </MenuItem>
          <MenuItem onClick={() => void exportTo(ExportType.Svg)}>
            <Component size={18} />
            Download as SVG file
          </MenuItem>
          <MenuItem onClick={() => void exportTo(ExportType.Png)}>
            <FileImage size={18} />
            Download as PNG file
          </MenuItem>
        </Menu>
      </Dropdown>
    </ExportContainer>
  );
};

export default ExportButton;
