import { useEffect } from 'react';
import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { Ampersands, LucideIcon } from 'lucide-react';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { FormLabel } from '@mui/joy';
import { InteractionZone } from '@components/dnd/base/interaction';
import { observer } from 'mobx-react';
import { useAtom } from '@hooks/useAtom';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

export const AndIcon: LucideIcon = Ampersands;

const And = (dndBlock: DNDBlock) => {
  const dndPath = `${dndBlock.path}:and`;
  const andBlock: DNDBlock[] = dndBlock.props?.and || [];
  const transitionId = useParams()[ParamsList.TransitionId];

  const dataItem = useAtom({
    block: dndBlock,
    initialData: {},
    dataType: 'condition',
    sourceId: transitionId,
    sourceName: 'Transition',
    library: 'condition'
  });

  // Add reference between parent and child
  useEffect(() => {
    if (!dataItem) return;
    andBlock.forEach((el) => {
      if (el.atomId === dndBlock.atomId) return;
      dataItem.addReferencingAtom(el.atomId);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataItem, dndBlock]);

  if (!dataItem) return <></>;

  return (
    <BlockBase
      dndBlock={dndBlock}
      hasTitle={false}
      icon={AndIcon}
      isStatement
      endControls={
        <EndControlsFB
          dataItem={dataItem}
          hasRequired={false}
          dndBlock={dndBlock}
        />
      }
    >
      <Column>
        <FormLabel sx={{ fontSize: '12px' }}>All conditions</FormLabel>
      </Column>
      <Column $width="100%">
        <InteractionZone path={dndPath} dndBlock={andBlock} />
      </Column>
    </BlockBase>
  );
};

export default observer(And);
