import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { LAYOUT_BORDER_RADIUS } from '../homeHeader/homeHeader.style';

export const EmptyButtonRoot = styled.div`
  padding-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const EmptyProcessRoot = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const EmptyProcessText = styled.h2`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.color.grey[4]};
`;

export const AddNewProcessBtn = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95%;
  box-sizing: border-box;
  padding: 0px 15px;
  border: solid 1px transparent;
  outline: none;
  background-color: ${(p) => p.theme.color.white[1]};
  color: ${(p) => p.theme.color.grey[1]};
  border-radius: ${LAYOUT_BORDER_RADIUS}px;
  font-weight: 600;
  cursor: pointer;
  font-size: 0.85rem;
  gap: 0.5rem;
  transition: background-color 0.2s ease;

  &:hover {
  }
`;
