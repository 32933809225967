import Lottie from "lottie-react";
import styled from "styled-components";
import { HOVERED_SCALE, NORMAL_SCALE, SELECTED_SCALE } from "./utils";

interface EmojiProps {
    $isSelected: boolean;
}

export const Emojis = styled.div`
  display: flex;
  position: relative;
  display: flex;
  align-items: center;
  top: 30%;
`;

export const EmojiWrapper = styled.div<EmojiProps>`
  cursor: pointer;
  margin: 0 12px;
  position: relative;
  transform: scale(${({ $isSelected }) => ($isSelected ? SELECTED_SCALE : NORMAL_SCALE)});
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(${({ $isSelected }) => ($isSelected ? SELECTED_SCALE : HOVERED_SCALE)});
  }
`;

export const EmojiLabel = styled.p`
  text-align: center;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  user-select: none;
`;

export const EmojiLottie = styled(Lottie)`
  height: 50px;
  width: 50px;
`;