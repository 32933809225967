import { TraceKeyMode } from '@/mobx/models/traceKey/schema';
import { TraceKeyModel } from '@/mobx/models/traceKey/traceKey.model';
import { InputField } from '@atoms/input';
import { observer } from 'mobx-react';
import { Button, FormControl, FormLabel, ToggleButtonGroup } from '@mui/joy';
import { traceKeyModeDataMap } from './data';

type Props = {
  traceKey: TraceKeyModel;
  parentName?: string;
};

export const TraceKeyInput = observer(({ traceKey, parentName }: Props) => {
  const onTraceKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    traceKey.setManualValue(e.target.value);
  };

  const onModeChange = (modeValue: unknown) => {
    if (!isChangeableMode(modeValue)) return;
    traceKey.setMode(modeValue, parentName);
  };
  return (
    <div className="flex w-full items-start gap-4">
      <div className="w-56">
        <InputField
          required
          label="Trace key"
          placeholder={traceKey.id}
          value={traceKey.visualValue}
          onChange={onTraceKeyChange}
          errors={traceKey.errors}
          warnings={traceKey.warnings}
        />
      </div>
      <FormControl size="sm">
        <FormLabel>Trace key mode</FormLabel>
        <ToggleButtonGroup
          value={traceKey.mode}
          onChange={(_, newValue) => {
            onModeChange(newValue);
          }}
          size="sm"
        >
          {Object.entries(traceKeyModeDataMap)
            .filter(([mode]) => (mode as TraceKeyMode) !== TraceKeyMode.Locked)
            .map(([mode, modeData]) => (
              <Button
                key={mode}
                value={mode}
                className="flex items-center gap-2"
                size="sm"
              >
                <modeData.icon size={16} />
                {modeData.label}
              </Button>
            ))}
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
});

const isChangeableMode = (
  mode: unknown
): mode is TraceKeyMode.Follow | TraceKeyMode.Free => {
  return (
    Object.values(TraceKeyMode).includes(mode as TraceKeyMode) &&
    (mode as TraceKeyMode) !== TraceKeyMode.Locked
  );
};
