import { AlignLeft, Link, Code, ScrollText, Pilcrow } from 'lucide-react';
import { TO_TextIconType } from './text.data';

type IconOption = {
  value: TO_TextIconType;
  label: string;
  icon: JSX.Element;
};

export const textIconOptions: IconOption[] = [
  { value: 'Text', label: 'Text', icon: <AlignLeft size={14} /> },
  { value: 'Link', label: 'Link', icon: <Link size={14} /> },
  { value: 'Code', label: 'Code', icon: <Code size={14} /> },
  { value: 'ScrollText', label: 'Scroll Text', icon: <ScrollText size={14} /> },
  { value: 'Pilcrow', label: 'Pilcrow', icon: <Pilcrow size={14} /> }
];
