const INFINITE_PRIORITY = 99999;

function SuppressUndo(keyboard, eventBus, directEditing) {
  keyboard.addListener(INFINITE_PRIORITY, function (event) {
    if (event.keyEvent.key == 'z' && event.keyEvent.ctrlKey) {
      eventBus.fire('studio.undo', { event });
      return false;
    }
    if (event.keyEvent.key == 'v' && event.keyEvent.ctrlKey) {
      eventBus.fire('studio.paste', { event });
      return false;
    }
    if (event.keyEvent.key == 'c' && event.keyEvent.ctrlKey) {
      eventBus.fire('studio.copy', { event });
      return false;
    }
    if (event.keyEvent.key == 'Delete' || event.keyEvent.key == 'Backspace') {
      eventBus.fire('studio.delete', { event });
      return false;
    }
  });

  eventBus.on(['create.end', 'autoPlace.end'], 250, () => {
    directEditing.cancel();
  });
}

SuppressUndo.$inject = ['keyboard', 'eventBus', 'directEditing'];

export default SuppressUndo;
