import styled from 'styled-components';

/** @deprecated never used */
export const TOWidgetMessage = styled.div`
  font-size: 12px;
  text-align: center;
`;

export const LabeledVariable = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  gap: 5px;

  label {
    font-size: 12px;
  }

  p {
    font-size: 16px;
    padding: 10px 0;
  }
`;
