import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_TEXT_VIEW_TYPES = ['Text', 'Code', 'Link', 'Prose'] as const;
export const TO_TextViewTypeSchema = z.enum(TO_TEXT_VIEW_TYPES);

export const TO_TextIconSchema = z.enum([
  'Text',
  'Link',
  'Code',
  'ScrollText',
  'Pilcrow'
]);

export type TO_TextIconType = z.infer<typeof TO_TextIconSchema>;

export const TO_TextDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_TextViewTypeSchema,
      hasIcon: z.boolean().default(false),
      icon: TO_TextIconSchema
    })
    .strict();

export type TO_TextData = z.infer<ReturnType<typeof TO_TextDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTOTextData: Omit<TO_TextData, 'selectedVariable'> = {
  title: 'Default Text Title',
  viewType: 'Text',
  hasIcon: false,
  icon: 'Text'
};
