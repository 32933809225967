import { AtomModel } from '@/mobx/models/atom.model';
import { Prefill } from '../../prefill';
import { RichTextData } from './richText.data';

type Props = {
  atom: AtomModel<RichTextData>;
};

export const RichTextModal = ({ atom }: Props) => {
  return (
    <>
      <Prefill atom={atom} />
    </>
  );
};
