import history from '@/routes/history';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';
import { gsap } from 'gsap';

/**
 * @typedef {import("diagram-js/lib/core/EventBus").default} EventBus
 * @typedef {import("diagram-js/lib/features/lasso-tool/LassoTool").default} LassoTool
 *
 */

/**
 *
 * @param {EventBus} eventBus
 * @param {LassoTool} lassoTool
 */
export default function DefaultCanvasBehavior(
  eventBus,
  lassoTool,
  selection,
  elementRegistry,
  canvas
) {
  let context;

  // listen for move on element mouse down;
  // allow others to hook into the event before us though
  eventBus.on('element.mousedown', 500, function (e) {
    return handleStart(e.originalEvent);
  });

  // Note that this event listener has a higher priority (1500) than the default one (1000). This is necessary to make sure your listener is reached first.
  // https://github.com/bpmn-io/bpmn-js/blob/b17a234bb43da51c91d30aab6ecbb166e8fce56c/lib/features/label-editing/LabelEditingProvider.js#L70
  eventBus.on('element.dblclick', 1500, function (event) {
    const element = event.element;
    if (
      isAny(element, ['bpmn:Task', 'bpmn:SequenceFlow', 'bpmn:MessageFlow'])
    ) {
      selection.select(element);
      // centerElement(element.id);

      const type = element.id.split('_')[0];
      const id = element.id.substring(element.id.indexOf('_') + 1);
      const processId = location.pathname.split('/')[1];

      if (type === 'Activity') {
        history.push(`/${processId}/editor/p/a/${id}/form`);
      }
      if (type === 'Flow') {
        history.push(`/${processId}/editor/p/t/${id}/condition`);
      }

      event.stopPropagation();
    }
  });

  function handleStart(event) {
    lassoTool.activateLasso(event);
    return true;
  }

  function centerElement(elementId) {
    const bbox = elementRegistry.get(elementId);
    const panel = document.getElementsByTagName('aside')[0];
    const panelWidth = panel ? panel.clientWidth : window.innerWidth / 2;
    let currentViewbox = canvas.viewbox();

    const elementMid = {
      x: bbox.x + bbox.width / 2,
      y: bbox.y + bbox.height / 2
    };

    if (bbox.type == 'bpmn:SequenceFlow') {
      const waypoints = bbox.waypoints;
      const bboxConnection = getBoundingBoxFromWaypoints(waypoints);

      elementMid.x = bboxConnection.x;
      elementMid.y = bboxConnection.y;
    }

    const panelWidthPercentage = (panelWidth / window.innerWidth) * 100;
    const pixelsRelativeToViewbox =
      (panelWidthPercentage / 100) * currentViewbox.width;
    gsap.to(currentViewbox, {
      x: elementMid.x - (currentViewbox.width - pixelsRelativeToViewbox) / 2,
      y: elementMid.y - currentViewbox.height / 2,
      width: currentViewbox.width - pixelsRelativeToViewbox,
      height: currentViewbox.height,
      duration: 0.5,
      onUpdate: () => {
        canvas.viewbox({
          x: currentViewbox.x,
          y: currentViewbox.y,
          width: currentViewbox.width,
          height: currentViewbox.height
        });
      }
    });
  }

  function getBoundingBoxFromWaypoints(waypoints) {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    waypoints.forEach((waypoint) => {
      minX = Math.min(minX, waypoint.x);
      minY = Math.min(minY, waypoint.y);
      maxX = Math.max(maxX, waypoint.x);
      maxY = Math.max(maxY, waypoint.y);
    });

    return {
      x: (minX + maxX) / 2,
      y: (minY + maxY) / 2
    };
  }

  this.isActive = function () {
    return !!context;
  };
}

DefaultCanvasBehavior.$inject = [
  'eventBus',
  'lassoTool',
  'selection',
  'elementRegistry',
  'canvas'
];
