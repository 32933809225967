import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  TypedDocumentNode
} from '@apollo/client';
import { newError } from './errors/errors';

export class GraphQLService {
  URL = '';
  client: ApolloClient<object>;

  constructor(URL: string) {
    this.URL = URL;

    const link = createHttpLink({
      uri: URL,
      credentials: 'include'
    });

    this.client = new ApolloClient({
      link,
      cache: new InMemoryCache()
    });
  }

  async query<T = unknown>(data: {
    query: TypedDocumentNode;
    variable: { [key: string]: string | number };
  }): Promise<Maybe<T>> {
    try {
      const response = await this.client.query<T>(data);

      if (response.errors) {
        throw newError(response.errors);
      }

      return response.data;
    } catch (e: unknown) {
      newError(e);
      return;
    }
  }
}
