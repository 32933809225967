import { FC } from 'react';
import { DNDBlock } from '@/components/dnd/base';
import RepositoryBlock from './block';
import { Plexer } from '../library.types';

interface LibraryProps {
  block: DNDBlock;
}

export interface TraceOverDefaultData {
  value: string;
}

export enum DTRBlocks {
  DataRepository = 'Data Repository'
}

export const DTR_INFO_DND = {
  title: 'Data Repository',
  description: 'Add data as a list. This data can be reused anywhere in Studio.'
}

const DTRPlexer: Plexer<DTRBlocks> = {
  [DTRBlocks.DataRepository]: RepositoryBlock
};

export const LibraryDTR: FC<LibraryProps> = ({ block }) => {
  const Block = DTRPlexer[block.type as DTRBlocks];
  return <Block {...block} />;
};
