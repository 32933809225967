import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';

import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';

import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';
import { FileUploadModal } from './fileUpload.modal';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { useAtom } from '@hooks/useAtom';
import { FileUploadIcon } from './fileUpload.utils';
import { initialFileUploadData } from './fileUpload.data';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

const FileUpload = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];

  const dataItem = useAtom({
    block,
    initialData: initialFileUploadData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.File
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.FileUpload} title`,
      content: <FileUploadModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );
  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={FileUploadIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          required
          label="Field title"
          placeholder="Aa"
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(FileUpload);
