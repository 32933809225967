import {
  LucideIcon,
  AtSign,
  ToggleRight,
  Sigma,
  Link2,
  Type,
  FileUp
} from 'lucide-react';
import { ALL_TYPE_KIND, PRIMARY_TYPES } from './globalVariable.data';
import { StaticType } from '@/mobx/models/code/types';
import { ResolvedVariableType } from '@/mobx/types/atom.types';

export const kindToLabelMap: Record<(typeof ALL_TYPE_KIND)[number], string> = {
  normal: 'Normal',
  list: 'List',
  custom: 'Custom'
};

type Info = {
  label: string;
  icon: LucideIcon;
  resolvedType: ResolvedVariableType;
};

export const primaryTypeToInfoMap: Record<
  (typeof PRIMARY_TYPES)[number],
  Info
> = {
  string: {
    label: 'Text',
    icon: Type,
    resolvedType: 'Text' as ResolvedVariableType
  },
  number: {
    label: 'Number',
    icon: Sigma,
    resolvedType: 'Number' as ResolvedVariableType
  },
  boolean: {
    label: 'Boolean',
    icon: ToggleRight,
    resolvedType: 'Boolean' as ResolvedVariableType
  },
  [StaticType.Email]: {
    label: 'Email',
    icon: AtSign,
    resolvedType: 'Text' as ResolvedVariableType
  },
  [StaticType.URL]: {
    label: 'URL',
    icon: Link2,
    resolvedType: 'Text' as ResolvedVariableType
  },
  [StaticType.FormFile]: {
    label: 'File',
    icon: FileUp,
    resolvedType: 'File' as ResolvedVariableType.File
  }
};

export const isCorrectTypeKind = (
  kind: unknown
): kind is (typeof ALL_TYPE_KIND)[number] => {
  return ALL_TYPE_KIND.includes(kind as (typeof ALL_TYPE_KIND)[number]);
};

export const isCorrectPrimaryType = (
  type: unknown
): type is (typeof PRIMARY_TYPES)[number] => {
  return PRIMARY_TYPES.includes(type as (typeof PRIMARY_TYPES)[number]);
};

export const getCustomTypeName = (customType: string): Maybe<string> => {
  const match = customType.match(/type\s+(\w+)\s*=/);
  if (!match) return undefined;
  return match[1];
};
