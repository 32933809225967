import { GlobalVariablesBlocks } from '.';
import { GlobalVariableDataSchema } from './blocks/globalVariableBlock/globalVariable.data';
import { z } from 'zod';

export const GlobalVariablesBlockToSchemaMap: Record<
  GlobalVariablesBlocks,
  z.Schema
> = {
  [GlobalVariablesBlocks.Variable]: GlobalVariableDataSchema()
};
