import { Outlet, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import PanelHeader from '@pages/panel/PanelHeader';
import { PanelBody } from '@pages/panel/panel.style';
import { TRANSITION_TAB_LINKS } from './transition.config';
import { ParamsList } from '@/routes/routes.types';
import useStores from '@/hooks/useStore';
import useProcess from '@hooks/useProcess';

const Transition = () => {
  const { transitionStore, workflowStore } = useStores();
  const { process } = useProcess();
  const transitionId = useParams<string>()[ParamsList.TransitionId] as string;
  const transition = transitionStore.get(transitionId);
  const wf = workflowStore.get(transition?.actionFrom?.workflow_id);

  if (process?.loading) {
    return <></>;
  }

  if (!process) {
    return <></>;
  }

  return (
    <>
      <PanelHeader
        workflowName={wf?.name ?? 'Error WF'}
        infos={[
          transition?.actionFrom?.name ?? 'Error Action 1',
          transition?.actionTo?.name ?? 'Error Action 2'
        ]}
        actionType="transition"
        tabLinks={TRANSITION_TAB_LINKS}
      />
      <PanelBody>
        <Outlet />
      </PanelBody>
    </>
  );
};

export default observer(Transition);
