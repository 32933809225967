import { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import {
  BlockConditionContainer,
  LeftSide,
  DragDotsContainer,
  DragDots,
  RightSideCondition
} from '../blockBase.style';
import { BlockBody, EndControlsContainer } from '../body.block.style';

interface BlockConditionProps {
  isDragging: boolean;
  isCollapsed: boolean;
  isNested: boolean;
  isCondition: boolean;
  preview: ConnectDragPreview;
  drag: ConnectDragSource;
  setHover: Dispatch<SetStateAction<boolean>>;
  isHovering: boolean;
  children: ReactNode;
  EndControls?: ReactElement;
}

const BlockCondition = ({
  isDragging,
  isCollapsed,
  isNested,
  isCondition,
  preview,
  drag,
  setHover,
  isHovering,
  EndControls,
  children
}: BlockConditionProps) => {
  return (
    <BlockConditionContainer
      $isDragging={isDragging}
      $isCollapsed={isCollapsed}
      ref={preview}
      $isNested={isNested}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-tag="Condition-block"
    >
      <LeftSide $isCondition={isCondition} ref={drag} data-tag="drag-dots">
        <DragDotsContainer>
          <DragDots />
        </DragDotsContainer>
      </LeftSide>
      <RightSideCondition>
        <section className="flex w-full flex-col justify-between gap-4">
          <BlockBody>{children}</BlockBody>
        </section>
        <EndControlsContainer $isCondition={isCondition}>
          {EndControls && (
            <EndControls.type {...EndControls.props} isHovering={isHovering} />
          )}
        </EndControlsContainer>
      </RightSideCondition>
    </BlockConditionContainer>
  );
};

export default BlockCondition;
