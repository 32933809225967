import { customAlphabet } from 'nanoid';

const DEFAULT_ALPHABET =
  '0123456789STRATUMNBCDEFGHIJKLOPQVWXYZstratumnbcdefghijklopqvwxyz';

/**
 * Generates a random ID with numbers, uppercase and lowercase letters. Default id length is 7.
 * @example
 * import { getNanoID } from '@/utils/nanoID';
 * const id1 = getNanoID(); // --> aB3cZdE5fGhIjKlMnOpQ2
 * const id2 = getNanoID(5); // --> aB3cZ
 * const id3 = getNanoID(3, '012345abcdef'); // --> 0a1
 * */
const nanoID = (size: number = 7, alphabet?: string): string => {
  const nanoid = customAlphabet(
    alphabet ?? DEFAULT_ALPHABET,
    size <= 21 ? size : 21
  );
  return nanoid();
};

export default nanoID;
