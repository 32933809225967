import styled from 'styled-components';

export const ToolbarItem = styled.div`
  border: ${({ theme }) => theme.border.default};
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  color: ${({ theme }) => theme.color.grey.dark};
  padding: 5px 11px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  text-align: center;
  line-height: 15px;
  transition: all 0.2s;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    cursor: grab;
    color: ${(p) => p.theme.color.grey.dark};
    box-shadow: ${(p) => p.theme.boxShadow.light};
  }
  &:active {
    cursor: grabbing;
  }
`;

export const ItemName = styled.div``;
