import { TitleInputSchema } from '@/utils/zod';
import { DEFAULT_COMMENT_TITLE } from '@components/dnd/library/formBuilder/blocks/comment/comment.data';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TI_CommentDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema
    })
    .strict();

export type TI_CommentData = z.infer<ReturnType<typeof TI_CommentDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTICommentData: Omit<TI_CommentData, 'selectedVariable'> = {
  title: DEFAULT_COMMENT_TITLE
};
