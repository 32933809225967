import React, { useEffect } from 'react';
import Oops from '@components/library/oops';
import { useNavigate } from 'react-router-dom';

type PageNotFoundProps = {
  description?: string;
  title?: string;
  label?: string;
};

const PageNotFound = ({
  description = 'An error has occured',
  title = 'An error has occured',
  label = 'Take me home'
}: PageNotFoundProps) => {
  const navigate = useNavigate();
  const goTo = () => {
    navigate('/');
  };
  useEffect(() => {
    document.title = title;
  }, [title]);

  return <Oops onHomeClick={goTo} description={description} label={label} />;
};

export default PageNotFound;
