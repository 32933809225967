const replacer = (_: string, group: string) => group.toUpperCase();

/** Converts a sentence to a camelCase string.
 * @example toCamelCase('Hello World') // 'helloWorld'
 * @example toCamelCase('HelloWorld') // 'helloworld'
 */
const toCamelCase = (text: string) => {
  if (!text) return text;
  const searchValue = /\s+(.)/g;
  return text.toLowerCase().replace(searchValue, replacer);
};

export default toCamelCase;
