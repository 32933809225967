import inherits from 'inherits-browser';
import RuleProvider from 'diagram-js/lib/features/rules/RuleProvider';

export default function StudioRules(eventBus) {
  RuleProvider.call(this, eventBus);
}

inherits(StudioRules, RuleProvider);

StudioRules.$inject = ['eventBus'];

const INF = 100000;
const DEBUG = false;

StudioRules.prototype.init = function () {
  this.addRule('connection.start', INF, function (context) {
    DEBUG && console.log('connection.start', context);
  });

  this.addRule('connection.create', INF, function (context) {
    DEBUG && console.log('connection.create', context);
  });

  this.addRule('connection.reconnect', INF, function (context) {
    DEBUG && console.log('connection.reconnect', context);
  });

  this.addRule('connection.updateWaypoints', INF, function (context) {
    DEBUG && console.log('connection.updateWaypoints', context);
  });

  this.addRule('elements.create', INF, function (context) {
    DEBUG && console.log('elements.create', context);
  });

  this.addRule('elements.move', INF, function (context) {
    DEBUG && console.log('elements.move', context);
  });

  this.addRule('element.copy', INF, function (context) {
    DEBUG && console.log('element.copy', context);
  });

  this.addRule('shape.resize', INF, function (context) {
    DEBUG && console.log('shape.resize', context);
  });

  this.addRule('shape.create', INF, function (context) {
    DEBUG && console.log('shape.create', context);
    if (context.target.type === 'bpmn:SequenceFlow') {
      return false;
    }
  });

  this.addRule('shape.attach', INF, function (context) {
    DEBUG && console.log('shape.attach', context);
  });
};
