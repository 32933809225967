import {
  Collaborator,
  InputProcess,
  ProcessModel
} from '@/mobx/models/process.model';
import { DndLibrary } from '@components/dnd/library';
import AsyncStore from './base/async.store';
import { LoadedDndModel } from './dnd.store';

import RootStore from './root.store';
import { TransitionLoaded } from './transition.store';
import { WorkflowLoaded } from './workflow.store';
import { z } from 'zod';
import { newError } from '@/services/errors/errors';
import { AtomLoaded } from './atom.store';
import { NotificationTemplatesLoaded } from '../types/notification.types';
import { RoleDraft } from '../types/process.types';
import { ApiResponseEmpty } from '@/utils/http';
import AccountService, { User } from '@/services/account/account.service';
import { runInAction } from 'mobx';

interface ProcessLoaded {
  id: string;
  bpmn_workflow: string;
  dataRepository: LoadedDndModel;
  icon: string;
  name: string;
  updated_at: string;
  created_at: string;
  published: boolean;
  workflows: WorkflowLoaded[];
  Transition: TransitionLoaded[]; // ! Transition with an 's', no upper case
  notificationTemplates: NotificationTemplatesLoaded[];
  atom: AtomLoaded[];
  draft: boolean;
  password_protected: boolean;
  can_edit: boolean;
  is_public: boolean;
  collaborators: Collaborator[] | undefined;
  user_role: RoleDraft | undefined;
}

interface InviteUserDTO {
  role: RoleDraft;
  emails: string[];
}

interface InviteUsersResponseDTO {
  users: Collaborator[];
}
interface UpdateRoleDTO {
  role: RoleDraft;
  userId: string;
}

interface UpdateProcessDTO {
  password?: string;
  is_public?: boolean;
  name?: string;
}

export const ProcessInfoLoadedSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    updated_at: z.string(),
    created_at: z.string(),
    iconUrl: z.string().optional(),
    published: z.boolean(),
    workflows: z.array(
      z.object({
        id: z.string(),
        published_id: z.string().nullable()
      })
    ),
    draft: z.boolean(),
    can_edit: z.boolean().optional()
  })
  .strict();

export const ProcessInfosLoadedSchema = z.array(ProcessInfoLoadedSchema);
export type ProcessInfoLoaded = z.infer<typeof ProcessInfoLoadedSchema>;

export class ProcessStore extends AsyncStore<ProcessModel> {
  currentProcessId?: string;

  constructor(rootStore: RootStore) {
    super(rootStore, ProcessModel, 'process');
  }

  public get currentProcess(): Maybe<ProcessModel> {
    return this.get(this.currentProcessId);
  }

  public async createNewProcess(inputProcess: InputProcess) {
    const { name, draft, template, bpmn_workflow } = inputProcess;
    template; // TODO: Remove this line when the template is used
    const newProcess = this.create();
    newProcess.name = name;
    newProcess.draft = draft;
    newProcess.bpmnWorkflow = bpmn_workflow;

    const dto = {
      name: newProcess.name,
      id: newProcess.id,
      draft: newProcess.draft,
      bpmnWorkflow: newProcess.bpmnWorkflow
    };

    const data: ProcessModel = await this.createNew(dto);
    return data;
  }

  public async generateCurrent() {
    const response = await this.httpWrapper.get<{
      workflowPublishedId: string;
    }>(`/${this.currentProcessId}/generate`);
    if (response) {
      if (this.currentProcess) {
        this.currentProcess.workflowIds = this.currentProcess.workflowIds?.map(
          (wf) => ({ ...wf, published_id: response.workflowPublishedId })
        );
      }
    }
  }

  public async updateProcess(
    id: Maybe<ProcessModel['id']>,
    data: UpdateProcessDTO
  ): Promise<ApiResponseEmpty> {
    const response = (await this.httpWrapper
      .put(`/${id}`, data)
      .catch((e: Error) => {
        newError(e, true, {
          customMessage: 'An error occurred during updating the process'
        });
      })) as ApiResponseEmpty;
    if (response) {
      runInAction(() => {
        if (this.currentProcess) {
          if ('password' in data) {
            this.currentProcess.password_protected =
              data.password === '' ? false : true;
          } else {
            Object.assign(this.currentProcess, data);
          }
        }
      });
    }
    return response;
  }

  public async inviteUser(
    processId: string,
    emails: string[],
    role: RoleDraft
  ): Promise<Maybe<Collaborator[]>> {
    const dto: InviteUserDTO = {
      emails,
      role
    };
    const response = await this.httpWrapper
      .post<InviteUsersResponseDTO>(`/${processId}/invites`, dto)
      .catch((e: Error) => {
        newError(e, true, {
          customMessage:
            'An error occured, user is not part of Stratumn or already invited'
        });
      });
    if (this.currentProcess?.collaborators && response) {
      this.currentProcess.collaborators = [
        ...this.currentProcess.collaborators,
        ...response.users
      ];
      return response.users;
    }
    return;
  }

  public async fetchPasswordProtectedProcess(
    id: string,
    password: string
  ): Promise<Maybe<ProcessModel>> {
    const dto = {
      password
    };
    const loadedProcess = await this.httpWrapper.post<ProcessLoaded>(
      `/${id}/authenticate`,
      dto
    );
    if (!loadedProcess) {
      const errorMessage = `Error while fetching the password protected process with id: ${id}`;
      newError(errorMessage, true, {
        customMessage: 'Error while loading the process'
      });
      throw new Error(errorMessage);
    }

    if (loadedProcess.draft) {
      // No need to fetch roles here because we are not connected
      return this.addDraftProcessFromLoaded(loadedProcess);
    }
    return this.addStratumnProcessFromLoaded(loadedProcess);
  }

  public async loadRoles(id: string): Promise<Maybe<Collaborator[]>> {
    return await this.httpWrapper.get<Collaborator[]>(`/${id}/roles`);
  }

  public async updateRole(
    id: ProcessModel['id'],
    role: RoleDraft,
    userId: string
  ): Promise<void> {
    const dto: UpdateRoleDTO = {
      role,
      userId
    };
    await this.httpWrapper.put(`/${id}/roles`, dto);
    if (this.currentProcess) {
      this.currentProcess.collaborators =
        this.currentProcess.collaborators?.map((collaborator) => {
          if (collaborator.id === userId) return { ...collaborator, role };
          else return collaborator;
        });
    }
  }

  public async deleteRole(
    id: ProcessModel['id'],
    userId: string
  ): Promise<void> {
    await this.httpWrapper.delete(`/${id}/roles/${userId}`);

    if (this.currentProcess) {
      this.currentProcess.collaborators =
        this.currentProcess.collaborators?.filter(
          (collab) => collab.id !== userId
        );
    }
  }

  public async fetchProcess(id: string): Promise<Maybe<ProcessModel>> {
    if (this.loading) return; // Prevents multiple calls on first load
    else this.loading = true;

    const loadedProcess: Maybe<ProcessLoaded> | string =
      await this.httpWrapper.getWithForbiddenPossibility<ProcessLoaded>(
        `/${id}`
      );
    if (
      typeof loadedProcess === 'string' &&
      loadedProcess === 'password_protected'
    ) {
      newError('Password Protected Page', true);
      throw new Error('Password Protected Page');
    }

    if (!loadedProcess) {
      const errorMessage = `Error while fetching the process with id: ${id}`;
      newError(errorMessage, true, {
        customMessage: 'Error while loading the process'
      });
      throw new Error(errorMessage);
    }

    if (loadedProcess.draft) {
      const user: Maybe<User> = await AccountService.getMe();
      if (user) {
        // We are only loading roles if we are connected
        const roles = await this.loadRoles(id);
        const user_role = roles?.find(
          (collaborator) => collaborator.id === user?.rowId
        )?.role;

        const loadedProcessWithRoles = {
          ...loadedProcess,
          collaborators: roles,
          user_role
        };
        return this.addDraftProcessFromLoaded(loadedProcessWithRoles);
      } else return this.addDraftProcessFromLoaded(loadedProcess);
    }
    return this.addStratumnProcessFromLoaded(loadedProcess);
  }

  private addStratumnProcessFromLoaded = (
    loadedProcess: ProcessLoaded
  ): Maybe<ProcessModel> => {
    const atomStore = this.rootStore.atomStore;
    const workflowStore = this.rootStore.workflowStore;
    const transitionStore = this.rootStore.transitionStore;
    const dndStore = this.rootStore.dndStore;
    const notificationTemplateStore = this.rootStore.notificationTemplateStore;

    /* ------------------------------ Atom loading ------------------------------ */
    atomStore.loadAtoms(loadedProcess.atom);

    /* ------------------------- Handle workflows ------------------------- */
    const workflowIds = workflowStore.loadWorkflows(loadedProcess.workflows);

    /* --------------------------- Handle transitions --------------------------- */
    transitionStore.loadTransitions(loadedProcess.Transition);

    /* -------------------------- Handle process's DNDs ------------------------- */
    const dataRepositoryDnd = dndStore.createDndFromLoaded(
      DndLibrary.DataRepository,
      loadedProcess.dataRepository
    );

    /* -------------------------- Handle notification templates ------------------*/
    notificationTemplateStore.loadNotificationTemplates(
      loadedProcess.notificationTemplates
    );

    /* ---------------------- Finish process instanciation ---------------------- */
    const newProcess: ProcessModel = new ProcessModel(
      this,
      loadedProcess.id,
      loadedProcess.bpmn_workflow,
      loadedProcess.published,
      loadedProcess.name,
      loadedProcess.created_at,
      loadedProcess.updated_at,
      loadedProcess.icon,
      loadedProcess.draft,
      workflowIds,
      dataRepositoryDnd.id,
      loadedProcess.password_protected,
      loadedProcess.can_edit,
      loadedProcess.is_public
    );

    this.set(newProcess.id, newProcess);

    dndStore.store_ready = true;
    this.loading = false;
    return newProcess;
  };

  private addDraftProcessFromLoaded = (
    loadedProcess: ProcessLoaded
  ): Maybe<ProcessModel> => {
    const newProcess: ProcessModel = new ProcessModel(
      this,
      loadedProcess.id,
      loadedProcess.bpmn_workflow,
      loadedProcess.published,
      loadedProcess.name,
      loadedProcess.created_at,
      loadedProcess.updated_at,
      loadedProcess.icon,
      loadedProcess.draft,
      [],
      undefined,
      loadedProcess.password_protected,
      loadedProcess.can_edit,
      loadedProcess.is_public,
      loadedProcess.user_role,
      loadedProcess.collaborators
    );

    this.set(newProcess.id, newProcess);

    this.loading = false;
    return newProcess;
  };
}
