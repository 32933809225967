import { AtomModel } from '@/mobx/models/atom.model';
import { observer } from 'mobx-react';
import VariableField from '@atoms/variableField';
import { FormControl, FormLabel } from '@mui/joy';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';
import { PrefillData } from './prefill.schema';
import { Trash2 } from 'lucide-react';

type Props = {
  atom: AtomModel<PrefillData>;
};
export const Prefill = observer(({ atom }: Props) => {
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;
  const { atomStore } = useStores();

  if (atom.data.prefill) {
    const tmpSelectedVariable = atomStore.getAtomById(
      atom.data.prefill.dataItemId,
      atom.data.prefill.blockType
    );

    if (tmpSelectedVariable instanceof Error) {
      newError("Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const onVariableSelected = (seletedVariableReference: DataItemReference) => {
    if (!seletedVariableReference) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    atom.data.prefill = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    selectedVariable.addReferencingAtom(atom.id);
  };

  const removePrefill = () => {
    atom.data.prefill = undefined;
    selectedVariable?.removeReferencingAtom(atom);
    selectedVariable = undefined;
  };

  return (
    <FormControl size="sm">
      <FormLabel>Prefill with a state variable</FormLabel>
      <div className="flex items-center gap-2">
        <VariableField
          value={selectedVariable?.data.title}
          onSelected={onVariableSelected}
        />
        {selectedVariable && (
          <div
            className="cursor-pointer rounded-md p-2 text-gray-600 hover:bg-red-50 hover:text-red-700"
            onClick={removePrefill}
          >
            <Trash2 size={17} />
          </div>
        )}
      </div>
    </FormControl>
  );
});
