import SettingHeader from '../SettingHeader';
import { ButtonContainer, GeneralSettingsInput } from './general-setting.style';
import { SettingBody } from '../style';
import ActionButton from '@atoms/button';
import { SettingsContent } from '../settings.style';
import { InputField } from '@atoms/input';
import useProcess from '@hooks/useProcess';
import { observer } from 'mobx-react';
import useStores from '@hooks/useStore';
import {
  Button,
  Checkbox,
  DialogActions,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  Modal,
  ModalDialog
} from '@mui/joy';
import { Dispatch, SetStateAction, useState } from 'react';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import { RoleDraft } from '@/mobx/types/process.types';
import { Info } from 'lucide-react';

interface BasicModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  nuke: () => Promise<void>;
  isDraft: boolean;
  stats?: {
    actionCount: number;
    transitionCount: number;
    workflowCount: number;
  };
}

export const DeleteModal = ({
  open,
  setOpen,
  nuke,
  isDraft,
  stats
}: BasicModalProps) => {
  const [deleteDisable, setDeleteDisable] = useState(true);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
    >
      <ModalDialog
        color="danger"
        layout="center"
        variant="outlined"
        sx={{ width: 500 }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} color="danger" />
        <Typography
          component="h2"
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Are you sure about deleting the process ?
        </Typography>
        <Typography>
          Deleting a process is completely irreversible.{' '}
          <strong>All data about the process will be lost.</strong>
        </Typography>
        {stats && (
          <>
            <Typography>Your process has:</Typography>
            <List marker="disc">
              {stats?.workflowCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
              {stats?.actionCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
              {stats?.transitionCount === 0 && (
                <ListItem>
                  <strong>{stats.workflowCount}</strong> workflow
                </ListItem>
              )}
            </List>
          </>
        )}
        {!isDraft && (
          <Checkbox
            color="neutral"
            label="I want to delete the process in Trace (all trace will be deleted has well)"
          />
        )}
        <Checkbox
          color="danger"
          label="I understand the consequences of this action"
          onChange={(e) => setDeleteDisable(!e.target.checked)}
        />
        <DialogActions>
          <Button
            variant="solid"
            color="danger"
            disabled={deleteDisable}
            onClick={() => {
              nuke().catch(() => {});
              setOpen(false);
            }}
          >
            Delete 💥
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

const GeneralSetting = () => {
  const { process } = useProcess();
  const [modalOpen, setModalOpen] = useState(false);
  const [processName, setProcessName] = useState(process?.name);
  const [error, setError] = useState(false);

  const { processStore, actionStore, transitionStore, workflowStore } =
    useStores();

  const stats = {
    actionCount: actionStore.data.size,
    transitionCount: transitionStore.data.size,
    workflowCount: workflowStore.data.size
  };

  const canDelete = process?.user_role !== RoleDraft.Viewer;

  const onSubmit = async () => {
    if (!processName) {
      return setError(true);
    } else if (error) {
      setError(false);
    }
    await processStore.updateProcess(process?.id, {
      name: processName
    });
  };

  async function deleteProcess() {
    const response = await processStore.delete(process?.id);
    if (response) {
      window.location.assign('/');
    }
  }
  return (
    <SettingsContent>
      <SettingHeader
        title="General Settings"
        description="Customize your processus"
      />
      <DeleteModal
        open={modalOpen}
        setOpen={setModalOpen}
        stats={stats}
        isDraft={process?.draft ?? false}
        nuke={deleteProcess}
      />

      {process?.loading && <SettingBody></SettingBody>}
      {!process?.loading && (
        <SettingBody>
          <GeneralSettingsInput>
            <FormControl error={error}>
              <InputField
                label="Processus name"
                placeholder="Aa"
                onChange={(e) => setProcessName(e.target.value)}
                value={processName}
                onBlur={() => void onSubmit()}
                error={error}
              />
              {error && (
                <FormHelperText>
                  <Info size={16} />
                  You can't enter an empty text
                </FormHelperText>
              )}
            </FormControl>
          </GeneralSettingsInput>

          <ButtonContainer>
            <ActionButton
              disabled={!canDelete}
              color="danger"
              value="Delete the process"
              onClick={() => {
                if (!process?.id) return;
                setModalOpen(true);
              }}
            />
          </ButtonContainer>
        </SettingBody>
      )}
    </SettingsContent>
  );
};

export default observer(GeneralSetting);
