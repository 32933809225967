import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';

import PasswordProtectedModal from '@components/passwordProtectedModal';
import Editor from './Editor';
import View from './View';
import { newError } from '@/services/errors/errors';

import { useUser } from '@/layout';
import { useNavigate, useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';
import { redirectToAccountUILogin } from '@/services/account/account.service';
import useProcess from '@hooks/useProcess';

const DraftEditor = () => {
  const processId = useParams()[ParamsList.ProcessId] as string;

  const { process, passwordProtectedPage, error } = useProcess();

  const { user, loadingUser } = useUser();
  const currentProcessId = useParams()[ParamsList.ProcessId];

  const navigate = useNavigate();
  const [bpmnMode, setBpmnMode] = useState<'view' | 'edit'>();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (passwordProtectedPage) {
      setShowModal(true);
      return;
    }
    if (error) {
      newError(error, true, {
        customMessage: `Error while loading the process : process doesn't exist or you don't have the access`
      });
      setTimeout(() => {
        !user ? redirectToAccountUILogin() : navigate('/');
      }, 3000);
    }

    if (process?.loading || loadingUser) return;
    const fetchProcess = () => {
      const isProtected = process?.password_protected;
      const hasRole = process?.user_role;
      const canEdit = process?.can_edit;

      if (!user || (user && !hasRole)) {
        if (!isProtected) {
          setBpmnMode('view');
        } else {
          setBpmnMode('view');
        }
      } else {
        // connected : check his role
        if (hasRole) {
          if (canEdit) {
            setBpmnMode('edit');
          } else {
            setBpmnMode('view');
          }
        }
      }
    };

    if (process) {
      fetchProcess();
    }
  }, [process, user, navigate, passwordProtectedPage, loadingUser, error]);
  return (
    !loadingUser && (
      <>
        {showModal && currentProcessId ? (
          <PasswordProtectedModal
            closeModal={() => {
              setShowModal(false);
              setBpmnMode('view');
            }}
            processId={currentProcessId}
          />
        ) : bpmnMode === 'edit' ? (
          <Editor />
        ) : bpmnMode === 'view' ? (
          <View processId={processId} user={user} />
        ) : (
          <></>
        )}
      </>
    )
  );
};

export default observer(DraftEditor);
