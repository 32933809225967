import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Viewer from 'bpmn-js/lib/Viewer';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import { Outlet } from 'react-router-dom';

import Canvas from 'diagram-js/lib/core/Canvas';

import {
  BpmnViewer,
  HeaderSection,
  HeaderWrapper,
  LoaderBox,
  LoaderContainer,
  NavbarEditorSection
} from './editor.style';
import RootStore from '@/mobx/store/root.store';
import useStores from '@hooks/useStore';
import { newError } from '@/services/errors/errors';
import NavigatedViewer from 'bpmn-js/lib/NavigatedViewer';
import { CircularProgress, Typography } from '@mui/joy';
import { User } from '@/services/account/account.service';
import Logo from '../../components/library/logo/logo.comp';
import { useTheme } from 'styled-components';
import ExportButton from '../../components/export';

export let IMPORT_STATUS: 'IMPORTING' | 'IMPORTED' = 'IMPORTING';

interface ViewProps {
  processId: string;
  user: User;
}
const View = ({ processId, user }: ViewProps) => {
  const theme = useTheme();
  const viewerRef = useRef<HTMLDivElement>(null);
  const { processStore } = useStores();

  const process = processStore.get(processId);
  const [viewerMode, setViewerMode] = useState<Viewer>();
  const rootStore: RootStore = useStores();

  const loadViewer = () => {
    const newViewer = new NavigatedViewer({
      container: viewerRef.current ?? undefined
    });

    setViewerMode(newViewer);
    document.getElementsByClassName('bjs-powered-by')[0] &&
      document.getElementsByClassName('bjs-powered-by')[0].remove();
  };

  useEffect(() => {
    loadViewer();
  }, []);

  useEffect(() => {
    if (!process || !!process.loading || !process.bpmnWorkflow) return;
    if (!viewerMode) {
      return;
    }

    viewerMode
      .importXML(process.bpmnWorkflow)
      .then(({ warnings }) => {
        IMPORT_STATUS = 'IMPORTED';
        if (warnings.length) {
          console.warn(warnings);
        }

        const canvas = viewerMode.get<Canvas>('canvas');
        canvas.zoom('fit-viewport');
      })
      .catch((error) => {
        newError(error, true, {
          customMessage: 'Error: process XML corrupted'
        });
      });
  }, [viewerMode, process, rootStore]);

  return (
    <>
      {!user && (
        <HeaderSection>
          <HeaderWrapper>
            <Logo />
            <NavbarEditorSection>
              <Typography level="title-lg" textColor={theme.color.grey[6]}>
                /
              </Typography>
              {process?.name}
            </NavbarEditorSection>
          </HeaderWrapper>
        </HeaderSection>
      )}
      {viewerMode && process && (
        <ExportButton modeler={viewerMode} processName={process.name} />
      )}
      <BpmnViewer ref={viewerRef} />
      <Outlet />
      {process?.loading && (
        <LoaderContainer>
          <LoaderBox>
            <CircularProgress />
          </LoaderBox>
        </LoaderContainer>
      )}
    </>
  );
};

export default observer(View);
