import { TitleInputSchema } from '@/utils/zod';
import { AtomReferenceSchema } from '@components/stateMenu/stataMenu.schema';
import { z } from 'zod';

export const TO_USER_VIEW_TYPES = ['Avatar', 'Activity', 'Text'] as const;
export const TO_UserViewTypeSchema = z.enum(TO_USER_VIEW_TYPES);

export const TO_UserDataSchema = (valueValidation?: boolean) =>
  z
    .object({
      title: valueValidation ? TitleInputSchema : z.string(),
      selectedVariable: AtomReferenceSchema,
      viewType: TO_UserViewTypeSchema
    })
    .strict();

export type TO_UserData = z.infer<ReturnType<typeof TO_UserDataSchema>>;

//? selectedVariable is set in the component on spawn using the selectedVariableRef from the block datInfo
export const initialTOUserData: Omit<TO_UserData, 'selectedVariable'> = {
  title: 'Default User Title',
  viewType: 'Avatar'
};
