import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { FormControl, FormLabel, Radio, RadioGroup, Textarea } from '@mui/joy';
import { LucideIcon, Variable } from 'lucide-react';
import BlockBase from '@/components/dnd/base/blockBase';
import EndControlsFB from '@components/dnd/library/formBuilder/endControls';
import { InputField } from '@atoms/input';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { DNDBlock } from '@components/dnd/base';
import {
  isCorrectTypeKind as isCorrectKindOfType,
  kindToLabelMap,
  primaryTypeToInfoMap
} from './globalVariable.utils';
import {
  ALL_TYPE_KIND,
  AllKindOfType,
  initialGlobalVariableData,
  PrimaryType
} from './globalVariable.data';
import { useAtom } from '@hooks/useAtom';
import useProcess from '@hooks/useProcess';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { TypeSelector } from './typeSelector';
import { newError } from '@/services/errors/errors';
import { StaticType } from '@/mobx/models/code/types';

export const GlobalVariableIcon: LucideIcon = Variable;

export const GlobalVariable = observer((dndBlock: DNDBlock) => {
  const { process } = useProcess();
  const workflow = process?.workflows[0];
  if (!workflow) return null;
  const atom = useAtom({
    block: dndBlock,
    dataType: 'globalVariable',
    initialData: initialGlobalVariableData,
    library: 'globalVariables',
    sourceId: workflow.id,
    sourceName: 'Workflow',
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!atom) return null;

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    atom.data.title = event.target.value;
  };

  const onKindOfTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isCorrectKindOfType(e.target.value)) {
      newError(`Unknown kind: ${e.target.value}`, true);
      return;
    }

    atom.data.variableType.selectedKind = e.target.value;

    reCalculateResolvedType(
      atom.data.variableType.selectedKind,
      atom.data.variableType.type
    );
  };

  const getSettingsModal = () => {
    return {
      title: atom.data.title ?? 'Global variable',
      content: <></>
    };
  };

  const onTypeChange = (type: PrimaryType): void => {
    const kind = atom.data.variableType.selectedKind;
    if (kind === 'custom' || !atom.variableInfo) return;

    atom.data.variableType.type = type;

    reCalculateResolvedType(kind, type);
  };

  const reCalculateResolvedType = (
    _kind?: AllKindOfType,
    _type?: PrimaryType
  ): void => {
    if (!atom.variableInfo?.resolvedType) return;
    const { selectedKind, type: variableType } = atom.data.variableType;
    const kind = _kind ?? selectedKind;
    const type = _type ?? variableType;

    switch (kind) {
      case 'custom':
        atom.variableInfo.resolvedType = ResolvedVariableType.Custom;
        break;
      case 'normal':
        atom.variableInfo.resolvedType =
          primaryTypeToInfoMap[type].resolvedType;
        break;
      case 'list': {
        if (type === StaticType.FormFile) {
          atom.variableInfo.resolvedType =
            primaryTypeToInfoMap[StaticType.FormFile].resolvedType; // this is really bad
          break;
        }
        atom.variableInfo.resolvedType = ResolvedVariableType.List;
        break;
      }
      default:
        newError(`Unknown kind: ${JSON.stringify(kind)}`, true);
    }
  };

  const onCustomTypeChange = (customType: string): void => {
    if (!atom.variableInfo?.resolvedType) return;
    atom.data.variableType.customType = customType;
    atom.variableInfo.resolvedType = ResolvedVariableType.Custom;
  };

  return (
    <BlockBase
      dndBlock={dndBlock}
      title={atom.data.title}
      dataItem={atom}
      icon={GlobalVariableIcon}
      endControls={
        <EndControlsFB
          hasRequired={false}
          dndBlock={dndBlock}
          dataItem={atom}
          settings={getSettingsModal()}
        />
      }
    >
      <Column $width="200px">
        <InputField
          required
          label="Variable title"
          value={atom.data.title}
          onChange={onTitleChange}
          placeholder="Aa"
        />
      </Column>
      <Column $width="100px">
        <FormControl size="sm">
          <FormLabel>Kind</FormLabel>
          <RadioGroup
            value={atom.data.variableType.selectedKind}
            name="radio-buttons-group"
          >
            {ALL_TYPE_KIND.map((kind) => (
              <Radio
                key={kind}
                value={kind}
                onChange={onKindOfTypeChange}
                label={kindToLabelMap[kind]}
                color="neutral"
                size="sm"
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Column>
      <TypeSelector
        {...atom.data.variableType}
        onTypeChange={onTypeChange}
        onCustomTypeChange={onCustomTypeChange}
      />
      <Column $width="100%">
        <FormControl size="sm">
          <FormLabel>Description</FormLabel>
          <Textarea
            minRows={2}
            onChange={(e) => {
              atom.data.description = e.target.value;
            }}
            placeholder={`${
              atom.data.title
                ? `The variable "${atom.traceKey.value}"`
                : 'This variable'
            } represents...`}
            value={atom.data.description}
          />
        </FormControl>
      </Column>
    </BlockBase>
  );
});
