import { BaseActionModel } from '@/mobx/models/action.model';
import BaseStore from './base/base.store';
import RootStore from './root.store';
import { DndLibrary } from '@components/dnd/library';
import { z } from 'zod';
import { LoadedDndModel, LoadedDndModelSchema } from './dnd.store';
import { DNDModel } from '@/mobx/models/dnd.model';
import { newError } from '@/services/errors/errors';
import { parseWithZod } from '@/utils/parseZodSchema';
import { DynamicType, LoadedCodeDataSchema } from '../models/code/types';
import { arrayToUnionType } from '@/utils/typescript/arrayToUnionType';
import { EmptyActionType } from '../models/code/constants';
import { TraceKeyDTOSchema } from '../models/traceKey/schema';
import { CodeModel } from '../models/code/code.model';
interface CreateActionDTO {
  bpmn_js_id: string;
  workflow_id: string;
  name?: string;
  type: 'ACTION' | 'START' | 'END';
}

const ActionTypeSchema = z.enum(['ACTION', 'START', 'END']);

export type ActionType = z.infer<typeof ActionTypeSchema>;

export const ActionLoadedSchema = z
  .object({
    id: z.string(),
    created_at: z.string().datetime(),
    updated_at: z.string().datetime(),
    type: ActionTypeSchema,
    metadata: z.object({
      name: z.string().optional(),
      bpmn_js_id: z.string().optional()
    }),
    calculated: z.unknown(),
    generation_id: z.number(),
    workflowId: z.string(),

    uiDndId: z.string().optional(),
    codeDndId: z.string().optional(),
    effectsDndId: z.string().optional(),
    customCodeId: z.string().optional(),

    ui: LoadedDndModelSchema,
    code: LoadedDndModelSchema,
    effects: LoadedDndModelSchema,
    generated_code: z.null().optional(),

    traceKey: TraceKeyDTOSchema,
    customCode: LoadedCodeDataSchema
  })
  .strict();

export type ActionLoaded = z.infer<typeof ActionLoadedSchema>;

export default class ActionStore extends BaseStore<BaseActionModel> {
  constructor(rootStore: RootStore) {
    super(rootStore, BaseActionModel, 'action');
    this.store_ready = true;
  }

  // this technical dept, gateway should have their own store / model
  public async createGateway(
    bpmnGatewayId: string,
    workflowId: string,
    type: 'START' | 'END'
  ) {
    const dto: CreateActionDTO = {
      bpmn_js_id: bpmnGatewayId,
      workflow_id: workflowId,
      type
    };

    const rawResponse = await this.httpWrapper.post<ActionLoaded>('/', dto);
    if (!rawResponse) {
      newError(
        `Error while creating new action with dto: 
      ${JSON.stringify(dto)}`,
        true,
        {
          customMessage: 'Error while creating new action'
        }
      );
    }

    const newGatewayData = parseWithZod(ActionLoadedSchema, rawResponse);
    if (!newGatewayData) return;
    return this.addLoadedActionToStore(newGatewayData);
  }

  public async createNewAction(
    bpmnActionId: string,
    workflowId: string
  ): Promise<Maybe<BaseActionModel>> {
    const dto: CreateActionDTO = {
      bpmn_js_id: bpmnActionId,
      workflow_id: workflowId,
      name: BaseActionModel.DEFAULT_ACTION_NAME,
      type: 'ACTION'
    };

    const rawResponse = await this.httpWrapper.post<ActionLoaded>('/', dto);
    if (!rawResponse) {
      newError(
        `Error while creating new action with dto: 
      ${JSON.stringify(dto)}`,
        true,
        {
          customMessage: 'Error while creating new action'
        }
      );
      // TODO delete the created action in the BPMN schema
      return;
    }

    const newActionData = parseWithZod(ActionLoadedSchema, rawResponse);
    if (!newActionData) return;
    return this.addLoadedActionToStore(newActionData);
  }

  public addLoadedActionToStore(actionLoaded: ActionLoaded): BaseActionModel {
    /* ------------------------ Handle drag & drop models ----------------------- */

    this.addActionDnd(DndLibrary.FormBuilder, actionLoaded.ui);
    this.addActionDnd(DndLibrary.CodeEditor, actionLoaded.code);
    this.addActionDnd(DndLibrary.EffectsBuilder, actionLoaded.effects);

    const newAction = new BaseActionModel(
      this,
      actionLoaded.id,
      actionLoaded.traceKey,
      actionLoaded.type,
      actionLoaded.workflowId,
      actionLoaded.ui.id,
      actionLoaded.code.id,
      actionLoaded.effects.id,
      actionLoaded.metadata.name,
      actionLoaded.customCode.id
    );

    this.set(newAction.id, newAction);

    const codeParent: CodeModel['parent'] = {
      type: 'action',
      id: actionLoaded.id
    };
    this.rootStore.codeStore.addLoadedCodeToStore(
      actionLoaded.customCode,
      codeParent
    );

    return newAction;
  }

  private addActionDnd = (
    library: DndLibrary,
    loadedDnd: LoadedDndModel
  ): DNDModel => {
    return this.rootStore.dndStore.createDndFromLoaded(library, loadedDnd);
  };

  public async deleteById(actionId: string): Promise<boolean> {
    const actionToDelete: Maybe<BaseActionModel> = this.get(actionId);

    if (!actionToDelete) {
      newError(`No action found with the id: ${actionId}`, true, {
        customMessage: 'Error while deleting the action'
      });
      return false;
    }

    return await actionToDelete.delete();
  }

  public getActionsTypeScriptType(): string {
    const actionKeys = this.getAllActions().map(
      (action) => action.traceKey.value
    );
    if (actionKeys.length === 0) return EmptyActionType;
    return `type ${DynamicType.Action} = ${arrayToUnionType(actionKeys)};`;
  }

  public getAllActions(): BaseActionModel[] {
    return Array.from(this.data.values()).filter(
      (action) => action.actionType === 'ACTION'
    );
  }
}
