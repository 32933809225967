import { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { FormLabel, FormControl } from '@mui/joy';

import { InputField } from '@atoms/input';
import { SwitchField } from '@atoms/switch';
import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import EndControlsFB, {
  SettingsConfig
} from '@components/dnd/library/formBuilder/endControls';
import { FbBlocks } from '@components/dnd/library/formBuilder/formBuilder.type';

import { useAtom } from '@hooks/useAtom';
import { initialRichTextData } from './richText.data';
import { RichTextIcon } from './richText.utils';
import { ParamsList } from '@/routes/routes.types';
import { useParams } from 'react-router-dom';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { RichTextModal } from './richText.modal';

const RichText = (block: DNDBlock) => {
  const actionId = useParams()[ParamsList.ActionId];
  const dataItem = useAtom({
    block,
    initialData: initialRichTextData,
    dataType: 'variable',
    sourceId: actionId,
    sourceName: 'Action',
    library: 'ui',
    variableInfo: {
      resolvedType: ResolvedVariableType.Text
    }
  });

  if (!dataItem) return null;

  const getSettingsModal = (): SettingsConfig => {
    return {
      title: dataItem.data.title || `${FbBlocks.RichText} title`,
      content: <RichTextModal atom={dataItem} />
    };
  };

  const getEndControls = () => (
    <EndControlsFB
      dndBlock={block}
      dataItem={dataItem}
      settings={getSettingsModal()}
      hasRequired
    ></EndControlsFB>
  );

  return (
    <BlockBase
      dndBlock={block}
      title={dataItem.data.title}
      dataItem={dataItem}
      icon={RichTextIcon}
      endControls={getEndControls()}
    >
      <Column>
        <InputField
          label="Field title"
          placeholder="Aa"
          required
          value={dataItem.data.title}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.title = e.target.value;
          }}
        />
      </Column>

      <Column>
        <InputField
          label="Placeholder"
          placeholder="Aa"
          value={dataItem.data.placeholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            dataItem.data.placeholder = e.target.value;
          }}
        />
      </Column>

      <Column $gap="0">
        <FormControl size="sm">
          <FormLabel>Additional features</FormLabel>
        </FormControl>
        <SwitchField
          label="Mentions"
          checked={dataItem.data.withMention}
          onChange={(e) => {
            dataItem.data.withMention = e.target.checked;
          }}
        />
      </Column>
    </BlockBase>
  );
};

export default observer(RichText);
