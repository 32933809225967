import { makeObservable, observable } from 'mobx';
import Model, { ModelError } from './base/base.model';
import BaseStore from '@/mobx/store/base/base.store';
import { DNDModel } from './dnd.model';
import { BaseActionModel } from './action.model';
import { newError } from '@/services/errors/errors';
import { DndLibrary } from '@components/dnd/library';
import { AtomModel } from './atom.model';
import { GlobalVariableData } from '@components/dnd/library/globalVariables/blocks/globalVariableBlock/globalVariable.data';

export class WorkflowModel extends Model {
  constructor(
    store: BaseStore<WorkflowModel>,
    id: Model['id'],
    public published_id: string,
    public traceInfoDndId: DNDModel['id'],
    public traceOverviewDndId: DNDModel['id'],
    public globalVariablesDndId: DNDModel['id'],
    public actionIds: BaseActionModel['id'][] = [],
    public name: string = 'Workflow default name',
    loading?: boolean
  ) {
    super(store, id, loading);

    makeObservable(this, {
      name: observable,
      published_id: observable
    });
  }

  get traceInfoDnd(): Maybe<DNDModel<DndLibrary.TraceInfo>> {
    return this.store.rootStore.dndStore.get(this.traceInfoDndId) as Maybe<
      DNDModel<DndLibrary.TraceInfo>
    >;
  }

  get traceOverviewDnd(): Maybe<DNDModel<DndLibrary.TraceOverview>> {
    return this.store.rootStore.dndStore.get(this.traceOverviewDndId) as Maybe<
      DNDModel<DndLibrary.TraceOverview>
    >;
  }

  get globalVariablesDnd(): Maybe<DNDModel<DndLibrary.GlobalVariables>> {
    return this.store.rootStore.dndStore.get(
      this.globalVariablesDndId
    ) as Maybe<DNDModel<DndLibrary.GlobalVariables>>;
  }

  get globalVariableAtoms(): AtomModel<GlobalVariableData>[] {
    const atomStore = this.store.rootStore.atomStore;
    const globalVariables = atomStore
      .toArray()
      .filter(
        (atom) =>
          atom.metaInfo.source.library === 'globalVariables' &&
          atom.metaInfo.source.id === this.id
      ) as AtomModel<GlobalVariableData>[];
    return globalVariables;
  }

  get actions(): Maybe<BaseActionModel>[] {
    return this.actionIds.map((actionId) => {
      const action = this.store.rootStore.actionStore.get(actionId);
      if (!action) {
        newError(
          `Workflow Model: Action not found in store for actionId ${actionId}}`
        );
      }
      return action;
    });
  }

  get toJSON() {
    return {};
  }

  get errors(): ModelError[] {
    return [];
  }
}
