import { DNDBlock } from '@components/dnd/base';
import BlockBase from '@components/dnd/base/blockBase';
import { LucideIcon, AlignJustify } from 'lucide-react';
import EndControls from '../endControls';
import { Column } from '@components/dnd/base/blockBase/body.block.style';
import { SelectField } from '@atoms/select';
import { Autocomplete, FormControl, Option } from '@mui/joy';
import { operatorOptions } from '../utils';
import { observer } from 'mobx-react';
import VariableField from '@atoms/variableField';
import useStores from '@hooks/useStore';
import { Suggestion, SuggestionSource } from '../condition.type';
import { MultiSelectField } from '@atoms/multiSelect';
import { InputField } from '@atoms/input';
import { newError } from '@/services/errors/errors';
import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { useAtom } from '@hooks/useAtom';
import {
  Operator,
  OperatorSchema,
  initialConditionData
} from './condition.data';
import { DataItemReference } from '@components/stateMenu/stataMenu.schema';
import { AtomModel } from '@/mobx/models/atom.model';
import { isValidType } from '@/utils/parseZodSchema';
import { useParams } from 'react-router-dom';
import { ParamsList } from '@/routes/routes.types';

export const ConditionIcon: LucideIcon = AlignJustify;

const Condition = (block: DNDBlock) => {
  let selectedVariable: Maybe<AtomModel<{ title: string }>>;

  const { atomStore } = useStores();

  const transitionId = useParams()[ParamsList.TransitionId];

  const dataItem = useAtom({
    block,
    initialData: initialConditionData,
    dataType: 'condition',
    sourceId: transitionId,
    sourceName: 'Transition',
    library: 'condition'
  });

  if (!dataItem) return null;

  if (dataItem.data.selectedVariable) {
    const tmpSelectedVariable = atomStore.getAtomById(
      dataItem.data.selectedVariable.dataItemId,
      dataItem.data.selectedVariable.blockType
    );

    if (tmpSelectedVariable instanceof Error) {
      newError("Couldn't find selected variable", false);
    } else {
      selectedVariable = tmpSelectedVariable as Maybe<
        AtomModel<{ title: string }>
      >;
    }
  }

  const initializeCondition = (
    resolvedVariableType: Maybe<ResolvedVariableType>
  ) => {
    if (!dataItem?.data) return;
    if (!resolvedVariableType) return;
    dataItem.data.condition = {
      value: null,
      operator: operatorOptions[resolvedVariableType][0].value
    };
  };

  const getSuggestionFromDataRepos = () => {
    const dataReposSuggestions: Suggestion[] = [];

    const allDataRepositories = atomStore.getAllDataRepositories();

    allDataRepositories.forEach((repo) => {
      const repoValues = repo.data.values;

      repoValues.forEach((value) => {
        const newSuggestion: Suggestion = {
          label: value.name,
          groupeBy: repo.data.title,
          source: SuggestionSource.DataRepository
        };
        dataReposSuggestions.push(newSuggestion);
      });
    });

    return dataReposSuggestions;
  };

  const handleSuggestions = () => {
    if (!dataItem) return;

    if (!dataItem.data.condition) {
      initializeCondition(selectedVariable?.variableInfo?.resolvedType);
    }

    if (!selectedVariable) return;
    const variableSuggestions = selectedVariable.possibleValues.map(
      (possibleValue) => {
        return {
          label: possibleValue,
          groupeBy: `${selectedVariable?.data.title} - data source`,
          source: SuggestionSource.Variable
        };
      }
    );

    const newSuggestions: Suggestion[] = [];

    newSuggestions.push(...variableSuggestions);

    const dataReposSuggestions = getSuggestionFromDataRepos();
    newSuggestions.push(...dataReposSuggestions);

    return newSuggestions;
  };

  const suggestions: Suggestion[] = handleSuggestions() ?? [];

  if (dataItem.data.condition && !dataItem.data.condition.operator) {
    const resolvedType = selectedVariable?.variableInfo?.resolvedType;
    if (!resolvedType) return;
    dataItem.data.condition.operator = operatorOptions[resolvedType][0].value;
  }

  const onVariableSelected = (seletedVariableReference: DataItemReference) => {
    if (!seletedVariableReference) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    dataItem.data.selectedVariable = seletedVariableReference;
    const selectedVariable = atomStore.getAtomById_Unsafe(
      seletedVariableReference.dataItemId
    );

    if (!selectedVariable) {
      newError('onVariableSelected(): selectedVariable is undefined');
      return;
    }

    selectedVariable.addReferencingAtom(block.atomId);
  };

  const onRightSideValueChange = (value: string) => {
    if (!dataItem.data.condition) {
      newError('dataItem.data.condition is undefined');
      return;
    }
    dataItem.data.condition.value = value;
  };

  const onOperatorChange = (value: string | null): void => {
    if (!isValidType(OperatorSchema, value)) {
      newError(`onOperatorChange(): invalid operator "${value}"`);
      return;
    }

    if (!dataItem.data.condition) {
      newError('dataItem.data.condition is undefined');
      return;
    }

    dataItem.data.condition.operator = value;
  };

  const renderOperatorSelect = (computedType: ResolvedVariableType) => {
    return (
      <Column $width="auto" $gap="0px">
        <SelectField
          label=""
          disabled={!dataItem?.data.selectedVariable?.dataItemId}
          value={
            dataItem.data.condition?.operator ??
            operatorOptions[computedType][0]?.value
          }
          sx={{ minWidth: '50px' }}
          onChange={(_, value) => onOperatorChange(value)}
        >
          {operatorOptions[computedType]?.map(({ value, label }, index) => (
            <Option key={index} value={value}>
              {label}
            </Option>
          ))}
        </SelectField>
      </Column>
    );
  };

  const renderAutocompleteInput = () => {
    return (
      <Column $width="200px" $gap="0px">
        <FormControl size="sm" disabled={!dataItem.data.condition?.operator}>
          <Autocomplete
            freeSolo
            placeholder="Aa"
            options={suggestions}
            getOptionLabel={(option: string | Suggestion) =>
              typeof option !== 'string' ? option.label : option
            }
            groupBy={(option) => option.groupeBy}
            inputValue={
              typeof dataItem.data.condition?.value === 'string'
                ? dataItem.data.condition.value
                : ''
            }
            onInputChange={(_, value) => onRightSideValueChange(value)}
          />
        </FormControl>
      </Column>
    );
  };

  const renderMultiSelect = () => {
    return (
      <Column $width="200px" $gap="0px">
        <MultiSelectField
          onChange={(_, selectedValues) => {
            if (!dataItem.data.condition) return;
            dataItem.data.condition.value = selectedValues;
          }}
          value={(dataItem.data.condition?.value as string[]) ?? []}
        >
          {suggestions
            .filter((s) => s.source === SuggestionSource.Variable)
            .map((option, index) => (
              <Option key={index} value={option.label}>
                {option.label}
              </Option>
            ))}
        </MultiSelectField>
      </Column>
    );
  };

  const renderNumberInput = () => {
    return (
      <Column $width="200px" $gap="0px">
        <InputField
          placeholder={`ex: ${Math.floor(Math.random() * 100) + 1}`}
          type="number"
          value={
            typeof dataItem.data.condition?.value === 'string'
              ? dataItem.data.condition.value
              : ''
          }
          onChange={(event) => {
            onRightSideValueChange(event.target.value);
          }}
        ></InputField>
      </Column>
    );
  };

  const renderRightSideValueSelect = (
    computedType: ResolvedVariableType,
    operator: Maybe<Operator>
  ) => {
    if (!operator) return <></>;

    switch (operator) {
      case 'allOf':
      case 'oneOf':
      case 'noneOf':
        return renderMultiSelect();
      case 'defined':
      case 'isTrue':
      case 'isFalse':
        return <></>;
      default: {
        if (computedType === ResolvedVariableType.Number) {
          return renderNumberInput();
        }
        return renderAutocompleteInput();
      }
    }
  };

  const renderRightSide = () => {
    const resolvedVariableType: Maybe<ResolvedVariableType> =
      selectedVariable?.variableInfo?.resolvedType;
    if (!resolvedVariableType) {
      newError(
        `renderRightSide(): resolvedVariableType is undefined for variable id: "${dataItem.data.selectedVariable?.dataItemId}"`
      );
      return;
    }

    return (
      <>
        {renderOperatorSelect(resolvedVariableType)}
        {renderRightSideValueSelect(
          resolvedVariableType,
          dataItem.data.condition?.operator
        )}
      </>
    );
  };

  return (
    <BlockBase
      dndBlock={block}
      isCondition
      icon={ConditionIcon}
      endControls={<EndControls dndBlock={block} />}
      hasTitle={false}
    >
      <Column $width="200px" $gap="0px">
        <FormControl size="sm">
          <VariableField
            onSelected={onVariableSelected}
            value={selectedVariable?.data?.title}
          />
        </FormControl>
      </Column>

      {selectedVariable && renderRightSide()}
    </BlockBase>
  );
};

export default observer(Condition);
