import { ChipLabel } from '@components/library/chipLabel';
import styled from 'styled-components';

type StateMenuProps = {
  $isSelected?: boolean;
  $onHover: boolean;
};

export const StateMenuRoot = styled.div`
  min-width: 600px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.color.grey[6]};
  font-size: 12px;
  box-shadow: ${({ theme }) => theme.boxShadow.indigo01};
  overflow: hidden;
  backdrop-filter: blur(25px) saturate(190%) contrast(50%) brightness(130%);
`;
export const StateMenuSearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  height: 30px;
`;
export const StateMenuChipType = styled(ChipLabel)`
  gap: 10px;
  border-radius: 3px;

  svg {
    cursor: pointer;
  }
`;

export const StateMenuInput = styled.input`
  outline: none;
  border: none;
  background: transparent;
  width: 100%;
`;

export const StateMenuList = styled.div`
  padding-top: 0px;
  overflow: auto;
  flex: 1;
  overflow: auto;
`;

export const StateMenuBody = styled.div`
  display: flex;
  height: 300px;
  border-top: 2px solid ${({ theme }) => theme.color.grey[6]};
  border-bottom: 2px solid ${({ theme }) => theme.color.grey[6]};
  overflow: auto;
`;

export const StateMenuResults = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > p {
    text-align: center;
    padding: 10px;
    font-size: 14px;
  }
`;

export const StateMenuItem = styled.div<StateMenuProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: ${({ $onHover }) => ($onHover ? '#FAFAFA' : 'inehrit')};
  border-bottom: 2px solid #f5f5f5;
  font-size: 14px;
  cursor: pointer;

  & > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const StateMenuTitle = styled.h2`
  color: ${({ theme }) => theme.color.grey[1]};
  font-size: 12px;
  padding: 10px;
  font-weight: 600;
`;

export const StateMenuRightColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-left: 2px solid ${({ theme }) => theme.color.grey[6]};
  flex: 4;
  overflow: hidden;

  & > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const SideBarItem = styled.div<StateMenuProps>`
  padding: 5px 10px;
  margin-top: 3px;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${({ theme, $isSelected, $onHover }) =>
    $isSelected
      ? theme.color.grey[5]
      : $onHover
      ? theme.color.grey[6]
      : 'inherit'};

  &:hover {
    ${({ theme, $isSelected }) =>
      $isSelected ? '' : `background-color: ${theme.color.grey[6]};`}
  }
`;
export const StateMenuLeftColumn = styled.div`
  position: relative;
  flex: auto;
  overflow: hidden;
  margin: 0 5px 0 5px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StateMenuVar = styled.div<StateMenuProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid ${({ theme }) => theme.color.grey[7]};
  cursor: pointer;
  background-color: ${({ theme, $onHover }) =>
    $onHover ? theme.color.grey[8] : 'inehrit'};

  &:hover {
    background-color: ${({ theme }) => theme.color.grey[8]};
  }

  div {
    font-size: 10px;
  }
`;

export const StateMenuFooter = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  font-family: 'JetBrains Mono';
  font-size: 10px;
  padding: 10px;
  gap: 25px;
`;

export const StateMenuNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.color.grey[2]};
  font-size: 12px;
`;

export const StateMenuMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 75%;
  text-align: center;
  margin: auto;
  color: ${({ theme }) => theme.color.grey[2]};
`;
