import { observer } from 'mobx-react';
import DNDBase from '@components/dnd/base';
import useStores from '@/hooks/useStore';
import useAction from '@hooks/useAction';

const FormBuilder = () => {
  const { dndStore } = useStores();
  const action = useAction();
  const formBuilderDnd = dndStore.get(action?.formDndId);
  if (!formBuilderDnd) {
    return <></>;
  }

  return <DNDBase dnd={formBuilderDnd} hasToolbar />;
};

export default observer(FormBuilder);
