import { FC } from 'react';
import { DNDBlock } from '@/components/dnd/base';
import { DNDInfo } from '../library.types';
import {
  DataTab,
  Date,
  FileUpload,
  List,
  Number,
  Text,
  Window,
  Comment
} from './blocks';

export enum TIBlocks {
  DataTab = 'TI_DataTab',
  Window = 'TI_Window',
  File = 'TI_File',
  Date = 'TI_Date',
  List = 'TI_List',
  Text = 'TI_Text',
  Number = 'TI_Number',
  Comment = 'TI_Comment'
}

export const isTIBlocks = (blockType: unknown): blockType is TIBlocks => {
  return Object.values(TIBlocks).includes(blockType as TIBlocks);
};

export const TI_INFO_DND: DNDInfo = {
  title: 'Trace Info',
  description:
    'Add a window to configure the panels present in the Trace inspector. You can nest windows together.'
};

const TraceInfoPlexer = {
  [TIBlocks.File]: FileUpload,
  [TIBlocks.Date]: Date,
  [TIBlocks.DataTab]: DataTab,
  [TIBlocks.List]: List,
  [TIBlocks.Window]: Window,
  [TIBlocks.Text]: Text,
  [TIBlocks.Number]: Number,
  [TIBlocks.Comment]: Comment
};

interface LibraryTraceInfoProps {
  block: DNDBlock;
}

export const LibraryTI: FC<LibraryTraceInfoProps> = ({ block }) => {
  const Block = TraceInfoPlexer[block.type as TIBlocks];
  return <Block {...block} />;
};
