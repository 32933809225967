import { FC, Fragment } from 'react';
import { DNDBlock } from '../base';
import { InteractionZone } from '../base/interaction';
import { DNDInfo, LibraryProps, ToolbarItem } from './library.types';
import { FB_INFO_DND, FB_TOOLBAR_ITEMS, LibraryFB } from './formBuilder';
import {
  LibraryNF,
  NFBlocks,
  NF_INFO_DND,
  NOTIF_TOOLBAR_ITEMS
} from './notification';
import { LibraryTI, TIBlocks, TI_INFO_DND } from './traceInfo';
import { LibraryTO, TOBlocks, TO_INFO_DND } from './traceOverview';
import {
  LibraryGlobalVariable,
  GlobalVariablesBlocks,
  GLOBAL_VARIABLES_INFO_DND
} from './globalVariables';
import {
  CONDITION_INFO_DND,
  CDT_TOOLBAR_ITEMS,
  LibraryTransition
} from './condition';
import { DTRBlocks, DTR_INFO_DND, LibraryDTR } from './dataRepository';
import nanoID from '@/utils/nanoID';
import toCamelCase from '@/utils/toCamelCase';
import { ConditionBlocks } from './condition/condition.type';
import { FbBlocks } from './formBuilder/formBuilder.type';
import { allDataSchemasMap } from '@/mobx/types/atom.types';

export const enum DndLibrary {
  /* ------------------------------- Action DNDs ------------------------------ */
  FormBuilder = 'FormBuilder',
  EffectsBuilder = 'EffectsBuilder',
  /** @deprecated */
  CodeEditor = 'CodeEditor',

  /* ------------------------------ Settings DNDs ----------------------------- */
  TraceOverview = 'TraceOverview',
  TraceInfo = 'TraceInfo',
  DataRepository = 'DataRepository',

  /* ----------------------------- Transition DNDs ---------------------------- */
  Condition = 'Condition',
  Notification = 'Notification',
  Priorities = 'Priorities',
  GlobalVariables = 'GlobalVariables'
}

const LIB_TO_ITEMS_MAP: Record<DndLibrary, ToolbarItem[]> = {
  [DndLibrary.FormBuilder]: FB_TOOLBAR_ITEMS,
  [DndLibrary.EffectsBuilder]: [],
  [DndLibrary.CodeEditor]: [],
  [DndLibrary.TraceInfo]: [],
  [DndLibrary.TraceOverview]: [],
  [DndLibrary.Condition]: CDT_TOOLBAR_ITEMS,
  [DndLibrary.DataRepository]: [],
  [DndLibrary.Notification]: NOTIF_TOOLBAR_ITEMS,
  [DndLibrary.Priorities]: [],
  [DndLibrary.GlobalVariables]: []
};

const LIB_TO_LIBRARY_MAP: Record<DndLibrary, FC<LibraryProps>> = {
  [DndLibrary.FormBuilder]: LibraryFB,
  [DndLibrary.EffectsBuilder]: () => <p>EffectsBuilder</p>,
  [DndLibrary.CodeEditor]: () => <p>CodeEditor</p>,
  [DndLibrary.TraceInfo]: LibraryTI,
  [DndLibrary.TraceOverview]: LibraryTO,
  [DndLibrary.Condition]: LibraryTransition,
  [DndLibrary.DataRepository]: LibraryDTR,
  [DndLibrary.Notification]: LibraryNF,
  [DndLibrary.Priorities]: () => <p>Priorities</p>,
  [DndLibrary.GlobalVariables]: LibraryGlobalVariable
};

export const LIB_TO_INFO_MAP: Record<DndLibrary, DNDInfo> = {
  [DndLibrary.FormBuilder]: FB_INFO_DND,
  [DndLibrary.EffectsBuilder]: { title: '', description: '' },
  [DndLibrary.CodeEditor]: { title: '', description: '' },
  [DndLibrary.TraceInfo]: TI_INFO_DND,
  [DndLibrary.TraceOverview]: TO_INFO_DND,
  [DndLibrary.Condition]: CONDITION_INFO_DND,
  [DndLibrary.DataRepository]: DTR_INFO_DND,
  [DndLibrary.Notification]: NF_INFO_DND,
  [DndLibrary.Priorities]: { title: '', description: '' },
  [DndLibrary.GlobalVariables]: GLOBAL_VARIABLES_INFO_DND
};

export const ALL_BLOCKS = [
  ...Object.values(FbBlocks),
  ...Object.values(TOBlocks),
  ...Object.values(TIBlocks),
  ...Object.values(ConditionBlocks),
  ...Object.values(DTRBlocks),
  ...Object.values(NFBlocks),
  ...Object.values(GlobalVariablesBlocks)
];

/** A type that includes the block types of all the DND libraries. */
export type AllBlocks = (typeof ALL_BLOCKS)[number] | 'root';

export type BlockTypes = keyof typeof allDataSchemasMap;

export const getToolbarItemsByLibrary = (
  library: DndLibrary
): ToolbarItem[] => {
  return LIB_TO_ITEMS_MAP[library] ?? [];
};

interface RenderLibraryBlockProps {
  library: DndLibrary;
  block: DNDBlock;
}
// general stuff for the DND rendering
const RenderLibraryBlock = ({ library, block }: RenderLibraryBlockProps) => {
  const error = "DND error: librairy doesn't exist";
  const Library = LIB_TO_LIBRARY_MAP[library] ?? <p>{error}</p>;
  if (!block) {
    console.warn('block is undefined');
    return;
  }

  return <Library block={block} />;
};

interface DNDBlockRenderProps {
  library: DndLibrary;
  block: DNDBlock | DNDBlock[];
  path: string;
  isLast?: boolean;
}

export const DNDBlockRender = ({
  library,
  block,
  path,
  isLast
}: DNDBlockRenderProps) => {
  const newDndBlocks: DNDBlock[] = !Array.isArray(block) ? [block] : block;

  const prepareSpawnedBlock = (dndBlock: DNDBlock) => {
    if (!dndBlock.blockState) {
      dndBlock.blockState = {};
    }
    if (!dndBlock.id) {
      dndBlock.id = `${library}_${toCamelCase(dndBlock.type)}_${nanoID(7)}`;
    }
  };

  newDndBlocks.forEach((dndBlock) => {
    prepareSpawnedBlock(dndBlock);
  });

  return (
    <>
      {newDndBlocks.map((dndBlock: DNDBlock, index: number) => (
        <Fragment key={index}>
          {index === 0 && path !== 'root' && (
            <InteractionZone isLast={isLast} path={`${path}|0`} dndBlock={[]} />
          )}
          <RenderLibraryBlock library={library} block={dndBlock} />
          {path !== 'root' && (
            <InteractionZone
              isLast={isLast}
              path={`${path}|${index + 1}`}
              dndBlock={[]}
            />
          )}
        </Fragment>
      ))}
    </>
  );
};
