import { ResolvedVariableType } from '@/mobx/types/atom.types';
import { Operator } from './blocks/condition.data';

export enum ConditionBlocks {
  Or = 'Or',
  And = 'And',
  Not = 'Not',
  Condition = 'Condition',
  CodeCondition = 'Code Condition'
}

export interface OperatorOption {
  label: string;
  value: Operator;
}
export type OperatorMap = Record<ResolvedVariableType, OperatorOption[]>;

export enum SuggestionSource {
  DataRepository = 'dataRepository',
  Variable = 'variable'
}

export type Suggestion = {
  label: string;
  groupeBy: string;
  source: SuggestionSource;
};
