import { useState, useEffect, useRef, ChangeEvent } from 'react';
import {
  StateMenuBody,
  StateMenuFooter,
  StateMenuNavigation,
  StateMenuRoot
} from './stateMenu.style';
import { ArrowBigDown, ArrowBigUp, CornerDownLeft } from 'lucide-react';
import ShortcutHelper from '../library/shortcut';
import { useKeyPress } from '@/hooks/useKeyPress';
import SearchBar from './SearchBar';
import SearchResult from './SearchResult';
import SideBar from './sidebar.stateMenu';
import VariableMenu from './VariableMenu';
import { useVariableData } from '@hooks/useVariables';
import { AtomModel } from '@/mobx/models/atom.model';
import { DataItemReference } from './stataMenu.schema';
import { newError } from '@/services/errors/errors';
import { ResolvedVariableType } from '@/mobx/types/atom.types';

type StateMenuProps = {
  onSelected: (variableReference: DataItemReference) => void;
  onClose?: () => void;
  isOverview?: boolean;
};

const StateMenu = ({
  onSelected,
  onClose,
  isOverview = false
}: StateMenuProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const allVariables = useVariableData();

  const allActionKeys: string[] = Array.from(allVariables.keys());
  const [searchVar, setSearchVar] = useState('');
  const [selectedParent, setSelectedParent] = useState<string | null>(null);

  const [cursor, setCursor] = useState<number>(0);
  const [hoveredVariable, setHoveredVariable] = useState<AtomModel<{
    title: string;
  }> | null>(null);
  const [hoverActionKey, setHoverActionKey] = useState<string | null>(null);
  const [isSearching, setIsSearching] = useState(false);

  const regex = new RegExp(/[$^*()+\-[\]\\,./{}|:<>?]/, 'gi');
  const searchReg = new RegExp(searchVar.replace(regex, '\\$&'), 'i'); // Escaping special characters to avoid an error

  useEffect(() => {
    if (selectedParent || isSearching) {
      setCursor(0);
    }
  }, [selectedParent, searchVar, isSearching]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [selectedParent, cursor]);

  const filteredVariables = allActionKeys.flatMap((actionKey: string) => {
    const actionVariables = allVariables.get(actionKey);
    return (
      actionVariables?.filter((variable) => {
        const matchesSearch = searchReg.test(variable.data.title);

        // If isOverview is true, remove comments
        const isNotCommentType = isOverview
          ? variable.variableInfo?.resolvedType !== ResolvedVariableType.Comment
          : true;

        return matchesSearch && isNotCommentType;
      }) ?? []
    );
  });

  useEffect(() => {
    if (allActionKeys.length && hoverActionKey && !isSearching) {
      setCursor(allActionKeys.indexOf(hoverActionKey));
    }

    if (!isSearching && hoveredVariable) {
      setCursor(filteredVariables.indexOf(hoveredVariable));
    }
  }, [
    allActionKeys,
    filteredVariables,
    hoverActionKey,
    hoveredVariable,
    isSearching
  ]);

  const onVariableAtomSelect = (index?: number) => {
    const list = isSearching ? filteredVariables : variablesByAction ?? [];
    const selectedDataItem = list[index ?? cursor];
    if (!selectedDataItem) {
      newError('onDataItemSelect(): selectedDataItem is undefined');
      return;
    }
    if (!onSelected) {
      newError('onDataItemSelect(): onSelected is undefined');
      return;
    }

    if (!onClose) {
      newError('onDataItemSelect(): onClose is undefined');
      return;
    }
    const variableReference: DataItemReference = {
      dataItemId: selectedDataItem.id,
      blockType: selectedDataItem.metaInfo.dnd.blockType,
      sourceId: selectedDataItem.metaInfo.source.id
    };

    onSelected(variableReference);
    onClose();
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    if (!selectedParent) {
      setIsSearching(!!searchTerm);
    }
    setSearchVar(searchTerm);
  };

  const handleActionClick = (key: string | null) => {
    setSelectedParent(key);
  };

  const resetStateMenu = () => {
    setSelectedParent(null);
    setSearchVar('');
    setCursor(0);
  };

  const onArrowPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      if (cursor === null && !isSearching) {
        setCursor(0);
        return;
      }
      if (selectedParent && variablesByAction) {
        setCursor((prevState) =>
          prevState < variablesByAction.length - 1 ? prevState + 1 : prevState
        );
      } else {
        const list = isSearching ? filteredVariables : filteredActions;
        const nextIndex = (cursor + 1) % list.length;
        setCursor(nextIndex);
      }
    } else if (event.key === 'ArrowUp') {
      if (cursor === null && !isSearching) {
        const lastIndex = allActionKeys.length - 1;
        setCursor(lastIndex);
        return;
      }
      if (selectedParent) {
        setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
      } else {
        const list = isSearching ? filteredVariables : filteredActions;
        const previousIndex = cursor === 0 ? list.length - 1 : cursor - 1;
        setCursor(previousIndex);
      }
    }
  };

  const onEnterPress = () => {
    if (selectedParent || isSearching) {
      onVariableAtomSelect();
    } else {
      setSelectedParent(filteredActions[cursor]);
    }
  };

  const onEscapePress = () => {
    if (isSearching) {
      setSearchVar('');
      setIsSearching(false);
      setCursor(0);
      return;
    }

    if (selectedParent) {
      setSelectedParent(null);
      setSearchVar('');
      setCursor(0);
      return;
    }

    onClose && onClose();
  };

  const onRightPress = (event: KeyboardEvent): void => {
    event.preventDefault();
    if (event.shiftKey || selectedParent || isSearching) return;
    setSelectedParent(filteredActions[cursor]);
  };

  const onLeftPress = (event: KeyboardEvent) => {
    event.preventDefault();
    if (selectedParent) {
      const currentIndex = filteredActions.indexOf(selectedParent);
      setCursor(currentIndex);

      if (event.key === 'Tab') {
        if (event.shiftKey) setSelectedParent(null);
        return;
      }
      setSelectedParent(null);
      setSearchVar('');
    }
  };

  const onBackspacePress = () => {
    if (selectedParent && !searchVar) {
      setSelectedParent(null);
    }
  };

  useKeyPress(['ArrowUp', 'ArrowDown'], onArrowPress);
  useKeyPress(['Enter'], onEnterPress);
  useKeyPress(['Escape'], onEscapePress);
  useKeyPress(['Backspace'], onBackspacePress);
  useKeyPress(['ArrowRight', 'Tab'], onRightPress);
  useKeyPress(['ArrowLeft', 'Tab'], onLeftPress);

  const filteredActions = allActionKeys.filter((actionKey: string) => {
    const actionVariables = allVariables.get(actionKey);

    if (actionVariables == undefined) {
      return false;
    }

    const hasSomeMatch = actionVariables.filter((variable) =>
      variable.data.title.match(searchReg)
    );
    return hasSomeMatch.length > 0;
  });

  const variablesByAction =
    !isSearching && cursor !== null
      ? allVariables
          .get(selectedParent || allActionKeys[cursor])
          ?.filter((variable) => {
            const matchesSearch = searchReg.test(variable.data.title);

            // If isOverview is true, remove comments
            const isNotCommentType = isOverview
              ? variable.variableInfo?.resolvedType !==
                ResolvedVariableType.Comment
              : true;

            return matchesSearch && isNotCommentType;
          })
      : [];

  return (
    <StateMenuRoot data-tag="statemenu">
      <SearchBar
        ref={inputRef}
        searchVar={searchVar}
        onSearch={onSearch}
        selectedAction={selectedParent}
        resetSearch={resetStateMenu}
      />
      <StateMenuBody>
        {isSearching ? (
          <SearchResult
            filteredVariables={filteredVariables || []}
            cursor={cursor}
            searchVar={searchVar}
            actionsState={allVariables}
            setHovered={setHoveredVariable}
            handleVarClick={onVariableAtomSelect}
          />
        ) : (
          <>
            <SideBar
              sideBarItemNames={allActionKeys}
              selectedSideBarItem={selectedParent}
              cursor={cursor}
              handleItemClick={handleActionClick}
              setHovered={setHoverActionKey}
            />
            <VariableMenu
              variablesByAction={variablesByAction || []}
              cursor={cursor}
              setCursor={setCursor}
              handleVarClick={onVariableAtomSelect}
              searchVar={searchVar}
              selectedAction={selectedParent}
            />
          </>
        )}
      </StateMenuBody>
      <StateMenuFooter>
        <StateMenuNavigation>
          <ShortcutHelper value={[<ArrowBigUp />, <ArrowBigDown />]} />
          Navigation
        </StateMenuNavigation>
        <StateMenuNavigation>
          <ShortcutHelper value={<CornerDownLeft />} />
          Select
        </StateMenuNavigation>
        <StateMenuNavigation>
          <ShortcutHelper value="esc" />
          Cancel
        </StateMenuNavigation>
      </StateMenuFooter>
    </StateMenuRoot>
  );
};

export default StateMenu;
