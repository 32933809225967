export enum VariableValueOrigin {
  DataRepository = 'dataRepository',
  DropDown = 'dropdown',
  User = 'user',
  state = 'state'
}
/** @deprecated */
export enum ComputedVariablesTypes {
  Text = 'Text',
  Number = 'Number',
  Date = 'Date',
  User = 'User',
  File = 'File',
  List = 'List',
  NotAVariable = 0
}
