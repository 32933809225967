import styled from 'styled-components';

export const CONTENT_PADDING = 0.5;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ProcessBlock = styled.section`
  background-color: ${(p) => p.theme.verRail.background.content};
  box-shadow: ${(p) => p.theme.verRail.boxShadow};
  border-radius: ${(p) => p.theme.verRail.borderRadius};

  box-sizing: border-box;
  overflow: hidden;
  font-size: 14px;
`;

export const Content = styled.div`
  padding: ${CONTENT_PADDING}rem;
  box-sizing: border-box;
`;

export const Footer = styled.footer`
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const SubmitButton = styled.button`
  height: 100%;
  box-sizing: border-box;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s ease;
  background-color: ${(p) => p.theme.color.indigoTailwind[700]};
  color: white;
  &:hover {
    background-color: ${(p) => p.theme.color.indigoTailwind[800]};
  }
`;

export const CancelButton = styled.div`
  height: 100%;
  box-sizing: border-box;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.2s ease;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  color: rgb(23, 26, 28);
  &:hover {
    background-color: ${(p) => p.theme.verRail.background.contentHover};
  }
`;

export const FileContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
`;
