import styled from 'styled-components';

export const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export const FileInput = styled('label')`
  height: 110px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const UploadTitle = styled('div')`
  font-size: 15px;
`;

export const UploadedTitle = styled(UploadTitle)`
  margin-top: 5px;
`;

export const UploadHelperText = styled('div')`
  font-size: 11px;
`;
