import { CustomElement } from '@pages/Settings/notifications/editor/slate';
import { z } from 'zod';

export const TextSchema: z.ZodType<CustomElement[]> = z.array(z.any()); //! bad

export const NotificationTemplateLoadedSchema = z
  .object({
    id: z.string(),
    title: z.string(),
    text: TextSchema,
    created_at: z.string(),
    updated_at: z.string(),
    process_id: z.string()
  })
  .strict();

export const NotificationTemplatesLoadedSchema = z.array(
  NotificationTemplateLoadedSchema
);

export type NotificationTemplatesLoaded = z.infer<
  typeof NotificationTemplateLoadedSchema
>;
