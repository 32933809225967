import { ReferenceModalModel } from '../models/referenceModal.model';
import RootStore from './root.store';

export default class ModalStore {
  rootStore: RootStore;

  referenceModal: ReferenceModalModel;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.referenceModal = new ReferenceModalModel(this.rootStore);
  }
}
