import { EventBusEventCallback } from 'bpmn-js/lib/BaseViewer';
import {
  BpmnEvent,
  CreateShapeEvent,
  CreateTransitionEvent,
  DeleteShapeEvent,
  StudioEvent
} from '../event.types';
import RootStore from '@/mobx/store/root.store';
import { EventBus } from 'bpmn-js/lib/features/modeling/Modeling';
import Modeler from 'bpmn-js/lib/Modeler';
import { onShapeCreated, onShapeDelete } from '../shape';
import { DeleteButtonPressed, deleteButtonPressed } from '../shape/action';
import { onTransitionCreated, onTransitionDeleted } from '../transition';
import { undoHandler } from '../undo/undo';
import { onElementsChanged } from '../xml/update';
import { ElementType, ModdleElement } from '../../bpmn.types';

export abstract class BpmnEventHandlerCreator {
  public abstract createHandler(
    modeler: Modeler,
    rootStore: RootStore
  ): BpmnEventHandler;
}

export class StratumnBpmnEventHandlerCreator extends BpmnEventHandlerCreator {
  public createHandler(
    modeler: Modeler,
    rootStore: RootStore
  ): BpmnEventHandler {
    return new StratumnBpmnEventHandler(modeler, rootStore);
  }
}

export class DraftBpmnEventHandlerCreator extends BpmnEventHandlerCreator {
  public createHandler(
    modeler: Modeler,
    rootStore: RootStore
  ): BpmnEventHandler {
    return new DraftBpmnEventHandler(modeler, rootStore);
  }
}

interface BpmnEventHandler {
  listenToEvents(): void;
}

class StratumnBpmnEventHandler implements BpmnEventHandler {
  private eventBus: EventBus;
  private modeler: Modeler;
  private rootStore: RootStore;

  constructor(receivedModeler: Modeler, rootStore: RootStore) {
    this.modeler = receivedModeler;
    this.eventBus = this.modeler.get<EventBus>('eventBus');
    this.rootStore = rootStore;
  }

  public listenToEvents(): void {
    /* ----------------------------- Shape listeners ---------------------------- */
    this.registerEvent('studio.shape.create', (event: CreateShapeEvent) => {
      onShapeCreated(event, this.rootStore);
    });
    this.registerEvent('studio.shape.delete', (event: DeleteShapeEvent) => {
      onShapeDelete(event, this.rootStore);
    });

    /* -------------------------- Transition listeners -------------------------- */
    this.registerEvent(
      'studio.connection.create',
      (event: CreateTransitionEvent) => {
        onTransitionCreated(event, this.rootStore);
      }
    );
    this.registerEvent(
      'studio.connection.delete',
      async (event: CreateTransitionEvent) => {
        await onTransitionDeleted(event, this.rootStore);
      }
    );

    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent('elements.changed', () => {
      return onElementsChanged(this.rootStore, this.modeler);
    });

    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent<ModdleElement>('element.changed', (event) => {
      // @ts-expect-error this is fine 🔥
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (event?.element?.di?.bpmnElement?.$type === ElementType.Lane) {
        this.rootStore.codeStore.lastCodeModel?.recomputePreCode();
      }
    });

    /* ----------------------------- Studio listener ---------------------------- */
    this.registerEvent('studio.undo', undoHandler);
    this.registerEvent('studio.action.delete', (event: DeleteButtonPressed) =>
      deleteButtonPressed(event, this.rootStore)
    );
  }

  private registerEvent<T>(
    eventName: BpmnEvent | StudioEvent,
    callback: EventBusEventCallback<T>
  ) {
    this.eventBus.on(eventName, callback);
  }
}

class DraftBpmnEventHandler implements BpmnEventHandler {
  private eventBus: EventBus;
  private modeler: Modeler;
  private rootStore: RootStore;

  constructor(receivedModeler: Modeler, rootStore: RootStore) {
    this.modeler = receivedModeler;
    this.eventBus = this.modeler.get<EventBus>('eventBus');
    this.rootStore = rootStore;
  }

  public listenToEvents(): void {
    /* ------------------------------ XML listener ------------------------------ */
    this.registerEvent('elements.changed', () =>
      onElementsChanged(this.rootStore, this.modeler)
    );
  }

  private registerEvent<T>(
    eventName: BpmnEvent | StudioEvent,
    callback: EventBusEventCallback<T>
  ) {
    this.eventBus.on(eventName, callback);
  }
}
