import { HTTPWrapper } from "@/utils/http";

class MetaDataServiceClass {
  private httpWrapper: HTTPWrapper<unknown>;
    constructor() {
      this.httpWrapper = new HTTPWrapper('metadata');
    }
  
  async submitFeedback(data) {
    const response = await this.httpWrapper.post(`/feedback`, data);

    if (response) {
      return response;
    }
    
    throw new Error('Error while sending feedback');
  }
}

export default MetaDataServiceClass;