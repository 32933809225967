import {
  DNDBlock,
  DNDMetaContext,
  DNDMetaContextI
} from '@components/dnd/base';
import { useContext } from 'react';
import { DNDBlockRender } from '../library';
import DropZone from './DropZone';

type InteractionZoneProps = {
  dndBlock: DNDBlock | DNDBlock[];
  path: string;
  isLast?: boolean;
};

export const InteractionZone = ({
  dndBlock,
  path,
  isLast
}: InteractionZoneProps) => {
  const library = useContext<DNDMetaContextI>(DNDMetaContext).library;

  if (Object.keys(dndBlock).length > 0) {
    return (
      <DNDBlockRender
        block={dndBlock}
        library={library}
        path={path}
        isLast={isLast}
      />
    );
  }

  const generate_path = (path: string) => {
    if (path.includes('|')) return path;
    return `${path}|0`;
  };

  return <DropZone data={{ path: generate_path(path) }} isLast={isLast} />;
};
