import { FC } from 'react';
import { DNDBlock } from '@/components/dnd/base';
import { DNDInfo } from '../library.types';
import { GlobalVariable } from './blocks/globalVariableBlock/globalVariable.comp';

export enum GlobalVariablesBlocks {
  Variable = 'GlobalVariable'
}

export const isGlobalVariablesBlocks = (
  blockType: unknown
): blockType is GlobalVariablesBlocks => {
  return Object.values(GlobalVariablesBlocks).includes(
    blockType as GlobalVariablesBlocks
  );
};

export const GLOBAL_VARIABLES_INFO_DND: DNDInfo = {
  title: 'Global Variables',
  description:
    "Define global variables that will be defined during the workflow's execution."
};

const GlobalVariableBlockPlexer = {
  [GlobalVariablesBlocks.Variable]: GlobalVariable
};

interface LibraryTraceOverviewProps {
  block: DNDBlock;
}

export const LibraryGlobalVariable: FC<LibraryTraceOverviewProps> = ({
  block
}) => {
  const Block = GlobalVariableBlockPlexer[block.type as GlobalVariablesBlocks];
  return <Block {...block} />;
};
